












































import { getCartographySiteNames, queryQualifications, resolveGroupNames, resolveTrainingModuleNames } from '../repository';
import Vue from 'vue';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import QualificationFieldsConfiguration from '../fieldsConfiguration/QualificationFieldsConfiguration';
import DisplayResolvedName from '../components/qualifications/DisplayResolvedName.vue';

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Qualifications',

  components: {
    DisplayResolvedName,
    FilteredListPage
  },

  data: () => ({
    fieldsConfiguration: QualificationFieldsConfiguration,
    query: queryQualifications,
    resolveCartographySiteNames: getCartographySiteNames,
    resolveGroupNames: resolveGroupNames,
    resolveModuleNames: resolveTrainingModuleNames
  })
});

