













































import Vue from 'vue';
import { MediationTrainingModuleItemModel, TrainingModuleItemType } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'AddTrainingModuleMediatioinElement',

  data: () => ({
    newElement: {} as MediationTrainingModuleItemModel,
    errorMessages: { title: '', description: '' },
    globalError: ''
  }),

  mounted () {
    this.newElement = { type: TrainingModuleItemType.Mediation, title: '', description: '' } as MediationTrainingModuleItemModel;
    this.titleValidator();
    this.descriptionValidator();
  },

  methods: {
    getBgColor (error: string): string {
      return error === '' ? 'bgAccent' : 'bgError';
    },
    onInsertClicked () {
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      this.$emit('insert', [this.newElement]);
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    titleValidator (): void {
      this.hideGlobalError();
      this.errorMessages.title = '';
      if (this.newElement.title !== this.newElement.title.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.title = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newElement.title.length === 0) {
        // title must be set
        this.errorMessages.title = this.$t('emptyFieldError').toString();
      }
    },
    descriptionValidator (): void {
      this.hideGlobalError();
      this.errorMessages.description = '';
      if (this.newElement.description !== this.newElement.description.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.description = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newElement.description.length === 0) {
        // title must be set
        this.errorMessages.description = this.$t('emptyFieldError').toString();
      }
    }
  }
});

