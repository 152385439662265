import { client } from './Client';
import { OrganisationInformation } from '../../_GeneratedClients/SpotClient';

/**
 * gets organization information from API
 *
 * @returns organization informations
 */
export async function getOrganizationInformation (): Promise<OrganisationInformation> {
  return await client.getOrganizationInformation();
}
