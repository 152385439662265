



















import Vue, { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';

export interface DisplayableTrainingPathInfo {
  id: string,
  title: string;
  reference: string;
  duration: string;
  moduleCounters: string;
  learner: string;
  status: string
}

export default Vue.extend({

  name: 'DisplayTrainingPathList',

  props: {
    trainingPathList: { type: Array as PropType<DisplayableTrainingPathInfo[]>, default: () => [] }
  },

  methods: {
    getTrainingPathV2Url (pathId: string): string {
      return this.$router.resolve({ name: 'ViewTrainingPath', params: { pathId: pathId } }).href;
    }
  },

  computed: {
    pathListHeaders (): DataTableHeader<DisplayableTrainingPathInfo>[] {
      return [
        { text: this.$t('trainingPath').toString(), align: 'start', sortable: true, value: 'title' },
        { text: this.$t('reference').toString(), align: 'start', sortable: true, value: 'reference' },
        { text: this.$t('duration').toString(), align: 'center', sortable: true, value: 'duration' },
        { text: this.$t('moduleCounters').toString(), align: 'center', sortable: true, value: 'moduleCounters' },
        { text: this.$t('learner').toString(), align: 'start', sortable: true, value: 'learner' },
        { text: this.$t('trainingPathStatus.status').toString(), align: 'center', sortable: true, value: 'status' }
      ];
    }
  }
});

