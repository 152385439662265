import { EntityCreatedModel, ResourceModel, ResourceParentType, ResourceType, UpdateResourceModel } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';

/**
 * Gets all resources attached to a SPOT object
 *
 * @param parentType the type of the parent (subject, function, ...)
 * @param parentId the identifier of the parent object
 * @returns the list of attached resources (can be empty)
 */
export async function getResources (parentType: ResourceParentType, parentId: string): Promise<ResourceModel[]> {
  return await client.getResources(parentType, parentId);
}

/**
 * Creates a resource
 *
 * @param parentType the type of the parent (subject, function, message, ...)
 * @param parentId the identifier of the parent object
 * @param resourceType the type of resource to be sent
 * @param name The name of the resource to be sent
 * @param link For link or youtube types : defines target url, for image, pdf or file types : stores original file name
 * @param comment (optional) The comment associated with the resource (Optional)
 * @param body (optional)
 * @returns the id of created resource
 */
export async function createResource (parentType: ResourceParentType, parentId: string, resourceType: ResourceType, name: string, link: string, comment: string | undefined, body: Blob | undefined): Promise<EntityCreatedModel> {
  return await client.createResource(parentType, parentId, resourceType, name, link, comment, body);
}

/**
 * Updates a resource
 *
 * @param parentType the type of the parent (subject, function, message, ...)
 * @param parentId the identifier of the parent object
 * @param resourceId the identifier of the resource
 * @param body the resource info to update
 */
export async function updateResource (parentType: ResourceParentType, parentId: string, resourceId: string, body: UpdateResourceModel): Promise<void> {
  await client.updateResource(parentType, parentId, resourceId, body);
}

/**
 * Reorder the resources
 *
 * @param parentType the type of the parent (subject, function, message, ...)
 * @param parentId the identifier of the parent object
 * @param resourceIds the list of resource identifiers in the expected order of display
 */
export async function reorderResources (parentType: ResourceParentType, parentId: string, resourceIds: string[]): Promise<void> {
  await client.reorderResource(parentType, parentId, resourceIds);
}

/**
 * Deletes a resource
 *
 * @param parentType the type of the parent (subject, function, ...)
 * @param parentId the identifier of the parent object
 * @param resourceId the identifier of the resource to delete
 */
export async function deleteResource (parentType: ResourceParentType, parentId: string, resourceId: string): Promise<void> {
  await client.deleteResource(parentType, parentId, resourceId);
}
