

































































































































































import Vue from 'vue';
import {
  autocompleteGroup,
  autocompleteUser,
  createTrainingPaths,
  getConnectedUser,
  getEmptyTrainingPath,
  getTrainingPathById,
  getUsersIdByGroups,
  isPermissionGranted
} from '../repository';
import {
  ApiException,
  AssignedTrainingModuleCreationOptions,
  AssignedTrainingModuleModel,
  CreateTrainingPathRequest,
  IdAndNameModel,
  UserModel
} from '../../_GeneratedClients/SpotClient';
import { Location } from 'vue-router';
import moment, { Moment } from 'moment';
import DatePicker from '../components/common/DatePicker.vue';
import DisplayTrainingPathModules from '../components/trainingPaths/DisplayTrainingPathModules.vue';
import MultiAutocomplete from '../components/common/MultiAutocomplete.vue';
import ReferencePicker from '../components/common/ReferencePicker.vue';
import eventBus from '../eventBus';
import { LocaleMessage } from 'vue-i18n';

export default Vue.extend({

  name: 'AddTrainingPath',

  components: {
    DatePicker,
    DisplayTrainingPathModules,
    MultiAutocomplete,
    ReferencePicker
  },

  data: () => ({
    me: { id: '', name: '', groups: [], permissions: [] } as UserModel,
    newPath: getEmptyTrainingPath(),
    selectedPersons: [] as IdAndNameModel[],
    selectedGroups: [] as IdAndNameModel[],
    globalError: '',
    errorMessages: { selectedPersons: '', selectedGroups: '', title: '', startDate: '', stopDate: '', modules: '', moduleDates: '' },
    learnerIdList: new Set<string>(),
    learnerIdListPromise: Promise.resolve(),
    autocompleteUser: autocompleteUser,
    autocompleteGroup: autocompleteGroup
  }),

  async created () {
    if (!(await isPermissionGranted('perm_TrainingManagement'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = connectedUser;
    }
    // search for url parameter (path id)
    const sourcePathId = this.$route.params.pathId ?? '';
    if (sourcePathId === '') {
      this.validate();
    } else {
      try {
        this.newPath = await getTrainingPathById(sourcePathId);
      } catch (err) {
        if (err instanceof ApiException && err.status === 404) {
          console.error(err);
          this.$router.push({ name: 'NotFound' });
        } else {
          eventBus.$emit('error', err, null);
        }
      }
    }
  },

  methods: {
    getBgColor (error: string): string {
      return error === '' ? 'bgAccent' : 'bgError';
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    addFailed (): void {
      this.globalError = this.$t('trainingPathDatesError').toString();
    },
    addModule (module: AssignedTrainingModuleModel): void {
      this.newPath.assignedModules.push(module);
    },
    updateModule (module: AssignedTrainingModuleModel, idx: number): void {
      this.newPath.assignedModules[idx] = { ...module };
      this.errorMessages.moduleDates = '';
    },
    removeModule (idx: number): void {
      this.newPath.assignedModules.splice(idx, 1);
    },
    upModule (idx: number): void {
      const buffer = this.newPath.assignedModules[idx];
      this.newPath.assignedModules.splice(idx, 1);
      this.newPath.assignedModules.splice(idx - 1, 0, buffer);
    },
    downModule (idx: number): void {
      const buffer = this.newPath.assignedModules[idx];
      this.newPath.assignedModules.splice(idx, 1);
      this.newPath.assignedModules.splice(idx + 1, 0, buffer);
    },
    async updateLearnerIdList (): Promise<void> {
      const learners = this.selectedGroups.length > 0 ? await getUsersIdByGroups(this.selectedGroups.map((sg) => sg.id)) : [];
      learners.push(...this.selectedPersons.map(u => u.id));
      this.learnerIdList = new Set<string>(learners);
    },
    async onSaveClicked (): Promise<void> {
      await this.learnerIdListPromise; // Wait for the users in the group to be expanded
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      // prepare creation
      const moduleList = [] as AssignedTrainingModuleCreationOptions[];
      for (const module of this.newPath.assignedModules) {
        moduleList.push({
          moduleId: module.module.id,
          referents: module.referents.map((r) => r.id),
          startDate: module.startDate,
          stopDate: module.stopDate
        });
      }
      // create the training path(s)
      try {
        const result = await createTrainingPaths({
          learnerIds: [...this.learnerIdList],
          visibleByPersons: this.newPath.visibleByPersons.map((p) => p.id),
          visibleByGroups: this.newPath.visibleByGroups.map((g) => g.id),
          title: this.newPath.title,
          reference: this.newPath.reference?.code ?? undefined,
          startDate: this.newPath.startDate,
          stopDate: this.newPath.stopDate,
          passThreshold: this.newPath.passThreshold,
          assignedModules: moduleList
        } as CreateTrainingPathRequest);
        if (result.length === 1) {
          this.$router.push({ name: 'ViewTrainingPath', params: { pathId: result[0].id } });
        } else {
          this.$router.push({ name: 'FilteredTrainingPaths' });
        }
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    validate (): void {
      this.hideGlobalError();
      this.titleValidator();
      this.modulesValidator();
    },
    targetValidator (): string | true {
      if (this.selectedPersons.length + this.selectedGroups.length === 0) {
        return this.$t('defineAtLeastOneLearnerOrGroup').toString();
      }
      if (this.learnerIdList.has(this.me.id)) {
        return this.$t('creatorCantBeLearner').toString();
      }
      if (this.newPath.assignedModules.some(m => m.referents.some(r => this.learnerIdList.has(r.id)))) {
        return this.$t('learnerCantBeReferent').toString();
      }
      return true;
    },
    titleValidator (): void {
      this.errorMessages.title = '';
      if (this.newPath.title !== this.newPath.title.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.title = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newPath.title.length === 0) {
        // name must be set
        this.errorMessages.title = this.$t('emptyFieldError').toString();
      }
    },
    modulesValidator (): void {
      this.errorMessages.modules = '';
      if (this.newPath.assignedModules.length === 0) {
        this.errorMessages.modules = 'error';
      }
      this.errorMessages.moduleDates = '';
      if (this.newPath.assignedModules.some((s) =>
        s.startDate.isBefore(this.newPath.startDate) ||
        s.startDate.isAfter(this.newPath.stopDate) ||
        s.stopDate.isBefore(this.newPath.startDate) ||
        s.stopDate.isAfter(this.newPath.stopDate) ||
        s.stopDate.isBefore(moment().startOf('day'))
      )) {
        // at least one module date is out of path range dates
        this.errorMessages.moduleDates = 'error';
      }
    }
  },

  computed: {
    pageTitle (): LocaleMessage {
      const key = this.newPath.id === '' ? 'createTrainingPath' : 'createFromTrainingPath';
      return this.$t(key);
    },
    minStopDate (): Moment {
      // The stop date must be in the future and after the start date
      const endOfToday = moment().endOf('day');
      if (this.newPath.startDate.isBefore(endOfToday)) {
        return endOfToday;
      }
      return this.newPath.startDate;
    },
    canAddOrEdit: function (): boolean {
      return this.errorMessages.startDate === '' && this.errorMessages.stopDate === '';
    },
    cancelRoute (): Location {
      return this.newPath.id === '' ? { name: 'FilteredTrainingPaths' } : { name: 'ViewTrainingPath', params: { pathId: this.newPath.id } };
    }
  },

  watch: {
    newPath: {
      // When something in the path changes, revalidate
      handler (): void {
        this.validate();
      },
      deep: true
    },
    learnerIdList: {
      handler (): void {
        this.validate();
      }
    },
    selectedPersons: {
      handler (): void {
        this.learnerIdListPromise = this.updateLearnerIdList();
      }
    },
    selectedGroups: {
      handler (): void {
        this.learnerIdListPromise = this.updateLearnerIdList();
      }
    }
  }
});

