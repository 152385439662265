<script lang="js">
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Bar } from 'vue-chartjs';
import Vue from 'vue';

export default Vue.extend({

  extends: Bar,

  props: {
    data: { type: Object, default: null },
    options: { type: Object, default: null }
  },

  mounted () {
    this.renderChart(this.data, this.options);
  },

  watch: {
    data: function () {
      this.renderChart(this.data, this.options);
    }
  }
});
</script>
