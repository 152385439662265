import { render, staticRenderFns } from "./ViewQualification.vue?vue&type=template&id=c214d60c&scoped=true&"
import script from "./ViewQualification.vue?vue&type=script&lang=ts&"
export * from "./ViewQualification.vue?vue&type=script&lang=ts&"
import style0 from "./ViewQualification.vue?vue&type=style&index=0&id=c214d60c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c214d60c",
  null
  
)

/* custom blocks */
import block0 from "./ViewQualification.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCol,VLayout,VProgressCircular,VRadio,VRadioGroup,VRow,VSheet})
