import {
  CreateOrUpdateQualificationRequest,
  EntityCreatedModel,
  QualificationModel,
  QueryRequest,
  // eslint-disable-next-line camelcase
  QueryResponse_QualificationModel_,
  // eslint-disable-next-line camelcase
  QueryResponse_UserQualificationModel_,
  UserQualificationModel,
  UserQualificationModuleModel
} from '../../_GeneratedClients/SpotClient';
import { client } from './Client';
import { Location } from 'vue-router';
import { ChronoUnit, LocalDate } from '@js-joda/core';

export const NeverValidated = -10000;
export const ValidatedForever = 10000;

export interface QualificationLegend { good: boolean; warn: boolean; none: boolean; }

export interface QualificationDashboardPrimaryInfo {
  id: string;
  name: string;
  total: number;
  obtained: number;
  qualificationState: keyof QualificationLegend | 'neutral';
  link: Location;
}

export interface QualificationDashboardSecondaryInfo {
  id: string;
  name: string;
  qualificationState: keyof QualificationLegend;
  expirationDate: string;
  remainingDays: number;
  obtainedlinkId: string;
  progressLinkId: string;
  userLinkId: string;
}

export interface QualificationDashboardGroup {
  item: QualificationDashboardPrimaryInfo;
  children: QualificationDashboardSecondaryInfo[];
}

export interface ExpirationState {
  qualificationState: keyof QualificationLegend;
  expirationDate: string;
  remainingDays: number;
}

/**
 * @returns an empty qualification object
 */
export function getEmptyQualification (): QualificationModel {
  return { id: '', name: '', cartographies: [], qualifiableGroups: [], modules: [], discussionId: '' };
}

/**
 * gets qualification list from api, with query filter(s)
 *
 * @param query query parameters (filters)
 * @returns a promise, the list of qualifications according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryQualifications (query: QueryRequest): Promise<QueryResponse_QualificationModel_> {
  const qualifications = await client.queryQualifications(query);
  return {
    results: qualifications.results,
    totalItems: qualifications.totalItems
  };
}

/**
 * Creates a qualification
 *
 * @param body qualification informations
 * @returns the identifier of created object
 */
export async function createQualification (body: CreateOrUpdateQualificationRequest): Promise<EntityCreatedModel> {
  return await client.createQualification(body);
}

/**
 * Gets a qualification from it's identifier
 *
 * @param qualificationId the identifier of the qualification to find
 * @returns the found qualification
 */
export async function getQualificationById (qualificationId: string): Promise<QualificationModel> {
  return await client.getQualificationById(qualificationId);
}

/**
 * Updates qualification information
 *
 * @param qualificationId the identifier of the qualification to update
 * @param body the new qualification information
 */
export async function updateQualification (qualificationId: string, body: CreateOrUpdateQualificationRequest): Promise<void> {
  await client.updateQualification(qualificationId, body);
}

/**
 * Deletes a registered qualification
 *
 * @param qualificationId the identifier of the qualification to delete
 */
export async function deleteQualification (qualificationId: string): Promise<void> {
  await client.deleteQualification(qualificationId);
}

/**
 * gets qualification-user info from api, with query filter(s)
 *
 * @param query query parameters (filters)
 * @returns a promise, the list of qualifications-users according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryUserQualifications (query: QueryRequest): Promise<QueryResponse_UserQualificationModel_> {
  const qualifications = await client.queryUserQualifications(query);
  return {
    results: qualifications.results,
    totalItems: qualifications.totalItems
  };
}

/**
 * Gets initialized qualification legend object
 *
 * @returns the initialized object
 */
export function getInitialQualificationLegend (): QualificationLegend {
  return { good: true, warn: true, none: true };
}

/**
 * gets background color name for qualification legend and tiles according their state
 *
 * @param state the current state of object
 * @returns background color name.
 */
export function getQualificationLegendColor (state: string): string {
  switch (state) {
    case 'good': return 'bgSuccess';
    case 'warn': return 'bgError';
    case 'none': return 'bgDisabled';
    default: return 'white';
  }
}

/**
 * Computes the expiration state of a qualification module
 *
 * @param module The module to check
 * @returns the expiration state of the module
 */
export function getUserQualificationModuleState (module: UserQualificationModuleModel): ExpirationState {
  return getExpirationStateFromDate(module.validUntil === undefined ? undefined : (module.validUntil === '' ? LocalDate.MAX : LocalDate.parse(module.validUntil)));
}

/**
 * Computes the expiration state of a qualification for a user
 *
 * @param qualification The qualification to check
 * @returns the expiration state of the user qualification
 */
export function getUserQualificationState (qualification: UserQualificationModel): ExpirationState {
  if (qualification.modules.length === 0) {
    return getExpirationStateFromDate(undefined);
  }

  // Aggregate the modules to get the minimal expiration date for this qualification
  const validUntil = qualification.modules.reduce((previous, current) => {
    if (current.validUntil === undefined || previous === undefined) {
      return undefined;// One of the module is undefined (never validated) the qualification is never validated
    }

    if (current.validUntil === '') {
      // This module will never expire, keep the current date limit as it is.
      return previous;
    }

    const currentDate = LocalDate.parse(current.validUntil);
    return currentDate.isBefore(previous) ? currentDate : previous;
  }, LocalDate.MAX as LocalDate | undefined);
  return getExpirationStateFromDate(validUntil);
}

/**
 * Given a date, returns an expiration state for the expiration date given as an argument
 *
 * @param expirationDate The expiration date. undefined means never validated, LocalDate.MAX means validated forever, otherwise it's the expiration date
 * @returns The expiration state
 */
function getExpirationStateFromDate (expirationDate: LocalDate|undefined): ExpirationState {
  if (expirationDate === undefined) {
    return { qualificationState: 'none', expirationDate: '', remainingDays: NeverValidated };
  }

  if (expirationDate === LocalDate.MAX) {
    // module is permanent
    return { qualificationState: 'good', expirationDate: '', remainingDays: ValidatedForever };
  }

  // module has an expiration date
  // assume that module.validuntil has the expected format date and contains a valid date
  const remainingDays = ChronoUnit.DAYS.between(LocalDate.now(), expirationDate);
  if (remainingDays >= 0) {
    // module is still valid
    return { qualificationState: remainingDays > 30 ? 'good' : 'warn', expirationDate: expirationDate.toJSON(), remainingDays: remainingDays };
  } else {
    // module is expired
    return { qualificationState: 'none', expirationDate: expirationDate.toJSON(), remainingDays: remainingDays };
  }
}

/**
 * Applies the legend filter to a qualification dashboard
 *
 * @param qualifications The qualifications to filter
 * @param filter The filter to apply
 * @returns The new list of the qualifications
 */
export function filterQualificationDashboard (qualifications: QualificationDashboardGroup[], filter: QualificationLegend): QualificationDashboardGroup[] {
  return qualifications.map(q => ({
    item: q.item,
    children: q.children.filter(c => filter[c.qualificationState] === true)
  }));
}
