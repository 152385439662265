










import Vue from 'vue';

export default Vue.extend({

  name: 'DisplayValue',

  /**
   * Displays in one line label and value, eventually labelAfter and valueAfter if set
   * labels are displayed with normal style, and values with highlight style
   * default colors are used if labelColor and valueColor are not set
   * */
  props: {
    // label to display (normal text)
    label: { type: String, default: '???' },
    // value to display (highlight text)
    value: { type: String, default: '???' },
    // extra label
    labelAfter: { type: String, default: '' },
    // extra value
    valueAfter: { type: String, default: '' },
    // label color
    labelColor: { type: String, default: 'secondary--text' },
    // value color
    valueColor: { type: String, default: 'accent--text' }
  },

  computed: {
    separator (): string {
      return this.label === '' ? '' : ' : ';
    }
  }
});

