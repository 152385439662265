













import Vue from 'vue';
import { getEmptyTrainingModule, getTrainingModuleById, getTrainingModuleUsageStats, isPermissionGranted, updateTrainingModule } from '../repository';
import AddOrEditTrainingModule from '../components/trainingPathModules/AddOrEditTrainingModule.vue';
import { CreateOrUpdateTrainingModuleRequest } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'EditTrainingModule',

  components: {
    AddOrEditTrainingModule
  },

  data: () => ({
    moduleToEdit: getEmptyTrainingModule(),
    moduleId: '',
    canEdit: false
  }),

  async created () {
    if (await isPermissionGranted('perm_TrainingManagement')) {
      this.moduleId = this.$route.params.moduleId;
      try {
        const module = await getTrainingModuleById(this.moduleId);
        this.moduleToEdit = { ...module };
        const moduleStats = await getTrainingModuleUsageStats(this.moduleId);
        this.canEdit = !moduleStats.hasAcceptedTrainingPath; // manager can edit only if module don't appear in an accepted (or started) training path
      } catch (err) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      }
    } else {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  },

  methods: {
    returnToView (): void {
      this.$router.push({ name: 'ViewTrainingModule', params: { moduleId: this.moduleId } });
    },
    async onSaveClicked (toSave: CreateOrUpdateTrainingModuleRequest): Promise<void> {
      try {
        await updateTrainingModule(this.moduleToEdit.id, toSave);
        this.returnToView();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

