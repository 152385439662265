import { autocompleteUser, FilterableSubjectModel, resolveSpotItemNames, resolveUserNames } from '../repository';
import { FieldType, FrontendAndQueryFieldConfiguration } from '../queryEngine';
import i18n from '../i18n';
import { nativeJs, LocalDate } from '@js-joda/core';
import { ContextItemType } from '../../_GeneratedClients/SpotClient';

export default {
  location: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.parent.location').toString(),
    getter: s => [...s.ancestors],
    resolveNames: resolveSpotItemNames,
    isLocation: true
  },
  name: {
    type: FieldType.string,
    displayName: i18n.t('subject.filter.content.name').toString(),
    getter: s => [s.name],
    sortable: true
  },
  activity: {
    type: FieldType.string,
    displayName: i18n.t('subject.filter.content.activity').toString(),
    getter: s => [...s.contexts.flatMap(ctx => ctx.hierarchy.filter(h => h.type === ContextItemType.Activity).map(h => h.name))]
  },
  function: {
    type: FieldType.string,
    displayName: i18n.t('subject.filter.content.function').toString(),
    getter: s => [...s.contexts.flatMap(ctx => ctx.hierarchy.filter(h => h.type === ContextItemType.Function).map(h => h.name))],
    sortable: true,
    filterable: false
  },
  node_status: {
    type: FieldType.string,
    displayName: i18n.t('subject.filter.content.nodeStatus').toString(),
    getter: s => [...s.mindMapItems.filter(m => m.status !== undefined).map(n => n.status)]
  },
  contributors: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.contributors').toString(),
    getter: s => s.contributors,
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  created_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.created').toString(),
    getter: s => (s.lifetimeEvents.creation) ? [s.lifetimeEvents.creation.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  created_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.created').toString(),
    getter: s => (s.lifetimeEvents.creation) ? [LocalDate.from(nativeJs(s.lifetimeEvents.creation.date.toDate()))] : [],
    sortable: true
  },
  updated_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.updated').toString(),
    getter: s => (s.lifetimeEvents.lastUpdate) ? [s.lifetimeEvents.lastUpdate.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  updated_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.updated').toString(),
    getter: s => (s.lifetimeEvents.lastUpdate) ? [LocalDate.from(nativeJs(s.lifetimeEvents.lastUpdate.date.toDate()))] : [],
    sortable: true
  },
  followed: {
    type: FieldType.boolean,
    displayName: i18n.t('subject.filter.status.followed').toString(),
    getter: s => [s.lifetimeEvents.followed !== undefined],
    sortable: true
  },
  followed_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.followed').toString(),
    getter: s => (s.lifetimeEvents.followed) ? [s.lifetimeEvents.followed.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  followed_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.followed').toString(),
    getter: s => (s.lifetimeEvents.followed) ? [LocalDate.from(nativeJs(s.lifetimeEvents.followed.date.toDate()))] : [],
    sortable: true
  },
  resolved: {
    type: FieldType.boolean,
    displayName: i18n.t('subject.filter.status.resolved').toString(),
    getter: s => [s.lifetimeEvents.resolved !== undefined],
    sortable: true
  },
  resolved_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.resolved').toString(),
    getter: s => (s.lifetimeEvents.resolved) ? [s.lifetimeEvents.resolved.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  resolved_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.resolved').toString(),
    getter: s => (s.lifetimeEvents.resolved) ? [LocalDate.from(nativeJs(s.lifetimeEvents.resolved.date.toDate()))] : [],
    sortable: true
  },
  criticity: {
    type: FieldType.boolean,
    displayName: i18n.t('subject.filter.status.criticity').toString(),
    getter: s => [s.lifetimeEvents.criticity !== undefined],
    sortable: true
  },
  criticity_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.criticity').toString(),
    getter: s => (s.lifetimeEvents.criticity) ? [s.lifetimeEvents.criticity.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  criticity_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.criticity').toString(),
    getter: s => (s.lifetimeEvents.criticity) ? [LocalDate.from(nativeJs(s.lifetimeEvents.criticity.date.toDate()))] : [],
    sortable: true
  },
  standardized: {
    type: FieldType.boolean,
    displayName: i18n.t('subject.filter.status.standardized').toString(),
    getter: s => [s.lifetimeEvents.standardized !== undefined],
    sortable: true
  },
  standardized_by: {
    type: FieldType.id,
    displayName: i18n.t('subject.filter.author.standardized').toString(),
    getter: s => (s.lifetimeEvents.standardized) ? [s.lifetimeEvents.standardized.userId] : [],
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  standardized_date: {
    type: FieldType.date,
    displayName: i18n.t('subject.filter.date.standardized').toString(),
    getter: s => (s.lifetimeEvents.standardized) ? [LocalDate.from(nativeJs(s.lifetimeEvents.standardized.date.toDate()))] : [],
    sortable: true
  }
} as Record<string, FrontendAndQueryFieldConfiguration<FilterableSubjectModel>>;
