




























import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';
import { Debouncer } from '../../Tools';

export default Vue.extend({
  name: 'Autocomplete',
  data () {
    return {
      search: '' as string | null,
      items: [] as IdAndNameModel[],
      isLoading: false,
      searchDebouncer: new Debouncer(500, (this as unknown as { performSearch: (search:string) => Promise<void> }).performSearch)
    };
  },
  props: {
    value: { type: Object as PropType<IdAndNameModel | null>, default: null },
    autocomplete: { type: Function as PropType<(search:string) => Promise<IdAndNameModel[]>>, required: true },
    label: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array as PropType<(((newValue: IdAndNameModel | null) => boolean | string) | boolean | string)[]>, default: () => [] },
    dense: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    clearable: { type: Boolean, default: false }
  },
  methods: {
    async performSearch (searchedValue: string) {
      const receivedValues = await this.autocomplete(searchedValue);
      const currentValue = this.value;
      if (currentValue !== null && !receivedValues.some(r => r.id === currentValue.id)) {
        // At the end, add the selected value if it is not in the list
        receivedValues.push(currentValue);
      }
      this.items = receivedValues;
      this.isLoading = false;
    }
  },
  watch: {
    search: {
      immediate: true,
      handler (searchedValue: string | null) {
        this.isLoading = true;
        this.searchDebouncer.run(searchedValue ?? '');
      }
    },
    autocomplete: {
      immediate: true,
      handler () {
        this.isLoading = true;
        this.searchDebouncer.run(this.search ?? '');
      }
    },
    displayedError: {
      immediate: true,
      handler (newError: string) {
        this.$emit('error', newError !== '');
      }
    }
  },
  computed: {
    displayedError () : string {
      if (this.required && this.value === null) {
        return this.$t('mustNotBeEmpty').toString();
      }

      for (const rule of this.rules) {
        let ruleResult = rule;
        if (typeof rule === 'function') {
          ruleResult = rule(this.value);
        }
        if (ruleResult === false) {
          return this.$t('invalidValue').toString();
        } else if (typeof ruleResult === 'string') {
          return ruleResult;
        }
      }

      return '';
    },
    backgroundColor () : string {
      if (this.readOnly === true) {
        return 'bgDisabled';
      }
      if (this.displayedError !== '') {
        return 'bgError';
      }
      return 'bgAccent';
    }
  }
});
