

















import Vue from 'vue';
import { isPermissionGranted } from '../repository';
import AddOrEditQualification from '../components/qualifications/AddOrEditQualification.vue';

export default Vue.extend({

  name: 'NewQualification',

  components: {
    AddOrEditQualification
  },

  async created () {
    if (!(await isPermissionGranted('perm_TableauBord'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  }
});

