/**
 * The string comparison operators
 */
export enum StringComparisonOperator {
  contains = 'contains',
  startsWith = 'startsWith',
  endsWith = 'endsWith',
  equals = 'equals',
  different = 'different'
}

/**
 * The date comparison operators
 */
export enum DateComparisonOperator {
  sameDay = 'sameDay',
  before = 'before',
  beforeOrSameDay = 'beforeOrSameDay',
  after = 'after',
  afterOrSameDay = 'afterOrSameDay'
  // between ?
}

/**
 * The boolean comparison operators
 */
export enum BooleanComparisonOperator {
  equals = 'equals',
}

/**
 * The enum comparison operators
 */
export enum EnumComparisonOperator {
  oneOf = 'oneOf',
  noneOf = 'noneOf',
}
