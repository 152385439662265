









import Vue from 'vue';
import { logout } from '../repository';

export default Vue.extend({
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Logout',
  async mounted () {
    await logout();
    this.$router.push({ name: 'Login', params: { returnUrl: '/spotv2/' } });
  }
});
