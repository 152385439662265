





























import Vue, { PropType } from 'vue';
import { ResourceModel, ResourceType } from '../../../../_GeneratedClients/SpotClient';
import { backendUrl } from '../../../Tools';

export default Vue.extend({

  name: 'DisplayCarouselResource',

  props: {
    resource: { type: Object as PropType<ResourceModel>, required: true },
    parentType: { type: String, required: true },
    parentId: { type: String, required: true },
    displayed: { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },

  computed: {
    resourceLink (): string {
      switch (this.resource.resourceType) {
        case ResourceType.Image:
        case ResourceType.Pdf:
        case ResourceType.File: return backendUrl + '/resource/' + this.parentType + '/' + this.parentId + '/' + this.resource.id + '/file';
        case ResourceType.Link: return this.resource.link;
        case ResourceType.Youtube: return 'https://www.youtube.com/embed/' + this.resource.link + '?autoplay=0&origin=*';
        default: return '';
      }
    }
  }
});

