













import { getQualificationLegendColor, QualificationDashboardPrimaryInfo } from '../../repository';
import Vue, { PropType } from 'vue';

export default Vue.extend({

  name: 'DisplayPrimaryQualificationElement',

  props: {
    item: { type: Object as PropType<QualificationDashboardPrimaryInfo>, required: true }
  },

  computed: {
    legendColor (): string {
      return getQualificationLegendColor(this.item.qualificationState);
    },
    valid (): string {
      let percent = 0;
      if (this.item.total > 0) {
        percent = Math.round(this.item.obtained / this.item.total * 100);
      }
      return this.$t('valid', [percent]).toString();
    }
  }
});

