



























import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';
import { Debouncer } from '../../Tools';

export default Vue.extend({
  name: 'MultiAutocomplete',
  data () {
    return {
      search: '' as string | null,
      items: [] as IdAndNameModel[],
      isLoading: false,
      searchDebouncer: new Debouncer(500, (this as unknown as { performSearch: (search:string) => Promise<void> }).performSearch)
    };
  },
  props: {
    value: { type: Array as PropType<IdAndNameModel[]>, required: true },
    autocomplete: { type: Function as PropType<(search:string) => Promise<IdAndNameModel[]>>, required: true },
    label: { type: String, default: '' },
    readOnly: { type: Boolean, default: false },
    required: { type: Boolean, default: false },
    rules: { type: Array as PropType<(((newValue: IdAndNameModel[]) => boolean | string) | boolean | string)[]>, default: () => [] },
    dense: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false }
  },
  methods: {
    async performSearch (searchedValue: string) {
      const receivedValues = await this.autocomplete(searchedValue);
      if (this.value.length > 0) {
        // At the end, add the selected values that are not in the list
        const receivedIds = new Set(receivedValues.map(v => v.id));
        receivedValues.push(...this.value.filter(existing => !receivedIds.has(existing.id)));
      }
      this.items = receivedValues;
      this.isLoading = false;
    },
    onInput (newValue: IdAndNameModel[]) {
      this.$emit('input', newValue);
    }
  },
  watch: {
    search: {
      immediate: true,
      handler (searchedValue: string | null) {
        this.isLoading = true;
        this.searchDebouncer.run(searchedValue ?? '');
      }
    },
    autocomplete: {
      immediate: true,
      handler () {
        this.isLoading = true;
        this.searchDebouncer.run(this.search ?? '');
      }
    },
    displayedError: {
      immediate: true,
      handler (newError: string) {
        this.$emit('error', newError !== '');
      }
    }
  },
  computed: {
    displayedError () : string {
      if (this.required && this.value.length === 0) {
        return this.$t('mustNotBeEmpty').toString();
      }

      for (const rule of this.rules) {
        let ruleResult = rule;
        if (typeof rule === 'function') {
          ruleResult = rule(this.value);
        }
        if (ruleResult === false) {
          return this.$t('invalidValue').toString();
        } else if (typeof ruleResult === 'string') {
          return ruleResult;
        }
      }

      return '';
    },
    backgroundColor () : string {
      if (this.readOnly === true) {
        return 'bgDisabled';
      }
      if (this.displayedError !== '') {
        return 'bgError';
      }
      return 'bgAccent';
    }
  }
});
