import i18n from '../i18n';
import { GroupPermission } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';

/**
 * Asks API to return the list of groups, with affected permissions.
 *
 * @returns The group list with groupId, groupName and the list of affected permissions
 */
export async function getAllPermissions (): Promise<GroupPermission[]> {
  return client.getAllPermissions();
}

/**
 * gets the translated permission name
 *
 * @param permission the permission identifier
 * @returns the translated permission name
 */
export function resolvePermissionName (permission: string): string {
  return i18n.t('permissionLabel.' + permission).toString();
}

/**
 * Asks API to return the list of permissions that can be assigned to a groups
 *
 * @returns the list of existing permissions
 */
export async function getGroupPermissionList (): Promise<string[]> {
  return await client.getGroupPermissionsList();
}

/**
 * Set new permissions on the specified group
 *
 * @param groupId The group identifier for which to set the permissions
 * @param permissions The permissions to set
 */
export async function setGroupPermissions (groupId: string, permissions: string[]): Promise<void> {
  client.setGroupPermissions(groupId, permissions);
}
