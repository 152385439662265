






















































import { getResources, isPermissionGranted, reorderResources } from '../../../repository';
import Vue from 'vue';
import { ResourceModel, ResourceParentType } from '../../../../_GeneratedClients/SpotClient';
import AddOrEditResource from './AddOrEditResource.vue';
import DisplayThumbnailResource from './DisplayThumbnailResource.vue';
import ResourceCarousel from './ResourceCarousel.vue';
import ReorderResource from './ReorderResource.vue';

export default Vue.extend({

  name: 'ResourceGallery',

  components: {
    AddOrEditResource,
    DisplayThumbnailResource,
    ReorderResource,
    ResourceCarousel
  },

  props: {
    parentType: { type: String, default: '' },
    parentId: { type: String, default: '' }
  },

  data: () => ({
    canEditResources: false,
    resources: [] as ResourceModel[],
    loaded: false,
    showAddOrEditResourceForm: false,
    showReorderResourceForm: false,
    selectedResource: undefined as ResourceModel | undefined,
    selectedIndex: 0,
    showCarousel: false
  }),

  async created () {
    this.canEditResources = await isPermissionGranted('perm_EditResourcesVideos');
    await this.getResources();
  },

  methods: {
    async getResources (): Promise<void> {
      if (this.parentType === '' || this.parentId === '' || this.loaded) {
        return;
      }
      this.resources = await getResources(this.parentType as ResourceParentType, this.parentId);
      this.loaded = true;
    },
    async reloadResources (index: number): Promise<void> {
      this.showAddOrEditResourceForm = false;
      this.loaded = false;
      await this.getResources();
      this.selectedIndex = index;
      if (this.resources.length === 0) {
        this.showCarousel = false;
      }
      this.$emit('resources-reloaded');
    },
    displayCarousel (index: number): void {
      this.selectedIndex = index;
      this.showCarousel = true;
    },
    addResource (): void {
      this.selectedResource = undefined;
      this.showAddOrEditResourceForm = true;
    },
    async reorder (resourceIds: string[]): Promise<void> {
      this.showReorderResourceForm = false;
      await reorderResources(this.parentType as ResourceParentType, this.parentId, resourceIds);
      this.loaded = false;
      await this.getResources();
    }
  },

  watch: {
    parentType: async function () {
      await this.getResources();
    },
    parentId: async function () {
      await this.getResources();
    }
  }
});

