import i18n from '../i18n';
import { FieldType } from '../queryEngine';
import { FrontendFieldConfiguration } from '../queryEngine/frontendFieldConfiguration';
import { autocompleteReference, autocompleteUser, resolveUserNames } from '../repository';
import { TrainingModuleItemType, TrainingModuleType, TrainingReferenceType } from '../../_GeneratedClients/SpotClient';

// Must be kept in sync with the backend
export default {
  enabled: {
    type: FieldType.boolean,
    displayName: i18n.t('module.filter.status.enabled').toString(),
    sortable: true
  },
  name: {
    type: FieldType.string,
    displayName: i18n.t('module.filter.content.name').toString(),
    sortable: true
  },
  module_type: {
    type: FieldType.enum,
    displayName: i18n.t('module.filter.content.type').toString(),
    enumType: TrainingModuleType,
    frontendEnumValues: Object.values(TrainingModuleType).map(type => ({ id: type, name: i18n.t('module.type.' + type).toString() })),
    sortable: true
  },
  goals: {
    type: FieldType.string,
    displayName: i18n.t('module.filter.content.goals').toString()
  },
  reference: {
    type: FieldType.id,
    displayName: i18n.t('module.filter.content.reference').toString(),
    autocomplete: (search: string) => autocompleteReference(TrainingReferenceType.Module, search),
    resolveNames: (ids) => Promise.resolve(Object.fromEntries(ids.map(i => [i, i])))
  },
  creator: {
    type: FieldType.id,
    displayName: i18n.t('module.filter.person.creator').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  creation_date: {
    type: FieldType.date,
    displayName: i18n.t('module.filter.date.created').toString(),
    sortable: true
  },
  item_type: {
    type: FieldType.enum,
    displayName: i18n.t('module.filter.content.itemType').toString(),
    enumType: TrainingModuleItemType,
    frontendEnumValues: Object.values(TrainingModuleItemType).map(type => ({ id: type, name: i18n.t('module.elementType.' + type).toString() }))
  }
} as Record<string, FrontendFieldConfiguration>;
