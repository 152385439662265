import { render, staticRenderFns } from "./DisplayThumbnailResource.vue?vue&type=template&id=bd37603e&scoped=true&"
import script from "./DisplayThumbnailResource.vue?vue&type=script&lang=ts&"
export * from "./DisplayThumbnailResource.vue?vue&type=script&lang=ts&"
import style0 from "./DisplayThumbnailResource.vue?vue&type=style&index=0&id=bd37603e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd37603e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VImg,VProgressCircular,VRow,VSheet})
