







































































































































import Vue from 'vue';
import {
  AssignedTrainingModuleModel,
  AssignedTrainingModuleCreationOptions,
  UpdateTrainingPathRequest,
  IdAndNameModel,
  UserModel,
  TrainingPathStatus
} from '../../_GeneratedClients/SpotClient';
import {
  autocompleteGroup,
  autocompleteUser,
  getConnectedUser,
  getEmptyTrainingPath,
  getTrainingPathById,
  updateTrainingPath
} from '../repository';
import DisplayTrainingPathModules from '../components/trainingPaths/DisplayTrainingPathModules.vue';
import DatePicker from '../components/common/DatePicker.vue';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import eventBus from '../eventBus';
import moment, { Moment } from 'moment';
import MultiAutocomplete from '../components/common/MultiAutocomplete.vue';
import ReferencePicker from '../components/common/ReferencePicker.vue';

export default Vue.extend({

  name: 'EditTrainingPath',

  components: {
    DisplayTrainingPathModules,
    DatePicker,
    DisplayValue,
    DisplayLifetimeEvent,
    MultiAutocomplete,
    ReferencePicker
  },

  data: () => ({
    me: { id: '', name: '', groups: [], permissions: [] } as UserModel,
    pathToEdit: getEmptyTrainingPath(),
    globalError: '',
    errorMessages: { title: '', startDate: '', stopDate: '', modules: '', moduleDates: '' },
    visibleByPersonsSelection: [] as IdAndNameModel[],
    visibleByGroupsSelection: [] as IdAndNameModel[],
    autocompleteUser: autocompleteUser,
    autocompleteGroup: autocompleteGroup
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = connectedUser;
    }
    const pathId = this.$route.params.pathId;
    try {
      this.pathToEdit = await getTrainingPathById(pathId);
      this.visibleByPersonsSelection = [...this.pathToEdit.visibleByPersons];
      this.visibleByGroupsSelection = [...this.pathToEdit.visibleByGroups];
    } catch (err) {
      console.error(err);
      this.$router.push({ name: 'NotFound' });
    }
    if (this.me.id !== this.pathToEdit.creation.userId && !this.me.permissions.includes('perm_TrainingPathAdmin')) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  },

  methods: {
    getBgColor (error: string, readonly = false): string {
      if (readonly === true) {
        return 'bgDisabled';
      }
      return error === '' ? 'bgAccent' : 'bgError';
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    addFailed (): void {
      this.globalError = this.$t('trainingPathDatesError').toString();
    },
    addModule (module: AssignedTrainingModuleModel): void {
      this.pathToEdit.assignedModules.push(module);
    },
    updateModule (module: AssignedTrainingModuleModel, idx: number): void {
      this.pathToEdit.assignedModules[idx] = { ...module };
      this.errorMessages.moduleDates = '';
    },
    removeModule (idx: number): void {
      this.hideGlobalError();
      this.pathToEdit.assignedModules.splice(idx, 1);
    },
    upModule (idx: number): void {
      this.hideGlobalError();
      const buffer = this.pathToEdit.assignedModules[idx];
      this.pathToEdit.assignedModules.splice(idx, 1);
      this.pathToEdit.assignedModules.splice(idx - 1, 0, buffer);
    },
    downModule (idx: number): void {
      this.hideGlobalError();
      const buffer = this.pathToEdit.assignedModules[idx];
      this.pathToEdit.assignedModules.splice(idx, 1);
      this.pathToEdit.assignedModules.splice(idx + 1, 0, buffer);
    },
    titleValidator (): void {
      this.errorMessages.title = '';
      if (this.pathToEdit.title !== this.pathToEdit.title.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.title = this.$t('beginEndWhitespaceError').toString();
      } else if (this.pathToEdit.title.length === 0) {
        // name must be set
        this.errorMessages.title = this.$t('emptyFieldError').toString();
      }
    },
    modulesValidator (): void {
      this.errorMessages.modules = '';
      if (this.pathToEdit.assignedModules.length === 0) {
        this.errorMessages.modules = 'error';
      }
      this.errorMessages.moduleDates = '';
      if (this.pathToEdit.assignedModules.some((s) =>
        s.startDate.isBefore(this.pathToEdit.startDate) ||
        s.startDate.isAfter(this.pathToEdit.stopDate) ||
        s.stopDate.isBefore(this.pathToEdit.startDate) ||
        s.stopDate.isAfter(this.pathToEdit.stopDate) ||
        (s.stopDate.isBefore(moment().startOf('day')) && this.pathToEdit.status === TrainingPathStatus.NotAccepted)
      )) {
        // at least one module date is out of path range dates
        this.errorMessages.moduleDates = 'error';
      }
    },
    validate (): void {
      this.hideGlobalError();
      this.titleValidator();
      this.modulesValidator();
    },
    async onSaveClicked (): Promise<void> {
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      try {
        const toUpdate: UpdateTrainingPathRequest = {
          title: this.pathToEdit.title,
          reference: this.pathToEdit.reference?.code ?? undefined,
          startDate: this.pathToEdit.startDate,
          stopDate: this.pathToEdit.stopDate,
          passThreshold: this.pathToEdit.passThreshold,
          assignedModules: [],
          visibleByPersons: [...this.visibleByPersonsSelection.map((p) => p.id)],
          visibleByGroups: [...this.visibleByGroupsSelection.map((g) => g.id)]
        };
        for (const module of this.pathToEdit.assignedModules) {
          const moduleUpdate: AssignedTrainingModuleCreationOptions = {
            moduleId: module.module.id,
            referents: module.referents.map((r) => r.id),
            startDate: module.startDate,
            stopDate: module.stopDate
          };
          toUpdate.assignedModules.push(moduleUpdate);
        }
        await updateTrainingPath(this.pathToEdit.id, toUpdate);
        this.$router.push({ name: 'ViewTrainingPath', params: { pathId: this.pathToEdit.id } });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  },

  computed: {
    minStopDate (): Moment | null {
      if (this.pathToEdit.status !== 'notAccepted') {
        return null;
      }
      // The stop date must be in the future and after the start date
      const endOfToday = moment().endOf('day');
      if (this.pathToEdit.startDate.isBefore(endOfToday)) {
        return endOfToday;
      }
      return this.pathToEdit.startDate;
    }
  },

  watch: {
    // When something in the path changes, revalidate
    pathToEdit: {
      handler (): void {
        this.validate();
      },
      deep: true
    },
    learnerIdList: {
      handler (): void {
        this.validate();
      }
    }
  }
});

