



















import Vue, { PropType } from 'vue';

export default Vue.extend({

  name: 'DisplayConfirmationDialogBox',

  props: {
    /** the width (in dots) of the component */
    width: { type: Number, default: 350 },
    /** dialog box is shown if value is true */
    showDialogBox: { type: Boolean, default: false },
    /** the title */
    boxTitle: { type: String, default: '???' },
    /** can be used to define displaying style of simple text (for slot part of component) */
    presentation: { type: String, default: '' },
    cancelButtonMessage: { type: String, default: '???' },
    cancelButtonColor: { type: String, default: 'primary' },
    acceptButtonMessage: { type: String, default: '???' },
    acceptButtonColor: { type: String, default: 'warning' },
    acceptMethod: { type: Function as PropType<() => Promise<void>>, required: true }
  },

  data: () => ({
    acceptDisabled: false
  }),

  methods: {
    async onAccept (): Promise<void> {
      this.acceptDisabled = true;
      try {
        await this.acceptMethod();
      } finally {
        this.acceptDisabled = false;
      }
    }
  }
});

