






















import Vue from 'vue';
import { IdAndNameModel, MediationModel } from '../../_GeneratedClients/SpotClient';
import { getEmptyMediation, createMediation, getConnectedUser } from '../repository';
import AddOrEditMediation from '../components/mediations/AddOrEditMediation.vue';
import moment from 'moment';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'NewMediation',

  components: {
    AddOrEditMediation
  },

  data: () => ({
    mediationToEdit: getEmptyMediation(),
    me: { id: '', name: '' } as IdAndNameModel
  }),

  created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = { id: connectedUser.id, name: connectedUser.name };
    }
    this.mediationToEdit.creator = { ...this.me };
    this.mediationToEdit.mediator = { ...this.me };
    this.mediationToEdit.schedulingDate = moment().startOf('day'); // current day, 00:00:00
  },

  methods: {
    gotoMediationList () {
      this.$router.push({ name: 'FilteredMediations' });
    },
    async onSaveClicked (newMediation: MediationModel): Promise<void> {
      try {
        const creationResult = await createMediation({
          title: newMediation.title,
          description: newMediation.description,
          schedulingDate: newMediation.schedulingDate,
          mediator: newMediation.mediator.id,
          participants: newMediation.participants.map((s) => s.id),
          visibleByGroups: newMediation.visibleByGroups.map((vg) => vg.id),
          visibleByPersons: newMediation.visibleByPersons.map((vp) => vp.id)
        });
        this.$router.push({ name: 'ViewMediation', params: { mediationId: creationResult.id } });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

