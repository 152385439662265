import { Location } from 'vue-router';

let locationBack = undefined as Location | undefined;

/**
 * returns an url for "return to list" buttons
 *
 * @param routeName the name of the route to use for going back
 * @returns the location to go back to
 */
export function backToList (routeName: string): Location {
  if (locationBack && locationBack.name === routeName) {
    // went from a list, and want to return to this list
    return locationBack;
  }
  // went from an other page
  return { name: routeName };
}

/**
 * Sets the location to go back
 *
 * @param back the location object to store
 */
export function setLocationBack (back: Location | undefined): void {
  locationBack = back;
}
