














import Vue from 'vue';

export default Vue.extend({

  name: 'QueryStringSelector',

  props: {
    value: { type: String, default: '' }
  },

  data: () => ({
    search: ''
  }),

  created () {
    this.setValue();
  },

  methods: {
    setValue () {
      this.search = this.value;
    },
    searchChanged () {
      this.$emit('string-selected', this.search);
    }
  },

  watch: {
    value: function () {
      this.setValue();
    }
  }
});

