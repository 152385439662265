























import Vue from 'vue';
import { MediationModel, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import { getConnectedUser, updateMediation, getMediationById } from '../repository';
import AddOrEditMediation from '../components/mediations/AddOrEditMediation.vue';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'EditMediation',

  components: {
    AddOrEditMediation
  },

  data: () => ({
    mediationToEdit: null as MediationModel | null,
    me: { id: '', name: '' } as IdAndNameModel
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true (because user has logged in to be there), to avoid a "possibly null" error
      this.me = { id: connectedUser.id, name: connectedUser.name };
    }
    const mediationId = this.$route.params.mediationId;
    try {
      const mediation = await getMediationById(mediationId);
      this.mediationToEdit = { ...mediation };
    } catch (err) {
      console.error(err);
      this.$router.push({ name: 'NotFound' });
    }
  },

  methods: {
    goBacktoMediation () {
      this.$router.push({ name: 'ViewMediation', params: { mediationId: this.$route.params.mediationId } });
    },
    async onSaveClicked (newMediation: MediationModel): Promise<void> {
      try {
        await updateMediation(newMediation.id, {
          title: newMediation.title,
          description: newMediation.description,
          schedulingDate: newMediation.schedulingDate,
          mediator: newMediation.mediator.id,
          participants: newMediation.participants.map((s) => s.id),
          visibleByGroups: newMediation.visibleByGroups.map((vg) => vg.id),
          visibleByPersons: newMediation.visibleByPersons.map((vp) => vp.id)
        });
        this.goBacktoMediation();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

