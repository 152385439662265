


































































import Vue, { PropType } from 'vue';
import {
  IdAndNameModel,
  LmsCourseModelGroupNode,
  LmsCourseModelNode,
  LmsCourseModelNodeType,
  LmsTrainingModuleItemModel,
  TrainingModuleItemType
} from '../../../_GeneratedClients/SpotClient';
import { getAllCourses, getCourseById } from '../../repository';
import GenericItemListSelector, { SelectableGenericItem } from '../common/GenericItemListSelector.vue';

export default Vue.extend({

  name: 'AddTrainingModuleLmsElements',

  components: {
    GenericItemListSelector
  },

  props: {
    lastContext: { type: Array as PropType<IdAndNameModel[]>, default: () => [] }
  },

  data: () => ({
    completeCourseList: [] as LmsCourseModelNode[],
    currentCourseList: [] as LmsCourseModelNode[],
    loading: true,
    context: [] as IdAndNameModel[],
    parentId: '',
    selectionList: [] as SelectableGenericItem[],
    selectionCount: 0,
    subselectionCount: 0,
    allListSelectorState: 0, // elements selected in selectionList : 0 -> none, 1-> some, 2 -> all
    counting: false
  }),

  async mounted () {
    this.completeCourseList = await getAllCourses();
    this.loading = false;
    this.context = this.lastContext;
    this.parentId = this.context.length === 0 ? '' : this.context[this.context.length - 1].id;
    await this.initSelectionList();
  },

  methods: {
    async initSelectionList (): Promise<void> {
      this.selectionList = [];
      this.selectionCount = 0;
      this.loading = true;
      if (this.parentId === '') {
        // this is root level
        this.currentCourseList = this.completeCourseList;
      } else {
        const parent = (await getCourseById(this.parentId)) as LmsCourseModelGroupNode;
        this.currentCourseList = parent.children;
      }
      for (const node of this.currentCourseList as LmsCourseModelNode[]) {
        this.selectionList.push({
          id: node.id,
          label: node.name,
          icon: this.getIcon(node.type),
          isSelectable: true,
          nextLevel: node.type === LmsCourseModelNodeType.Group && node.children.length > 0 ? ['all'] : [],
          selected: false
        });
      }
      this.selectionCount = 0;
      this.subselectionCount = 0;
      this.loading = false;
    },
    getIcon (type: LmsCourseModelNodeType): string {
      switch (type) {
        case LmsCourseModelNodeType.Group: return 'mdi-folder-outline';
        case LmsCourseModelNodeType.Resource: return 'mdi-file-document-outline';
        case LmsCourseModelNodeType.Quiz: return 'mdi-file-question-outline';
        default: return '';
      }
    },
    getNumberOfSelectedItems (root: LmsCourseModelNode): number {
      if (root.type !== LmsCourseModelNodeType.Group) {
        return 1;
      }
      let nb = 0;
      for (const node of root.children) {
        if (node.type === LmsCourseModelNodeType.Group) {
          nb += this.getNumberOfSelectedItems(node);
        } else {
          nb++;
        }
      }
      return nb;
    },
    updateCounter (): void {
      this.counting = true;
      this.selectionCount = 0;
      this.subselectionCount = 0;
      for (let i = 0; i < this.selectionList.length; i++) {
        if (this.selectionList[i].selected === true) {
          this.selectionCount++;
          this.subselectionCount += this.getNumberOfSelectedItems(this.currentCourseList[i]);
        }
      }
      this.allListSelectorState = this.selectionCount === 0 ? 0 : this.selectionCount === this.selectionList.length ? 2 : 1;
      this.counting = false;
    },
    select (idx: number): void {
      this.selectionList[idx].selected = !this.selectionList[idx].selected;
      this.updateCounter();
    },
    selectAllClicked (): void {
      const commonState = this.allListSelectorState !== 2;
      for (const item of this.selectionList) {
        item.selected = commonState;
      }
      this.updateCounter();
    },
    async nextLevel (idx: number): Promise<void> {
      this.context.push({ id: this.selectionList[idx].id, name: this.selectionList[idx].label });
      this.$emit('context', this.context);
      this.parentId = this.selectionList[idx].id;
      await this.initSelectionList();
    },
    async returnToRoot (): Promise<void> {
      this.context = [];
      this.$emit('context', this.context);
      this.parentId = '';
      await this.initSelectionList();
    },
    async returnToLevel (idx: number): Promise<void> {
      this.context = this.context.slice(0, idx + 1);
      this.$emit('context', this.context);
      this.parentId = this.context[idx].id;
      await this.initSelectionList();
    },
    onInsertClicked () {
      if (this.selectionCount === 0 || this.counting === true) {
        return;
      }
      const selected = this.selectionList.filter((i) => i.selected === true);
      const response = [] as LmsTrainingModuleItemModel[];
      for (const item of selected) {
        response.push({
          type: TrainingModuleItemType.Lms,
          lmsCourseId: item.id
        });
      }
      this.$emit('insert', response);
    }
  },

  computed: {
    allListIcon (): string {
      switch (this.allListSelectorState) {
        case 1: return 'mdi-checkbox-intermediate';
        case 2: return 'mdi-checkbox-marked';
        case 0:
        default: return 'mdi-checkbox-blank-outline';
      }
    },
    countMessageColor: function (): string {
      return this.selectionCount === 0 ? 'warning--text' : 'success--text';
    },
    countMessage: function (): string {
      let text = this.selectionCount + ' ' + this.$t('selectedItems') + ', ' + this.$t('total');
      text += ' ' + this.subselectionCount + ' ' + this.$t('selectedSubitems');
      return text;
    }
  }
});

