import { FieldType } from './fieldConfiguration';
import {
  BooleanQueryCriterion,
  DateQueryCriterion,
  EnumQueryCriterion,
  IdQueryCriterion,
  MultipleQueryCriteriaType,
  QueryCriterion,
  StringQueryCriterion
} from './criteria';
import { DateComparisonOperator, EnumComparisonOperator, StringComparisonOperator } from './operators';
import { QueryFieldConfiguration } from './queryFieldConfiguration';
import { LocalDate } from '@js-joda/core';
import { SortingCriterion } from './sorting';

/**
 * Performs the given query with the specified field configuration on the specified source
 *
 * @param config The fields that we can query on
 * @param query The query that is performed
 * @param sorting The sorting criterion that gives the order of the result
 * @param source The source against which to perform the query
 * @returns The filtered items
 */
export function doQuery<T> (config: Record<string, QueryFieldConfiguration<T>>, query: QueryCriterion | null, sorting: SortingCriterion[], source: T[]): T[] {
  const filter = query ? buildFilterFunction(config, query) : null;
  const sortingFunction = buildSortingFunction(config, sorting);
  const results = filter ? source.filter(filter) : [...source];
  if (sortingFunction !== null) {
    results.sort(sortingFunction);
  }
  return results;
}

/**
 * The function that creates the functions that tells whether or not to include this object in the search result
 *
 * @param config The fields configurations
 * @param query The (part of the) query for which to create a function
 * @returns The composite function that checks whether the current object matches the query.
 */
function buildFilterFunction<T> (config: Record<string, QueryFieldConfiguration<T>>, query: QueryCriterion): (obj: T) => boolean {
  switch (query.type) {
    case MultipleQueryCriteriaType.and:
    {
      const childrenPredicates = query.criteria.map(c => buildFilterFunction(config, c));
      return (obj: T) => {
        for (const p of childrenPredicates) {
          if (!p(obj)) {
            return false;
          }
        }
        return true;
      };
    }
    case MultipleQueryCriteriaType.or:
    {
      const childrenPredicates = query.criteria.map(c => buildFilterFunction(config, c));
      return (obj: T) => {
        for (const p of childrenPredicates) {
          if (p(obj)) {
            return true;
          }
        }
        return false;
      };
    }

    // single criterion
    case FieldType.string:
    {
      const fieldConfiguration = getAndCheckFieldConfiguration(config, query.property, query.type);
      return buildStringFilter(fieldConfiguration.getter, query);
    }
    case FieldType.date:
    {
      const fieldConfiguration = getAndCheckFieldConfiguration(config, query.property, query.type);
      return buildDateFilter(fieldConfiguration.getter, query);
    }
    case FieldType.boolean:
    {
      const fieldConfiguration = getAndCheckFieldConfiguration(config, query.property, query.type);
      return buildBooleanFilter(fieldConfiguration.getter, query);
    }
    case FieldType.enum:
    {
      const fieldConfiguration = getAndCheckFieldConfiguration(config, query.property, query.type);
      const allowedValues = Object.values(fieldConfiguration.enumType);
      const invalidValues = query.values.filter(q => allowedValues.every(allowed => allowed !== q));
      if (invalidValues.length > 0) {
        throw new Error(`Invalid enum values : ${invalidValues.join(',')}`);
      }

      return buildEnumFilter(fieldConfiguration.getter, query);
    }
    case FieldType.id:
    {
      const fieldConfiguration = getAndCheckFieldConfiguration(config, query.property, query.type);
      return buildEnumFilter(fieldConfiguration.getter, query);
    }
  }
}

/**
 * Gets the field configuration for the given property and checks that it exists, is filterable and is of the provided type
 *
 * @param config The field configurations
 * @param property The property to get
 * @param fieldType The expected field type
 * @returns The retrieved field configuration
 */
// Inspired from https://stackoverflow.com/a/71601215/2663813
// That weird syntax helps TypeScript to know that if we pass FieldType.string in the fieldType, this method returns a StringQueryFieldConfiguration
// using a vodoo dark magic trick from typescript.
function getAndCheckFieldConfiguration<T, FT extends QueryFieldConfiguration<T>['type']> (config: Record<string, QueryFieldConfiguration<T>>, property: string, fieldType: FT): Extract<QueryFieldConfiguration<T>, { type: FT }> {
  const fieldConfiguration = config[property];
  if (!fieldConfiguration) {
    throw new Error(`Unable to build query: field ${property} not found in field configuration`);
  }

  if (fieldConfiguration.filterable === false) {
    throw new Error(`Unable to build query: field ${property} is not filterable`);
  }

  if (fieldConfiguration.type !== fieldType) {
    throw new Error(`Unable to build query: field ${property} is declared as ${fieldConfiguration.type} instead of ${fieldType} in field configuration`);
  }

  return fieldConfiguration as Extract<QueryFieldConfiguration<T>, { type: FT }>;
}

/**
 * Creates a filter for a string criterion
 *
 * @param getter The getter that gets a field value
 * @param query The query criterion
 * @returns the filter function
 */
function buildStringFilter<T> (getter: (obj: T) => string[], query: StringQueryCriterion) : (obj: T) => boolean {
  switch (query.operator) {
    case StringComparisonOperator.equals: return (obj) => getter(obj).some(s => s.toLocaleUpperCase() === query.value.toLocaleUpperCase());
    case StringComparisonOperator.different: return (obj) => getter(obj).some(s => s.toLocaleUpperCase() !== query.value.toLocaleUpperCase());
    case StringComparisonOperator.startsWith: return (obj) => getter(obj).some(s => s.toLocaleUpperCase().startsWith(query.value.toLocaleUpperCase()));
    case StringComparisonOperator.endsWith: return (obj) => getter(obj).some(s => s.toLocaleUpperCase().endsWith(query.value.toLocaleUpperCase()));
    case StringComparisonOperator.contains: return (obj) => getter(obj).some(s => s.toLocaleUpperCase().includes(query.value.toLocaleUpperCase()));
  }
}

/**
 * Creates a filter for a date criterion
 *
 * @param getter The getter that gets a field value
 * @param query The query criterion
 * @returns the filter function
 */
function buildDateFilter<T> (getter: (obj: T) => LocalDate[], query: DateQueryCriterion) : (obj: T) => boolean {
  switch (query.operator) {
    case DateComparisonOperator.after: return (obj) => getter(obj).some(d => d.isAfter(query.value));
    case DateComparisonOperator.afterOrSameDay: return (obj) => getter(obj).some(d => d.isAfter(query.value) || d.isEqual(query.value));
    case DateComparisonOperator.before: return (obj) => getter(obj).some(d => d.isBefore(query.value));
    case DateComparisonOperator.beforeOrSameDay: return (obj) => getter(obj).some(d => d.isBefore(query.value) || d.isEqual(query.value));
    case DateComparisonOperator.sameDay: return (obj) => getter(obj).some(d => d.isEqual(query.value));
  }
}

/**
 * Creates a filter for a boolean criterion
 *
 * @param getter The getter that gets a field value
 * @param query The query criterion
 * @returns the filter function
 */
function buildBooleanFilter<T> (getter: (obj: T) => boolean[], query: BooleanQueryCriterion) : (obj: T) => boolean {
  return (obj) => getter(obj).some(b => b === query.value);
}

/**
 * Creates a filter for an enum, id or location criterion
 *
 * @param getter The getter that gets a field value
 * @param query The query criterion
 * @returns the filter function
 */
function buildEnumFilter<T> (getter: (obj: T) => string[], query: EnumQueryCriterion | IdQueryCriterion) : (obj: T) => boolean {
  switch (query.operator) {
    case EnumComparisonOperator.oneOf: return (obj) => getter(obj).some(e => query.values.some(q => e === q));
    case EnumComparisonOperator.noneOf: return (obj) => !getter(obj).some(e => query.values.some(q => e === q));
  }
}

/**
 * Creates a functions that can be used in the Array<T>.sort function to sort the results
 *
 * @param config The fields configurations
 * @param sorting The ordering criterion
 * @returns The functions that sorts an array of T the way described by the sorting parameter
 */
function buildSortingFunction<T> (config: Record<string, QueryFieldConfiguration<T>>, sorting: SortingCriterion[]): ((a: T, b: T) => number) | null {
  if (sorting.length === 0) {
    return null;
  }

  // The built comparator along with the ascending boolean
  const comparators: ([(a: T, b: T) => number, boolean])[] = [];
  for (const s of sorting) {
    const fieldConfiguration = config[s.property];
    if (!fieldConfiguration) {
      throw new Error(`Unable to build sorting: field ${s.property} not found in field configuration`);
    }
    if (fieldConfiguration.sortable !== true) {
      throw new Error(`Unable to build sorting: field ${s.property} is not sortable`);
    }
    let comparator: (a: T, b: T) => number;
    switch (fieldConfiguration.type) {
      case FieldType.string: comparator = buildStringComparator(fieldConfiguration.getter); break;
      case FieldType.date: comparator = buildDateComparator(fieldConfiguration.getter); break;
      case FieldType.boolean: comparator = buildBooleanComparator(fieldConfiguration.getter); break;
      case FieldType.enum: comparator = buildEnumComparator(fieldConfiguration.getter, fieldConfiguration.enumType); break;
      case FieldType.id: throw new Error('id sorting not implemented: probably not what you would expect');
    }
    comparators.push([comparator, s.ascending ?? true]);
  }

  return (a: T, b: T) : number => {
    for (const [comparator, ascending] of comparators) {
      const comparison : number = comparator(a, b);
      if (comparison !== 0) {
        return ascending ? comparison : -comparison;
      }
    }

    return 0;
  };
}

/**
 * The function that builds a string comparator with the given getter
 *
 * @param getter The getter used to retrieve the property value
 * @returns The comparator that sorts items of type T in ascending order
 */
function buildStringComparator<T> (getter: (obj: T) => string[]): ((a: T, b: T) => number) {
  return (a, b) => {
    const aProp = getter(a);
    const bProp = getter(b);
    if (aProp.length === 0 || bProp.length === 0) {
      // One of the items is empty, and empty comes last in ascending order
      return bProp.length - aProp.length;
    }
    if (aProp.length > 1 || bProp.length > 1) {
      console.warn('Trying to sort on a multi-value property. This will only sort on the first value.');
    }
    return aProp[0].localeCompare(bProp[0], undefined, { sensitivity: 'base' });
  };
}

/**
 * The function that builds a date comparator with the given getter
 *
 * @param getter The getter used to retrieve the property value
 * @returns The comparator that sorts items of type T in ascending order
 */
function buildDateComparator<T> (getter: (obj: T) => LocalDate[]): ((a: T, b: T) => number) {
  return (a, b) => {
    const aProp = getter(a);
    const bProp = getter(b);
    if (aProp.length === 0 || bProp.length === 0) {
      // One of the items is empty, and empty comes last in ascending order
      return bProp.length - aProp.length;
    }
    if (aProp.length > 1 || bProp.length > 1) {
      console.warn('Trying to sort on a multi-value property. This will only sort on the first value.');
    }
    return aProp[0].compareTo(bProp[0]);
  };
}

/**
 * The function that builds a boolean comparator with the given getter
 *
 * @param getter The getter used to retrieve the property value
 * @returns The comparator that sorts items of type T in ascending order
 */
function buildBooleanComparator<T> (getter: (obj: T) => boolean[]): ((a: T, b: T) => number) {
  return (a, b) => {
    const aProp = getter(a);
    const bProp = getter(b);
    if (aProp.length === 0 || bProp.length === 0) {
      // One of the items is empty, and empty comes last in ascending order
      return bProp.length - aProp.length;
    }
    if (aProp.length > 1 || bProp.length > 1) {
      console.warn('Trying to sort on a multi-value property. This will only sort on the first value.');
    }
    // If a is true and b is false, then a is greater than b and will be last in the list
    return aProp[0] === bProp[0] ? 0 : (aProp[0] ? 1 : -1);
  };
}

/**
 * The function that builds an enum comparator with the given getter
 *
 * @param getter The getter used to retrieve the property value
 * @param enumType The enum will be ordered in the same order as enum values are declared in this object
 * @returns The comparator that sorts items of type T in ascending order
 */
function buildEnumComparator<T> (getter: (obj: T) => string[], enumType: Record<string, string>): ((a: T, b: T) => number) {
  const enumOrders = new Map<string, number>(Object.entries(enumType).map(([, value], index) => [value, index]));
  return (a, b) => {
    const aProp = getter(a);
    const bProp = getter(b);
    if (aProp.length === 0 || bProp.length === 0) {
      // One of the items is empty, and empty comes last in ascending order
      return bProp.length - aProp.length;
    }
    if (aProp.length > 1 || bProp.length > 1) {
      console.warn('Trying to sort on a multi-value property. This will only sort on the first value.');
    }
    const aIndex = enumOrders.get(aProp[0]);
    const bIndex = enumOrders.get(bProp[0]);
    if (aIndex === undefined) {
      throw new Error('Unable to sort, unknown enum value ' + aProp[0]);
    }
    if (bIndex === undefined) {
      throw new Error('Unable to sort, unknown enum value ' + bProp[0]);
    }
    return aIndex - bIndex;
  };
}
