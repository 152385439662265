

























import Vue, { PropType } from 'vue';
import { ResourceModel, ResourceType } from '../../../../_GeneratedClients/SpotClient';
import { backendUrl } from '../../../Tools';

export default Vue.extend({

  name: 'DisplayThumbnailResource',

  props: {
    resource: { type: Object as PropType<ResourceModel>, default: () => ({ id: '', name: '', resourceType: ResourceType.Link, link: '' }) },
    parentType: { type: String, required: true },
    parentId: { type: String, required: true }
  },

  methods: {
    limitString (source: string): string {
      // container width accept about 22 char max
      if (source.length < 22) {
        return source;
      }
      return source.substring(0, 19).concat('...');
    }
  },

  computed: {
    resourceLink (): string {
      switch (this.resource.resourceType) {
        case ResourceType.Image: return backendUrl + '/resource/' + this.parentType + '/' + this.parentId + '/' + this.resource.id + '/file';
        case ResourceType.Pdf: return require('../../../assets/pdf-file.png');
        case ResourceType.File: return require('../../../assets/generic-file.png');
        case ResourceType.Link: return require('../../../assets/web-link.png');
        case ResourceType.Youtube: return this.resource.link === '' ? '' : 'https://img.youtube.com/vi/' + this.resource.link + '/default.jpg';
        default: return '';
      }
    }
  }
});

