import { LocalDate } from '@js-joda/core';
import { FieldType } from './fieldConfiguration';
import { BooleanComparisonOperator, DateComparisonOperator, EnumComparisonOperator, StringComparisonOperator } from './operators';

export type QueryCriterion = MultipleQueryCriteria | SingleQueryCriterion;
export type SingleQueryCriterion = StringQueryCriterion | DateQueryCriterion | BooleanQueryCriterion | EnumQueryCriterion | IdQueryCriterion;

/**
 * The type of criterion
 */
export enum MultipleQueryCriteriaType {
  or = 'or',
  and = 'and'
}

/**
 * A criterion that contains multiple criteria, combined by a logical AND or OR
 */
export interface MultipleQueryCriteria {
  /**
   * The type of criterion
   */
  type: MultipleQueryCriteriaType,

  /**
   * The children criteria
   */
  criteria: QueryCriterion[]
}

/**
 * A criterion that compares a string property
 */
export interface StringQueryCriterion {
  /**
   * The type of criterion
   */
  type: FieldType.string,

  /**
   * The property key
   */
  property: string,

  /**
   * The operator used to compare
   */
  operator: StringComparisonOperator,

  /**
   * The value to compare to
   */
  value: string
}

/**
 * A criterion that compares a date property
 */
export interface DateQueryCriterion {
  /**
   * The type of criterion
   */
  type: FieldType.date,

  /**
   * The property key
   */
  property: string,

  /**
   * The operator used to compare
   */
  operator: DateComparisonOperator,

  /**
   * The value to compare to.
   */
  value: LocalDate
}

/**
 * A criterion that compares a boolean property
 */
export interface BooleanQueryCriterion {
  /**
   * The type of criterion
   */
  type: FieldType.boolean,

  /**
   * The property key
   */
  property: string,

  /**
   * The operator used to compare
   */
  operator: BooleanComparisonOperator,

  /**
   * The value to compare to.
   */
  value: boolean
}

/**
 * A criterion that compares an enum property
 */
export interface EnumQueryCriterion {
  /**
   * The type of criterion
   */
  type: FieldType.enum,

  /**
   * The property key
   */
  property: string,

  /**
   * The operator used to compare
   */
  operator: EnumComparisonOperator,

  /**
   * The values to compare to
   */
  values: string[]
}

/**
 * A criterion that compares a date property
 */
export interface IdQueryCriterion {
  /**
   * The type of criterion
   */
  type: FieldType.id,

  /**
   * The property key
   */
  property: string,

  /**
   * The operator used to compare
   */
  operator: EnumComparisonOperator,

  /**
   * The values to compare to
   */
  values: string[]
}
