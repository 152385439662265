






























































































import {
  autocompleteCartographySite,
  createSubject,
  dataPreloadUnload,
  duplicateSubject,
  getActivitiesFromCartography,
  getCartographySiteIndex,
  getEmptySubject,
  getFunctionsFromCartography,
  getSubActivitiesFromActivityAndCartography,
  updateSubject,
  VForm
} from '../../repository';
import Vue, { PropType } from 'vue';
import { ContextItemType, CreateOrUpdateSubjectRequest, IdAndNameModel, SubjectModel } from '../../../_GeneratedClients/SpotClient';
import SimplePageLayout from '../presentation/SimplePageLayout.vue';
import Autocomplete from '../common/Autocomplete.vue';
import eventBus from '../../eventBus';

export default Vue.extend({

  name: 'AddOrEditSubject',

  components: {
    SimplePageLayout,
    Autocomplete
  },

  props: {
    subject: { type: Object as PropType<SubjectModel>, default: () => getEmptySubject() },
    lockCartography: { type: Boolean, default: false },
    duplicate: { type: Boolean, default: false }
  },

  data: () => ({
    newName: '',
    currentCartographyId: '',
    currentFunctionId: '',
    currentActivityId: '',
    currentSubActivityId: '',
    cartographySiteIndex: [] as IdAndNameModel[],
    selectedCartographySite: null as null | IdAndNameModel,
    autocompleteCartographySite: autocompleteCartographySite,
    functionIndex: [] as IdAndNameModel[],
    selectedFunction: null as null | IdAndNameModel,
    activityIndex: [] as IdAndNameModel[],
    selectedActivity: null as null | IdAndNameModel,
    subActivityIndex: [] as IdAndNameModel[],
    selectedSubActivity: null as null | IdAndNameModel,
    globalError: '',
    form: {} as VForm
  }),

  async created () {
    // get all cartographies
    this.cartographySiteIndex = await getCartographySiteIndex();
    this.form = this.$refs.globalForm as VForm;
    await this.initData();
  },

  methods: {
    async initData (): Promise<void> {
      if (this.cartographySiteIndex.length === 0) {
        // created not yet finished, just return and wait for created() to be finished.
        return;
      }
      this.newName = this.subject.name;
      if (this.creationMode) {
        // this is a creation
        if (typeof this.$route.query.cartography === 'string' && this.$route.query.cartography !== '') {
          // Try to read cartography from query parameters
          this.currentCartographyId = this.$route.query.cartography;
          const found = this.cartographySiteIndex.find(csi => csi.id === this.currentCartographyId);
          this.selectedCartographySite = found === undefined ? null : found;
          if (this.selectedCartographySite && typeof this.$route.query.function === 'string' && this.$route.query.function !== '') {
            this.currentFunctionId = this.$route.query.function;
          }
          if (this.selectedCartographySite && typeof this.$route.query.activity === 'string' && this.$route.query.activity !== '') {
            this.currentActivityId = this.$route.query.activity;

            if (typeof this.$route.query.subactivity === 'string' && this.$route.query.subactivity !== '') {
              this.currentSubActivityId = this.$route.query.subactivity;
            }
          }
        } else {
          this.selectedCartographySite = null;
        }
      } else {
        // this is an update -> find current cartography, function, activity and sub-activity identifiers
        const contexts = this.subject.contexts.flatMap(ctx => ctx.hierarchy);
        const cartographyIds = contexts.filter(c => c.type === ContextItemType.Cartography).map(c => c.id);
        this.currentCartographyId = cartographyIds[0]; // would always exist and be unique
        const functionIds = contexts.filter(c => c.type === ContextItemType.Function).map(f => f.id);
        this.currentFunctionId = functionIds[0]; // would always exist and be unique
        const activityIds = contexts.filter(c => c.type === ContextItemType.Activity).map(a => a.id);
        this.currentActivityId = activityIds[0]; // would always exist and represents the activity
        if (activityIds[1]) { // If it exists, represents the sub-activity
          this.currentSubActivityId = activityIds[1];
        }
        // select current cartography (if found)
        const found = this.cartographySiteIndex.find(csi => csi.id === this.currentCartographyId);
        this.selectedCartographySite = found === undefined ? null : found;
      }
      await this.initContext();
      this.form.validate();
    },
    async initContext (): Promise<void> {
      await this.initFunctionIndex();
      await this.initActivityIndex();
    },
    async initFunctionIndex (): Promise<void> {
      this.functionIndex = [] as IdAndNameModel[];
      this.selectedFunction = null;
      if (this.selectedCartographySite !== null) {
        // prepare function index
        const functionList = await getFunctionsFromCartography(this.selectedCartographySite.id);
        this.functionIndex = functionList.map(f => ({ id: f.id, name: f.name }));
        const found = this.functionIndex.find(f => f.id === this.currentFunctionId);
        if (found !== undefined) {
          this.selectedFunction = found;
        }
      }
    },
    async initActivityIndex (): Promise<void> {
      this.selectedActivity = null;
      if (this.selectedCartographySite === null) {
        // no cartography selected -> empty activity list
        this.activityIndex = [];
      } else {
        // prepare activity index
        const activityList = await getActivitiesFromCartography(this.selectedCartographySite.id);
        this.activityIndex = activityList.map(a => ({ id: a.id, name: a.name }));
        const found = this.activityIndex.find(a => a.id === this.currentActivityId);
        if (found !== undefined) {
          this.selectedActivity = found;
        }
      }
      await this.initSubActivityIndex();
    },
    async initSubActivityIndex (): Promise<void> {
      this.selectedSubActivity = null;
      if (this.selectedActivity === null) {
        // no activity selected -> empty sub-activity list
        this.subActivityIndex = [];
      } else {
        // prepare sub-activity index
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const subActivityList = await getSubActivitiesFromActivityAndCartography(this.selectedActivity.id, this.selectedCartographySite!.id);
        this.subActivityIndex = subActivityList.map(sa => ({ id: sa.id, name: sa.name }));
        const found = this.subActivityIndex.find(sa => sa.id === this.currentSubActivityId);
        if (found !== undefined) {
          this.selectedSubActivity = found;
        }
      }
    },
    async onSaveClicked (): Promise<void> {
      if (!this.form.validate()) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      try {
        const subjectInfo: CreateOrUpdateSubjectRequest = {
          name: this.newName,
          /* eslint-disable @typescript-eslint/no-non-null-assertion */
          cartographyId: this.selectedCartographySite!.id,
          functionId: this.selectedFunction!.id,
          activityId: this.selectedSubActivity ? this.selectedSubActivity.id : this.selectedActivity!.id
          /* eslint-enable @typescript-eslint/no-non-null-assertion */
        };
        let subjectId = this.subject.id;
        if (this.creationMode) {
          // this is a creation
          subjectId = await createSubject(subjectInfo);
        } else if (this.duplicate) {
          // this is a creation from existing subject
          subjectId = await duplicateSubject(subjectId, subjectInfo);
        } else {
          // this is an update of existing subject
          await updateSubject(subjectId, subjectInfo);
        }
        dataPreloadUnload(); // reset local storage to force to reload
        this.$router.push({ name: 'Subject', params: { subjectId: subjectId } });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    newNameValidator (): string | true {
      if (this.newName !== this.newName.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.newName.length === 0) {
        return this.$t('emptyFieldError').toString();
      }
      return true;
    },
    selectionValidator (selection: IdAndNameModel | null): string | true {
      if (selection === null) {
        return this.$t('emptyFieldError').toString();
      }
      return true;
    }
  },

  computed: {
    creationMode (): boolean {
      return this.subject.id === '';
    }
  },

  watch: {
    subject: {
      immediate: true,
      handler () {
        this.initData();
      }
    },
    selectedCartographySite: {
      immediate: true,
      handler: async function () {
        await this.initContext();
      }
    },
    selectedActivity: {
      immediate: true,
      handler: async function () {
        await this.initSubActivityIndex();
      }
    }
  }
});

