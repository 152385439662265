



















































































































import Vue, { PropType } from 'vue';
import { ResourceModel, ResourceParentType, ResourceType } from '../../../../_GeneratedClients/SpotClient';
import DisplayCarouselResource from './DisplayCarouselResource.vue';
import { backendUrl } from '../../../Tools';
import AddOrEditResource from './AddOrEditResource.vue';
import eventBus from '../../../eventBus';
import { deleteResource } from '../../../repository';
import DisplayThumbnailResource from './DisplayThumbnailResource.vue';
import DisplayConfirmationDialogBox from '../DisplayConfirmationDialogBox.vue';

export default Vue.extend({

  name: 'ResourceCarousel',

  components: {
    AddOrEditResource,
    DisplayCarouselResource,
    DisplayConfirmationDialogBox,
    DisplayThumbnailResource
  },

  props: {
    showCarousel: { type: Boolean, default: false },
    canEdit: { type: Boolean, default: false },
    resources: { type: Array as PropType<ResourceModel[]>, required: true },
    // note : assume that there is at least one resource in list
    preselect: { type: Number, default: 0 },
    parentType: { type: String, required: true },
    parentId: { type: String, required: true }
  },

  data: () => ({
    screenHeight: window.innerHeight,
    selectedResource: 0,
    carouselHeight: 500, // default value in carousel vuetify component
    showAddOrEditResourceForm: false,
    resourceToEdit: undefined as ResourceModel | undefined,
    showConfirmDeleteDialogBox: false
  }),

  created () {
    this.selectedResource = this.preselect;
  },

  mounted () {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize (): void {
      this.screenHeight = window.innerHeight;
      this.carouselHeight = this.screenHeight > 700 ? this.screenHeight * 0.9 : this.screenHeight * 0.85; // arbitrary 90% or 85% of window height
    },
    hasFile (resource: ResourceModel): boolean {
      return resource.resourceType === ResourceType.Image || resource.resourceType === ResourceType.Pdf || resource.resourceType === ResourceType.File;
    },
    getLink (resource: ResourceModel): string {
      switch (resource.resourceType) {
        case ResourceType.File:
        case ResourceType.Image:
        case ResourceType.Pdf: return backendUrl + '/resource/' + this.parentType + '/' + this.parentId + '/' + resource.id + '/file';
        case ResourceType.Link: return resource.link;
        case ResourceType.Youtube: return 'https://www.youtube.com/embed/' + resource.link + '?autoplay=0&origin=*';
        default: return '';
      }
    },
    edit (resource: ResourceModel): void {
      this.resourceToEdit = { ...resource };
      this.showAddOrEditResourceForm = true;
    },
    updated (): void {
      this.showAddOrEditResourceForm = false;
      this.$emit('updated', this.selectedResource);
    },
    remove (resource: ResourceModel): void {
      this.resourceToEdit = { ...resource };
      this.showConfirmDeleteDialogBox = true;
    },
    async deleteResource (): Promise<void> {
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteResource(this.parentType as ResourceParentType, this.parentId, this.resourceToEdit!.id);
        this.showConfirmDeleteDialogBox = false;
        this.$emit('updated', 0);
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  },

  computed: {
    resourceName (): string {
      return this.resourceToEdit?.name ?? '';
    }
  },

  watch: {
    preselect: function (): void {
      this.selectedResource = this.preselect;
    },
    resources: function (): void {
      this.selectedResource = this.preselect;
    }
  }
});

