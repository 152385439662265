











import Vue, { PropType } from 'vue';
import DatePicker, { DatePickerErrorType } from '../common/DatePicker.vue';
import moment, { Moment } from 'moment';
import { convert, LocalDate, nativeJs } from '@js-joda/core';

export default Vue.extend({

  name: 'QueryDateSelector',

  components: {
    DatePicker
  },

  props: {
    value: { type: Object as PropType<LocalDate>, default: undefined }
  },

  data: () => ({
    selectedDate: moment()
  }),

  methods: {
    dateChanged (newDate: Moment) {
      // this is a valid date
      this.selectedDate = newDate;
      this.$emit('date-selected', LocalDate.from(nativeJs(this.selectedDate.toDate())));
    },
    onError (newError: DatePickerErrorType | null) {
      this.$emit('error-changed', newError !== null);
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: function () {
        if (this.value) {
          const newValue = moment(convert(this.value as LocalDate).toDate()); // TODO: DatePicker returns a moment value for now, we would need to change it to LocalDate instead to avoid conversions
          if (!this.selectedDate.isSame(newValue)) {
            this.selectedDate = newValue;
            this.dateChanged(this.selectedDate);
          }
        }
      }
    }
  }
});

