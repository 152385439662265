




















































































import Vue, { PropType } from 'vue';
import { ActivityModel, CartographyModel, ContextItemType, IdAndNameModel, SiteModel } from '../../../_GeneratedClients/SpotClient';
import { getSiteList, getSpotItemById, getSubjectsFromFunction } from '../../repository';
import GenericItemListSelector, { SelectableGenericItem } from '../common/GenericItemListSelector.vue';

export default Vue.extend({

  name: 'QueryLocationSelector',

  components: {
    GenericItemListSelector
  },

  props: {
    values: { type: Array as PropType<IdAndNameModel[]>, default: () => [] },
    showSubjects: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  data: () => ({
    context: [] as IdAndNameModel[],
    parentId: '',
    showItemSelector: false,
    selectionList: [] as SelectableGenericItem[],
    activity: false
  }),

  async created () {
    if (this.values.length > 0) { // a value is already defined
      // get first element -> get context -> get parentId
      const base = await getSpotItemById(this.values[0].id);
      if (base !== undefined && base.item.type !== ContextItemType.Site) {
        this.activity = base.item.type === ContextItemType.Activity;
        for (const node of base.contexts[0].hierarchy) {
          this.context.push({ id: node.id, name: node.name });
          this.parentId = node.id;
        }
      }
    }
    await this.setSelectorValues();
  },

  methods: {
    async setSelectorValues (): Promise<void> {
      this.selectionList = [];
      if (this.parentId === '') {
        // preload sites level
        for (const site of await getSiteList()) {
          this.selectionList.push({
            id: site.item.id,
            label: site.item.name,
            icon: 'mdi-folder-outline',
            isSelectable: true,
            nextLevel: ['all'],
            selected: this.values.some((i) => i.id === site.item.id)
          } as SelectableGenericItem);
        }
      } else {
        // preload children of parent level
        const parent = await getSpotItemById(this.parentId);
        if (parent) {
          switch (parent.item.type) {
            case ContextItemType.Site:
              for (const cartography of (parent.item as SiteModel).cartographies) {
                this.selectionList.push({
                  id: cartography.id,
                  label: cartography.name,
                  icon: 'mdi-folder-outline',
                  isSelectable: true,
                  nextLevel: [this.$t('activities').toString(), this.$t('functions').toString()],
                  selected: this.values.some((i) => i.id === cartography.id)
                } as SelectableGenericItem);
              }
              break;
            case ContextItemType.Activity:
              for (const activity of (parent.item as ActivityModel).activities) {
                this.selectionList.push({
                  id: activity.id,
                  label: activity.name,
                  icon: 'mdi-folder-outline',
                  isSelectable: true,
                  nextLevel: activity.activities.length > 0 ? ['all'] : [],
                  selected: this.values.some((i) => i.id === activity.id)
                } as SelectableGenericItem);
              }
              if (this.showSubjects) {
                for (const subject of (parent.item as ActivityModel).subjects) {
                  this.selectionList.push({
                    id: subject.id,
                    label: subject.name,
                    icon: 'mdi-text-box-outline',
                    isSelectable: true,
                    nextLevel: [],
                    selected: this.values.some((i) => i.id === subject.id)
                  } as SelectableGenericItem);
                }
              }
              break;
            case ContextItemType.Cartography:
              if (this.activity === true) { // children are activities
                for (const activity of (parent.item as CartographyModel).activities) {
                  this.selectionList.push({
                    id: activity.id,
                    label: activity.name,
                    icon: 'mdi-folder-outline',
                    isSelectable: true,
                    nextLevel: activity.activities.length > 0 ? ['all'] : [],
                    selected: this.values.some((i) => i.id === activity.id)
                  } as SelectableGenericItem);
                }
              } else {
                // function level
                for (const func of (parent.item as CartographyModel).functions) {
                  this.selectionList.push({
                    id: func.id,
                    label: func.name,
                    icon: 'mdi-folder-outline',
                    isSelectable: true,
                    nextLevel: this.showSubjects ? ['all'] : [],
                    selected: this.values.some((i) => i.id === func.id)
                  } as SelectableGenericItem);
                }
              }
              break;
            case ContextItemType.Function: {
              // normally, only used when showSubject is true
              const subjects = await getSubjectsFromFunction(this.parentId);
              for (const subject of subjects) {
                this.selectionList.push({
                  id: subject.id,
                  label: subject.name,
                  icon: 'mdi-text-box-outline',
                  isSelectable: true,
                  nextLevel: [],
                  selected: this.values.some((i) => i.id === subject.id)
                } as SelectableGenericItem);
              }
            }
              break;
          }
        }
      }
    },
    select (idx: number): void {
      this.selectionList[idx].selected = !this.selectionList[idx].selected;
    },
    selectAllClicked (): void {
      const commonState = this.allListSelectorState !== 2;
      for (const item of this.selectionList) {
        item.selected = commonState;
      }
    },
    async nextLevel (idx: number, type: number): Promise<void> {
      this.context.push({ id: this.selectionList[idx].id, name: this.selectionList[idx].label });
      this.parentId = this.selectionList[idx].id;
      this.activity = type === 0;
      await this.setSelectorValues();
    },
    async returnToRoot (): Promise<void> {
      this.parentId = '';
      this.context = [];
      await this.setSelectorValues();
    },
    async returnToLevel (idx: number): Promise<void> {
      this.context = this.context.slice(0, idx + 1);
      this.parentId = this.context[idx].id;
      await this.setSelectorValues();
    },
    onSelectClicked (): void {
      if (this.selectedCount === 0) {
        return;
      }
      const selectedList = [] as IdAndNameModel[];
      for (const item of this.selectionList.filter((i) => i.selected === true)) {
        selectedList.push({ id: item.id, name: item.label });
      }
      this.$emit('location-selected', selectedList);
      this.showItemSelector = false;
    },
    reset (): void {
      this.$emit('location-selected', []);
    }
  },

  computed: {
    allListIcon (): string {
      switch (this.allListSelectorState) {
        case 1: return 'mdi-checkbox-intermediate';
        case 2: return 'mdi-checkbox-marked';
        case 0:
        default: return 'mdi-checkbox-blank-outline';
      }
    },
    selectedList (): string {
      return this.values.map((v) => v.name).join(', ');
    },
    error (): boolean {
      return this.values.length === 0;
    },
    bgColor (): string {
      if (this.disabled) {
        return 'bgDisabled';
      }
      return this.error === true ? 'bgError' : 'bgAccent';
    },
    selectedCount: function (): number {
      return this.selectionList.filter((i) => i.selected === true).length;
    },
    allListSelectorState: function (): number {
      // elements selected in selectionList : 0 -> none, 1-> some, 2 -> all
      const nbSel = this.selectedCount;
      return nbSel === 0 ? 0 : nbSel === this.selectionList.length ? 2 : 1;
    },
    countMessageColor: function (): string {
      return this.selectedCount === 0 ? 'warning--text' : 'success--text';
    },
    countMessage: function (): string {
      return this.selectedCount + ' ' + this.$t('selectedItems');
    }
  },

  watch: {
    values: async function () {
      await this.setSelectorValues();
    }
  }
});

