























































import Vue, { PropType } from 'vue';
import { AssignedTrainingModuleModel, AssignedTrainingModuleStatus, IdAndNameModel, TrainingModuleItemResult } from '../../../_GeneratedClients/SpotClient';
import moment, { Moment } from 'moment';
import AddOrEditTrainingPathModule from './AddOrEditTrainingPathModule.vue';
import { getEmptyTrainingModule } from '../../repository';
import DisplayValue from '../common/DisplayValue.vue';

export default Vue.extend({

  name: 'EmptyComponent',

  components: {
    AddOrEditTrainingPathModule,
    DisplayValue
  },

  props: {
    assignedModules: { type: Array as PropType<AssignedTrainingModuleModel[]>, required: true },
    canAddOrEdit: { type: Boolean, default: false },
    startDate: { type: moment, required: true },
    stopDate: { type: moment, required: true },
    mustEndInFuture: { type: Boolean, default: false },
    parentLearnerIds: { type: Set as PropType<Set<string>>, required: true }
  },

  data: () => ({
    showAddOrEditModule: false,
    moduleToEdit: {} as AssignedTrainingModuleModel,
    idx: -1
  }),

  methods: {
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getStartDateColor (date: Moment): string {
      return date.isSameOrAfter(this.startDate) && date.isSameOrBefore(this.stopDate) ? 'primary--text' : 'error--text font-weight-bold';
    },
    getStopDateColor (date: Moment): string {
      let good = date.isSameOrAfter(this.startDate) && date.isSameOrBefore(this.stopDate);
      if (this.mustEndInFuture) {
        good = good && date.isSameOrAfter(moment().startOf('day'));
      }
      return good ? 'primary--text' : 'error--text font-weight-bold';
    },
    plusModuleClicked (): void {
      if (this.canAddOrEdit) {
        // create a new empty assigned module
        this.moduleToEdit = {
          module: getEmptyTrainingModule(),
          index: -1,
          disabled: false,
          status: AssignedTrainingModuleStatus.NotStarted,
          referents: [] as IdAndNameModel[],
          startDate: this.startDate.clone(),
          stopDate: this.stopDate.clone(),
          results: [] as TrainingModuleItemResult[]
        };
        this.showAddOrEditModule = true;
        this.$emit('hideError');
      } else {
        this.$emit('addFailed');
      }
    },
    editModuleClicked (module: AssignedTrainingModuleModel, idx: number): void {
      if (this.canAddOrEdit) {
        // edit an exisitng assigned module
        this.idx = idx;
        this.moduleToEdit = { ...module };
        this.showAddOrEditModule = true;
        this.$emit('hideError');
      } else {
        this.$emit('addFailed');
      }
    },
    addModule (module: AssignedTrainingModuleModel): void {
      this.showAddOrEditModule = false;
      this.$emit('add', module);
    },
    updateModule (module:AssignedTrainingModuleModel, idx: string): void {
      this.showAddOrEditModule = false;
      this.$emit('update', module, idx);
    },
    moduleUp (idx: number): void {
      this.$emit('up', idx);
    },
    moduleDown (idx: number): void {
      this.$emit('down', idx);
    },
    deleteModule (idx: number): void {
      this.$emit('remove', idx);
    }
  }
});

