














































import Vue from 'vue';
import { TrainingModuleItemType, UrlTrainingModuleItemModel } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'AddTrainingModuleUrlElement',

  data: () => ({
    newElement: {} as UrlTrainingModuleItemModel,
    errorMessages: { url: '', label: '' },
    globalError: ''
  }),

  mounted () {
    this.newElement = { type: TrainingModuleItemType.Url, url: '', label: '' } as UrlTrainingModuleItemModel;
    this.urlValidator();
    this.labelValidator();
  },

  methods: {
    getBgColor (error: string): string {
      return error === '' ? 'bgAccent' : 'bgError';
    },
    onInsertClicked () {
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      this.$emit('insert', [this.newElement]);
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    urlValidator (): void {
      this.hideGlobalError();
      this.errorMessages.url = '';
      if (this.newElement.url !== this.newElement.url.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.url = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newElement.url.length === 0) {
        // title must be set
        this.errorMessages.url = this.$t('emptyFieldError').toString();
      } else if (this.newElement.url.match(/^https?:\/\/([\da-z.-]+)\.([a-z]{2,6})([/\w.-]*)*\/?/g) === null) {
        this.errorMessages.url = this.$t('notAValidUrl').toString();
      }
    },
    labelValidator (): void {
      this.hideGlobalError();
      this.errorMessages.label = '';
      if (this.newElement.label !== this.newElement.label.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.errorMessages.label = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newElement.label.length === 0) {
        // title must be set
        this.errorMessages.label = this.$t('emptyFieldError').toString();
      }
    }
  }
});

