




























import { getSiteTree } from '../repository';
import Vue from 'vue';
import { TreeItem } from '../../_GeneratedClients/SpotClient';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Sites',

  components: {
    SimplePageLayout
  },

  data: () => ({
    loading: true,
    siteList: [] as TreeItem[],
    openSite: undefined as undefined | number,
    opensiteId: ''
  }),

  async mounted () {
    this.siteList = await getSiteTree();
    if (this.$route.query.cartographyId) {
      const cartographyId = this.$route.query.cartographyId as string;
      for (let index = 0; index < this.siteList.length; index++) {
        if (this.siteList[index].children.some(c => c.item.id === cartographyId)) {
          this.openSite = index;
          this.opensiteId = this.siteList[index].item.id;
          break;
        }
      }
      setTimeout(this.toOpenSite, 200); // must wait after the end of elements creation (arbitrary 200 ms)
    } else if (this.siteList.length === 1) {
      // if there is only one site, show contained cartographies
      this.openSite = 0;
    }
    this.loading = false;
  },

  methods: {
    toOpenSite (): void {
      if (this.opensiteId === '') {
        return;
      }
      const siteTitle = (this.$refs[this.opensiteId] as Element[])[0] as HTMLElement; // div with title (not a VueElement)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const siteContainer = siteTitle.parentElement!.parentElement; // define site container without using VueElement
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      siteContainer!.scrollIntoView({ behavior: 'smooth' }); // scroll to opened site
    }
  }

});
