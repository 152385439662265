














import Vue, { PropType } from 'vue';
import { ContextItemType, ContextModel } from '../../../_GeneratedClients/SpotClient';
import { ContextNodeViewModel } from '../../repository';

export default Vue.extend({

  name: 'DisplayContext',

  props: {
    contexts: { type: Array as PropType<ContextModel[]>, default: () => [] as ContextModel[] },
    elementType: { type: String, default: '' },
    activateLinks: { type: Boolean, default: false }
  },

  methods: {
    onClose () {
      // used only with search bar
      this.$emit('close');
    }
  },

  computed: {
    displayableContext (): ContextNodeViewModel[] {
      const result = [] as ContextNodeViewModel[];
      if (!this.contexts || this.contexts.length === 0) {
        return result;
      }
      // Get the cartographies nodes from all contexts
      const cartographyNodes = this.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Cartography)
        .map((ctxnode) => ({ name: ctxnode.name, link: { name: 'Cartography', params: { cartographyId: ctxnode.id } } })));
      // Get the function nodes in all contexts
      const functionNodes = this.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Function)
        .map(ctxnode => ({ name: ctxnode.name, link: { name: 'ViewFunction', params: { functionId: ctxnode.id } } })));
      // Get the function nodes in all contexts
      const activityNodes = this.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Activity)
        .map(ctxnode => ({ name: ctxnode.name, link: undefined })));
      // Get the subjects nodes from all contexts
      const subjectNodes = this.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Subject)
        .map((ctxnode) => ({ name: ctxnode.name, link: { name: 'Subject', params: { subjectId: ctxnode.id } } })));
      // create context
      switch (this.elementType as ContextItemType) {
        case ContextItemType.Function:
          result.push(cartographyNodes[0]);
          break;
        case ContextItemType.Subject:
          result.push(cartographyNodes[0]);
          if (functionNodes.length > 0) {
            result.push(functionNodes[0]);
          }
          for (const activity of activityNodes) {
            result.push(activity);
          }
          break;
        case ContextItemType.MindmapNode:
          result.push(cartographyNodes[0]);
          if (functionNodes.length > 0) {
            result.push(functionNodes[0]);
          }
          for (const activity of activityNodes) {
            result.push(activity);
          }
          result.push(subjectNodes[0]);
          break;
      }
      return result;
    }
  }
});

