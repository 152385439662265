


































import Vue from 'vue';
import { SubjectModel, UserModel } from '../../_GeneratedClients/SpotClient';
import { getConnectedUser, getSubjectById } from '../repository';
import DisplayContext from '../components/common/DisplayContext.vue';
import AddOrEditSubject from '../components/subjects/AddOrEditSubject.vue';

export default Vue.extend({

  name: 'EditSubject',

  components: {
    DisplayContext,
    AddOrEditSubject
  },

  data: () => ({
    me: {} as UserModel,
    subject: {} as SubjectModel,
    loaded: false
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = connectedUser;
    }
    if (!this.me.permissions.includes('perm_EditPdv')) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
    const subjectId = this.$route.params.subjectId;
    try {
      this.subject = await getSubjectById(subjectId);
      this.loaded = true;
    } catch (err) {
      // the different cases are not treated separately
      console.error(err);
      this.$router.push({ name: 'NotFound' });
    }
  }
});

