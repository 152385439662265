














import Vue, { PropType } from 'vue';
import { MediationTrainingModuleItemModel } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'DisplayTrainingModuleMediationElement',

  props: {
    element: { type: Object as PropType<MediationTrainingModuleItemModel>, required: true }
  }
});

