import moment, { Moment } from 'moment';
import { client } from './Client';
import { VisitsCache } from './Types';

let visitCacheLoadingPromise: Promise<void> | undefined;
let visitsCache: VisitsCache = {};

/**
 * Sets the last visit date on the entity with the specified identifier
 *
 * @param entityId The entity identifier
 * @param date The date to set
 */
export function setEntityLastVisit (entityId: string, date: Moment): void {
  visitsCache[entityId] = date;
}

/**
 * Loads the visits cache from the API
 *
 * @returns the promise
 */
async function getAllVisitsFromApi (): Promise<void> {
  const visits = await client.getVisits();
  for (const key in visits) {
    visitsCache[key] = moment(visits[key]);
  }
}

/**
 * Returns the list of all visits for this user, keyed by entity identifier
 *
 * @returns The visits cache loaded from the API
 */
export async function getAllVisits (): Promise<VisitsCache> {
  await visitsCachePreload();
  return visitsCache;
}

/**
 * Preloads the visits cache. Ugly, but the DB doesn't give us the choice.
 *
 * @returns The promise that resolves when it has been saved.
 */
export async function visitsCachePreload (): Promise<void> {
  if (!visitCacheLoadingPromise) {
    visitCacheLoadingPromise = getAllVisitsFromApi();
  }

  await visitCacheLoadingPromise;
}

/**
 * unload the preloaded list
 */
export function visitsCachePreloadUnload (): void {
  visitCacheLoadingPromise = undefined;
  visitsCache = {};
}
