import { render, staticRenderFns } from "./DisplayMessage.vue?vue&type=template&id=64b87ef6&scoped=true&"
import script from "./DisplayMessage.vue?vue&type=script&lang=ts&"
export * from "./DisplayMessage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64b87ef6",
  null
  
)

/* custom blocks */
import block0 from "./DisplayMessage.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VIcon,VRow})
