


























import Vue, { PropType } from 'vue';

export interface SelectableGenericItem { id: string; label: string; icon: string; nextLevel: string[]; isSelectable: boolean; selected: boolean; }

export default Vue.extend({

  name: 'GenericItemListSelector',

  props: {
    itemsList: { type: Array as PropType<SelectableGenericItem[]>, default: () => [] }
  },

  methods: {
    select (item: SelectableGenericItem, idx: number): void {
      if (item.isSelectable === true) {
        this.$emit('select', idx);
      }
    },
    gotoNextLevel (idx: number, type: number): void {
      this.$emit('nextLevel', idx, type);
    }
  }

});

