













import { getConnectedUser } from '../../repository';
import Vue from 'vue';
import { ContributionsQueryGranularity, UserModel } from '../../../_GeneratedClients/SpotClient';
import ChartContributions from './ChartContributions.vue';

export default Vue.extend({

  name: 'ChartMyContributions',

  components: {
    ChartContributions
  },

  props: {
    height: { type: Number, default: 300 }
  },

  data: () => ({
    me: {} as UserModel,
    granularity: ContributionsQueryGranularity.Month,
    duration: 12, // one year (monthly)
    startComputing: false
  }),

  mounted () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
    }
    this.startComputing = true;
  }
});

