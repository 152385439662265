














import Vue from 'vue';
import moment from 'moment';
import { CreateOrUpdateTrainingModuleRequest, IdAndNameModel, LifetimeEvent } from '../../_GeneratedClients/SpotClient';
import { createTrainingModule, getConnectedUser, getEmptyTrainingModule, isPermissionGranted } from '../repository';
import AddOrEditTrainingModule from '../components/trainingPathModules/AddOrEditTrainingModule.vue';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'NewTrainingModule',

  components: {
    AddOrEditTrainingModule
  },

  data: () => ({
    moduleToEdit: getEmptyTrainingModule(),
    me: { id: '', name: '' } as IdAndNameModel
  }),

  async created () {
    if (!(await isPermissionGranted('perm_TrainingManagement'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = { id: connectedUser.id, name: connectedUser.name };
    }
    this.moduleToEdit.creation = { userId: this.me.id, userName: this.me.name, date: moment() } as LifetimeEvent;
  },

  methods: {
    gotoTrainingModuleList () {
      this.$router.push({ name: 'FilteredTrainingModules' });
    },
    async onSaveClicked (toSave: CreateOrUpdateTrainingModuleRequest): Promise<void> {
      try {
        const result = await createTrainingModule(toSave);
        if (result.id !== null && result.id !== undefined && result.id !== '') {
          this.$router.push({ name: 'ViewTrainingModule', params: { moduleId: result.id } });
        } else {
          this.gotoTrainingModuleList();
        }
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

