















































































import Vue from 'vue';
import { getConnectedUser, getActionableMediations, createMediation, setMediationStatus } from '../../repository';
import { MediationStatus, CreateOrUpdateMediationRequest, RequestedMediationStatus } from '../../../_GeneratedClients/SpotClient';
import { truncate } from '../../Tools';
import eventBus from '../../eventBus';

interface WorkItemAction { icon: string; action: string; }
interface WorkItem { itemId: string; label: string; actions: WorkItemAction[] }

export default Vue.extend({

  name: 'TeamworkMenu',

  data: () => ({
    workItems: [] as WorkItem[],
    workItemsLoaded: false,
    showCreateUnplannedMediationDialogBox: false,
    newMediationTitle: '',
    mediationTitleError: ''
  }),

  methods: {
    async createWorkItemList () {
      this.workItemsLoaded = false;
      this.workItems = [] as WorkItem[];
      const me = getConnectedUser();
      if (me) {
        const actionableMediations = await getActionableMediations();
        let toAdd = false;
        for (const mediation of actionableMediations) {
          const actions = Array<WorkItemAction>();
          toAdd = false;
          if (me.id === mediation.mediator.id) {
            // i am the mediator, can start, pause, stop and validate, depending of mediation status
            switch (mediation.status) {
              case MediationStatus.Created:
                actions.push({ icon: 'mdi-play', action: 'start' });
                toAdd = true;
                break;
              case MediationStatus.Started:
                actions.push({ icon: 'mdi-pause', action: 'pause' });
                actions.push({ icon: 'mdi-stop', action: 'stop' });
                toAdd = true;
                break;
              case MediationStatus.Paused:
                actions.push({ icon: 'mdi-play', action: 'start' });
                actions.push({ icon: 'mdi-stop', action: 'stop' });
                toAdd = true;
                break;
              case MediationStatus.Stopped:
                actions.push({ icon: 'mdi-clipboard-check-outline', action: 'validate' });
                toAdd = true;
                break;
            }
          } else if (mediation.status === MediationStatus.Validated &&
              mediation.participants.some((s) => s.id === me.id) &&
              mediation.signatures.every((s) => s.userId !== me.id)) {
            // im a participant, and i haven't yet signed the validated mediation
            actions.push({ icon: 'mdi-draw', action: 'sign' });
            toAdd = true;
          }
          if (toAdd) {
            this.workItems.push({
              itemId: mediation.id,
              label: mediation.title,
              actions: actions
            });
          }
        }
      }
      this.workItemsLoaded = true;
    },
    async onActionClicked (id: string, action: string) {
      switch (action) {
        case 'start':
          await setMediationStatus(id, { status: RequestedMediationStatus.Started });
          break;
        case 'pause':
          await setMediationStatus(id, { status: RequestedMediationStatus.Paused });
          break;
        case 'stop':
          await setMediationStatus(id, { status: RequestedMediationStatus.Stopped });
          break;
        case 'validate':
        case 'sign':
          this.$router.push({ name: 'ViewMediation', params: { mediationId: id } });
      }
    },
    showCreateMediationDialogBox () {
      this.newMediationTitle = '';
      this.showCreateUnplannedMediationDialogBox = true;
      this.mediationTitleValidator();
    },
    async createNewUnplannedMediation () {
      if (this.mediationTitleError !== '') {
        return;
      }
      const me = getConnectedUser();
      this.showCreateUnplannedMediationDialogBox = false;
      if (me) { // always true, but to avoid a "possibly null error"
        const newMediation: CreateOrUpdateMediationRequest = {
          title: this.newMediationTitle,
          description: '',
          mediator: me.id,
          participants: [],
          visibleByGroups: [],
          visibleByPersons: []
        };
        try {
          await createMediation(newMediation);
        } catch (err) {
          eventBus.$emit('error', err, null);
        }
      }
    },
    mediationTitleValidator () {
      this.mediationTitleError = '';
      if (this.newMediationTitle !== this.newMediationTitle.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.mediationTitleError = this.$t('beginEndWhitespaceError').toString();
      } else if (this.newMediationTitle.length === 0) {
        // name must be set
        this.mediationTitleError = this.$t('emptyFieldError').toString();
      }
    },
    getLabel (label: string): string {
      return truncate(label, 25);
    }
  }
});

