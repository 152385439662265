import { getAllCartographies } from './Search';
import { ActivityModel, CartographyStatsModel, CartographyZone, ContextItemType, IdAndNameModel, TreeItem } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';

export interface CartographyActivityCounter {
  id: string;
  name: string;
  subjectsCount: number;
}

/**
 * Gets the activity pictograms for a cartography
 *
 * @param cartographyId The identifier of the cartography for which to retrieve the activity pictos
 * @returns The pictos, as data URL for each activity id
 */
export function getCartographyActivityPictos (cartographyId: string): Promise<Record<string, string>> {
  return client.getCartographyActivityPictos(cartographyId);
}

/**
 * Gets a list of clickable zones according to a cartography image
 *
 * @param cartographyId the cartography identifier
 * @returns the list of clickable zones as CartographyZone model
 */
export function getCartographyImageZones (cartographyId: string): Promise<CartographyZone[]> {
  return client.getCartographyImageZones(cartographyId);
}

/**
 * Gets the read/write stats for a given cartography
 *
 * @param cartographyId The cartography identifier
 * @returns The requested stats
 */
export async function getCartographyStats (cartographyId: string): Promise<CartographyStatsModel> {
  const carto = (await getAllCartographies()).find(c => c.item.id === cartographyId);
  if (carto === undefined) {
    throw new Error('Cartography not found');
  }

  return carto.item.stats;
}

/**
 * Gets the read/write stats for a given cartography
 *
 * @param cartographyId The cartography identifier
 * @returns The requested stats
 */
export async function getCartographyActivitiesCounters (cartographyId: string): Promise<CartographyActivityCounter[]> {
  const carto = (await getAllCartographies()).find(c => c.item.id === cartographyId);
  if (carto === undefined) {
    throw new Error('Cartography not found');
  }

  return carto.item.activities.map(activity => ({
    id: activity.id,
    name: activity.name,
    subjectsCount: getTotalSubjectsInActivity(activity)
  }));
}

/**
 * Gets the subjects count in an ActivityModel and all its children
 *
 * @param activity The activity
 * @returns The number of subjects
 */
export function getTotalSubjectsInActivity (activity: ActivityModel): number {
  return activity.subjects.length + activity.activities.reduce((accumulator, currentValue) => accumulator + getTotalSubjectsInActivity(currentValue), 0);
}

/**
 * gets the list of groups allowed to see a cartography. Can be allowed directly on cartography or from parent site (concatenation of two sources)
 *
 * @param cartographyId the identifier of the cartography
 * @returns the list of groups allowed to see the cartography
 */
export async function getCartographyAllowedGroups (cartographyId: string): Promise<IdAndNameModel[]> {
  const groupIds = await client.getAllowedGroupsForItem(ContextItemType.Cartography, cartographyId);
  const groupNames = await client.getGroupNames(groupIds);
  return Object.keys(groupNames).map(k => ({ id: k, name: groupNames[k] }));
}

/**
 * gets the entire tree of sites and catographies
 *
 * @returns the cartographies tree (all cartographies for all sites)
 */
export async function getCartographiesTree (): Promise<TreeItem[]> {
  return await client.getCartographiesTree();
}

/**
 * gets the list of items (sites and cartographies) that can be seen by a given group
 *
 * @param groupId the identifier of the group
 * @returns the list of items (sites and cartographies) than the group can see
 */
export async function getAllowedItemsForGroup (groupId: string): Promise<string[]> {
  return await client.getAllowedItemsForGroup(groupId);
}

/**
 * Sets the list of items (sites and cartographies) that can be seen by a given groups
 *
 * @param groupId the identifier of the group
 * @param allowedItemIds the list of allowed items for this group
 */
export async function setAllowedItemsForGroup (groupId: string, allowedItemIds: string[]): Promise<void> {
  await client.setAllowedItemsForGroup(groupId, allowedItemIds);
}

/**
 * gets the map of cartography-site names given their id (format : "cartography_name (site_name)")
 *
 * @param cartogarphyIds The cartography identifiers for which to resolve names
 * @returns he map that gives the identifiers as key, and the cartography-site names as values. Id that are not found are ignored in the result.
 */
export async function getCartographySiteNames (cartogarphyIds: string[]): Promise<Record<string, string>> {
  return await client.getCartographySiteNames(cartogarphyIds);
}
