










import { getTrainingPathProgressLegendColor, TrainingPathProgressLegend } from '../../repository';
import Vue, { PropType } from 'vue';

export default Vue.extend({

  name: 'DisplayTrainingPathProgressLegend',

  props: {
    value: { type: Object as PropType<TrainingPathProgressLegend>, required: true }
  },

  methods: {
    legendColor (state: string): string {
      return getTrainingPathProgressLegendColor(state);
    },
    toggle (key: keyof TrainingPathProgressLegend): void {
      this.$emit('input', { ...this.value, [key]: !this.value[key] });
    }
  }
});

