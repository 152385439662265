import { FieldType, FrontendAndQueryFieldConfiguration } from '../queryEngine';
import i18n from '../i18n';
import { CartographyModel } from '../../_GeneratedClients/SpotClient';
import { autocompleteSite, resolveSpotItemNames, SpotItem } from '../repository';

export default {
  name: {
    type: FieldType.string,
    displayName: i18n.t('cartography.filter.content.name').toString(),
    getter: c => [c.item.name],
    sortable: true
  },
  location: {
    type: FieldType.id,
    displayName: i18n.t('cartography.filter.parent.location').toString(),
    getter: c => [...c.contexts.flatMap(ctx => ctx.hierarchy[0].id)],
    autocomplete: autocompleteSite,
    resolveNames: resolveSpotItemNames
  },
  site: {
    type: FieldType.string,
    displayName: i18n.t('cartography.filter.parent.site').toString(),
    getter: c => [...c.contexts[0].hierarchy.map(h => h.name), c.item.name],
    filterable: false,
    sortable: true
  }
} as Record<string, FrontendAndQueryFieldConfiguration<SpotItem<CartographyModel>>>;
