


















import Vue, { PropType } from 'vue';
import { ISearchResponseCategory } from '../../repository';

export default Vue.extend({

  name: 'SearchTitle',

  props: {
    waiting: { type: Boolean, default: false },
    title: { type: String, default: ' ??? ' },
    itemList: { type: Object as PropType<ISearchResponseCategory>, default: () => ({ total: 0, items: [] }) },
    isReduced: { type: Boolean, default: true },
    minDisplay: { type: Number, default: 1 }
  },

  methods: {
    onExpandReduceClicked () {
      const action = this.isReduced ? 'expand' : 'reduce';
      this.$emit(action);
    }
  },

  computed: {
    getCounter () : string {
      if (this.waiting === true) {
        return '';
      }
      if (this.itemList.total <= this.minDisplay || this.itemList.total === this.itemList.items.length) {
        return '(' + this.itemList.total + ')';
      }
      return '(1-' + this.itemList.items.length + '/' + this.itemList.total + ')';
    }
  }
});

