






















import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import Vue from 'vue';
import DisplayQualificationElement from '../components/qualifications/DisplayQualificationElement.vue';
import { getConnectedUser, getInitialQualificationLegend, getUserQualificationModuleState, getUserQualificationState, isPermissionGranted, QualificationDashboardGroup, QualificationDashboardSecondaryInfo, queryUserQualifications, resolveTrainingModuleNames, resolveUserNames, filterQualificationDashboard } from '../repository';
import { sortArrayByNumber } from '../Tools';
import { ApiException, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';
import DisplayQualificationLegend from '../components/qualifications/DisplayQualificationLegend.vue';

export default Vue.extend({

  name: 'UserQualificationDashboard',

  components: {
    DisplayQualificationElement,
    DisplayQualificationLegend,
    SimplePageLayout
  },

  data: () => ({
    legendSelector: getInitialQualificationLegend(),
    loading: true,
    qualifications: [] as QualificationDashboardGroup[],
    user: { id: '', name: '' } as IdAndNameModel
  }),

  async mounted () {
    const connectedUser = await getConnectedUser();
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    this.user.id = connectedUser!.id;
    const askedUserId = this.$route.params.userId ?? '';
    if (askedUserId !== '' && askedUserId !== this.user.id) {
      // logged user isn't asked user -> logged user must have permission to see dashboards
      if (!(await isPermissionGranted('perm_TableauBord'))) {
        // user is not allowed to navigate there -> go to forbidden page
        this.$router.replace({ name: 'Forbidden' });
        return;
      }
      const userName = (await resolveUserNames([askedUserId]))[askedUserId];
      if (!userName) {
        this.$router.replace({ name: 'NotFound' });
      }
      this.user = { id: askedUserId, name: userName };
    }
    try {
      // get qualifications data for the user
      const query = 'user oneOf ' + this.user.id;
      const userQualificationList = (await queryUserQualifications({ query: query })).results;
      const moduleIds = userQualificationList.flatMap(uq => uq.modules.map(m => m.module));
      const moduleNameMapping = await resolveTrainingModuleNames([...moduleIds]);
      for (const qualif of userQualificationList) {
        const currentQualification: QualificationDashboardGroup = {
          item: {
            id: qualif.qualification,
            name: qualif.qualificationName,
            total: qualif.modules.length,
            obtained: 0,
            qualificationState: getUserQualificationState(qualif).qualificationState,
            link: { name: 'ViewQualification', params: { qualificationId: qualif.qualification } }
          },
          children: []
        };
        for (const module of qualif.modules) {
          const currentModule: QualificationDashboardSecondaryInfo = {
            id: module.module,
            name: moduleNameMapping[module.module],
            obtainedlinkId: module.validationTrainingPath ?? '',
            progressLinkId: module.requalificationTrainingPath ?? '',
            userLinkId: '', // not used here
            ...getUserQualificationModuleState(module)
          };
          if (currentModule.remainingDays >= 0) {
            currentQualification.item.obtained++;
          }
          currentQualification.children.push(currentModule);
        }
        // sort modules
        sortArrayByNumber(currentQualification.children, c => c.remainingDays, 'desc');
        this.qualifications.push(currentQualification);
      }
      // resolve module names
      this.loading = false;
    } catch (err) {
      if (err instanceof ApiException && err.status === 404) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      } else {
        eventBus.$emit('error', err, null);
      }
    }
  },

  computed: {
    filteredQualifications (): QualificationDashboardGroup[] {
      return filterQualificationDashboard(this.qualifications, this.legendSelector);
    }
  }
});

