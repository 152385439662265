/**
 * An error that occurs when the given query is invalid
 */
export class QueryParsingError extends Error {
  position: number;

  /**
   * The constructor
   *
   * @param position The error position
   * @param message The error message
   */
  constructor (position: number, message: string) {
    super(message);
    this.position = position;
  }
}
