





import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';

export default Vue.extend({

  name: 'PanZoomZoneArea',

  props: {
    coords: { type: String, required: true },
    to: { type: Object as PropType<Location>, required: true }
  },

  mounted () {
    let touchStart: {x: number, y: number} | undefined;
    const area = this.$refs.area as HTMLAreaElement;
    area.addEventListener('click', (evt) => {
      evt.preventDefault();
    });
    area.addEventListener('pointerdown', (evt) => {
      if (!evt.isPrimary || evt.button !== 0) {
        return;
      }
      touchStart = { x: evt.screenX, y: evt.screenY };
    });
    area.addEventListener('pointerup', (evt) => {
      if (!evt.isPrimary || evt.button !== 0) {
        return;
      }
      const touchEnd = { x: evt.screenX, y: evt.screenY };
      if (touchStart !== undefined) {
        const distance = Math.sqrt(Math.pow(touchEnd.x - touchStart.x, 2) + Math.pow(touchEnd.y - touchStart.y, 2));
        if (distance < 10) {
          this.$router.push(this.to);
        }
      }
    });
  },

  computed: {
    link (): string {
      return this.$router.resolve(this.to).href;
    }
  }
});

