import { autocompleteUser, resolveUserNames } from '../repository';
import { FieldType } from '../queryEngine';
import i18n from '../i18n';
import { MediationStatus } from '../../_GeneratedClients/SpotClient';
import { FrontendFieldConfiguration } from '../queryEngine/frontendFieldConfiguration';

// Must be kept in sync with the backend
export default {
  title: {
    type: FieldType.string,
    displayName: i18n.t('mediation.filter.content.title').toString(),
    sortable: true
  },
  description: {
    type: FieldType.string,
    displayName: i18n.t('mediation.filter.content.description').toString()
  },
  scheduled: {
    type: FieldType.boolean,
    displayName: i18n.t('mediation.filter.scheduled.planned').toString(),
    sortable: true
  },
  scheduling_date: {
    type: FieldType.date,
    displayName: i18n.t('mediation.filter.date.scheduling').toString(),
    sortable: true
  },
  creator: {
    type: FieldType.id,
    displayName: i18n.t('mediation.filter.person.creator').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  mediator: {
    type: FieldType.id,
    displayName: i18n.t('mediation.filter.person.mediator').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  status: {
    type: FieldType.enum,
    displayName: i18n.t('mediation.status').toString(),
    enumType: MediationStatus,
    frontendEnumValues: Object.values(MediationStatus).map(status => ({ id: status, name: i18n.t('mediation.steps.' + status).toString() })),
    sortable: true
  },
  last_update: {
    type: FieldType.date,
    displayName: i18n.t('mediation.filter.date.updated').toString(),
    sortable: true
  },
  execution_start: {
    type: FieldType.date,
    displayName: i18n.t('mediation.filter.date.executionStart').toString(),
    sortable: true
  },
  execution_stop: {
    type: FieldType.date,
    displayName: i18n.t('mediation.filter.date.executionStop').toString(),
    sortable: true
  },
  participants: {
    type: FieldType.id,
    displayName: i18n.t('mediation.filter.person.participants').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  }
} as Record<string, FrontendFieldConfiguration>;
