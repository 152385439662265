import { LocalDate } from '@js-joda/core';
import { MultipleQueryCriteriaType, QueryCriterion, SingleQueryCriterion } from './criteria';
import { FieldConfiguration, FieldType } from './fieldConfiguration';
import { BooleanComparisonOperator, DateComparisonOperator, EnumComparisonOperator, StringComparisonOperator } from './operators';
import { QueryParsingError } from './QueryParsingError';

/*
EBNF of the query syntax:

Query = Term | AndQuery | OrQuery
Term = ({" "}, "(" , Query , ")", {" "}) | SingleQueryCriterion
AndQuery = Term, "&", Term, {"&", Term}
OrQuery = Term, "|", Term, {"|", Term}
SingleQueryCriterion = {" "}, Field, {" "}, Operator, {" "}, ( Value | MultipleValue ), {" "}
Field = ? ASCII letters and digits ?
Operator = " ", ? operators from operators.ts ?, " "
Value = ? Any string, but following characters must be escaped with a \ : "()|&, \" . Empty value is replaced with \0 ?
MultipleValue = Value, { {" "}, ",", {" "}, Value}
*/
const specialCharsRegex = /([()|&, \\])/;

/**
 * Transforms a query into its string representation
 *
 * @param query The query to serialize
 * @returns the string representation of the query
 */
export function serializeQuery (query: QueryCriterion | null): string {
  if (query === null) {
    return '';
  }

  if (query.type === MultipleQueryCriteriaType.and || query.type === MultipleQueryCriteriaType.or) {
    if (query.criteria.length === 0) {
      return '';
    } else if (query.criteria.length === 1) {
      // Simplify by removing the "multiple" stage, and avoids wrapping in useless parenthesis
      return serializeQuery(query.criteria[0]);
    }

    return '(' + query.criteria.map(criterion => serializeQuery(criterion)).join(query.type === MultipleQueryCriteriaType.and ? '&' : '|') + ')';
  }

  const singleQuery = query as SingleQueryCriterion;
  return singleQuery.property + ' ' + singleQuery.operator + ' ' + serializeQueryValue(singleQuery);
}

/**
 * Serializes the value(s) of a single criterion
 *
 * @param query The criterion for which to serialize the value(s)
 * @returns The serialized and escaped values
 */
function serializeQueryValue (query: SingleQueryCriterion): string {
  if (query.type === FieldType.enum || query.type === FieldType.id) {
    return query.values.map(v => encodeToken(v)).join(',');
  } else {
    // NOTE: LocalDate.toString() returns the date formatted as YYYY-MM-DD, so there's only one code path
    return encodeToken(query.value.toString());
  }
}

/**
 * Escapes special characters for inclusion in a query
 *
 * @param value The value to escape
 * @returns The escaped value
 */
export function encodeToken (value: string) : string {
  if (value === '') {
    return '\\0'; // \0 means empty string
  }
  return value.replace(new RegExp(specialCharsRegex, 'g'), '\\$1');
}

/**
 * Extracts the given part of the query and decodes it (removes escaping slashes)
 *
 * @param query The query string to decode
 * @param startIndex The start index of the token to decode
 * @param endIndex The end index (exclusive) of the token to decode
 * @returns The decoded token
 */
function decodeToken (query: string, startIndex: number, endIndex: number): string {
  let currentIndex = startIndex;
  const characters: string[] = [];
  while (currentIndex < endIndex) {
    if (query[currentIndex] === '\\') {
      // Escape sequence, decode
      currentIndex++;
      if (currentIndex === endIndex) {
        throw new QueryParsingError(currentIndex, 'Unterminated escape sequence');
      }
      const escapedChar = query[currentIndex];
      if (escapedChar === '0') { // \0 means empty string, so skip that token
        currentIndex++;
        continue;
      } else if (!specialCharsRegex.test(escapedChar)) {
        throw new QueryParsingError(currentIndex, `Escaped char '${escapedChar}' is not a special char`);
      }
    }

    characters.push(query[currentIndex]);
    currentIndex++;
  }

  return characters.join('');
}

/**
 * Transforms a string representation of a query into its QueryCriterion equivalent
 *
 * Throws if it failed to parse
 *
 * @param fieldsConfiguration The fields configuration
 * @param query The query to deserialize
 * @throws When a parsing error occurs
 * @returns The deserialized query or null if the query string was empty.
 */
export function parseQuery (fieldsConfiguration: Record<string, FieldConfiguration>, query: string): QueryCriterion | null {
  return parseSubQuery(fieldsConfiguration, query, 0, query.length);
}

/**
 * Parses a substring as a query
 *
 * @param fieldsConfiguration The fields configuration
 * @param query The query to deserialize
 * @param startIndex The beginning of the part to deserialize
 * @param endIndex The end (exclusive) of the part to deserialize
 * @throws When a parsing error occurs
 * @returns The deserialized query or null if the query string was empty.
 */
function parseSubQuery (fieldsConfiguration: Record<string, FieldConfiguration>, query: string, startIndex: number, endIndex: number): QueryCriterion | null {
  let currentIndex = skipSpaces(query, startIndex, endIndex);
  let multipleQueryType: MultipleQueryCriteriaType | null = null;
  const terms: QueryCriterion[] = [];

  let lastSeenToken: 'operator' | 'term' | null = null;

  // General shape lookup : identify terms and operators.
  // We need to differenciate between the following shapes:
  // - AndQuery : Term & Term... => Returns a MultipleQueryCriteria
  // - OrQuery : Term | Term... => Returns a MultipleQueryCriteria
  // - wrapped query : (Query) => Unwraps and calls parseSubQuery recursively
  // - SingleQueryCriterion : a equals 123 => Returns a SingleQueryCriterion
  while (currentIndex < endIndex) {
    const currentChar = query[currentIndex];
    if (lastSeenToken === 'term') {
      // Now, expect an operator
      switch (currentChar) {
        case '&':
          if (multipleQueryType !== null && multipleQueryType !== MultipleQueryCriteriaType.and) {
            throw new QueryParsingError(currentIndex, 'Cannot mix & and | in the same query without parenthesis');
          }

          multipleQueryType = MultipleQueryCriteriaType.and;
          break;
        case '|':
          if (multipleQueryType !== null && multipleQueryType !== MultipleQueryCriteriaType.or) {
            throw new QueryParsingError(currentIndex, 'Cannot mix & and | in the same query without parenthesis');
          }

          multipleQueryType = MultipleQueryCriteriaType.or;
          break;
        default: throw new QueryParsingError(currentIndex, `Unexpected character ${currentChar}`);
      }

      currentIndex = skipSpaces(query, currentIndex + 1, endIndex);
      lastSeenToken = 'operator';
    } else {
      // We expect a term or a subquery wrapped with ()
      if (currentChar === '(') {
        const endParenthesisIndex = findMatchingParenthesisIndex(query, currentIndex, endIndex);
        // Parse the criterion
        const subResult = parseSubQuery(fieldsConfiguration, query, currentIndex + 1, endParenthesisIndex);
        if (subResult === null) {
          throw new QueryParsingError(currentIndex, 'Parenthesis does not contain a query');
        }

        terms.push(subResult);
        currentIndex = skipSpaces(query, endParenthesisIndex + 1, endIndex);
      } else if (specialCharsRegex.test(currentChar)) {
        // It's a separator, that's not expected
        throw new QueryParsingError(currentIndex, `Unexpected character ${currentChar}`);
      } else {
        // Parse the term as a single criterion
        const subResult = parseSingleCriterion(fieldsConfiguration, query, currentIndex, endIndex);
        terms.push(subResult.term);
        currentIndex = subResult.endIndex; // parseSingleCriterion already skips space
      }

      lastSeenToken = 'term';
    }
  }

  if (lastSeenToken === 'operator') {
    throw new QueryParsingError(currentIndex, 'An operator must be followed by a term');
  }

  if (terms.length === 0) {
    return null;
  }

  if (terms.length === 1) {
    return terms[0];
  }

  if (multipleQueryType === null) {
    throw new QueryParsingError(currentIndex, 'Multiple terms were parsed, but no operator was found. This is a bug in the algorithm.');
  }

  return {
    type: multipleQueryType,
    criteria: terms
  };
}

/**
 * Gets the index of the matching parenthesis
 *
 * @param query The query string to analyze
 * @param startIndex The index of the opening parenthesis
 * @param endIndex The limit of the string to consider
 * @returns the index of the matching parenthesis
 */
function findMatchingParenthesisIndex (query: string, startIndex: number, endIndex: number): number {
  let parenthesisCount = 1;
  let currentIndex = startIndex + 1;
  while (currentIndex < endIndex) {
    if (query[currentIndex] === '(') {
      parenthesisCount++;
    } else if (query[currentIndex] === ')') {
      parenthesisCount--;
      if (parenthesisCount === 0) {
        return currentIndex;
      }
    } else if (query[currentIndex] === '\\') {
      // Skip next character
      currentIndex++;// Note: if \ is the last character, that's not an issue here since we don't access the item before checking bounds in the while condition.
    }
    currentIndex++;
  }

  throw new QueryParsingError(startIndex, 'Matching parenthesis not found');
}

/**
 * Parses a single criterion of the form "Field Operator Value"
 *
 * @param fieldsConfiguration The fields configuration
 * @param query The query to deserialize
 * @param startIndex The beginning of the part to deserialize
 * @param endIndex The end (exclusive) of the part to deserialize
 * @throws When a parsing error occurs
 * @returns The deserialized criterion, and the end position (exclusive) of the substring that was parsed
 */
function parseSingleCriterion (fieldsConfiguration: Record<string, FieldConfiguration>, query: string, startIndex: number, endIndex: number): {endIndex: number, term: SingleQueryCriterion} {
  const fieldParsing = readToken(query, startIndex, endIndex);
  let currentIndex = fieldParsing.endIndex;
  const field = fieldsConfiguration[fieldParsing.token];
  if (!field) {
    throw new QueryParsingError(currentIndex, `Field ${fieldParsing.token} is not valid for this query`);
  }

  const operatorIndex = currentIndex;
  const operatorParsing = readToken(query, currentIndex, endIndex);
  currentIndex = operatorParsing.endIndex;

  let result: SingleQueryCriterion;
  switch (field.type) {
    case FieldType.string:
      {
        const valueParsing = readToken(query, currentIndex, endIndex);
        currentIndex = valueParsing.endIndex;

        result = {
          type: field.type,
          property: fieldParsing.token,
          operator: parseOperator(StringComparisonOperator, operatorParsing.token, operatorIndex),
          value: valueParsing.token
        };
      }
      break;
    case FieldType.date:
      {
        const valueParsing = readToken(query, currentIndex, endIndex);

        let date;
        try {
          date = LocalDate.parse(valueParsing.token);
        } catch (err) {
          throw new QueryParsingError(currentIndex, 'Invalid date. Must be formatted as YYYY-MM-DD');
        }

        currentIndex = valueParsing.endIndex;

        result = {
          type: field.type,
          property: fieldParsing.token,
          operator: parseOperator(DateComparisonOperator, operatorParsing.token, operatorIndex),
          value: date
        };
      }
      break;
    case FieldType.boolean:
      {
        const valueParsing = readToken(query, currentIndex, endIndex);

        if (valueParsing.token !== 'true' && valueParsing.token !== 'false') {
          throw new QueryParsingError(currentIndex, 'Boolean value must be "true" or "false".');
        }

        currentIndex = valueParsing.endIndex;

        result = {
          type: field.type,
          property: fieldParsing.token,
          operator: parseOperator(BooleanComparisonOperator, operatorParsing.token, operatorIndex),
          value: valueParsing.token === 'true'
        };
      }
      break;
    case FieldType.enum:
      {
        const valueParsing = readTokenList(query, currentIndex, endIndex);
        const missingEnumValues = valueParsing.tokens.filter(e => !Object.values(field.enumType).includes(e));
        if (missingEnumValues.length > 0) {
          throw new QueryParsingError(currentIndex, `${missingEnumValues.join(', ')} is/are missing from the enum type.`);
        }
        currentIndex = valueParsing.endIndex;

        result = {
          type: field.type,
          property: fieldParsing.token,
          operator: parseOperator(EnumComparisonOperator, operatorParsing.token, operatorIndex),
          values: valueParsing.tokens
        };
      }
      break;
    case FieldType.id:
      {
        const valueParsing = readTokenList(query, currentIndex, endIndex);
        currentIndex = valueParsing.endIndex;

        result = {
          type: field.type,
          property: fieldParsing.token,
          operator: parseOperator(EnumComparisonOperator, operatorParsing.token, operatorIndex),
          values: valueParsing.tokens
        };
      }
      break;
  }

  return {
    endIndex: currentIndex,
    term: result
  };
}

/**
 * Checks that the operator is of the given enum type, and returns it properly typed.
 *
 * @param enumType The enumeration
 * @param operator The read operator value
 * @param originalIndex The index of the operator in the original query string (used if an error occurs)
 * @returns The operator value, typed as T
 */
function parseOperator<T> (enumType: Record<string, T>, operator: string, originalIndex: number) : T {
  const convertedValue = operator as unknown as T;
  if (Object.values(enumType).includes(convertedValue)) {
    // It is a valid enum value, return it
    return convertedValue;
  }

  throw new QueryParsingError(originalIndex, 'This operator is not valid for this field type.');
}

/**
 * Reads a token (field name, operator or single value) until the next special character.
 * Skips spaces before/after the token.
 * All other special characters at the beginning must have been skipped before this method is called.
 *
 * @param query The query to deserialize
 * @param startIndex The beginning of the part to deserialize
 * @param endIndex The end (exclusive) of the part to deserialize
 * @throws When a parsing error occurs
 * @returns The read value and the position of the delimiter, just after the read value.
 */
function readToken (query: string, startIndex: number, endIndex: number): {endIndex: number, token: string} {
  startIndex = skipSpaces(query, startIndex, endIndex);
  let currentIndex = startIndex;
  while (currentIndex < endIndex) {
    const currentChar = query[currentIndex];

    if (currentChar === '\\') {
      // Escape sequence, skip next and continue
      currentIndex += 2;
    } else if (specialCharsRegex.test(currentChar)) {
      // It is a delimiter
      break;
    } else {
      currentIndex++;
    }
  }

  if (currentIndex === startIndex) {
    throw new QueryParsingError(currentIndex, 'The token must not be empty');
  }

  const decodedToken = decodeToken(query, startIndex, currentIndex);

  currentIndex = skipSpaces(query, currentIndex, endIndex);

  return { endIndex: currentIndex, token: decodedToken };
}

/**
 * Reads a token list, like a list of values in an enum criterion.
 * Skips spaces before/after the tokens.
 * All other special characters at the beginning must have been skipped before this method is called.
 *
 * @param query The query to deserialize
 * @param startIndex The beginning of the part to deserialize
 * @param endIndex The end (exclusive) of the part to deserialize
 * @throws When a parsing error occurs
 * @returns The read value and the position of the delimiter, just after the read value.
 */
function readTokenList (query: string, startIndex: number, endIndex: number): {endIndex: number, tokens: string[]} {
  startIndex = skipSpaces(query, startIndex, endIndex);
  let currentIndex = startIndex;
  const tokens: string[] = [];
  while (true) {
    const subResult = readToken(query, currentIndex, endIndex);
    currentIndex = subResult.endIndex;
    tokens.push(subResult.token);

    if (currentIndex === endIndex || query[currentIndex] !== ',') {
      // End reached
      break;
    }
    // Skip the ,
    currentIndex++;
  }

  return { endIndex: currentIndex, tokens: tokens };
}

/**
 * Returns the next position of a character that is not a space
 *
 * @param query The query to deserialize
 * @param startIndex The beginning of the part to deserialize
 * @param endIndex The end (exclusive) of the part to deserialize
 * @returns The index of the next token
 */
function skipSpaces (query: string, startIndex: number, endIndex: number): number {
  let currentIndex = startIndex;
  while (currentIndex < endIndex && query[currentIndex] === ' ') {
    currentIndex++;
  }

  return currentIndex;
}
