











































































































































































































import Vue from 'vue';
import {
  ContextViewModel,
  dataPreloadUnload,
  deleteSubject,
  followSubject,
  getConnectedUser,
  getContextViewModel,
  getDiagramV1Link,
  getMindmapV1Link,
  getSubjectById,
  isPermissionGranted,
  rateSubject,
  Reloadable,
  resolveSubject,
  standardizeSubject
} from '../repository';
import moment from 'moment';
import DisplayContext from '../components/common/DisplayContext.vue';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayMindMap from '../components/subjects/DisplayMindMap.vue';
import DisplayDiagram from '../components/subjects/DisplayDiagram.vue';
import Discussion from '../components/common/Discussion.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { LifetimeEvent, ContextModel, SubjectModel, SubjectLifetimeEventModel } from '../../_GeneratedClients/SpotClient';
import { TranslateResult } from 'vue-i18n';
import { Location } from 'vue-router';
import { backToList } from '../helpers/filteredListNavigation';
import DoublePageLayout from '../components/presentation/DoublePageLayout.vue';
import DisplayConfirmationDialogBox from '../components/common/DisplayConfirmationDialogBox.vue';
import DisplayStandardizedMindMap from '../components/subjects/DisplayStandardizedMindMap.vue';
import ResourceGallery from '../components/common/resources/ResourceGallery.vue';

interface RatingModel { text: TranslateResult; value: number; }

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Subject',

  components: {
    Discussion,
    DisplayConfirmationDialogBox,
    DisplayContext,
    DisplayDiagram,
    DisplayLifetimeEvent,
    DisplayMindMap,
    DisplayStandardizedMindMap,
    DisplayValue,
    DoublePageLayout,
    ResourceGallery
  },

  data: () => ({
    subject: {} as SubjectModel,
    creation: undefined as LifetimeEvent | undefined,
    lastUpdate: undefined as LifetimeEvent | undefined,
    followed: undefined as LifetimeEvent | undefined,
    resolved: undefined as LifetimeEvent | undefined,
    criticity: undefined as LifetimeEvent | undefined,
    standardized: undefined as LifetimeEvent | undefined,
    displayFollowForm: false,
    displayResolveForm: false,
    displayCriticityForm: false,
    frequencyItems: [] as RatingModel[],
    frequencyInput: 0,
    frequencyValue: 0,
    gravityItems: [] as RatingModel[],
    gravityInput: 0,
    gravityValue: 0,
    displayStandardizeForm: false,
    discussionId: '',
    canStandardize: false,
    canEdit: false,
    canDelete: false,
    showConfirmDeletion: false,
    loaded: false,
    disableButtons: false
  }),

  async mounted () {
    this.canStandardize = await isPermissionGranted('perm_ValidNotHaute');
    this.canEdit = await isPermissionGranted('perm_EditPdv');
    this.canDelete = await isPermissionGranted('perm_RemPdv');
    await this.loadPageData();
  },

  methods: {
    async loadPageData (): Promise<void> {
      const subjectId = this.$route.params.subjectId;
      if (subjectId === '' || subjectId === undefined || subjectId === null) {
        this.$router.push('/NotFound');
        return;
      }
      try {
        this.subject = { ...await getSubjectById(subjectId) };
        this.subject.lifetimeEvents = { ...this.subject.lifetimeEvents };
        this.discussionId = this.subject.discussionId;
        this.initItemLists();
        for (const eventType of Object.keys(this.subject.lifetimeEvents) as (keyof SubjectLifetimeEventModel)[]) {
          this[eventType] = this.subject.lifetimeEvents[eventType];
        }
        if (this.subject.ratings) {
          this.frequencyInput = this.subject.ratings.frequency;
          this.frequencyValue = this.frequencyInput;
          this.gravityInput = this.subject.ratings.gravity;
          this.gravityValue = this.gravityInput;
        }
        this.loaded = true;
      } catch (err) {
        console.error(err);
        // TODO : get return error, log (?), send error message (?), .. do something else ?
        this.$router.push('/NotFound');
      }
    },
    // updates local subjectDetailViewModel (to update current display)
    updateEventTime (propertyName: keyof SubjectLifetimeEventModel): void {
      const connectedUser = getConnectedUser();
      if (!connectedUser) {
        console.error('Cannot get the connected user info. Are you connected ?');
        return;
      }
      const event: LifetimeEvent = { userId: connectedUser.id, userName: connectedUser.name, date: moment() };
      this[propertyName] = event;
      this.subject.lifetimeEvents[propertyName] = { ...event };
      this.lastUpdate = event;
      this.subject.lifetimeEvents.lastUpdate = { ...event };
      this.refreshDiscussion();
    },
    refreshDiscussion (): void {
      (this.$refs.discussion as Reloadable).reload();
    },
    goBackToList (): void {
      this.$router.push(backToList('FilteredSubjects'));
    },
    async onConfirmFollow (): Promise<void> {
      this.displayFollowForm = false;
      await followSubject(this.subject.id);
      this.updateEventTime('followed');
    },
    async onConfirmResolved (): Promise<void> {
      this.displayResolveForm = false;
      await resolveSubject(this.subject.id);
      this.updateEventTime('resolved');
      // set followed if not yet set
      if (!this.followed) {
        this.updateEventTime('followed');
      }
    },
    openCriticityForm (): void {
      this.frequencyInput = this.frequencyValue;
      this.gravityInput = this.gravityValue;
      this.displayCriticityForm = true;
    },
    async onConfirmCriticity (): Promise<void> {
      this.displayCriticityForm = false;
      this.frequencyValue = this.frequencyInput;
      this.gravityValue = this.gravityInput;
      this.subject.ratings = { frequency: this.frequencyValue, gravity: this.gravityValue, detectability: 0 };
      await rateSubject(this.subject.id, this.subject.ratings);
      this.updateEventTime('criticity');
    },
    async onConfirmStandardized (): Promise<void> {
      this.displayStandardizeForm = false;
      await standardizeSubject(this.subject.id);
      this.updateEventTime('standardized');
    },
    initItemLists (): void {
      for (let i = 0; i < 6; i++) {
        this.frequencyItems.push({ text: this.$t('frequencyLevel.' + i), value: i });
        this.gravityItems.push({ text: this.$t('gravityLevel.' + i), value: i });
      }
    },
    getContextViewModel (contexts: ContextModel[]): ContextViewModel[] {
      return getContextViewModel(contexts);
    },
    routeToMindMapV1 (subject: SubjectModel, standardized = false): Location | null {
      if (subject.contexts.length === 0) {
        return null; // Not yet loaded (would never occur)
      }
      return { name: 'V1Redirection', query: { to: getMindmapV1Link(subject, standardized) } };
    },
    routeToDiagramV1 (subject: SubjectModel): Location | null {
      if (subject.contexts.length === 0) {
        return null; // Not yet loaded (would never occur)
      }
      return { name: 'V1Redirection', query: { to: getDiagramV1Link(subject) } };
    },
    async removeSubject (): Promise<void> {
      this.disableButtons = true;
      this.showConfirmDeletion = false;
      await deleteSubject(this.subject.id);
      dataPreloadUnload();
      this.goBackToList();
    }
  },

  computed: {
    subjectId (): string {
      return this.subject.id ?? '';
    },
    frequencyText (): string {
      return this.$t('frequencyLevel.' + this.frequencyValue).toString();
    },
    gravityText (): string {
      return this.$t('gravityLevel.' + this.gravityValue).toString();
    },
    mainNodeStatus (): string {
      const firstNode = this.subject.mindMapItems.find((n) => n.code === '0');
      return firstNode?.status ?? '';
    }
  },

  watch: {
    $route (to, from) {
      if (to !== from) {
        this.loadPageData();
      }
    }
  }
});
