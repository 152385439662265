











import Vue from 'vue';
import HeaderBar from './components/headerBar/HeaderBar.vue';
import DisplayError from './components/common/DisplayError.vue';
import { getOrganizationInformation } from './repository';

export default Vue.extend({
  name: 'App',
  components: {
    HeaderBar,
    DisplayError
  },
  data: () => ({}),
  async created () {
    const appName = 'SPOT';
    const organizationInfo = await getOrganizationInformation();
    const customerName = organizationInfo.customerName;
    const title = document.querySelector('title');
    if (title) {
      title.innerText = customerName === '' ? appName : appName + ' - ' + customerName;
    }
  }
});

