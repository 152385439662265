





























































































































































import DoublePageLayout from '../components/presentation/DoublePageLayout.vue';
import Vue from 'vue';
import { backToList } from '../helpers/filteredListNavigation';
import {
  deleteQualification,
  filterQualificationDashboard,
  getCartographySiteNames,
  getEmptyQualification,
  getInitialQualificationLegend,
  getQualificationById,
  getUserQualificationModuleState,
  getUserQualificationState,
  isPermissionGranted,
  QualificationDashboardGroup,
  QualificationDashboardSecondaryInfo,
  queryUserQualifications,
  Reloadable,
  resolveGroupNames,
  resolveTrainingModuleNames,
  resolveUserNames
} from '../repository';
import DisplayConfirmationDialogBox from '../components/common/DisplayConfirmationDialogBox.vue';
import { ApiException, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';
import DisplayQualificationElement from '../components/qualifications/DisplayQualificationElement.vue';
import { sortArrayByNumber } from '../Tools';
import DisplayQualificationLegend from '../components/qualifications/DisplayQualificationLegend.vue';
import Discussion from '../components/common/Discussion.vue';
import ResourceGallery from '../components/common/resources/ResourceGallery.vue';

export default Vue.extend({

  name: 'ViewQualification',

  components: {
    Discussion,
    DisplayConfirmationDialogBox,
    DisplayQualificationElement,
    DisplayQualificationLegend,
    DoublePageLayout,
    ResourceGallery
  },

  data: () => ({
    canManageQualification: false,
    canSeeDashboard: false,
    cartographyList: [] as IdAndNameModel[],
    disableButtons: false,
    displaySelection: 'user',
    groupList: [] as IdAndNameModel[],
    legendSelector: getInitialQualificationLegend(),
    loading: true,
    loadingDashboard: true,
    modules: [] as QualificationDashboardGroup[],
    moduleList: {} as Record<string, QualificationDashboardGroup>,
    qualification: getEmptyQualification(),
    qualificationId: '',
    showConfirmDeletion: false,
    users: [] as QualificationDashboardGroup[]
  }),

  async created () {
    this.canSeeDashboard = await isPermissionGranted('perm_TableauBord');
    this.canManageQualification = await isPermissionGranted('perm_QualificationAdmin');
    // search for url parameter (qualification id)
    this.qualificationId = this.$route.params.qualificationId ?? '??';
    try {
      this.qualification = await getQualificationById(this.qualificationId);
      // get cartographies, groups and modules names
      const cartoSites = await getCartographySiteNames(this.qualification.cartographies);
      for (const id in cartoSites) {
        this.cartographyList.push({ id: id, name: cartoSites[id] });
      }
      const groupes = await resolveGroupNames(this.qualification.qualifiableGroups);
      for (const id in groupes) {
        this.groupList.push({ id: id, name: groupes[id] });
      }
      const modules = await resolveTrainingModuleNames(this.qualification.modules);
      for (const id in modules) {
        // prepare module list for dashboard
        this.moduleList[id] = {
          item: {
            id: id,
            name: modules[id],
            total: 0,
            obtained: 0,
            qualificationState: 'neutral',
            link: { name: 'ViewTrainingModule', params: { moduleId: id } }
          },
          children: []
        };
      }
      this.loading = false;
      if (this.canSeeDashboard) {
        // get qualifiable users for this qualification
        const query = 'qualification oneOf ' + this.qualificationId;
        const userList = (await queryUserQualifications({ query: query })).results;
        const userMapping = await resolveUserNames([...userList.map(uq => uq.user)]);
        for (const user of userList) {
          const currentUser: QualificationDashboardGroup = {
            item: {
              id: user.user,
              name: userMapping[user.user],
              total: user.modules.length,
              obtained: 0,
              qualificationState: getUserQualificationState(user).qualificationState,
              link: { name: 'UserQualificationDashboard', params: { userId: user.user } }
            },
            children: []
          };
          for (const module of user.modules) {
            const currentModuleForUser: QualificationDashboardSecondaryInfo = {
              id: module.module,
              name: modules[module.module],
              obtainedlinkId: module.validationTrainingPath ?? '',
              progressLinkId: module.requalificationTrainingPath ?? '',
              userLinkId: '', // not used here
              ...getUserQualificationModuleState(module)
            };
            // add module in user list
            currentUser.children.push(currentModuleForUser);
            // add user in module list
            const moduleItem = this.moduleList[module.module];
            moduleItem.children.push({
              ...currentModuleForUser,
              id: user.user,
              name: userMapping[user.user]
            });

            if (currentModuleForUser.remainingDays >= 0) {
              currentUser.item.obtained++;
              moduleItem.item.obtained++;
            }
            moduleItem.item.total++;
          }
          sortArrayByNumber<QualificationDashboardSecondaryInfo>(currentUser.children, c => c.remainingDays, 'desc');
          this.users.push(currentUser);
        }
        // sort user lists in moduleList array
        for (const id in this.moduleList) {
          sortArrayByNumber<QualificationDashboardSecondaryInfo>(this.moduleList[id].children, c => c.remainingDays, 'desc');
        }
        // transform moduleList (record) to modules (array)
        this.modules = Object.values(this.moduleList);
        this.loadingDashboard = false;
      }
    } catch (err) {
      if (err instanceof ApiException && err.status === 404) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      } else {
        eventBus.$emit('error', err, null);
      }
    }
  },

  methods: {
    goBackToList () {
      this.$router.push(backToList('FilteredQualifications'));
    },
    async removeQualification (): Promise<void> {
      this.disableButtons = true;
      this.showConfirmDeletion = false;
      await deleteQualification(this.qualification.id);
      this.goBackToList();
    },
    refreshDiscussion (): void {
      (this.$refs.discussion as Reloadable).reload();
    }
  },

  computed: {
    filteredUsers (): QualificationDashboardGroup[] {
      return filterQualificationDashboard(this.users, this.legendSelector);
    },
    filteredModules (): QualificationDashboardGroup[] {
      return filterQualificationDashboard(this.modules, this.legendSelector);
    }
  }
});

