import { render, staticRenderFns } from "./ResourceGallery.vue?vue&type=template&id=5d2e5904&scoped=true&"
import script from "./ResourceGallery.vue?vue&type=script&lang=ts&"
export * from "./ResourceGallery.vue?vue&type=script&lang=ts&"
import style0 from "./ResourceGallery.vue?vue&type=style&index=0&id=5d2e5904&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d2e5904",
  null
  
)

/* custom blocks */
import block0 from "./ResourceGallery.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VRow})
