









import { getQualificationLegendColor, QualificationLegend } from '../../repository';
import Vue, { PropType } from 'vue';

export default Vue.extend({

  name: 'DisplayQualificationLegend',

  props: {
    value: { type: Object as PropType<QualificationLegend>, required: true }
  },

  methods: {
    legendColor (state: string): string {
      return getQualificationLegendColor(state);
    },
    toggle (key: keyof QualificationLegend): void {
      this.$emit('input', { ...this.value, [key]: !this.value[key] });
    }
  }
});

