











































































import Vue, { PropType } from 'vue';
import { AssignedTrainingModuleModel, IdAndNameModel, TrainingModuleItemResult, TrainingModuleModel } from '../../../_GeneratedClients/SpotClient';
import DatePicker from '../common/DatePicker.vue';
import moment, { Moment } from 'moment';
import TrainingModulePicker from './TrainingModulePicker.vue';
import { autocompleteUser, getEmptyTrainingModule } from '../../repository';
import MultiAutocomplete from '../common/MultiAutocomplete.vue';

export default Vue.extend({

  name: 'AddOrEditTrainingPathModule',

  components: {
    DatePicker,
    TrainingModulePicker,
    MultiAutocomplete
  },

  props: {
    /** object to edit. Assume that if object id is empty, component acts as "add a new module". Otherwise, it acts as "edit existing module" */
    module: { type: Object as PropType<AssignedTrainingModuleModel>, required: true },
    /** sent and reused by parent component (index in displayed module list). -1 means "not used" */
    idx: { type: Number, default: -1 },
    /** parent path start date */
    startDate: { type: moment, required: true },
    /** parent path stop date */
    stopDate: { type: moment, required: true },
    /** parent learners (to avoid set a referent same as a learner ) */
    parentLearnerIds: { type: Set as PropType<Set<string>>, required: true }
  },

  data: () => ({
    /** contains displayed object data. Will be fill during initModuleToEdit() */
    moduleToEdit: {
      module: getEmptyTrainingModule(),
      referents: [] as IdAndNameModel[],
      startDate: moment(),
      stopDate: moment(),
      results: [] as TrainingModuleItemResult[]
    } as AssignedTrainingModuleModel,
    /** an error message for global process */
    globalError: '',
    /** error messages for each of form fields */
    errorMessages: { module: '', referents: '', startDate: '', stopDate: '' },
    /** the selected module */
    selectedModule: getEmptyTrainingModule(),
    /** the selected referent(s) */
    selectedReferents: [] as IdAndNameModel[],
    autocompleteUser: autocompleteUser
  }),

  methods: {
    initModuleToEdit (): void {
      this.moduleToEdit = { ...this.module };
      this.selectedReferents = [...this.moduleToEdit.referents];
      this.selectedModule = { ...this.moduleToEdit.module };
      this.moduleValidator(this.selectedModule);
    },
    getBgColor (error: string): string {
      return error === '' ? 'bgAccent' : 'bgError';
    },
    setError (fieldName: 'startDate' | 'stopDate', hasError: boolean): void {
      this.hideGlobalError();
      this.errorMessages[fieldName] = hasError ? 'error' : '';
    },
    hideGlobalError (): void {
      this.globalError = '';
    },
    moduleValidator (module : TrainingModuleModel) {
      this.hideGlobalError();
      this.errorMessages.module = '';
      if (module.id === '') {
        this.errorMessages.module = this.$t('emptyFieldError').toString();
      } else {
        this.selectedModule = { ...module };
      }
    },
    referentCannotBeLearner (selectedReferents: IdAndNameModel[]) {
      if (selectedReferents.some(r => this.parentLearnerIds.has(r.id))) {
        return this.$t('learnerCantBeReferent').toString();
      }
      return true;
    },
    onInsertClicked (): void {
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      this.moduleToEdit.module = this.selectedModule;
      this.moduleToEdit.referents = this.selectedReferents;
      this.$emit('add', this.moduleToEdit);
    },
    onSaveClicked (): void {
      if (Object.values(this.errorMessages).some((v) => v !== '')) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      this.moduleToEdit.module = { ...this.selectedModule };
      this.moduleToEdit.referents = [...this.selectedReferents];
      this.$emit('update', this.moduleToEdit, this.idx);
    }
  },

  computed: {
    pageTitle (): string {
      return this.module.module.id === '' ? this.$t('addModule').toString() : this.$t('editModule').toString();
    },
    minStopDate (): Moment {
      // The stop date must be in the future and after the start date
      const endOfToday = moment().endOf('day');
      if (this.moduleToEdit.startDate.isBefore(endOfToday)) {
        return endOfToday;
      }

      return this.moduleToEdit.startDate;
    }
  },

  watch: {
    module: {
      immediate: true,
      handler: function () {
        this.initModuleToEdit();
      }
    }
  }
});

