import { render, staticRenderFns } from "./Qualifications.vue?vue&type=template&id=699599f5&scoped=true&"
import script from "./Qualifications.vue?vue&type=script&lang=ts&"
export * from "./Qualifications.vue?vue&type=script&lang=ts&"
import style0 from "./Qualifications.vue?vue&type=style&index=0&id=699599f5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "699599f5",
  null
  
)

/* custom blocks */
import block0 from "./Qualifications.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCol,VRow})
