














































































































































































import Vue from 'vue';
import { getConnectedUser, getEmptyMediation, getMediationById, Reloadable, setMediationStatus, signMediation } from '../repository';
import Discussion from '../components/common/Discussion.vue';
import eventBus from '../eventBus';
import { ApiException, IdAndNameModel, MediationStatus, RequestedMediationStatus, TrackingPeriodModel, UserModel } from '../../_GeneratedClients/SpotClient';
import DoublePageLayout from '../components/presentation/DoublePageLayout.vue';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { durationToText, sanitizeHTML } from '../Tools';
import { getMediationDuration } from '../helpers/MediationHelper';
import { backToList } from '../helpers/filteredListNavigation';
import DisplayConfirmationDialogBox from '../components/common/DisplayConfirmationDialogBox.vue';

export default Vue.extend({

  name: 'ViewMediation',

  components: {
    Discussion,
    DisplayConfirmationDialogBox,
    DisplayLifetimeEvent,
    DisplayValue,
    DoublePageLayout
  },

  data: () => ({
    me: {} as UserModel,
    mediation: getEmptyMediation(),
    discussionId: '',
    showStopConfirmationForm: false,
    showValidationConfirmationForm: false,
    showInvalidationConfirmationForm: false,
    showSignConfirmationForm: false,
    showCancellationConfirmationForm: false
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
    }
    const mediationId = this.$route.params.mediationId;
    try {
      this.mediation = await getMediationById(mediationId);
      this.discussionId = this.mediation.discussionId;
    } catch (err) {
      if (err instanceof ApiException) {
        switch (err.status) {
          case 403:
            // connected user is not allowed to view this mediation -> redirect to forbidden page
            this.$router.replace({ path: '/forbidden' });
            break;
          case 404:
            this.$router.push({ name: 'NotFound' });
            break;
          default:
            eventBus.$emit('error', err, null);
            break;
        }
      }
    }
  },

  methods: {
    goBackToList () {
      this.$router.push(backToList('FilteredMediations'));
    },
    toEditPage () {
      this.$router.push({ name: 'EditMediation', params: { mediationId: this.mediation.id } });
    },
    toReportPage () {
      this.$router.push({ name: 'EditMediationReport', params: { mediationId: this.mediation.id } });
    },
    reloadDiscussion () {
      (this.$refs.discussion as Reloadable).reload();
    },
    async onStopConfirmationClicked (): Promise<void> {
      this.showStopConfirmationForm = false;
      this.showInvalidationConfirmationForm = false;
      try {
        await setMediationStatus(this.mediation.id, { status: RequestedMediationStatus.Stopped });
        this.mediation.status = MediationStatus.Stopped;
        this.reloadDiscussion();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async onValidateConfirmationClicked (): Promise<void> {
      this.showValidationConfirmationForm = false;
      try {
        await setMediationStatus(this.mediation.id, { status: RequestedMediationStatus.Validated });
        this.mediation.status = MediationStatus.Validated;
        this.reloadDiscussion();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async onSignConfirmationClicked (): Promise<void> {
      this.showSignConfirmationForm = false;
      // change status
      try {
        await signMediation(this.mediation.id);
        // reload page with new parameters
        this.$router.go(0);
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async onDeleteConfirmationClicked (): Promise<void> {
      this.showCancellationConfirmationForm = false;
      try {
        await setMediationStatus(this.mediation.id, { status: RequestedMediationStatus.Canceled });
        this.mediation.status = MediationStatus.Canceled;
        this.reloadDiscussion();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    participantColor (participant: IdAndNameModel): string {
      // computes color to display participant name (normal : blue, signed : green)
      return this.mediation.signatures.some((s) => s.userId === participant.id) ? 'success--text' : 'accent--text';
    },
    participantIcon (participant: IdAndNameModel): boolean {
      return this.mediation.signatures.some((s) => s.userId === participant.id);
    },
    rangePeriodToText (periods: TrackingPeriodModel[]): string {
      if (periods.length === 0) {
        return '';
      }
      let text = this.$t('from').toString() + ' ';
      text += periods[0].start.format(this.$t('dateFormat.dateTimePattern').toString());
      text += ' ' + this.$t('to').toString() + ' ';
      text += periods[periods.length - 1].stop?.format(this.$t('dateFormat.dateTimePattern').toString()) ?? '';
      return text;
    },
    timePeriodToText (periods: TrackingPeriodModel[]): string {
      return durationToText(getMediationDuration(periods));
    },
    hasStatus (askedStatus: string[]): boolean {
      return askedStatus.some((s) => s === this.mediation.status.toString());
    }
  },

  computed: {
    description (): string {
      const text = sanitizeHTML(this.mediation.description);
      return text.replace(/\n/g, '<br />');
    },
    plannedDate (): string {
      return this.mediation.schedulingDate === undefined ? '' : this.mediation.schedulingDate.format(this.$t('dateFormat.largeDateTimePattern').toString());
    },
    canEditMediation (): boolean {
      if (this.hasStatus(['canceled'])) {
        return false;
      }
      if (this.me.id === this.mediation.creator.id) {
        // i am creator, can edit in any cases
        return true;
      }
      if (this.me.id === this.mediation.mediator.id && !(this.hasStatus(['Validated', 'Archived']))) {
        // mediator can edit (when not validated, and not archived)
        return true;
      }
      // other cases : can't edit
      return false;
    },
    canStop (): boolean {
      // mediator can stop, only if started or paused (and not canceled)
      return this.hasStatus(['canceled']) ? false : (this.hasStatus(['started', 'paused'])) && this.me.id === this.mediation.mediator.id;
    },
    canEditReport (): boolean {
      // mediator can validate, only if status is stopped and all mandatory fields are filled (and not canceled)
      return this.hasStatus(['canceled']) ? false : this.me.id === this.mediation.mediator.id && this.hasStatus(['stopped']) && this.mediation.participants.length > 0;
    },
    canValidate (): boolean {
      // mediator can validate, only if same as canEditReport + reporting must exist and comment or items list isn't empty
      return this.mediation.reporting === undefined ? false : this.canEditReport;
    },
    canInvalidate (): boolean {
      // mediator can invalidate, but only if current status is "Validated"
      return this.hasStatus(['validated']) && this.me.id === this.mediation.mediator.id;
    },
    canSign (): boolean {
      return this.hasStatus(['validated']) ? this.mediation.participants.some((p) => p.id === this.me.id) && this.mediation.signatures.every((p) => p.userId !== this.me.id) : false;
    },
    canCancel (): boolean {
      if (this.hasStatus(['canceled'])) {
        return false;
      }
      if (this.me.id === this.mediation.creator.id && this.hasStatus(['created'])) {
        // creator can cancel mediation only if status is "created"
        return true;
      }
      if (this.me.id === this.mediation.mediator.id && !this.hasStatus(['archived'])) {
        // mediator can cancel mediation if status isn't "archived"
        return true;
      }
      return false;
    }
  }
});

