

















































































































































import Discussion from '../components/common/Discussion.vue';
import {
  getCartographyActivityPictos,
  getCartographyAllowedGroups,
  getCartographyImageZones,
  getCartographySiteNames,
  getConnectedUser,
  getSpotItemById,
  getTotalSubjectsInActivity
} from '../repository';
import Vue from 'vue';
import { Location } from 'vue-router';
import { ActivityModel, CartographyModel, CartographyZone, ContextItemType, IdAndNameModel, SiteModel, SubjectModel, UserModel } from '../../_GeneratedClients/SpotClient';
import { backToList } from '../helpers/filteredListNavigation';
import PanZoomZone from '../components/common/PanZoomZone.vue';
import PanZoomZoneArea from '../components/common/PanZoomZoneArea.vue';
import { backendUrl, loadImage, sleep } from '../Tools';
import eventBus from '../eventBus';
import cartographyPDF from '../helpers/cartographyPDF';
import DoublePageLayout from '../components/presentation/DoublePageLayout.vue';
import ChartContributions from '../components/dashboards/ChartContributions.vue';

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Cartography',

  components: {
    ChartContributions,
    Discussion,
    DoublePageLayout,
    PanZoomZone,
    PanZoomZoneArea
  },

  data: () => ({
    loaded: false,
    me: {} as UserModel,
    site: undefined as SiteModel | undefined,
    cartography: undefined as CartographyModel | undefined,
    canSeeUsage: false,
    canSeeQualifications: false,
    discussionId: '',
    backendUrl: backendUrl,
    cartoImageUrl: '',
    cartoImageSize: { width: 1, height: 1 },
    zoneList: [] as CartographyZone[],
    pictoUrls: {} as Record<string, string>,
    showCartographyUsage: false,
    showAllowedGroups: false,
    allowedGroups: [] as IdAndNameModel[]
  }),

  async mounted () {
    await this.initData();
  },

  methods: {
    async initData (): Promise<void> {
      const cartographyId = this.$route.params.cartographyId;
      if (cartographyId === '' || cartographyId === undefined || cartographyId === null) {
        this.$router.push('/NotFound');
        return;
      }
      try {
        const spotItem = await getSpotItemById(cartographyId);
        if (spotItem === undefined) {
          // try to know if cartography exists on server side by attempting to resole name
          const serverSideName = (await getCartographySiteNames([cartographyId]))[cartographyId];
          if (serverSideName === undefined) {
            // it's really a "not found error"
            this.$router.push('/NotFound');
          } else {
            // cartography exisits on server side, but user is not allowed to see it -> "forbidden error"
            this.$router.replace('/forbidden');
          }
          return;
        }
        // can user see dashboards ?
        const connectedUser = getConnectedUser();
        if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
          this.me = connectedUser;
        }
        if (this.me.permissions.includes('perm_TableauBord')) {
          this.canSeeQualifications = true;
          const siteItem = await getSpotItemById(spotItem.contexts[0].hierarchy[0].id);
          if (siteItem !== undefined) {
            this.site = { ...siteItem.item as SiteModel };
          }
        }
        if (this.me.permissions.includes('perm_VisuTrack')) {
          this.canSeeUsage = true;
        }
        this.cartography = { ...spotItem.item as CartographyModel };
        this.discussionId = this.cartography.discussionId;
        this.pictoUrls = await getCartographyActivityPictos(this.cartography.id); // urls of activity pictograms for this cartography
        const pictograms: Record<string, HTMLImageElement> = {};
        for (const activityid in this.pictoUrls) {
          pictograms[activityid] = await loadImage(this.pictoUrls[activityid]);
        }
        // get clickable zones (activities and functions)
        this.zoneList = await getCartographyImageZones(this.cartography.id);

        // select function zones
        const functionZones = this.zoneList.filter((z) => z.type === ContextItemType.Function);

        const subjectsCounters = this.countSubjectByFunctionsAndActivity(this.cartography);

        // create cartography image
        const baseImage = await loadImage(backendUrl + '/cartography/' + this.cartography.id + '/image');
        const canvas = document.createElement('canvas');
        const canvasContext = canvas.getContext('2d');
        if (!canvasContext) {
          throw new Error('Failed to initialize canvas');
        }
        this.cartoImageSize.width = canvasContext.canvas.width = baseImage.width;
        this.cartoImageSize.height = canvasContext.canvas.height = baseImage.height;
        canvasContext.drawImage(baseImage, 0, 0, baseImage.width, baseImage.height);

        // select activity zones
        const activityZones = this.zoneList.filter((z) => z.type === ContextItemType.Activity);
        // for each activity, count attached subjects and insert value in image (on the right of activity zone)
        let fontSize = 36;
        canvasContext.font = `bold ${fontSize}px arial`;
        for (const activity of activityZones) {
          const spotActivity = this.cartography.activities.find((a) => a.id === activity.id);
          const activityCounter = spotActivity // would be always true
            ? getTotalSubjectsInActivity(spotActivity).toString()
            : '0';
          // add activity counters in activity zones
          const text = canvasContext.measureText(activityCounter);
          const padding = 12;
          canvasContext.fillText(activityCounter, activity.zone.x + activity.zone.width - text.width - padding, activity.zone.y + fontSize + padding);
        }

        // add activity pictos/subject counters for each function (beside function clickable zone)
        fontSize = 24;
        const pictoEdge = 50;
        const overlapWidth = 15;
        canvasContext.font = `bold ${fontSize}px arial`;
        canvasContext.textAlign = 'center';
        canvasContext.textBaseline = 'top';
        for (const func of functionZones) {
          const y = func.zone.y; // picto set on top of zone. (cannot be centered due to powerpoint structure, used to create image and clickable zones)
          let x = 0;
          let offsetLeft = func.zone.x - pictoEdge + overlapWidth;
          let offsetRight = func.zone.x + func.zone.width - overlapWidth;
          for (let i = 0; i < activityZones.length; i++) {
            const activityId = activityZones[i].id;
            if (subjectsCounters[func.id] && subjectsCounters[func.id][activityId]) {
              // counter is not zero, add activity picto in image
              if (i < 2) {
                // picto is inserted on the left of zone
                x = offsetLeft;
                offsetLeft -= pictoEdge - overlapWidth;
              } else {
                // picto is inserted on the right of zone
                x = offsetRight;
                offsetRight += pictoEdge - overlapWidth;
              }
              canvasContext.drawImage(pictograms[activityId], x, y, pictoEdge, pictoEdge);
              const counterText = subjectsCounters[func.id][activityId].toString();
              const text = canvasContext.measureText(counterText);
              // ActualBoundingBoxAscent is negative when textBaseline is 'top'
              // The smallest height that contains the text
              const actualBoundingHeight = text.actualBoundingBoxAscent + text.actualBoundingBoxDescent;
              // To align the text to the middle of the point, we need to subtract half the bounding box height, and the margin above the text (i.e. between the "top" baseline and the actualBoundingBoxAscent position)
              const baselineCompensation = text.actualBoundingBoxAscent + actualBoundingHeight / 2;
              canvasContext.fillText(counterText, x + pictoEdge / 2, y + pictoEdge / 2 - baselineCompensation);
            }
          }
        }

        // set dataUrl from canvas (to display completed image in template)
        this.cartoImageUrl = canvas.toDataURL(); // default values are ok
        // get groups having permission on this cartography
        this.allowedGroups = await getCartographyAllowedGroups(cartographyId);
        this.loaded = true;
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    getCartoPdf () {
      return cartographyPDF(this.cartography, this.cartoImageUrl, this.pictoUrls);
    },
    getCoordString (zone: CartographyZone): string {
      return zone.zone.x + ',' + zone.zone.y + ',' + (zone.zone.x + zone.zone.width) + ',' + (zone.zone.y + zone.zone.height);
    },
    goBackToList (): void {
      this.$router.push(backToList('FilteredCartographies'));
    },
    getPageLocation (zone: CartographyZone): Location {
      if (!this.loaded) {
        return {};
      }
      switch (zone.type) {
        case ContextItemType.Activity: {
          return { name: 'FilteredSubjects', params: { filter: 'location oneOf ' + zone.id }, query: { sorting: 'function' } };
        }
        case ContextItemType.Function:
          return { name: 'ViewFunction', params: { functionId: zone.id } };
        default:
          return {};
      }
    },
    getSubjectInActivity (activity: ActivityModel): SubjectModel[] {
      return [...activity.subjects, ...activity.activities.flatMap(subActivity => this.getSubjectInActivity(subActivity))];
    },
    // returns a mapping with format: <functionId, <activityId, subjectCount>>
    countSubjectByFunctionsAndActivity (cartography: CartographyModel): Record<string, Record<string, number>> {
      // Process subjects in each activity, and store them in the appropriate functions
      const subjectsCounters: Record<string, Record<string, number>> = {};
      for (const activity of cartography.activities) {
        for (const subject of this.getSubjectInActivity(activity)) {
          const functionId = (subject.contexts.flatMap(ctx => ctx.hierarchy).find(node => node.type === ContextItemType.Function))?.id;
          if (functionId) {
            // the subject is attached to a function. count it
            if (subjectsCounters[functionId]) {
              subjectsCounters[functionId][activity.id] = (subjectsCounters[functionId][activity.id] ?? 0) + 1;
            } else {
              subjectsCounters[functionId] = {
                [activity.id]: 1
              };
            }
          }
        }
      }
      return subjectsCounters;
    },
    async resizeCarto (): Promise<void> {
      await sleep(300); // waiting for end of open/close side panel animation
      (this.$refs.panZoomZone as Vue & { fitToView : () => void }).fitToView();
    }
  },

  computed: {
    cartographyId (): string {
      return this.cartography?.id ?? '';
    },
    siteName (): string {
      if (this.site === undefined) {
        return '???';
      }
      return this.site.name;
    },
    routeToCartographyUsageV1 (): Location | null {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.loaded === true ? { name: 'V1Redirection', query: { to: 'spot/' + encodeURIComponent(this.cartography!.code) + '/statligne' } } : null;
    },
    selectedSubjects (): Location | null {
      if (this.loaded === false) {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const filter = 'location oneOf ' + this.cartography!.id;
      return { name: 'FilteredSubjects', params: { filter: filter } };
    }
  }
});

