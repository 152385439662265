import { render, staticRenderFns } from "./Subject.vue?vue&type=template&id=50667cb3&scoped=true&"
import script from "./Subject.vue?vue&type=script&lang=ts&"
export * from "./Subject.vue?vue&type=script&lang=ts&"
import style0 from "./Subject.vue?vue&type=style&index=0&id=50667cb3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50667cb3",
  null
  
)

/* custom blocks */
import block0 from "./Subject.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VCol,VIcon,VProgressCircular,VRow,VSelect,VSheet})
