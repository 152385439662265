import { Duration, ZonedDateTime } from '@js-joda/core';
import { TrackingPeriodModel } from '../../_GeneratedClients/SpotClient';

/**
 * Calculation of the duration of mediation
 *
 * @param periods periods of mediation (start and stop date/time)
 * @returns the total duration in minutes
 */
export function getMediationDuration (periods: TrackingPeriodModel[]): number {
  // warning : at this time, period start and period stop are recorded as Moment -> must translate to JSJoda ZoneDateTime
  let duration = Duration.ofMinutes(0);
  for (const period of periods) {
    const start = ZonedDateTime.parse(period.start.format());
    if (period.stop) { // always true, but to avoid "possibly null" error
      const stop = ZonedDateTime.parse(period.stop?.format());
      duration = duration.plus(Duration.between(start, stop));
    }
  }
  return duration.toMinutes();
}
