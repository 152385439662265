






















import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import Vue from 'vue';
import DisplayQualificationElement from '../components/qualifications/DisplayQualificationElement.vue';
import { filterQualificationDashboard, getInitialQualificationLegend, getUserQualificationState, getUsersIdByGroups, isPermissionGranted, QualificationDashboardGroup, QualificationDashboardSecondaryInfo, queryQualifications, queryUserQualifications, resolveGroupNames, resolveUserNames } from '../repository';
import { sortArrayByNumber } from '../Tools';
import { ApiException, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';
import DisplayQualificationLegend from '../components/qualifications/DisplayQualificationLegend.vue';

export default Vue.extend({

  name: 'GroupQualificationDashboard',

  components: {
    DisplayQualificationElement,
    DisplayQualificationLegend,
    SimplePageLayout
  },

  data: () => ({
    group: { id: '', name: '' } as IdAndNameModel,
    legendSelector: getInitialQualificationLegend(),
    loading: true,
    qualifications: [] as QualificationDashboardGroup[]
  }),

  async mounted () {
    if (!(await isPermissionGranted('perm_TableauBord'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
      return;
    }
    const groupId = this.$route.params.groupId;
    if (groupId === '' || groupId === undefined || groupId === null) {
      this.$router.push('/NotFound');
      return;
    }
    try {
      // get group name
      this.group = { id: groupId, name: (await resolveGroupNames([groupId]))[groupId] ?? '???' };
      // get qualifications for this group
      let query = 'qualifiableGroups oneOf ' + groupId;
      const groupQualifications = (await queryQualifications({ query: query })).results;
      if (groupQualifications.length === 0) {
        // Doesn't contain any element, that's fine, display an empty page
        this.loading = false;
        return;
      }
      const qualificationIds = groupQualifications.map(g => g.id);
      // get user id list for this group/qualifications
      const userIds = await getUsersIdByGroups([groupId]);
      if (userIds.length === 0) {
        // Doesn't contain any element, that's fine, display an empty page
        this.loading = false;
        return;
      }
      const userNameMapping = await resolveUserNames(userIds);
      // get qualifications data for these users
      query = 'user oneOf ' + userIds.join(',') + ' & qualification oneOf ' + qualificationIds.join(',');
      const userQualificationList = (await queryUserQualifications({ query: query })).results;
      const qualificationList: Record<string, QualificationDashboardGroup> = {};
      for (const user of userQualificationList) {
        // initialize qualification if not yet set
        if (qualificationList[user.qualification] === undefined) {
          qualificationList[user.qualification] = {
            item: {
              id: user.qualification,
              name: user.qualificationName,
              total: 0,
              obtained: 0,
              qualificationState: 'neutral',
              link: { name: 'ViewQualification', params: { qualificationId: user.qualification } }
            },
            children: []
          };
        }
        // initialize user counters
        const currentUser: QualificationDashboardSecondaryInfo = {
          id: user.user,
          name: userNameMapping[user.user],
          obtainedlinkId: '', // not used here
          progressLinkId: '', // not used here
          userLinkId: user.user,
          ...getUserQualificationState(user)
        };
        if (currentUser.remainingDays >= 0) {
          qualificationList[user.qualification].item.obtained++;
        }
        qualificationList[user.qualification].children.push(currentUser);
        qualificationList[user.qualification].item.total++;
      }
      // sort user list for each qualification
      for (const id in qualificationList) {
        sortArrayByNumber<QualificationDashboardSecondaryInfo>(qualificationList[id].children, c => c.remainingDays, 'desc');
      }
      // transform qualificationList (record) to qualifications (array)
      this.qualifications = Object.values(qualificationList);
      this.loading = false;
    } catch (err) {
      if (err instanceof ApiException && err.status === 404) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      } else {
        eventBus.$emit('error', err, null);
      }
    }
  },

  computed: {
    filteredQualifications (): QualificationDashboardGroup[] {
      return filterQualificationDashboard(this.qualifications, this.legendSelector);
    }
  }
});

