








































































import Vue from 'vue';
import { SearchResponse, itemSearch, ISearchResponseCategory } from '../../repository';
import SearchTitle from './SearchTitle.vue';
import SearchList from './SearchList.vue';

export default Vue.extend({

  name: 'SearchBar',

  components: {
    SearchTitle,
    SearchList
  },

  data: () => ({
    minSearchLength: 1, // min length of input to fire search
    minDisplay: 5, // nb of lines to display in reduced mode
    maxDisplay: 30, // nb of lines to display in non reduce mode
    searchKey: '', // user input in search bar
    timer: 0,
    showResults: false,
    searchResults: null as SearchResponse | null,
    // The currently expanded item (shown without the minLines restriction, all others are hidden.)
    // If it is null, all items show at most "minDisplay" lines
    expanded: null as (keyof SearchResponse) | null
  }),

  created () {
    window.addEventListener('resize', this.resizeSearchZone);
  },

  destroyed () {
    window.removeEventListener('resize', this.resizeSearchZone);
  },

  methods: {
    resizeSearchZone () {
      const zone = this.$refs.result as HTMLElement;
      if (zone) {
        const bottom = '-' + (window.innerHeight - 65) + 'px';
        zone.style.bottom = bottom;
      }
    },
    keypressed () {
      // possible on nullable textfield
      if (this.searchKey === null || this.searchKey === undefined) {
        this.searchKey = '';
      }
      if (this.searchKey.length < this.minSearchLength) {
        this.resetSearch();
      } else {
        this.showResults = true;
        this.reduce();
        if (this.timer > 0) {
          window.clearTimeout(this.timer);
        }
        this.timer = window.setTimeout(this.searchSend, 500);
      }
    },
    resetResults () {
      this.searchKey = '';
      this.resetSearch();
    },
    async searchSend () {
      this.searchResults = null;
      if (this.searchKey === '') {
        return; // to avoid sending a search with empty parameter
      }
      this.resizeSearchZone();
      this.searchResults = await itemSearch(this.searchKey, this.maxDisplay);
    },
    expand (source: keyof SearchResponse) {
      this.expanded = source;
    },
    reduce () {
      this.expanded = null;
    },
    resetSearch () {
      this.showResults = false;
      this.searchResults = null;
      this.expanded = null;
    },
    computeCategoryList (category: keyof SearchResponse) : ISearchResponseCategory {
      if (this.searchResults) {
        if (this.expanded === category) { // This category is expanded, return it in full
          return this.searchResults[category];
        } else if (this.expanded === null) { // No other category is expanded
          return {
            total: this.searchResults[category].total,
            items: this.searchResults[category].items.slice(0, this.minDisplay)
          };
        }
      }
      // Not loaded or another category is expanded, return no result.
      return { total: 0, items: [] };
    }
  },

  computed: {
    waiting (): boolean {
      return this.searchResults === null;
    },
    showCarto (): boolean {
      return this.expanded === null || this.expanded === 'cartography';
    },
    cartoList () : ISearchResponseCategory {
      return this.computeCategoryList('cartography');
    },
    showFunction (): boolean {
      return this.expanded === null || this.expanded === 'function';
    },
    functionList () : ISearchResponseCategory {
      return this.computeCategoryList('function');
    },
    showSubject (): boolean {
      return this.expanded === null || this.expanded === 'subject';
    },
    subjectList () : ISearchResponseCategory {
      return this.computeCategoryList('subject');
    },
    showNode (): boolean {
      return this.expanded === null || this.expanded === 'node';
    },
    nodeList () : ISearchResponseCategory {
      return this.computeCategoryList('node');
    }
  }
});

