import { render, staticRenderFns } from "./GenericItemListSelector.vue?vue&type=template&id=213921d5&scoped=true&"
import script from "./GenericItemListSelector.vue?vue&type=script&lang=ts&"
export * from "./GenericItemListSelector.vue?vue&type=script&lang=ts&"
import style0 from "./GenericItemListSelector.vue?vue&type=style&index=0&id=213921d5&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "213921d5",
  null
  
)

/* custom blocks */
import block0 from "./GenericItemListSelector.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
