



























































import Vue, { PropType } from 'vue';
import {
  ActivityModel,
  CartographyModel,
  ContextItemType,
  IdAndNameModel,
  SiteModel,
  SpotModuleItemType,
  SpotTrainingModuleItemModel,
  TrainingModuleItemType
} from '../../../_GeneratedClients/SpotClient';
import {
  getSiteList,
  getSpotItemById,
  getSpotTypeFromId,
  getSubjectsCountFromActivity,
  getSubjectsCountFromFunction,
  getSubjectsFromFunction
} from '../../repository';
import GenericItemListSelector, { SelectableGenericItem } from '../common/GenericItemListSelector.vue';

export default Vue.extend({

  name: 'AddTrainingModuleSpotElements',

  components: {
    GenericItemListSelector
  },

  props: {
    lastContext: { type: Array as PropType<IdAndNameModel[]>, default: () => [] }
  },

  data: () => ({
    context: [] as IdAndNameModel[],
    parentId: '',
    selectionList: [] as SelectableGenericItem[],
    selectedCount: 0,
    subjectCount: 0,
    showAllSelector: false,
    allListSelectorState: 0, // elements selected in selectionList : 0 -> none, 1-> some, 2 -> all
    fromFunction: false
  }),

  async mounted () {
    this.context = this.lastContext;
    this.parentId = this.context.length === 0 ? '' : this.context[this.context.length - 1].id;
    await this.initSelectionList();
  },

  methods: {
    async initSelectionList (type = 0): Promise<void> {
      this.selectionList = [];
      if (this.parentId === '') { // *** site level *****
        for (const site of await getSiteList()) {
          this.selectionList.push({
            id: site.item.id,
            label: site.item.name,
            icon: 'mdi-folder-outline',
            isSelectable: false,
            nextLevel: ['all'],
            selected: false
          });
        }
        this.showAllSelector = false;
      } else {
        const parentItem = await getSpotItemById(this.parentId);
        if (parentItem) {
          switch (parentItem.item.type) {
            case ContextItemType.Site: // *** cartography level *****
              for (const cartography of (parentItem.item as SiteModel).cartographies) {
                this.selectionList.push({
                  id: cartography.id,
                  label: cartography.name,
                  icon: 'mdi-folder-outline',
                  isSelectable: true,
                  nextLevel: [this.$t('spotLevel.activity').toString(), this.$t('spotLevel.function').toString()],
                  selected: false
                } as SelectableGenericItem);
              }
              this.showAllSelector = true;
              break;
            case ContextItemType.Cartography: // *** activity or function level *****
              switch (type) {
                case 0 : // *** activity level *****
                  for (const activity of (parentItem.item as ActivityModel).activities) {
                    const haveChild = activity.activities.length > 0 || activity.subjects.length > 0;
                    this.selectionList.push({
                      id: activity.id,
                      label: activity.name,
                      icon: 'mdi-folder-outline',
                      isSelectable: true,
                      nextLevel: haveChild === true ? ['all'] : [],
                      selected: false
                    } as SelectableGenericItem);
                  }
                  this.fromFunction = false;
                  break;
                case 1 : // *** function level *****
                  for (const func of (parentItem.item as CartographyModel).functions) {
                    this.selectionList.push({
                      id: func.id,
                      label: func.name,
                      icon: 'mdi-folder-outline',
                      isSelectable: true,
                      nextLevel: await getSubjectsCountFromFunction(func.id) === 0 ? [] : ['all'],
                      selected: false
                    } as SelectableGenericItem);
                  }
                  this.fromFunction = true;
                  break;
              }
              this.showAllSelector = true;
              break;
            case ContextItemType.Activity: { // *** sub-activity and/or subject level *****
              const activities = (parentItem.item as ActivityModel).activities;
              const subjects = (parentItem.item as ActivityModel).subjects;
              this.showAllSelector = activities.length > 0 || subjects.length > 0;
              for (const activity of activities) {
                this.selectionList.push({
                  id: activity.id,
                  label: activity.name,
                  icon: 'mdi-folder-outline',
                  isSelectable: true,
                  nextLevel: activity.activities.length > 0 || activity.subjects.length > 0 ? ['all'] : [],
                  selected: false
                } as SelectableGenericItem);
              }
              for (const subject of subjects) {
                this.selectionList.push({
                  id: subject.id,
                  label: subject.name,
                  icon: 'mdi-text-box-outline',
                  isSelectable: true,
                  nextLevel: [],
                  selected: false
                } as SelectableGenericItem);
              }
              break;
            }
            case ContextItemType.Function: // *** subject level *****
              for (const subject of await getSubjectsFromFunction(this.parentId)) {
                this.selectionList.push({
                  id: subject.id,
                  label: subject.name,
                  icon: 'mdi-text-box-outline',
                  isSelectable: true,
                  nextLevel: [],
                  selected: false
                } as SelectableGenericItem);
              }
              this.showAllSelector = true;
              break;
          }
        }
      }
      this.updateCounter();
    },
    async updateCounter (): Promise<void> {
      let subjectCounter = 0;
      const selected = this.selectionList.filter((i) => i.selected === true);
      for (const item of selected) {
        const itemType = await getSpotTypeFromId(item.id);
        switch (itemType) {
          case ContextItemType.Cartography:
          case ContextItemType.Activity: {
            subjectCounter += await getSubjectsCountFromActivity(item.id);
            break;
          }
          case ContextItemType.Function:
            subjectCounter += await getSubjectsCountFromFunction(item.id);
            break;
          case ContextItemType.Subject:
            subjectCounter++;
            break;
        }
      }
      this.selectedCount = selected.length;
      this.subjectCount = subjectCounter;
      this.allListSelectorState = this.selectedCount === 0 ? 0 : this.selectedCount === this.selectionList.length ? 2 : 1;
    },
    select (idx: number): void {
      this.selectionList[idx].selected = !this.selectionList[idx].selected;
      this.updateCounter();
    },
    selectAllClicked (): void {
      const commonState = this.allListSelectorState !== 2;
      for (const item of this.selectionList) {
        item.selected = commonState;
      }
      this.updateCounter();
    },
    async nextLevel (idx: number, type: number): Promise<void> {
      this.context.push({ id: this.selectionList[idx].id, name: this.selectionList[idx].label });
      this.$emit('context', this.context);
      this.parentId = this.selectionList[idx].id;
      await this.initSelectionList(type);
    },
    async returnToRoot (): Promise<void> {
      this.parentId = '';
      this.context = [];
      this.$emit('context', this.context);
      await this.initSelectionList();
    },
    async returnToLevel (idx: number): Promise<void> {
      this.context = this.context.slice(0, idx + 1);
      this.$emit('context', this.context);
      this.parentId = this.context[idx].id;
      await this.initSelectionList(this.fromFunction === true ? 1 : 0);
    },
    async onInsertClicked () {
      if (this.selectedCount === 0) {
        return;
      }
      const selection = this.selectionList.filter((i) => i.selected === true);
      const response = [] as SpotTrainingModuleItemModel[];
      for (const item of selection) {
        const type = await getSpotTypeFromId(item.id);
        if (type) { // would be always true
          response.push({
            type: TrainingModuleItemType.Spot,
            spotItemType: type.toString() as SpotModuleItemType,
            spotItemId: item.id
          });
        }
      }
      this.$emit('insert', response);
    }
  },

  computed: {
    allListIcon (): string {
      switch (this.allListSelectorState) {
        case 1: return 'mdi-checkbox-intermediate';
        case 2: return 'mdi-checkbox-marked';
        case 0:
        default: return 'mdi-checkbox-blank-outline';
      }
    },
    countMessageColor: function (): string {
      return this.selectedCount === 0 ? 'warning--text' : 'success--text';
    },
    countMessage: function (): string {
      let text = this.selectedCount + ' ' + this.$t('selectedItems') + ', ' + this.$t('total');
      text += ' ' + this.subjectCount + ' ' + this.$t('subjects');
      return text;
    }
  }
});

