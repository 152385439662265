
























import Vue from 'vue';
export default Vue.extend({
  name: 'SimplePageLayout',
  props: { title: { type: String, default: () => '' } },
  computed: {
    hasTitleContent (): boolean { return this.title !== ''; },
    hasIconMenu (): boolean { return !!this.$slots.iconMenu; },
    hasSubtitleContent (): boolean { return !!this.$slots.subtitle; },
    hasFooterContent (): boolean { return !!this.$slots.footer; }
  }
});
