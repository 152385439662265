import { render, staticRenderFns } from "./AddTrainingModuleLmsElements.vue?vue&type=template&id=47a6b498&scoped=true&"
import script from "./AddTrainingModuleLmsElements.vue?vue&type=script&lang=ts&"
export * from "./AddTrainingModuleLmsElements.vue?vue&type=script&lang=ts&"
import style0 from "./AddTrainingModuleLmsElements.vue?vue&type=style&index=0&id=47a6b498&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47a6b498",
  null
  
)

/* custom blocks */
import block0 from "./AddTrainingModuleLmsElements.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VIcon,VProgressCircular,VRow})
