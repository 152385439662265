

















import Vue from 'vue';
import { UserModel } from '../../_GeneratedClients/SpotClient';
import { getConnectedUser } from '../repository';
import AddOrEditSubject from '../components/subjects/AddOrEditSubject.vue';

export default Vue.extend({

  name: 'NewSubject',

  components: {
    AddOrEditSubject
  },

  data: () => ({
    me: {} as UserModel
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // always true, but to avoid "possibly null" error
      this.me = connectedUser;
    }
    if (!this.me.permissions.includes('perm_EditPdv')) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  }
});

