import {
  IdAndNameModel,
  MediationModel,
  MediationStatus,
  TrackingPeriodModel,
  LifetimeEvent,
  CreateOrUpdateMediationRequest,
  MediationStatusRequest,
  TrackingActionResult,
  MediationReport,
  MediationReportItem,
  QueryRequest,
  // eslint-disable-next-line camelcase
  QueryResponse_MediationModel_,
  EntityCreatedModel
} from '../../_GeneratedClients/SpotClient';
import { client } from './Client';
import moment, { Moment } from 'moment';

/**
 * gets an empty MediationModel object
 *
 * @returns an empty MediationModel object.
 */
export function getEmptyMediation (): MediationModel {
  return {
    id: '',
    title: '',
    description: '',
    status: MediationStatus.Created,
    schedulingDate: undefined,
    lastUpdate: { userId: '', userName: '', date: moment() } as LifetimeEvent,
    trackingPeriods: Array<TrackingPeriodModel>(),
    creator: { id: '', name: '' } as IdAndNameModel,
    mediator: { id: '', name: '' } as IdAndNameModel,
    participants: Array<IdAndNameModel>(),
    visibleByGroups: Array<IdAndNameModel>(),
    visibleByPersons: Array<IdAndNameModel>(),
    discussionId: '',
    reporting: { comment: '', items: Array<MediationReportItem>() } as MediationReport,
    signatures: Array<LifetimeEvent>()
  };
}

/**
 * transforms all dates from iso string to moment
 *
 * @param mediation the object where to transform dates
 * @returns mediation object with dates as moment instead of iso strings
 */
function mediationDatesToMoment (mediation: MediationModel): MediationModel {
  if (mediation.schedulingDate) {
    mediation.schedulingDate = moment(mediation.schedulingDate);
  }
  mediation.lastUpdate.date = moment(mediation.lastUpdate.date);
  for (const period of mediation.trackingPeriods) {
    period.start = moment(period.start);
    if (period.stop) {
      period.stop = moment(period.stop);
    }
  }
  if (mediation.reporting) {
    for (const item of mediation.reporting.items) {
      if (item.date) {
        item.date = moment(item.date);
      }
    }
  }
  for (const signature of mediation.signatures) {
    signature.date = moment(signature.date);
  }
  return mediation;
}

/**
 * gets mediation list from api
 *
 * @param query query parameters
 * @returns a promise, the list of mediations according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryMediations (query: QueryRequest): Promise<QueryResponse_MediationModel_> {
  const mediations = await client.queryMediations(query);
  return {
    results: mediations.results.map((m) => mediationDatesToMoment(m)),
    totalItems: mediations.totalItems
  };
}

/**
 * gets a mediation list from api, where connected user can do something (as play, pause, stop, ...)
 * this list is used for "Teamwork" button
 *
 * @returns a promise, a list of mediations
 */
export async function getActionableMediations (): Promise<MediationModel[]> {
  const actionableMediations = await client.getActionableMediations();
  return actionableMediations.map((m) => mediationDatesToMoment(m));
}

/**
 * Asks API to create a new mediation
 *
 * @param newMediation elements used to create a new mediation
 * @returns a promise, the id of the new mediation just created
 */
export async function createMediation (newMediation: CreateOrUpdateMediationRequest): Promise<EntityCreatedModel> {
  return await client.createMediation(newMediation);
}

/**
 * Asks API to update a mediation
 *
 * @param mediationId the identifier of the mediation to update
 * @param mediation new elements to replace old ones
 */
export async function updateMediation (mediationId: string, mediation: CreateOrUpdateMediationRequest): Promise<void> {
  await client.updateMediation(mediationId, mediation);
}

/**
 * Gets a mediation object from API
 *
 * @param mediationId the identifier of the mediation to obtain
 * @returns a promise, the mediation object asked
 */
export async function getMediationById (mediationId: string): Promise<MediationModel> {
  const mediation = await client.getMediationById(mediationId);
  return mediationDatesToMoment(mediation);
}

/**
 * Asks API to change a mediation status
 *
 * @param mediationId the identifier of the mediation
 * @param requestedStatus the new status for the mediation
 * @returns a propise
 */
export async function setMediationStatus (mediationId: string, requestedStatus: MediationStatusRequest): Promise<void> {
  return await client.setMediationStatus(mediationId, requestedStatus);
}

/**
 * Gets from API all tracked actions that connected user made between "from" and "to" dates
 *
 * @param from start date/time period
 * @param to stop date/time period
 * @returns a promise, a list of tracked actions
 */
export async function getMyTracking (from: Moment, to: Moment): Promise<TrackingActionResult[]> {
  const actions = await client.getMyTracking(from, to);
  // transform all dates from iso string to moment
  for (const action of actions) {
    action.date = moment(action.date);
  }
  return actions;
}

/**
 * Asks API to update the mediation tracking periods
 *
 * @param mediationId the identifier of the mediation to update
 * @param trackingPeriods the new periods to replace old ones
 */
export async function updateTrackingPeriods (mediationId: string, trackingPeriods: TrackingPeriodModel[]): Promise<void> {
  await client.updateTrackingPeriods(mediationId, trackingPeriods);
}

/**
 * asks API to register a report for a mediation
 *
 * @param mediationId the identifier of the mediation
 * @param report the report informations to save
 */
export async function saveMediationReport (mediationId: string, report: MediationReport): Promise<void> {
  await client.saveMediationReport(mediationId, report);
}

/**
 * asks API to register the signature action of the connected user (for a validated mediation)
 *
 * @param mediationId the identifier of the mediation
 */
export async function signMediation (mediationId: string): Promise<void> {
  await client.signMediation(mediationId);
}
