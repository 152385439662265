






































































import {
  autocompleteCartographySite,
  autocompleteGroup,
  autocompleteTrainingModule,
  createQualification,
  getCartographySiteNames,
  getEmptyQualification,
  resolveGroupNames,
  resolveTrainingModuleNames,
  updateQualification,
  VForm
} from '../../repository';
import Vue, { PropType } from 'vue';
import { CreateOrUpdateQualificationRequest, IdAndNameModel, QualificationModel } from '../../../_GeneratedClients/SpotClient';
import SimplePageLayout from '../presentation/SimplePageLayout.vue';
import eventBus from '../../eventBus';
import MultiAutocomplete from '../common/MultiAutocomplete.vue';

export default Vue.extend({

  name: 'AddOrEditQualification',

  components: {
    MultiAutocomplete,
    SimplePageLayout
  },

  props: {
    qualification: { type: Object as PropType<QualificationModel>, default: () => getEmptyQualification() }
  },

  data: () => ({
    form: {} as VForm,
    newName: '',
    moduleError: false,
    globalError: '',
    autocompleteCartographySite: autocompleteCartographySite,
    selectedCartographies: [] as IdAndNameModel[],
    autocompleteGroup: autocompleteGroup,
    selectedGroups: [] as IdAndNameModel[],
    autocompleteTrainingModule: autocompleteTrainingModule,
    selectedTrainingModules: [] as IdAndNameModel[]

  }),

  async mounted () {
    this.form = this.$refs.globalForm as VForm;
    await this.initData();
  },

  methods: {
    async initData (): Promise<void> {
      this.newName = this.qualification.name;
      if (!this.creationMode) {
        // this is an update -> find selected elements
        this.newName = this.qualification.name;
        // cartographies
        const cartoSites = await getCartographySiteNames(this.qualification.cartographies);
        this.selectedCartographies = Object.keys(cartoSites).map(key => ({ id: key, name: cartoSites[key] }));
        // groups
        const groupes = await resolveGroupNames(this.qualification.qualifiableGroups);
        this.selectedGroups = Object.keys(groupes).map(key => ({ id: key, name: groupes[key] }));
        // modules
        const modules = await resolveTrainingModuleNames(this.qualification.modules);
        this.selectedTrainingModules = Object.keys(modules).map(key => ({ id: key, name: modules[key] }));
      }
      this.form.validate();
    },
    async onSaveClicked (): Promise<void> {
      if (!this.form.validate() || this.moduleError) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      try {
        const qualificationInfo: CreateOrUpdateQualificationRequest = {
          name: this.newName,
          cartographies: this.selectedCartographies.map(c => c.id),
          qualifiableGroups: this.selectedGroups.map(g => g.id),
          modules: this.selectedTrainingModules.map(tm => tm.id)
        };
        let qualificationId = this.qualification.id;
        if (this.creationMode) {
          // this is a creation
          qualificationId = (await createQualification(qualificationInfo)).id;
        } else {
          // this is an update of existing qualification
          await updateQualification(qualificationId, qualificationInfo);
        }
        this.$router.push({ name: 'ViewQualification', params: { qualificationId: qualificationId } });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    nameValidator (): string | true {
      if (this.newName !== this.newName.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.newName.length === 0) {
        return this.$t('emptyFieldError').toString();
      }
      return true;
    }
  },

  computed: {
    creationMode (): boolean {
      return this.qualification.id === '';
    }
  },

  watch: {
    qualification (): void {
      this.initData();
    }
  }
});

