






























import Vue, { PropType } from 'vue';
import moment, { Moment } from 'moment';

/**
 * The type of error that the time picker emits
 */
export enum TimePickerErrorType {
  /**
   * The input value failed to parse
   */
  parse = 'parse',

  /**
   * The selected value is less than the minimum time
   */
  lessThanMin = 'lessThanMin',

  /**
   * The selected value is greater than the maximum time
   */
  greaterThanMax = 'greaterThanMax'
}

export default Vue.extend({

  name: 'TimePicker',

  props: {
    label: { type: String, default: function () { return this.$t('defaultLabel'); } },
    value: { type: moment, required: true },
    min: { type: Object as PropType<Moment | null>, default: null },
    max: { type: Object as PropType<Moment | null>, default: null },
    readOnly: { type: Boolean, default: true },
    compact: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false }
  },

  data: () => ({
    inputTime: '',
    currentError: null as TimePickerErrorType | null,
    showPicker: false
  }),

  methods: {
    getBgColor (): string {
      if (this.readOnly === true) {
        return 'bgDisabled';
      }
      return this.currentError === null ? 'bgAccent' : 'bgError';
    },
    onTimeChanged () {
      this.checkTime(this.parseUserTime(this.inputTime), true);
    },
    checkTime (time: Moment, emitInputEvent: boolean): void {
      if (time.isValid()) {
        if (emitInputEvent) {
          this.$emit('input', time);
        }
        const duration = moment.duration(time.diff(time.clone().startOf('day')));
        if (this.min && duration < moment.duration(this.min.diff(this.min.clone().startOf('day')))) {
          this.currentError = TimePickerErrorType.lessThanMin;
        } else if (this.max && moment.duration(this.max.diff(this.max.clone().startOf('day'))) < duration) {
          this.currentError = TimePickerErrorType.greaterThanMax;
        } else {
          this.currentError = null;
        }
      } else {
        this.currentError = TimePickerErrorType.parse;
      }
    },
    displayPicker () {
      this.$emit('focus'); // clicked on append-icon
      this.showPicker = true;
    },
    pickerTimeChanged (newPickerTime: string): void {
      const parsedTime = moment(newPickerTime, 'HH:mm');
      this.checkTime(parsedTime, true);
    },
    formatUserTime (time: Moment): string {
      return time.format('HH:mm');
    },
    parseUserTime (valueToParse: string): Moment {
      return moment(valueToParse, 'HH:mm');
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: function (newValue: Moment): void {
        this.inputTime = this.formatUserTime(newValue);
        this.checkTime(this.value, false);
      }
    },
    min (): void {
      this.checkTime(this.value, false);
    },
    max (): void {
      this.checkTime(this.value, false);
    },
    currentError: {
      immediate: true,
      handler: function (newError: TimePickerErrorType | null): void {
        this.$emit('error-changed', newError);
      }
    }
  },

  computed: {
    pickerTime () : string {
      return this.value.format('HH:mm');
    },
    displayedError () : string {
      switch (this.currentError) {
        case TimePickerErrorType.parse: return this.$t('errors.parse').toString();

        // The two errors below can only be triggered if min or max are not null.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        case TimePickerErrorType.lessThanMin: return this.$t('errors.lessThanMin', { min: this.formatUserTime(this.min!) }).toString();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        case TimePickerErrorType.greaterThanMax: return this.$t('errors.greaterThanMax', { max: this.formatUserTime(this.max!) }).toString();
        default: return '';
      }
    }
  }
});

