
























































import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import { getAllGroups, getAllowedItemsForGroup, getCartographiesTree, isPermissionGranted, setAllowedItemsForGroup } from '../repository';
import Vue from 'vue';
import { ContextItemType, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import { sortArrayByString } from '../Tools';
import eventBus from '../eventBus';
import DisplayConfirmationDialogBox from '../components/common/DisplayConfirmationDialogBox.vue';

interface Element { type:ContextItemType; id: string; name: string; isGranted: boolean; oldGrant: boolean; }
interface Site { item: Element; children: Element[]; }

export default Vue.extend({

  name: 'CartographiesPermissions',

  components: {
    DisplayConfirmationDialogBox,
    SimplePageLayout
  },

  data: () => ({
    siteList: [] as Site[],
    itemIndex: {} as Record<string, Element>, // site or cartography id -> site or cartography element
    groupList: [] as IdAndNameModel[],
    selectedGroup: null as IdAndNameModel | null,
    oldSelectedGroup: { id: '', name: '' } as IdAndNameModel,
    loading: true,
    confirmGroupChange: false
  }),

  async created () {
    if (!(await isPermissionGranted('perm_SetPermissions'))) {
      // user is not allowed to manage permissions -> redirect to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    } else {
      try {
        // create site list with children cartographies (all sites and all cartographies regardless permissions)
        const spotSites = await getCartographiesTree();
        this.siteList = spotSites.map(ss => ({
          item: { type: ContextItemType.Site, id: ss.item.id, name: ss.item.name, isGranted: false, oldGrant: false },
          children: ss.children.map(c => ({ type: ContextItemType.Cartography, id: c.item.id, name: c.item.name, isGranted: false, oldGrant: false }))
        }));
        // set itemIndex
        for (const site of this.siteList) {
          this.itemIndex[site.item.id] = site.item;
          for (const child of site.children) {
            this.itemIndex[child.id] = child;
          }
        }
        // get groupList
        this.groupList = await getAllGroups();
        sortArrayByString(this.groupList, (g) => g.name);
        this.selectedGroup = this.groupList[0]; // assume that there is at least one group defined, and arbitrary get first of list
        this.oldSelectedGroup = this.selectedGroup;
        // get sites and cartographies grants
        await this.updateGrants();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  },

  methods: {
    async onGroupChanged (): Promise<void> {
      if (this.changesToSave) {
        this.confirmGroupChange = true;
      } else {
        await this.updateGrants();
      }
    },
    goBackToPreviousGroup (): void {
      this.confirmGroupChange = false;
      this.selectedGroup = this.oldSelectedGroup;
    },
    async confirmGroupSelection (): Promise<void> {
      this.confirmGroupChange = false;
      await this.updateGrants();
    },
    async updateGrants (): Promise<void> {
      this.loading = true;
      // set oldGroup
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      this.oldSelectedGroup = this.selectedGroup!;
      // reset all grants
      for (const site of this.siteList) {
        site.item.isGranted = false;
        site.item.oldGrant = false;
        for (const child of site.children) {
          child.isGranted = false;
          child.oldGrant = false;
        }
      }
      // gets grants for selected group
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const allowedItemIds = await getAllowedItemsForGroup(this.selectedGroup!.id);
        // update grants in site list
        for (const itemId of allowedItemIds) {
          if (this.itemIndex[itemId]) {
            this.itemIndex[itemId].isGranted = true;
            this.itemIndex[itemId].oldGrant = true;
            if (this.itemIndex[itemId].type === ContextItemType.Site) {
              // site is selected -> all children must be selected too
              const found = this.siteList.find(s => s.item.id === itemId);
              if (found !== undefined) {
                for (const child of found.children) {
                  child.isGranted = true;
                  child.oldGrant = true;
                }
              }
            }
          }
        }
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        this.loading = false;
      }
    },
    toggleSite (site: Site): void {
      site.item.isGranted = !site.item.isGranted;
      for (const child of site.children) {
        child.isGranted = site.item.isGranted;
      }
    },
    getSiteIcon (site: Site): string {
      if (site.item.isGranted) {
        return 'mdi-checkbox-marked';
      }
      if (site.children.some(c => c.isGranted)) {
        return 'mdi-checkbox-intermediate';
      }
      return 'mdi-checkbox-blank-outline';
    },
    toggleChild (site: Site, child: Element): void {
      child.isGranted = !child.isGranted;
      if (!child.isGranted) {
        site.item.isGranted = false;
      }
    },
    getChildIcon (site: Site, child: Element): string {
      return child.isGranted ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline';
    },
    async onSaveClicked (): Promise<void> {
      try {
        const allowedItemIds = [] as string[];
        for (const site of this.siteList) {
          if (site.item.isGranted) {
            allowedItemIds.push(site.item.id);
          } else {
            allowedItemIds.push(...site.children.filter(c => c.isGranted).map(c => c.id));
          }
        }
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await setAllowedItemsForGroup(this.selectedGroup!.id, allowedItemIds);
        // update current old selection
        for (const site of this.siteList) {
          site.item.oldGrant = site.item.isGranted;
          for (const child of site.children) {
            child.oldGrant = child.isGranted;
          }
        }
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  },

  computed: {
    changesToSave (): boolean {
      return this.siteList.some(s => s.item.isGranted !== s.item.oldGrant || s.children.some(c => c.isGranted !== c.oldGrant));
    }
  }
});

