























































































import Vue, { PropType } from 'vue';
import eventBus from '../../eventBus';
import { createOrUpdateTrainingReference, deleteTrainingReference, getEmptyTrainingReference, getTrainingReferenceUsageStats, VForm } from '../../repository';
import { TrainingReferenceModel, TrainingReferenceType } from '../../../_GeneratedClients/SpotClient';
import DisplayConfirmationDialogBox from '../common/DisplayConfirmationDialogBox.vue';

export default Vue.extend({

  name: 'AddOrEditTrainingReference',

  components: {
    DisplayConfirmationDialogBox
  },

  props: {
    referenceType: { type: String, default: undefined },
    trainingReference: { type: Object as PropType<TrainingReferenceModel>, required: true }
  },

  data: () => ({
    reference: getEmptyTrainingReference(),
    title: '',
    erasable: false,
    globalError: '',
    showConfirmDeletion: false
  }),

  async mounted () {
    await this.initForm();
  },

  methods: {
    async initForm (): Promise<void> {
      this.globalError = '';
      if (this.referenceType === undefined) {
        // undefined means that there is nothing to display (component is not displayed by parent) -> reference is set to empty to avoid template errors
        this.reference = getEmptyTrainingReference();
        return;
      }
      this.reference = { ...this.trainingReference };
      if (this.creation) {
        this.title = this.referenceType === TrainingReferenceType.Module
          ? this.$t('addTrainingModuleReference').toString()
          : this.$t('addTrainingPathReference').toString();
        this.erasable = false;
      } else {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.title = this.referenceType === TrainingReferenceType.Module
          ? this.$t('editTrainingModuleReference').toString()
          : this.$t('editTrainingPathReference').toString();
        try {
          const usage = await getTrainingReferenceUsageStats(this.referenceType as TrainingReferenceType, this.reference.code);
          this.erasable = usage.count === 0;
        } catch (err) {
          console.error(err);
          this.erasable = true;
        }
      }
      (this.$refs.globalForm as VForm).validate();
    },
    async onSaveClicked () {
      if (!(this.$refs.globalForm as VForm).validate()) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      try {
        await createOrUpdateTrainingReference(this.referenceType as TrainingReferenceType, this.reference);
        this.$emit('close');
        this.gotoPageList();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async deleteReference (): Promise<void> {
      try {
        this.showConfirmDeletion = false;
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await deleteTrainingReference(this.referenceType as TrainingReferenceType, this.reference!.code);
        this.$emit('close');
        this.gotoPageList();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    gotoPageList (): void {
      let nextRouteName = '';
      switch (this.referenceType) {
        case TrainingReferenceType.Module: nextRouteName = 'FilteredTrainingModuleReferences'; break;
        case TrainingReferenceType.Path: nextRouteName = 'FilteredTrainingPathReferences'; break;
      }
      const currentRouteName = this.$route.name;
      if (nextRouteName === currentRouteName) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: nextRouteName });
      }
    },
    codeValidator (): string | true {
      if (this.reference === undefined) {
        return true;
      }
      if (this.reference.code !== this.reference.code.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.reference.code.length === 0) {
        return this.$t('emptyFieldError').toString();
      }
      return true;
    },
    labelValidator (): string | true {
      if (this.reference === undefined) {
        return true;
      }
      if (this.reference.label !== this.reference.label.trim()) {
        // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      } else if (this.reference.label.length === 0) {
        // name must be set
        return this.$t('emptyFieldError').toString();
      }
      return true;
    }
  },

  computed: {
    creation (): boolean {
      return this.trainingReference.code === '';
    }
  },

  watch: {
    referenceType: async function () {
      if (this.trainingReference !== undefined) {
        await this.initForm();
      }
    },
    trainingReference: async function () {
      if (this.trainingReference !== undefined) {
        await this.initForm();
      }
    }
  }
});

