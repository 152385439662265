



































































import Vue, { PropType } from 'vue';
import DateTimePicker from '../common/DateTimePicker.vue';
import { Moment } from 'moment';

/**
 * An activity period of a mediation (between a start and a pause/stop)
 * Same as the TrackingPeriodModel, but always has a value for stop
 */
export interface FinishedTrackingPeriodModel {
  /** The start date of the period */
  start: Moment;
  /** The stop date of the period */
  stop: Moment;
}

export default Vue.extend({

  name: 'EditTrackingPeriod',

  components: {
    DateTimePicker
  },

  props: {
    value: { type: Object as PropType<FinishedTrackingPeriodModel>, required: true },
    min: { type: Object as PropType<Moment | null>, default: null },
    max: { type: Object as PropType<Moment | null>, default: null },
    deletable: { type: Boolean, default: false }
  },

  data: () => ({
    showDeleteConfirmationForm: false,
    hasStartDateError: false,
    hasStopDateError: false
  }),

  methods: {
    getFormattedDate (date: Moment) {
      return date.format(this.$t('dateFormat.dateTimePattern').toString());
    },
    onDeleteClicked () {
      this.showDeleteConfirmationForm = false;
      this.$emit('delete');
    }
  },
  watch: {
    hasStartDateError (): void {
      this.$emit('error-changed', this.hasStartDateError || this.hasStopDateError);
    },
    hasStopDateError (): void {
      this.$emit('error-changed', this.hasStartDateError || this.hasStopDateError);
    }
  }
});

