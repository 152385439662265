













import Vue from 'vue';
import { createTrainingModule, getEmptyTrainingModule, getTrainingModuleById, isPermissionGranted } from '../repository';
import AddOrEditTrainingModule from '../components/trainingPathModules/AddOrEditTrainingModule.vue';
import { CreateOrUpdateTrainingModuleRequest } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'CreateFromTrainingModule',

  components: {
    AddOrEditTrainingModule
  },

  data: () => ({
    moduleToEdit: getEmptyTrainingModule(),
    moduleId: '',
    canEdit: true
  }),

  async created () {
    if (await isPermissionGranted('perm_TrainingManagement')) {
      this.moduleId = this.$route.params.moduleId;
      try {
        const module = await getTrainingModuleById(this.moduleId);
        this.moduleToEdit = { ...module };
        this.moduleToEdit.name = ''; // user must set a new name
        this.moduleToEdit.id = '';
      } catch (err) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      }
    } else {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  },

  methods: {
    returnToView (): void {
      this.$router.push({ name: 'ViewTrainingModule', params: { moduleId: this.moduleId } });
    },
    async onSaveClicked (toSave: CreateOrUpdateTrainingModuleRequest): Promise<void> {
      try {
        const result = await createTrainingModule(toSave);
        if (result.id !== null && result.id !== undefined && result.id !== '') {
          this.$router.push({ name: 'ViewTrainingModule', params: { moduleId: result.id } });
        } else {
          this.$router.push({ name: 'FilteredTrainingModules' });
        }
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

