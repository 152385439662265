














































import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';
import { getFilterList } from '../../repository';
import FilterBar from './FilterBar.vue';
import { serializeQuery, parseQuery, QueryCriterion, MultipleQueryCriteriaType, FrontendFieldConfiguration, serializeSorting, SortingCriterion, parseSorting } from '../../queryEngine';
import { QueryRequest } from '../../../_GeneratedClients/SpotClient';
import eventBus from '../../eventBus';
import { setLocationBack } from '../../helpers/filteredListNavigation';

/**
 * The generic shape of the response items from the API
 */
interface QueryResponse {
    /** The query results in the page */
    results: unknown[];
    /** The total number of items (useful to get the number of pages) */
    totalItems: number;
}

export default Vue.extend({

  name: 'FilteredListPage',

  components: {
    FilterBar
  },

  props: {
    /** define list and configuration of possibles criteria to build a filter */
    fieldsConfiguration: { type: Object as PropType<Record<string, FrontendFieldConfiguration>>, required: true },
    /** type of object using the filter (type of parent list objects) - used to manage registered filters */
    entityType: { type: String, default: '', required: true },
    /** The name of the route on which this page is (used to redirect after filters and pages navigation) */
    routeName: { type: String, default: '', required: true },
    pageTitle: { type: String, default: '', required: true },
    queryMethod: { type: Function as PropType<(query: QueryRequest) => QueryResponse>, required: true },
    onBeforeDataLoad: { type: Function as PropType<() => Promise<void>>, default: () => () => Promise.resolve() }
  },

  data: () => ({
    currentFilter: '',
    currentSorting: '',
    loaded: false,
    displayList: [] as unknown[],
    filter: { type: MultipleQueryCriteriaType.and, criteria: [] } as QueryCriterion,
    sorting: [] as SortingCriterion[],
    maxPerPage: 50, // must be more than 0
    totalItems: 0,
    nbPages: 0,
    lastLoadedPage: 0, // 0 = nothing loaded, 1 = first page
    targetedIndex: 0,
    targetedPage: 0
  }),

  async mounted () {
    const applyDefaultFilter = (this.$route.query.applyDefaultFilter as string | undefined) === 'true';
    if (applyDefaultFilter) {
      // Redirect to the default filter
      const registeredFilterList = await getFilterList(this.entityType);
      const defaultFilter = registeredFilterList.find((f) => f.isDefault === true);
      if (defaultFilter !== undefined) {
        this.applyFilter(defaultFilter.filter, defaultFilter.sorting ?? '');
        return;
      }
    }
    this.currentFilter = this.$route.params.filter ?? '';
    this.currentSorting = this.$route.query.sorting as string | undefined ?? '';
    const itemIdentifier = this.$route.hash;
    if (itemIdentifier !== undefined) {
      const itemIndex = parseInt(itemIdentifier.split('-')[1]);
      if (!isNaN(itemIndex)) {
        this.targetedIndex = itemIndex;
        this.targetedPage = Math.trunc(itemIndex / this.maxPerPage) + 1;
      }
    }
    setLocationBack(undefined); // reset return location
    this.filter = { type: MultipleQueryCriteriaType.and, criteria: [] };
    if (this.currentFilter !== '') {
      try {
        this.filter = parseQuery(this.fieldsConfiguration, this.currentFilter) ?? this.filter;
      } catch (err) {
        console.error(err);
        // TODO: Show error, but for now, just use the default query.
      }
    }
    if (this.currentSorting !== '') {
      try {
        this.sorting = parseSorting(this.fieldsConfiguration, this.currentSorting);
      } catch (err) {
        console.error(err);
        // TODO: Show error, but for now, just use the default ordering.
      }
    }
    await this.onBeforeDataLoad();
    await this.initDisplay();
    // if list isn't empty, set position in page (scroll)
    if (this.totalItems > 0 && this.targetedIndex > 0) {
      const element = (this.$refs['item-' + (this.targetedIndex - 1)] as Element[])[0] as HTMLElement;
      if (element !== undefined) {
        const scrollHeight = element.offsetTop;
        (this.$refs.itemList as HTMLElement).scrollTo({ top: scrollHeight, left: 0, behavior: 'smooth' });
      }
    }
  },

  methods: {
    async initDisplay (): Promise<void> {
      try {
        this.loaded = false;
        const response = await this.queryMethod({
          query: serializeQuery(this.filter),
          page: 0, // first page (The API is 0-based)
          pageSize: this.maxPerPage * Math.max(this.targetedPage, 1),
          sorting: serializeSorting(this.sorting)
        });
        this.displayList = response.results;
        this.totalItems = response.totalItems;
        this.lastLoadedPage = Math.max(this.targetedPage, 1);
        this.nbPages = Math.ceil(this.totalItems / this.maxPerPage);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        this.loaded = true;
      }
    },
    async addPage (_entries: IntersectionObserverEntry, _observer: IntersectionObserver, isIntersecting: boolean): Promise<void> {
      if (isIntersecting === true && this.lastLoadedPage < this.nbPages) {
        try {
          const response = await this.queryMethod({
            query: serializeQuery(this.filter),
            page: this.lastLoadedPage,
            pageSize: this.maxPerPage,
            sorting: serializeSorting(this.sorting)
          });
          this.lastLoadedPage++;
          this.displayList.push(...response.results);
        } catch (err) {
          eventBus.$emit('error', err, null);
        }
      }
    },
    getQuery (page: number) {
      if (this.$route.query.sorting === undefined) {
        return { page: page.toString() };
      }
      return { page: page.toString(), sorting: this.$route.query.sorting as string };
    },
    applyFilter (filter: string, sorting: string) {
      if ((this.$route.params.filter ?? '') !== filter || (this.$route.query.sorting ?? '') !== sorting) {
        // current route parameters aren't asked parameters -> reload page with asked parameters
        const routeParams: Record<string, string> = {};
        if (filter !== '') {
          routeParams.filter = filter;
        }
        const routeQuery: Record<string, string> = {};
        if (sorting !== '') {
          routeQuery.sorting = sorting;
        }
        this.$router.push({
          name: this.routeName,
          params: routeParams,
          query: routeQuery
        });
      }
    },
    saveLocationBack (id: number): void {
      const hash = '#item-' + id;
      const location: Location = {
        name: this.routeName,
        hash: hash
      };
      if (this.currentFilter) {
        location.params = { filter: this.currentFilter };
      }
      if (this.currentSorting) {
        location.query = { sorting: this.currentSorting };
      }
      setLocationBack(location);
    }
  }
});

