import { ContextItemType, ContextModel, SubjectModel } from '../../_GeneratedClients/SpotClient';
import { ContextNodeViewModel, ContextViewModel, ISpotItem } from './Types';

/**
 * gets link to navigate to v1 objects
 *
 * @param spotItem the spot object to which the link should point (in v1 user interface)
 * @returns a string used in html link
 */
export function getSpotItemV1Link (spotItem: ISpotItem): string {
  switch (spotItem.item.type) {
    case ContextItemType.Function: return getFunctionV1Link(spotItem.contexts[0].hierarchy[1].code, spotItem.item.code);
    case ContextItemType.MindmapNode: {
      const functionHierarchy = spotItem.contexts.find(ctx => ctx.hierarchy.some(ctxitem => ctxitem.type === ContextItemType.Function));
      if (functionHierarchy) {
        return getMindmapNodeV1Link(functionHierarchy.hierarchy[1].code, functionHierarchy.hierarchy[2].code, functionHierarchy.hierarchy[3].code, spotItem.item.code);
      }
      break;
    }
    default:
      console.error('Type ' + spotItem.item.type + ' was not expected');
  }
  return '';
}

/**
 * gets link to navigate to v1 functions
 *
 * @param cartographyCode the cartography code used to build the link
 * @param functionCode the function code used to build the link
 * @returns The v1 URL to the given function page
 */
export function getFunctionV1Link (cartographyCode: string, functionCode: string): string {
  return 'spot/' + encodeURIComponent(cartographyCode) + '/fonction/' + encodeURIComponent(functionCode);
}

/**
 * gets link to navigate to v1 mindmaps
 *
 * @param subject the subject node containing the targetted mindmap
 * @param standardized (default = false) : must be true to get the standardized mindmap rather than the current mindmap
 * @returns The v1 URL to the given mindmap
 */
export function getMindmapV1Link (subject: SubjectModel, standardized = false): string {
  const functionHierarchy = subject.contexts.find(ctx => ctx.hierarchy.some(ctxitem => ctxitem.type === ContextItemType.Function));
  if (functionHierarchy) { // would be always true
    const cartographyCode = encodeURIComponent(functionHierarchy.hierarchy[1].code);
    const functionCode = encodeURIComponent(functionHierarchy.hierarchy[2].code);
    const subjectCode = encodeURIComponent(subject.code);
    const link = 'spot/' + cartographyCode + '/fonction/' + functionCode + '/pdvhaut/' + subjectCode;
    return standardized ? link + '/true/' : link + '//';
  }
  return '';
}

/**
 * Computes the link to the diagram in the V1 frontend
 *
 * @param subject The subject for which to generate the diagram URL
 * @returns The v1 URL to the given entity
 */
export function getDiagramV1Link (subject: SubjectModel): string {
  const functionHierarchy = subject.contexts.find(ctx => ctx.hierarchy.some(ctxitem => ctxitem.type === ContextItemType.Function));
  if (functionHierarchy) {
    return 'spot/' + encodeURIComponent(functionHierarchy.hierarchy[1].code) + '/fonction/' + encodeURIComponent(functionHierarchy.hierarchy[2].code) + '/pdvbas/' + encodeURIComponent(subject.code);
  }
  return '';
}

/**
 * gets link to navigate to v1 mindmap nodes
 *
 * @param cartographyCode the cartography code used to build the link
 * @param functionCode the function code used to build the link
 * @param subjectCode the subject code used to build the link
 * @param nodeCode the node code used to build the link
 * @returns The v1 URL to the given node
 */
export function getMindmapNodeV1Link (cartographyCode: string, functionCode: string, subjectCode: string, nodeCode: string): string {
  return 'spot/' + encodeURIComponent(cartographyCode) + '/fonction/' + encodeURIComponent(functionCode) + '/pdvhaut/' + encodeURIComponent(subjectCode) + '//' + encodeURIComponent(nodeCode);
}

/**
 * Transforms the given ContextModel list in a ContextViewModel list
 *
 * @param contexts The contexts to transform
 * @returns the transformed contexts
 */
export function getContextViewModel (contexts: ContextModel[]): ContextViewModel[] {
  // creates the context view-model
  const response = [] as ContextViewModel[];
  for (const context of contexts) {
    const nodes = [] as ContextNodeViewModel[];
    for (const node of context.hierarchy) {
      let link: string | undefined;
      switch (node.type) {
        case ContextItemType.Site:
        case ContextItemType.Activity:
          nodes.push({ name: node.name, link: undefined });
          break;
        case ContextItemType.Cartography:
          nodes.push({ name: node.name, link: { name: 'Cartography', params: { cartographyId: node.id } } });
          break;
        case ContextItemType.Function:
          link = getFunctionV1Link(context.hierarchy[1].code, context.hierarchy[2].code);
          nodes.push({ name: node.name, link: { name: 'V1Redirection', query: { to: link } } });
          break;
        case ContextItemType.Subject:
          nodes.push({ name: node.name, link: { name: 'Subject', params: { subjectId: node.id } } });
          break;
      }
    }
    response.push({ nodes: nodes });
  }
  return response;
}
