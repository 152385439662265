














import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';
import MultiAutocomplete from '../common/MultiAutocomplete.vue';

export default Vue.extend({

  name: 'QueryIdSelector',

  components: {
    MultiAutocomplete
  },

  props: {
    value: { type: Array as PropType<IdAndNameModel[]>, default: function () { return [] as IdAndNameModel[]; } },
    autocomplete: { type: Function, required: true }
  }
});

