import SubjectFieldsConfiguration from '../fieldsConfiguration/SubjectFieldsConfiguration';
import { doQuery, parseQuery } from '.';
import { QueryRequest, SubjectModel } from '../../_GeneratedClients/SpotClient';
import { getAllSubjects } from '../repository';
import { parseSorting } from './sorting';

/** The result object of a query */
// eslint-disable-next-line camelcase
export interface QueryResponse_SubjectModel {
  /** The query results in the page */
  results: SubjectModel[];
  /** The total number of items (useful to get the number of pages) */
  totalItems: number;
}

/**
 * Performs a subject query given the filters parameters.
 *
 * @param query The query that the subjects need to match
 * @returns The filtered list of the subjects
 */
// eslint-disable-next-line camelcase
export async function querySubjects (query: QueryRequest): Promise<QueryResponse_SubjectModel> {
  // TODO: move this to the server-side
  const criterion = parseQuery(SubjectFieldsConfiguration, query.query);
  const sorting = parseSorting(SubjectFieldsConfiguration, query.sorting ?? '');
  const source = await getAllSubjects();

  const filteredItems = doQuery(SubjectFieldsConfiguration, criterion, sorting, source);
  let pagedFilteredItems = filteredItems;

  if (query.pageSize !== undefined) {
    const start = (query.page ?? 0) * query.pageSize;
    pagedFilteredItems = filteredItems.slice(start, start + query.pageSize);
  }

  return {
    results: pagedFilteredItems,
    totalItems: filteredItems.length
  };
}
