import { client } from './Client';
import { IdAndNameModel, SavedTrainingPathReportConfiguration, UserInfoModel } from '../../_GeneratedClients/SpotClient';

/**
 * Gets users infos from API
 *
 * @param userIdList the list of user identifiants
 * @returns complete profile infos of asked users
 */
export async function getUsersByIds (userIdList: string[]): Promise<{ [key: string]: UserInfoModel }> {
  return client.getUsersByIds(userIdList);
}

/**
 * format user identity infos as a string
 *
 * @param source the original user informations
 * @param withExternalId if true, adds external id after the name
 * @returns the user name (firstname + lastname), with external id if asked (and if exists)
 */
export function getUserName (source: UserInfoModel, withExternalId = false): string {
  let name = source.firstName + ' ' + source.lastName;
  if (withExternalId) {
    const externalId = getUserExternalId(source);
    name += externalId === '' ? '' : ' (' + externalId + ')';
  }
  return name;
}

/**
 * return user external id, if exists, otherwise, returns empty string
 *
 * @param source the original user informations
 * @returns the user external id
 */
export function getUserExternalId (source: UserInfoModel): string {
  return source.externalId ?? '';
}

/**
 * Gets the list of all existing groups
 *
 * @returns the list of existing groups
 */
export async function getAllGroups (): Promise<IdAndNameModel[]> {
  // Note : searchGroup, with an empty string as parameter, returns all the registered groups.
  return await client.searchGroup('');
}

/**
 * Gets the map of group names given their id
 *
 * @param ids The searched group identifiers
 * @returns The mapping that associates the identifiers to the names
 */
export async function resolveGroupNames (ids: string[]): Promise<Record<string, string>> {
  return await client.getGroupNames(ids);
}

/**
 * gets the training path report registered configurations for the connected user
 *
 * @returns the registered configurations
 */
export async function getUserTrainingPathReportConfigurations (): Promise<SavedTrainingPathReportConfiguration[]> {
  return await client.getUserTrainingPathReportConfigurations();
}

/**
 * Save the training path report configurations for the connected user
 *
 * @param configurations the configurations to save
 * @returns nothing
 */
export async function SaveUserTrainingPathReportConfiguration (configurations: SavedTrainingPathReportConfiguration[]): Promise<void> {
  return await client.saveUserTrainingPathReportConfigurations(configurations);
}
