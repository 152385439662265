









import Vue, { PropType } from 'vue';
import { ContextItemType } from '../../../_GeneratedClients/SpotClient';
import { ISpotItem } from '../../repository';

interface SourceNode { name: string; color: string; }

export default Vue.extend({

  name: 'DisplayModuleSpotSource',

  props: {
    element: { type: Object as PropType<ISpotItem | undefined>, default: undefined }
  },
  computed: {
    displayableSource (): SourceNode[] {
      const result = [] as SourceNode[];
      if (this.element === undefined) {
        return result;
      }
      switch (this.element.item.type) {
        case ContextItemType.Cartography: {
          result.push({ name: this.element.item.name, color: 'bgSuccess' });
          break;
        }
        case ContextItemType.Function: {
          result.push({ name: this.element.contexts[0].hierarchy[1].name, color: 'bgDisabled' });
          result.push({ name: this.element.item.name, color: 'bgSuccess' });
          break;
        }
        case ContextItemType.Activity: {
          result.push({ name: this.element.contexts[0].hierarchy[1].name, color: 'bgDisabled' });
          for (let i = 2; i < this.element.contexts[0].hierarchy.length; i++) {
            const node = this.element.contexts[0].hierarchy[i];
            result.push({ name: node.name, color: 'bgDisabled' });
          }
          result.push({ name: this.element.item.name, color: 'bgSuccess' });
          break;
        }
        case ContextItemType.Subject: {
          result.push({ name: this.element.contexts[0].hierarchy[1].name, color: 'bgDisabled' });
          let fidx = -1; // function context not yet detected
          let aidx = -1; // activity context not yet detected
          if (this.element.contexts.length === 1) { // subject is only attached to an activity
            aidx = 0;
          } else { // assume that the two first contexts are function and activity context from same cartography (unknown order)
            if (this.element.contexts[0].hierarchy[2].type === ContextItemType.Function) {
              fidx = 0; aidx = 1; // first context = function, second = activity
            } else {
              fidx = 1; aidx = 0; // first context = activity, second = function
            }
          }
          if (fidx > -1) { // -1 = no function context
            // contexts[fidx] is function context
            result.push({ name: this.element.contexts[fidx].hierarchy[2].name, color: 'bgSuccess' });
          }
          // contexts[aidx] is activity context
          for (let i = 2; i < this.element.contexts[aidx].hierarchy.length; i++) {
            result.push({
              name: this.element.contexts[aidx].hierarchy[i].name,
              color: i < this.element.contexts[aidx].hierarchy.length - 1 ? 'bgDisabled' : 'bgSuccess'
            });
          }
        }
      }
      return result;
    }
  }
});

