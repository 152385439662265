import { backendUrl, getFriendlyDate } from '../Tools';
import moment from 'moment';
import { ActivityModel, CartographyModel, ContextItemType, SubjectModel } from '../../_GeneratedClients/SpotClient';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import { getTotalSubjectsInActivity } from '../repository';

(pdfMake.vfs as { [file: string]: string }) = pdfFonts.pdfMake.vfs;

/**
 * Create carto PDF with pdfmake : http://pdfmake.org/#/
 *
 * @param carto Cartography of the current page
 * @param cartoImageUrl Url of the current cartography image
 * @param pictoUrls Url of the differents activities's pictograms
 */

export default function cartographyPDF (carto: CartographyModel | undefined, cartoImageUrl: string, pictoUrls: Record<string, string>): void {
// Create the pdf only if there is a cartography available
  if (carto !== undefined) {
    const docPdf: TDocumentDefinitions = {
      content: [
        {
          image: 'cartography',
          fit: [570, 810],
          alignment: 'center'
        },
        {
          pageBreak: 'before',
          columnGap: 25,
          // Can only display columns two by two due to the width and readability
          columns: carto.activities.slice(0, 2).map(activity => getColumnPdf(activity, pictoUrls))
        },
        {
          pageBreak: 'before',
          columnGap: 25,
          columns: carto.activities.slice(2, 4).map(activity => getColumnPdf(activity, pictoUrls))
        }
      ],
      // style dictionnary
      styles: {
        header: {
          fillColor: '#b1b1b1',
          fontSize: 15,
          bold: true
        },
        activity: {
          alignment: 'center',
          fillColor: '#dddddd',
          fontSize: 12,
          bold: true
        }
      },
      // Images to use in pdf content
      images: {
        cartography: cartoImageUrl,
        logo: `${backendUrl}/organization/logo`
      },
      // Header and footer, not displayed on the 1st page dedicated to the catography
      header: (page) => {
        if (page !== 1) {
          return {
            alignment: 'center',
            columns: [
              { image: 'logo', alignment: 'left', fit: [70, 20] },
              { text: carto.name, alignment: 'center' },
              { text: '' }
            ],
            margin: [15, 15]
          };
        }
      },
      footer: (page, pages) => {
        return {
          columns: [
            page !== 1 ? { text: getFriendlyDate(moment(), 'shortDate'), alignment: 'left' } : { text: '' },
            { alignment: 'right', text: [{ text: page.toString(), italics: true }, '/', { text: pages.toString(), italics: true }] }
          ],
          margin: [15, 0, 15, 0]
        };
      }
    };
    // Download the pdf with cartography's name
    pdfMake.createPdf(docPdf).download(carto.name);
  }
}

/**
 * Create activity's table in a column
 *
 * @param activity Activities and subActivities of the current cartography
 * @param pictoUrls Url of the differents activities's pictograms
 * @returns A cell content that contains a table that represents a single activity
 */

function getColumnPdf (activity: ActivityModel, pictoUrls: Record<string, string>) {
  return [
    {
      table: {
        widths: [30, 'auto', 30],
        body: [
          [
            { image: pictoUrls[activity.id], fit: [15, 15], border: [true, true, false, true], style: 'header' },
            { text: activity.name, alignment: 'center', border: [false, true, false, true], style: 'header' },
            { text: getTotalSubjectsInActivity(activity), alignment: 'right', border: [false, true, true, true], style: 'header' }
          ],
          // Subjects without activities
          ...activity.subjects.map(getSubjectPdfTable),
          // Actitivies and their subjects
          ...activity.activities.flatMap((subActivity) => [
            [{ text: subActivity.name, colSpan: 3, style: 'activity' }, {}, {}],
            ...subActivity.subjects.map(getSubjectPdfTable)
          ])
        ]
      }
    }
  ];
}

/**
 * Display activities's subjects
 *
 * @param subject Subjects of each activities ans subActivities
 * @returns An arryay with the subject code and name
 */

function getSubjectPdfTable (subject: SubjectModel) {
  return [
    subject.contexts.flatMap(ctx => ctx.hierarchy).find(node =>
      node.type === ContextItemType.Function)?.code ?? '',
    { text: subject.name, colSpan: 2 },
    {}
  ];
}
