

















import { Moment } from 'moment';
import Vue, { PropType } from 'vue';
import { LifetimeEvent } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'SubjectStep',

  props: {
    events: { type: Object as PropType<{[key: string]: LifetimeEvent }>, required: true },
    name: { type: String as PropType<string>, required: true }
  },

  computed: {
    checked () : false | Moment {
      return Object.hasOwnProperty.call(this.events, this.name) && this.events[this.name].date;
    }
  }
});
