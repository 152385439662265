















































































































import { autocompleteGroup, autocompleteUserWithoutDefault } from '../../repository';
import { convert, LocalDate } from '@js-joda/core';
import moment, { Moment } from 'moment';
import Vue, { PropType } from 'vue';
import { AssignedTrainingModuleStatus, IdAndNameModel, TrainingModuleType, TrainingPathStatus } from '../../../_GeneratedClients/SpotClient';
import DatePicker from '../common/DatePicker.vue';
import MultiAutocomplete from '../common/MultiAutocomplete.vue';
import { firstLowerCase } from '../../Tools';

interface StatusSelector { id: string; name: string; checked: boolean; }

export default Vue.extend({

  name: 'TrainingPathReportParameters',

  components: {
    DatePicker,
    MultiAutocomplete
  },

  props: {
    startDate: { type: String, required: true },
    stopDate: { type: String, required: true },
    learnerList: { type: Array as PropType<IdAndNameModel[]>, default: () => [] as IdAndNameModel[] },
    groupList: { type: Array as PropType<IdAndNameModel[]>, default: () => [] as IdAndNameModel[] },
    pathStatutes: { type: Array as PropType<TrainingPathStatus[]>, default: () => [] as TrainingPathStatus[] },
    moduleStatutes: { type: Array as PropType<AssignedTrainingModuleStatus[]>, default: () => [] as AssignedTrainingModuleStatus[] },
    moduleTypes: { type: Array as PropType<TrainingModuleType[]>, default: () => [] as TrainingModuleType[] }
  },

  data: () => ({
    startDateError: false,
    stopDateError: false,
    usersError: false,
    autocompleteUser: autocompleteUserWithoutDefault,
    groupsError: false,
    autocompleteGroup: autocompleteGroup,
    pathStatusSelector: [] as StatusSelector[],
    moduleStatusSelector: [] as StatusSelector[],
    moduleTypeSelector: [] as StatusSelector[]
  }),

  created () {
    // path status selector creation
    for (const status in TrainingPathStatus) {
      if (isNaN(Number(status))) {
        const idx = firstLowerCase(status);
        this.pathStatusSelector.push({ id: idx, name: this.$t('status.' + idx), checked: false } as StatusSelector);
      }
    }
    // assigned modules status selector creation
    for (const status in AssignedTrainingModuleStatus) {
      if (isNaN(Number(status))) {
        const idx = firstLowerCase(status);
        this.moduleStatusSelector.push({ id: idx, name: this.$t('status.' + idx), checked: false } as StatusSelector);
      }
    }
    // assigned modules types selector creation
    for (const type in TrainingModuleType) {
      if (isNaN(Number(type))) {
        const idx = firstLowerCase(type);
        this.moduleTypeSelector.push({ id: idx, name: this.$t('moduleType.' + idx), checked: false } as StatusSelector);
      }
    }
    this.$emit('error', this.globalError);
  },

  methods: {
    update (element: string, newValue: Moment | IdAndNameModel[] | TrainingPathStatus[] | AssignedTrainingModuleStatus[] | TrainingModuleType[]): void {
      switch (element) {
        case 'start':
        case 'stop': this.$emit('update', element, LocalDate.parse((newValue as Moment).format('YYYY-MM-DD'))); break;
        case 'learnerList':
        case 'groupList': this.$emit('update', element, newValue); break;
      }
    },
    updatePathStatutes (): void {
      this.$emit('update', 'pathStatutes', this.pathStatusSelector.filter(ps => ps.checked).map(ps => ps.id));
    },
    updateModuleStatutes (): void {
      this.$emit('update', 'moduleStatutes', this.moduleStatusSelector.filter(ms => ms.checked).map(ms => ms.id));
    },
    updateModuleTypes (): void {
      this.$emit('update', 'moduleTypes', this.moduleTypeSelector.filter(mt => mt.checked).map(mt => mt.id));
    },
    targetValidator (): string | true {
      if (this.learnerList.length + this.groupList.length === 0) {
        return this.$t('defineAtLeastOneLearnerOrGroup').toString();
      }
      return true;
    }
  },

  computed: {
    start: function (): Moment {
      if (this.startDate === '') {
        return moment();
      }
      return moment(convert(LocalDate.parse(this.startDate)).toDate());
    },
    stop: function (): Moment {
      if (this.stopDate === '') {
        return moment();
      }
      return moment(convert(LocalDate.parse(this.stopDate)).toDate());
    },
    pathStatusError: function (): boolean {
      return this.pathStatusSelector.every(ps => !ps.checked);
    },
    moduleStatusError: function (): boolean {
      return this.moduleStatusSelector.every(ms => !ms.checked);
    },
    moduleTypeError: function (): boolean {
      return this.moduleTypeSelector.every(mt => !mt.checked);
    },
    globalError: function (): boolean {
      return this.startDateError || this.stopDateError || this.usersError || this.groupsError || this.pathStatusError || this.moduleStatusError || this.moduleTypeError;
    }
  },

  watch: {
    pathStatutes: {
      immediate: false,
      handler: function (): void {
        for (const ps of this.pathStatusSelector) {
          ps.checked = this.pathStatutes.includes(ps.id as TrainingPathStatus);
        }
      }
    },
    moduleStatutes: {
      immediate: false,
      handler: function (): void {
        for (const ms of this.moduleStatusSelector) {
          ms.checked = this.moduleStatutes.includes(ms.id as AssignedTrainingModuleStatus);
        }
      }
    },
    moduleTypes: {
      immediate: false,
      handler: function (): void {
        for (const mt of this.moduleTypeSelector) {
          mt.checked = this.moduleTypes.includes(mt.id as TrainingModuleType);
        }
      }
    },
    globalError (): void {
      this.$emit('error', this.globalError);
    }
  }
});

