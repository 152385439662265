


































































































































import Vue from 'vue';
import { LocaleMessage } from 'vue-i18n';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import {
  AssignedTrainingModuleModel,
  AssignedTrainingModuleStatus,
  DocumentReportOptions,
  IdAndNameModel,
  MediationTrainingModuleItemResult,
  ModuleReportOptions,
  SavedTrainingPathReportConfiguration,
  TrainingModuleItemResult,
  TrainingModuleItemType,
  TrainingModuleType,
  TrainingPathModel,
  TrainingPathReportOptions,
  TrainingPathReportSelectors,
  TrainingPathStatus,
  UserInfoModel
} from '../../_GeneratedClients/SpotClient';
import {
  getMediationById,
  getUserExternalId,
  getUserName,
  getUsersByIds,
  getUsersIdByGroups,
  getUserTrainingPathReportConfigurations,
  queryPaths,
  resolveGroupNames,
  resolveUserNames,
  SaveUserTrainingPathReportConfiguration,
  SignatureImage
} from '../repository';
import eventBus from '../eventBus';
import { filesToZipDownload, PrintableInfos, PrintableLearnerInfos, PrintableModuleInfos, PrintableTrainingPathInfos } from '../helpers/TrainingPathReportPDF';
import TrainingPathReportParameters from '../components/trainingPathReports/TrainingPathReportParameters.vue';
import TrainingPathReportOptionsSelector from '../components/trainingPathReports/TrainingPathReportOptionsSelector.vue';
import DisplayTrainingPathList, { DisplayableTrainingPathInfo } from '../components/trainingPathReports/DisplayTrainingPathList.vue';
import { getMediationDuration } from '../helpers/MediationHelper';
import { deduplicateArray, durationToText, firstLowerCase, periodToText, sortArrayByString, truncate } from '../Tools';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import ExcelJS from 'exceljs';
import slugify from 'slugify';

interface ExcelColumnTitle { header: string; key: string; }
interface UserInfo extends UserInfoModel { fullName: string; }

export default Vue.extend({

  name: 'TrainingPathReport',

  components: {
    SimplePageLayout,
    TrainingPathReportParameters,
    TrainingPathReportOptionsSelector,
    DisplayTrainingPathList
  },

  data: () => ({
    gradeScale: 10, // according to Moodle grading
    satisfactionScale: 5, // one to five star(s)
    warningMessage: '' as string | LocaleMessage,
    reportParameters: { start: '', stop: '', learnerIdList: [], groupIdList: [], pathStatutes: [], moduleStatutes: [], moduleTypes: [] } as TrainingPathReportSelectors,
    start: '',
    stop: '',
    learnerList: [] as IdAndNameModel[],
    groupList: [] as IdAndNameModel[],
    pathStatutes: [] as TrainingPathStatus[],
    moduleStatutes: [] as AssignedTrainingModuleStatus[],
    moduleTypes: [] as TrainingModuleType[],
    parametersError: false,
    optionsError: false,
    trainingPathSelector: {
      status: true,
      duration: true,
      completion: true,
      passThreshold: true,
      grade: true,
      managerComment: true,
      learnerComment: true,
      learnerSatisfaction: true
    } as TrainingPathReportOptions,
    moduleSelector: {
      module: true,
      moduleType: true,
      status: true,
      duration: true,
      completion: true,
      referent: true,
      mediations: true,
      mediators: true,
      passThreshold: true,
      grade: true,
      referentComment: true,
      learnerComment: true,
      learnerSatisfaction: true
    } as ModuleReportOptions,
    documentSelector: { documentTitle: '', displayExternalIds: true, learnerSignature: true, managerSignature: true, managerSignatureLabel: '' } as DocumentReportOptions,
    managerSignatureImage: undefined as SignatureImage | undefined,
    alreadyPrepared: false,
    preparing: false,
    printableInfos: {} as PrintableInfos,
    displayablePathList: [] as DisplayableTrainingPathInfo[],
    additionalUsers: {} as Record<string, UserInfo>,
    displayPathList: false,
    excelWorkBook: new ExcelJS.Workbook(),
    excelRow: {} as Record<string, string | number>,
    showSaveParameters: false,
    currentParametersName: '',
    parametersNameError: '',
    configurations: [] as SavedTrainingPathReportConfiguration[],
    configurationsLoaded: false
  }),

  async created () {
    this.documentSelector.documentTitle = this.$t('defaultTitle').toString();
    this.documentSelector.managerSignatureLabel = this.$t('defaultManagerSignatureLabel').toString();
    this.configurations = await getUserTrainingPathReportConfigurations();
    const defaultConfig = this.configurations.find(c => c.isDefault);
    if (defaultConfig === undefined) {
      // due to timing, the default values are set only now
      this.start = LocalDate.now().withMonth(1).withDayOfMonth(1).format(DateTimeFormatter.ofPattern(this.$t('dateFormat.jsJodaDatePattern').toString())); // first day of current year
      this.stop = LocalDate.now().withMonth(12).withDayOfMonth(31).format(DateTimeFormatter.ofPattern(this.$t('dateFormat.jsJodaDatePattern').toString())); // last day of current year
      this.pathStatutes = [TrainingPathStatus.NotAccepted, TrainingPathStatus.Accepted, TrainingPathStatus.Started, TrainingPathStatus.Finished, TrainingPathStatus.Validated, TrainingPathStatus.Archived];
      this.moduleStatutes = [AssignedTrainingModuleStatus.NotStarted, AssignedTrainingModuleStatus.Started, AssignedTrainingModuleStatus.Finished, AssignedTrainingModuleStatus.Validated, AssignedTrainingModuleStatus.Archived];
      this.moduleTypes = [TrainingModuleType.Training];
    } else {
      this.loadConfiguration(defaultConfig);
    }
    this.configurationsLoaded = true;
  },

  methods: {
    async setConfigAsDefault (idx: number): Promise<void> {
      if (this.configurations[idx].isDefault) {
        // unselect default value
        this.configurations[idx].isDefault = false;
      } else {
        // unselect old value (if exists)
        const oldDefault = this.configurations.find((f) => f.isDefault);
        if (oldDefault) {
          oldDefault.isDefault = false;
        }
        // select new value
        this.configurations[idx].isDefault = true;
      }
      try {
        await SaveUserTrainingPathReportConfiguration(this.configurations);
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    truncated (text: string): string {
      return truncate(text, 25);
    },
    bookmarkColor (idx: number): string {
      return this.configurations[idx].isDefault ? 'accent--text' : 'secondary--text';
    },
    bookmarkIcon (idx: number): string {
      return this.configurations[idx].isDefault ? 'mdi-bookmark-check' : 'mdi-bookmark-outline';
    },
    loadConfiguration (config: SavedTrainingPathReportConfiguration): void {
      this.warningMessage = '';
      this.reportParameters = { ...config.selectors };
      this.documentSelector = { ...config.documentOptions };
      this.trainingPathSelector = { ...config.pathOptions };
      this.moduleSelector = { ...config.moduleOptions };
      this.managerSignatureImage = undefined;
      this.alreadyPrepared = false;
    },
    async deleteConfiguration (idx: number): Promise<void> {
      try {
        this.configurations.splice(idx, 1);
        await SaveUserTrainingPathReportConfiguration(this.configurations);
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    createExcelContext (): void {
      this.excelWorkBook = new ExcelJS.Workbook();
      this.excelWorkBook.addWorksheet(this.$t('excelWorksheetName').toString());
      // create column names (depending of selectors choices)
      const columns: ExcelColumnTitle[] = [];

      // --- Learners columns ----------
      this.addExcelColumnTitle(columns, 'learnerFirstName');
      this.addExcelColumnTitle(columns, 'learnerLastName');
      this.addExcelColumnTitle(columns, 'learnerFullName');
      this.addExcelColumnTitle(columns, 'learnerExternalId', this.documentSelector.displayExternalIds);

      // --- Training paths columns ----------
      this.addExcelColumnTitle(columns, 'trainingPathTitle');
      this.addExcelColumnTitle(columns, 'trainingPathReference');
      this.addExcelColumnTitle(columns, 'trainingPathStartPeriod');
      this.addExcelColumnTitle(columns, 'trainingPathStopPeriod');
      this.addExcelColumnTitle(columns, 'totalTrainingModules');
      // number of modules for each selected statutes
      for (const status of this.moduleStatutes) {
        this.addExcelColumnTitle(columns, firstLowerCase(status) + 'TrainingModules');
      }
      this.addExcelColumnTitle(columns, 'trainingPathDuration', this.trainingPathSelector.duration);
      this.addExcelColumnTitle(columns, 'trainingPathStatus', this.trainingPathSelector.status);
      this.addExcelColumnTitle(columns, 'trainingPathCompletion', this.trainingPathSelector.completion);
      this.addExcelColumnTitle(columns, 'trainingPathPassThreshold', this.trainingPathSelector.passThreshold, this.gradeScale);
      this.addExcelColumnTitle(columns, 'trainingPathObtainedGrade', this.trainingPathSelector.grade, this.gradeScale);
      this.addExcelColumnTitle(columns, 'trainingPathManagerComment', this.trainingPathSelector.managerComment);
      this.addExcelColumnTitle(columns, 'trainingPathLearnerComment', this.trainingPathSelector.learnerComment);
      this.addExcelColumnTitle(columns, 'trainingPathLearnerSatisfaction', this.trainingPathSelector.learnerSatisfaction, this.satisfactionScale);

      // --- Training modules columns ----------
      if (this.moduleSelector.module) {
        this.addExcelColumnTitle(columns, 'trainingModuleName');
        this.addExcelColumnTitle(columns, 'trainingModuleReference');
        this.addExcelColumnTitle(columns, 'trainingModuleGoals');
        this.addExcelColumnTitle(columns, 'trainingModuleStartPeriod');
        this.addExcelColumnTitle(columns, 'trainingModuleStopPeriod');
        this.addExcelColumnTitle(columns, 'trainingModuleType', this.moduleSelector.moduleType);
        this.addExcelColumnTitle(columns, 'trainingModuleDuration', this.moduleSelector.duration);
        this.addExcelColumnTitle(columns, 'trainingModuleStatus', this.moduleSelector.status);
        this.addExcelColumnTitle(columns, 'trainingModuleCompletion', this.moduleSelector.completion);
        this.addExcelColumnTitle(columns, 'trainingModulePassThreshold', this.moduleSelector.passThreshold, this.gradeScale);
        this.addExcelColumnTitle(columns, 'trainingModuleObtainedGrade', this.moduleSelector.grade, this.gradeScale);
        if (this.moduleSelector.referent) {
          this.addExcelColumnTitle(columns, 'trainingModuleReferentFirstName');
          this.addExcelColumnTitle(columns, 'trainingModuleReferentLastName');
          this.addExcelColumnTitle(columns, 'trainingModuleReferentFullName');
          this.addExcelColumnTitle(columns, 'trainingModuleReferentExternalId', this.documentSelector.displayExternalIds);
        }
        this.addExcelColumnTitle(columns, 'trainingModuleReferentComment', this.moduleSelector.referentComment);
        this.addExcelColumnTitle(columns, 'trainingModuleLearnerComment', this.moduleSelector.learnerComment);
        this.addExcelColumnTitle(columns, 'trainingModuleLearnerSatisfaction', this.moduleSelector.learnerSatisfaction, this.satisfactionScale);

        // --- Mediations columns ----------
        this.addExcelColumnTitle(columns, 'mediationTotalDuration', this.moduleSelector.mediations);
        this.addExcelColumnTitle(columns, 'mediationTotalNumber', this.moduleSelector.mediations);
        if (this.moduleSelector.mediators) {
          this.addExcelColumnTitle(columns, 'mediatorFirstName');
          this.addExcelColumnTitle(columns, 'mediatorLastName');
          this.addExcelColumnTitle(columns, 'mediatorFullName');
          this.addExcelColumnTitle(columns, 'mediatorExternalId', this.documentSelector.displayExternalIds);
        }
      }
      this.excelWorkBook.worksheets[0].columns = columns;
    },
    addExcelColumnTitle (columns: ExcelColumnTitle[], propertyName: string, condition = true, scale = 0): void {
      if (condition) {
        if (scale > 0) {
          columns.push({ header: this.$t('excelColumnTitles.' + propertyName, [scale]).toString(), key: propertyName });
        } else {
          columns.push({ header: this.$t('excelColumnTitles.' + propertyName).toString(), key: propertyName });
        }
      }
    },
    initializeExcelRow (user: UserInfoModel): void {
      this.excelRow = {
        learnerFirstName: user.firstName,
        learnerLastName: user.lastName,
        learnerFullName: user.lastName + ' ' + user.firstName
      };
      if (this.documentSelector.displayExternalIds) {
        this.excelRow.learnerExternalId = user.externalId ?? '';
      }
    },
    insertExcelRow (): void {
      this.excelWorkBook.worksheets[0].addRow(this.excelRow);
    },
    updateParameter (
      parameter: string,
      newValue: LocalDate | IdAndNameModel[] | TrainingPathStatus[] | AssignedTrainingModuleStatus[] | TrainingModuleType[] | TrainingPathReportOptions | ModuleReportOptions | DocumentReportOptions | SignatureImage | undefined
    ): void {
      this.warningMessage = '';
      switch (parameter) {
        case 'start': this.start = (newValue as LocalDate).format(DateTimeFormatter.ofPattern(this.$t('dateFormat.jsJodaDatePattern').toString())); break;
        case 'stop': this.stop = (newValue as LocalDate).format(DateTimeFormatter.ofPattern(this.$t('dateFormat.jsJodaDatePattern').toString())); break;
        case 'learnerList': this.learnerList = [...(newValue as IdAndNameModel[])]; break;
        case 'groupList': this.groupList = [...(newValue as IdAndNameModel[])]; break;
        case 'pathStatutes': this.pathStatutes = [...(newValue as TrainingPathStatus[])]; break;
        case 'moduleStatutes': this.moduleStatutes = [...(newValue as AssignedTrainingModuleStatus[])]; break;
        case 'moduleTypes': this.moduleTypes = [...(newValue as TrainingModuleType[])]; break;
        case 'path': this.trainingPathSelector = { ...newValue as TrainingPathReportOptions }; break;
        case 'module': this.moduleSelector = { ...newValue as ModuleReportOptions }; break;
        case 'document': this.documentSelector = { ...newValue as DocumentReportOptions }; break;
        case 'signatureImage': this.managerSignatureImage = newValue === undefined ? undefined : { ...newValue as SignatureImage }; break;
      }
      this.alreadyPrepared = false;
    },
    async prepare (): Promise<boolean> {
      if (this.parametersError || this.optionsError) {
        this.warningMessage = this.$t('atLeastOneErrorLeft');
        return false;
      }
      if (this.alreadyPrepared) {
        return true;
      }
      try {
        this.preparing = true;
        // prepare excel context
        this.createExcelContext();
        // prepare learner id list
        const learnerIds = deduplicateArray([
          ...(this.groupList.length > 0 ? await getUsersIdByGroups(this.groupList.map(group => group.id)) : []),
          ...this.learnerList.map(learner => learner.id)
        ]);
        // prepare query to get all training paths according to given learners, dates and statutes
        // note: the start and stop dates are already in the correct format (YYYY-MM-DD).
        let query = 'learner oneOf ' + learnerIds.join(',');
        query += ' & start_date beforeOrSameDay ' + this.stop + ' & stop_date afterOrSameDay ' + this.start;
        query += ' & status oneOf ' + this.pathStatutes.map(ps => firstLowerCase(ps)).join(',');
        // now, get list (the list may contain paths that will not be used due to statutes and types of modules)
        const trainingPathList = (await queryPaths({ query: query })).results;
        // create initial user list (sorted by name).
        const userList = await getUsersByIds(learnerIds);
        let userBaseList: UserInfo[] = Object.keys(userList).map(uId => ({ ...userList[uId], fullName: userList[uId].lastName + ' ' + userList[uId].firstName }));
        userBaseList = sortArrayByString(userBaseList, u => u.fullName);
        // prepare displayable and printable lists (and excel content)
        this.printableInfos.title = this.documentSelector.documentTitle;
        this.printableInfos.period = periodToText(LocalDate.parse(this.start), LocalDate.parse(this.stop));
        this.printableInfos.learnerSignatureLabel = this.documentSelector.learnerSignature ? this.$t('learnerSignatureLabel').toString() : '';
        this.printableInfos.managerSignatureLabel = this.documentSelector.managerSignature ? this.documentSelector.managerSignatureLabel : '';
        this.printableInfos.managerSignatureImage = (this.documentSelector.managerSignature && this.managerSignatureImage !== undefined) ? { ...this.managerSignatureImage } : undefined;
        this.printableInfos.learnerList = [];
        this.displayablePathList = [];
        // add users having paths and modules according to parameters to displayable list, printableList and Excel export
        for (const user of userBaseList) {
          const userPathList = trainingPathList.filter(p => p.learner.id === user.id);
          if (userPathList.length === 0) {
            // no path for this user -> next user
            continue;
          }
          // found at least one training path for this user -> prepare PrintableLearnerInfos, and for each path, search for modules according to request
          const currentLearner: PrintableLearnerInfos = {
            name: getUserName(user),
            externalId: this.documentSelector.displayExternalIds ? getUserExternalId(user) : '',
            pathList: []
          };
          this.initializeExcelRow(user);
          for (const path of userPathList) {
            // select module list according to given statutes an types
            const selectedModuleStatutes = this.moduleStatutes.map(ms => firstLowerCase(ms));
            const moduleTypes = this.moduleTypes.map(mt => firstLowerCase(mt));
            const moduleList = path.assignedModules.filter(am => selectedModuleStatutes.includes(firstLowerCase(am.status)) && moduleTypes.includes(firstLowerCase(am.module.moduleType)));
            if (moduleList.length > 0) {
              // found at least one assigned module to be taken in account for this training path
              this.addPathToDisplayableList(path, moduleList, user);
              await this.addPathToPrintableList(path, moduleList, currentLearner);
            }
          }
          if (currentLearner.pathList.length > 0) {
            this.printableInfos.learnerList.push(currentLearner);
          }
        }
        this.alreadyPrepared = true;
        return true;
      } catch (err) {
        eventBus.$emit('error', err, null);
        return false;
      } finally {
        this.preparing = false;
      }
    },
    async showTrainingPaths (): Promise<void> {
      if (await this.prepare()) {
        if (this.printableInfos.learnerList.length === 0) {
          this.warningMessage = this.$t('nothingToDisplay');
        } else {
          this.displayPathList = true;
        }
      }
    },
    async generateExcelExport (): Promise<void> {
      if (await this.prepare()) {
        if (this.printableInfos.learnerList.length === 0) {
          this.warningMessage = this.$t('nothingToExport');
        } else {
          // generate file
          const data = await this.excelWorkBook.xlsx.writeBuffer();
          const excelBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // make and click a temporary link to download the Blob
          const link = document.createElement('a');
          link.href = URL.createObjectURL(excelBlob);
          link.download = slugify(this.$t('excelFileName').toString()) + '.xlsx';
          link.click();
          link.remove();
        }
      }
    },
    async generatePdfReport (): Promise<void> {
      if (await this.prepare()) {
        if (this.printableInfos.learnerList.length === 0) {
          this.warningMessage = this.$t('nothingToPrint');
        } else {
          await filesToZipDownload(this.printableInfos);
        }
      }
    },
    addPathToDisplayableList (path: TrainingPathModel, modules: AssignedTrainingModuleModel[], user: UserInfo): void {
      this.displayablePathList.push({
        id: path.id,
        title: path.title,
        reference: path.reference?.code ?? '',
        duration: durationToText(path.totalEstimatedDuration),
        moduleCounters: modules.length.toString(),
        learner: user.fullName,
        status: path.status.toString()
      });
    },
    async addPathToPrintableList (path: TrainingPathModel, modules: AssignedTrainingModuleModel[], learner: PrintableLearnerInfos): Promise<void> {
      // create mandatory infos
      const printablePath: PrintableTrainingPathInfos = {
        title: path.title,
        reference: path.reference ? path.reference.code : '',
        period: periodToText(LocalDate.parse(path.startDate.format('YYYY-MM-DD')), LocalDate.parse(path.stopDate.format('YYYY-MM-DD'))),
        moduleList: []
      };
      // mandatory infos for excel
      this.excelRow.trainingPathTitle = path.title;
      this.excelRow.trainingPathReference = path.reference?.code ?? '';
      this.excelRow.trainingPathStartPeriod = LocalDate.parse(path.startDate.format('YYYY-MM-DD')).format(DateTimeFormatter.ofPattern(this.$t('jodaPattern').toString()));
      this.excelRow.trainingPathStopPeriod = LocalDate.parse(path.stopDate.format('YYYY-MM-DD')).format(DateTimeFormatter.ofPattern(this.$t('jodaPattern').toString()));
      this.excelRow.totalTrainingModules = path.assignedModules.length;
      for (const status of this.moduleStatutes) {
        this.excelRow[firstLowerCase(status) + 'TrainingModules'] = path.assignedModules.filter(am => am.status === firstLowerCase(status)).length;
      }
      // add optional infos
      if (this.trainingPathSelector.duration) {
        printablePath.duration = durationToText(path.totalEstimatedDuration);
        this.excelRow.trainingPathDuration = path.totalEstimatedDuration;
      }
      if (this.trainingPathSelector.status) {
        printablePath.status = this.$t('trainingPathStatus.' + path.status).toString();
        this.excelRow.trainingPathStatus = printablePath.status;
      }
      if (this.trainingPathSelector.completion) {
        if (path.totalSubModuleItems === 0) { // would never occurs
          printablePath.completion = '-';
          this.excelRow.trainingPathCompletion = '';
        } else {
          const percent = Math.round(path.doneSubModuleItems / path.totalSubModuleItems * 100);
          printablePath.completion = percent + ' %';
          this.excelRow.trainingPathCompletion = percent;
        }
      }
      if (this.trainingPathSelector.passThreshold) {
        printablePath.passThreshold = path.passThreshold + '/' + this.gradeScale;
        this.excelRow.trainingPathPassThreshold = path.passThreshold;
      }
      if (this.trainingPathSelector.grade) {
        const averageGrade = this.getAverageGrade(path, modules);
        printablePath.grade = averageGrade === '-' ? '-' : averageGrade + '/' + this.gradeScale;
        this.excelRow.trainingPathObtainedGrade = averageGrade === '-' ? '' : averageGrade;
      }
      if (this.trainingPathSelector.managerComment) {
        printablePath.managerComment = path.validation?.comment ?? '';
        this.excelRow.trainingPathManagerComment = path.validation?.comment ?? '';
      }
      if (this.trainingPathSelector.learnerComment) {
        printablePath.learnerComment = path.signature?.comment ?? '';
        this.excelRow.trainingPathLearnerComment = path.signature?.comment ?? '';
      }
      if (this.trainingPathSelector.learnerSatisfaction) {
        printablePath.learnerSatisfaction = path.signature ? path.signature.satisfaction + '/' + this.satisfactionScale : '';
        this.excelRow.trainingPathLearnerSatisfaction = path.signature ? path.signature.satisfaction : '';
      }
      if (this.moduleSelector.module) {
        for (const module of modules) {
          await this.addModuleToPrintableList(module, printablePath);
        }
      } else {
        this.insertExcelRow();
      }
      learner.pathList.push(printablePath);
    },
    async addModuleToPrintableList (module: AssignedTrainingModuleModel, printablePath: PrintableTrainingPathInfos): Promise<void> {
      // create mandatory infos
      const printableModule: PrintableModuleInfos = {
        name: module.module.name,
        reference: module.module.reference?.code ?? '',
        period: periodToText(LocalDate.parse(module.startDate.format('YYYY-MM-DD')), LocalDate.parse(module.stopDate.format('YYYY-MM-DD'))),
        goals: module.module.goals,
        mediators: []
      };
      // mandatory infos for excel
      this.excelRow.trainingModuleName = module.module.name;
      this.excelRow.trainingModuleReference = module.module.reference?.code ?? '';
      this.excelRow.trainingModuleGoals = module.module.goals;
      this.excelRow.trainingModuleStartPeriod = LocalDate.parse(module.startDate.format('YYYY-MM-DD')).format(DateTimeFormatter.ofPattern(this.$t('jodaPattern').toString()));
      this.excelRow.trainingModuleStopPeriod = LocalDate.parse(module.stopDate.format('YYYY-MM-DD')).format(DateTimeFormatter.ofPattern(this.$t('jodaPattern').toString()));
      // add optional infos
      if (this.moduleSelector.moduleType) {
        printableModule.moduleType = this.$t('trainingModuleType.' + module.module.moduleType).toString();
        this.excelRow.trainingModuleType = printableModule.moduleType;
      }
      if (this.moduleSelector.duration) {
        printableModule.duration = durationToText(module.module.estimatedDuration);
        this.excelRow.trainingModuleDuration = module.module.estimatedDuration;
      }
      if (this.moduleSelector.status) {
        printableModule.status = this.$t('trainingModuleStatus.' + module.status).toString();
        this.excelRow.trainingModuleStatus = printableModule.status;
      }
      if (this.moduleSelector.completion) {
        const completion = this.getModuleCompletion(module.results);
        printableModule.completion = completion === -1 ? '-' : completion + ' %';
        this.excelRow.trainingModuleCompletion = completion === -1 ? '' : completion;
      }
      if (this.moduleSelector.passThreshold) {
        printableModule.passThreshold = module.module.passThreshold + '/' + this.gradeScale;
        this.excelRow.trainingModulePassThreshold = module.module.passThreshold;
      }
      if (this.moduleSelector.grade) {
        printableModule.grade = module.grading ? module.grading.grade + '/' + this.gradeScale : '-';
        this.excelRow.trainingModuleObtainedGrade = module.grading ? module.grading.grade : '';
      }
      if (this.moduleSelector.referent) {
        if (module.grading) {
          const searchedId = module.grading.referent.id;
          const referent = await this.getAdditionalUser(searchedId);
          printableModule.referent = getUserName(referent, this.documentSelector.displayExternalIds);
          this.excelRow.trainingModuleReferentFirstName = referent.firstName;
          this.excelRow.trainingModuleReferentLastName = referent.lastName;
          this.excelRow.trainingModuleReferentFullName = referent.fullName;
          if (this.documentSelector.displayExternalIds) {
            this.excelRow.trainingModuleReferentExternalId = referent.externalId ?? '';
          }
        } else {
          printableModule.referent = '';
          this.excelRow.trainingModuleReferentFirstName = '';
          this.excelRow.trainingModuleReferentLastName = '';
          this.excelRow.trainingModuleReferentFullName = '';
          if (this.documentSelector.displayExternalIds) {
            this.excelRow.trainingModuleReferentExternalId = '';
          }
        }
      }
      if (this.moduleSelector.referentComment) {
        printableModule.referentComment = module.grading?.comment ?? '';
        this.excelRow.trainingModuleReferentComment = module.grading?.comment ?? '';
      }
      if (this.moduleSelector.learnerComment) {
        printableModule.learnerComment = module.signature?.comment ?? '';
        this.excelRow.trainingModuleLearnerComment = module.signature?.comment ?? '';
      }
      if (this.moduleSelector.learnerSatisfaction) {
        printableModule.learnerSatisfaction = module.signature ? module.signature.satisfaction + '/' + this.satisfactionScale : '';
        this.excelRow.trainingModuleLearnerSatisfaction = module.signature ? module.signature.satisfaction : '';
      }
      // add mediation infos if asked
      if (this.moduleSelector.mediations) {
        const mediationIds = module.results.filter(r => r.type === TrainingModuleItemType.Mediation).flatMap(r => (r as MediationTrainingModuleItemResult).mediationIds);
        let totalMediationTime = 0;
        const mediatorIds = new Set<string>();
        for (const mediationId of mediationIds) {
          try {
            const mediation = await getMediationById(mediationId);
            totalMediationTime += getMediationDuration(mediation.trackingPeriods);
            mediatorIds.add(mediation.mediator.id);
          } catch (err) {
            console.error('Mediation ' + mediationId, err);
            // If the user does not have the right to view the mediation, the mediation is not counted, nothing else
          }
        }
        printableModule.mediationsNumber = mediationIds.length.toString();
        printableModule.globalMediationDuration = durationToText(totalMediationTime);
        this.excelRow.mediationTotalNumber = mediationIds.length;
        this.excelRow.mediationTotalDuration = totalMediationTime;
        // add mediators, if asked
        if (this.moduleSelector.mediators) {
          if (mediatorIds.size > 0) {
            for (const mediatorId of mediatorIds) {
              const mediator = await this.getAdditionalUser(mediatorId);
              printableModule.mediators.push(getUserName(mediator, this.documentSelector.displayExternalIds));
              this.excelRow.mediatorFirstName = mediator.firstName;
              this.excelRow.mediatorLastName = mediator.lastName;
              this.excelRow.mediatorFullName = mediator.fullName;
              if (this.documentSelector.displayExternalIds) {
                this.excelRow.mediatorExternalId = mediator.externalId ?? '';
              }
              this.insertExcelRow();
            }
          } else {
            this.excelRow.mediatorFirstName = '';
            this.excelRow.mediatorLastName = '';
            this.excelRow.mediatorFullName = '';
            if (this.documentSelector.displayExternalIds) {
              this.excelRow.mediatorExternalId = '';
            }
            this.insertExcelRow();
          }
        } else {
          this.insertExcelRow();
        }
      } else {
        this.insertExcelRow();
      }
      // add module to list
      printablePath.moduleList.push(printableModule);
    },
    getAverageGrade (path: TrainingPathModel, modules: AssignedTrainingModuleModel[]): string | number {
      if (path.validation === undefined || path.totalAssignedModules !== modules.length) {
        // average grade can't be computed
        return '-';
      }
      let sum = 0;
      for (const module of modules) {
        if (module.grading !== undefined) {
          sum += module.grading.grade;
        }
      }
      const average = sum / modules.length;
      return (Math.round(average * 10) / 10); //  *10 /10 : to have just one decimal
    },
    async getAdditionalUser (userId: string): Promise<UserInfo> {
      let searchedUser = this.additionalUsers[userId];
      if (searchedUser === undefined) {
        // this user is not yet in list --> add it
        try {
          const newUser = (await getUsersByIds([userId]))[userId];
          searchedUser = { ...newUser, fullName: newUser.lastName + ' ' + newUser.firstName } as UserInfo;
        } catch {
          // user don't (yet?) exists. Don't know why, but replace info by 'X', nothing else (will be useful for printing)
          searchedUser = { id: '', login: '', email: '', firstName: 'X', lastName: 'X', fullName: 'X', groups: [], isActive: false } as UserInfo;
        }
        this.additionalUsers[userId] = searchedUser;
      }
      return searchedUser;
    },
    getModuleCompletion (elements: TrainingModuleItemResult[]): number {
      let totalItems = 0;
      let completedItems = 0;
      for (const item of elements) {
        totalItems += item.totalSubModuleItems;
        completedItems += item.doneSubModuleItems;
      }
      if (totalItems === 0) { // would never occur, but to avoid "divide by 0"
        return -1;
      }
      return Math.round(completedItems / totalItems * 100);
    },
    showSavePanel (): void {
      if (this.parametersError || this.optionsError) {
        this.warningMessage = this.$t('atLeastOneErrorLeft');
        return;
      }
      this.currentParametersName = '';
      this.showSaveParameters = true;
      this.parametersNameValidator();
    },
    parametersNameValidator (): void {
      this.parametersNameError = '';
      if (this.currentParametersName !== this.currentParametersName.trim()) {
        // whitespace(s) detected at the beginning or end of text
        this.parametersNameError = this.$t('beginEndWhitespaceError').toString();
      } else if (this.currentParametersName.length === 0) {
        // name must be set
        this.parametersNameError = this.$t('emptyFieldError').toString();
      }
    },
    async onSaveParametersClicked (): Promise<void> {
      if (this.parametersNameError !== '') {
        return;
      }
      try {
        // generate config to save
        const newConfig: SavedTrainingPathReportConfiguration = {
          name: this.currentParametersName,
          isDefault: false,
          selectors: {
            start: this.start,
            stop: this.stop,
            learnerIdList: this.learnerList.map(l => l.id),
            groupIdList: this.groupList.map(g => g.id),
            pathStatutes: [...this.pathStatutes],
            moduleStatutes: [...this.moduleStatutes],
            moduleTypes: [...this.moduleTypes]
          },
          documentOptions: { ...this.documentSelector },
          pathOptions: { ...this.trainingPathSelector },
          moduleOptions: { ...this.moduleSelector }
        };
        // search for an existing config with same name
        const existIndex = this.configurations.findIndex(c => c.name === newConfig.name);
        if (existIndex > -1) {
          // the given config name already exists -> replace config, but preserve default config status
          this.configurations[existIndex] = { ...newConfig, isDefault: this.configurations[existIndex].isDefault };
        } else {
          this.configurations.push(newConfig);
          this.configurations = sortArrayByString(this.configurations, c => c.name);
        }
        await SaveUserTrainingPathReportConfiguration(this.configurations);
        this.showSaveParameters = false;
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    }
  },

  watch: {
    reportParameters: {
      // immediate: true,
      deep: true,
      handler: async function (): Promise<void> {
        this.start = this.reportParameters.start;
        this.stop = this.reportParameters.stop;
        // users
        this.learnerList = [];
        const userNames = await resolveUserNames(this.reportParameters.learnerIdList);
        Object.keys(userNames).map(id => (this.learnerList.push({ id: id, name: userNames[id] })));
        // groups
        this.groupList = [];
        const groupNames = await resolveGroupNames(this.reportParameters.groupIdList);
        Object.keys(groupNames).map(id => (this.groupList.push({ id: id, name: groupNames[id] })));
        // path statutes
        this.pathStatutes = [...this.reportParameters.pathStatutes];
        // module statutes
        this.moduleStatutes = [...this.reportParameters.moduleStatutes];
        // module types
        this.moduleTypes = [...this.reportParameters.moduleTypes];
      }
    }
  }
});

