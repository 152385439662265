import { FieldType, FrontendAndQueryFieldConfiguration } from '../queryEngine';
import i18n from '../i18n';
import { ContextItemType, FunctionModel } from '../../_GeneratedClients/SpotClient';
import { autocompleteCartography, resolveSpotItemNames, SpotItem } from '../repository';

export default {
  name: {
    type: FieldType.string,
    displayName: i18n.t('function.filter.content.name').toString(),
    getter: f => [f.item.name],
    sortable: true
  },
  location: {
    type: FieldType.id,
    displayName: i18n.t('function.filter.parent.location').toString(),
    getter: c => [...c.contexts.flatMap(ctx => ctx.hierarchy.filter(h => h.type === ContextItemType.Cartography).map(c => c.id))],
    autocomplete: autocompleteCartography,
    resolveNames: resolveSpotItemNames
  },
  cartography: {
    type: FieldType.string,
    displayName: i18n.t('function.filter.parent.cartography').toString(),
    getter: c => [...c.contexts.flatMap(ctx => ctx.hierarchy.filter(h => h.type === ContextItemType.Cartography).map(c => c.name))],
    sortable: true,
    filterable: false
  }
} as Record<string, FrontendAndQueryFieldConfiguration<SpotItem<FunctionModel>>>;
