import { FieldConfiguration } from './fieldConfiguration';

/**
 * The criterion used to determine the order of the results
 */
export interface SortingCriterion {
  /**
   * The property on which to sort
   */
  property: string,

  /**
   * Sort in ascending order, default true
   */
  ascending?: boolean
}

/**
 * Serializes sorting criteria as a string like `title,-date`.
 *
 * @param sorting The sorting criteria to serialize
 * @returns The serialized string
 */
export function serializeSorting (sorting: SortingCriterion[]): string {
  return sorting.map(s => (s.ascending === false) ? '-' + s.property : s.property).join(',');
}

/**
 * Parses the sorting criteria from a string serialized with serializeSorting
 *
 * @param fieldsConfiguration The fields configuration
 * @param sorting The string that contains the serialized sorting configuration
 * @returns The parsed sorting criteria
 */
export function parseSorting (fieldsConfiguration: Record<string, FieldConfiguration>, sorting: string): SortingCriterion[] {
  const criteria = sorting.split(',');
  const results: SortingCriterion[] = [];
  for (const c of criteria) {
    if (c === '') {
      continue;
    }

    const parsed = {
      property: c,
      ascending: true
    };

    if (c[0] === '-') {
      parsed.ascending = false;
      parsed.property = c.substring(1);
    }

    const fieldConfig = fieldsConfiguration[parsed.property];
    if (!fieldConfig) {
      throw new SortingParsingError(`field ${parsed.property} not found in field configuration`);
    } else if (fieldConfig.sortable !== true) {
      throw new SortingParsingError(`field ${parsed.property} is not sortable`);
    }

    results.push(parsed);
  }
  return results;
}

/**
 * An error that occurs when the given query is invalid
 */
export class SortingParsingError extends Error {
}
