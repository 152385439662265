

















import Vue, { PropType } from 'vue';
import { ContextModel } from '../../../_GeneratedClients/SpotClient';
import { ContextViewModel, getContextViewModel, getSpotItemV1Link, ISearchResponseCategory, ISpotItem } from '../../repository';
import { sanitizeHTML } from '../../Tools';
import DisplayContext from '../common/DisplayContext.vue';
import { Location } from 'vue-router';

export default Vue.extend({

  name: 'SearchList',

  components: {
    DisplayContext
  },

  props: {
    itemList: { type: Object as PropType<ISearchResponseCategory>, default: () => ({ total: 0, items: [] }) },
    level: { type: String, required: true },
    search: { type: String, required: true }
  },

  methods: {
    routeTo (item: ISpotItem): Location {
      if (this.level === 'subject') {
        // goto subject page inside v2
        return { name: 'Subject', params: { subjectId: item.item.id } };
      } else if (this.level === 'function') {
        // goto function page inside v2
        return { name: 'ViewFunction', params: { functionId: item.item.id } };
      } else if (this.level === 'cartography') {
        // goto cartography page inside v2
        return { name: 'Cartography', params: { cartographyId: item.item.id } };
      } else {
        // other levels : go to external link to v1
        return { name: 'V1Redirection', query: { to: getSpotItemV1Link(item) } };
      }
    },
    closeAsked () {
      this.$emit('close');
    },
    getContextViewModel (contexts: ContextModel[]): ContextViewModel[] {
      return getContextViewModel(contexts);
    },
    formatSearchResult (name: string) {
      const searched = this.search?.toUpperCase() ?? '';
      // on insère le code html pour mettre les occurences en surbrillance
      const searchLength = searched.length;
      // pour chaque titre
      const title = sanitizeHTML(name);
      const refText = title.toUpperCase();
      let last = 0;
      let next = 0;
      let result = '';
      do {
        next = refText.indexOf(searched, last);
        if (next > -1) { // si on a trouvé la prochaine occurence
          result += title.substring(last, next) + '<span class="warning--text font-weight-bold">';
          last = next + searchLength;
          result += title.substring(next, last) + '</span>';
        }
      } while (next !== -1);
      return result + title.substring(last); // on remplace le titre (en ajoutant l'éventuelle fin du texte)
    }
  }
});

