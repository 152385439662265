

















import Vue from 'vue';

export default Vue.extend({

  name: 'RadioStep',

  props: {
    label: { type: String, required: true },
    checked: { type: Boolean, required: true }
  }
});
