import moment from 'moment';
import { ContextItemType, CreateOrUpdateSubjectRequest, SubjectModel, SubjectRatings } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';
import { reloadSubject } from './Search';
import { setEntityLastVisit } from './Visits';

export type FilterableSubjectModel = SubjectModel & { ancestors: Set<string> };

/**
 * Creates an empty SubjectModel
 *
 * @returns The created subjectModel
 */
export function getEmptySubject (): SubjectModel {
  return {
    id: '',
    code: '',
    name: '',
    type: ContextItemType.Subject,
    contexts: [],
    lifetimeEvents: {},
    ratings: { frequency: 0, gravity: 0, detectability: 0 },
    mindMapItems: [],
    hasDiagram: false,
    discussionId: '',
    contributors: []
  };
}

/**
 * Creates a new subject
 *
 * @param subjectInfo the subject info needed to create it
 * @returns the identifier of created subject
 */
export async function createSubject (subjectInfo: CreateOrUpdateSubjectRequest): Promise<string> {
  return (await client.createSubject(subjectInfo)).id;
}

/**
 * Duplicates a subject
 *
 * @param subjectId the identifier of original subject to duplicate
 * @param subjectInfo the subject info needed to create it
 * @returns the identifier of created subject
 */
export async function duplicateSubject (subjectId: string, subjectInfo: CreateOrUpdateSubjectRequest): Promise<string> {
  return (await client.duplicateSubject(subjectId, subjectInfo)).id;
}

/**
 * deletes a subject in database
 *
 * @param subjectId the identifier of the subject to delete
 */
export async function deleteSubject (subjectId: string): Promise<void> {
  await client.deleteSubject(subjectId);
}

/**
 * Updates a subject
 *
 * @param subjectId the identifier of the subject to update
 * @param values the subject new information
 * @returns the identifier of created subject
 */
export async function updateSubject (subjectId: string, values: CreateOrUpdateSubjectRequest): Promise<void> {
  await client.updateSubject(subjectId, values);
}

/**
 * Marks this subject as followed by the current user
 *
 * @param subjectId The subject to follow
 * @returns The promise
 */
export async function followSubject (subjectId: string): Promise<void> {
  try {
    await client.followSubject(subjectId);
    const now = moment();
    await reloadSubject(subjectId);
    setEntityLastVisit(subjectId, now);
  } catch (err) {
    console.error(err);
  }
}

/**
 * Marks this subject as resolved by the current user
 *
 * @param subjectId The subject to resolve
 * @returns The promise
 */
export async function resolveSubject (subjectId: string): Promise<void> {
  try {
    await client.resolveSubject(subjectId);
    const now = moment();
    await reloadSubject(subjectId);
    setEntityLastVisit(subjectId, now);
  } catch (err) {
    console.error(err);
  }
}

/**
 * Evaluates the criticity of the subject
 *
 * @param subjectId The subject to evaluate
 * @param body The new subject rating
 * @returns The promise
 */
export async function rateSubject (subjectId: string, body: SubjectRatings): Promise<void> {
  try {
    await client.rateSubject(subjectId, body);
    const now = moment();
    await reloadSubject(subjectId);
    setEntityLastVisit(subjectId, now);
  } catch (err) {
    console.error(err);
  }
}

/**
 * Marks this subject as standardized by the current user
 *
 * @param subjectId The subject to standardize
 * @returns The promise
 */
export async function standardizeSubject (subjectId: string): Promise<void> {
  try {
    await client.standardizeSubject(subjectId, { comment: '' });
    const now = moment();
    await reloadSubject(subjectId);
    setEntityLastVisit(subjectId, now);
  } catch (err) {
    console.error(err);
  }
}
