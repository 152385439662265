import { client } from './Client';
import { dataPreload, dataPreloadUnload } from './Search';
import { IdentityProviderModel, LoginModel, UserModel } from '../../_GeneratedClients/SpotClient';
import { filterPreloadUnload, filtersPreload } from './Filter';
import { visitsCachePreloadUnload, visitsCachePreload } from './Visits';
let connectedUser: UserModel | null = null;

type ConnectedUserCallback = (loggedUser: UserModel | null) => void;
type SpotBroadcastEvent = { type: 'login' }
let connectedUserCallback: ConnectedUserCallback = () => { /* does nothing */ };
let tokenIssuanceDate = 0;

const minAgeBeforeTokenRefresh = 3600000;// refresh at most once per hour

// The communication channel between tabs that allow to pass login/logout messages
const broadcastChannel = new BroadcastChannel('spot');

broadcastChannel.addEventListener('message', (ev : MessageEvent<SpotBroadcastEvent>) => {
  switch (ev.data.type) {
    case 'login': window.location.reload(); break;// For now, only reload the page to handle the login
  }
});

/**
 * A function that returns the requested cookie
 *
 * @param searchedKey The key to look for
 * @returns The value of the requested cookie, undefined if not found
 */
export function getCookie (searchedKey: string): string | undefined {
  const cookies = decodeURIComponent(document.cookie).split(';');
  const foundCookie = cookies.map(c => c.trim()).find(c => c.startsWith(searchedKey + '='));
  if (foundCookie !== undefined) {
    // found !
    return foundCookie.split('=')[1];
  }
}

/**
 * Attempts to log the user with the cookie
 *
 * @returns A boolean indicating whether the login succeeded
 */
export async function loginFromCookie (): Promise<boolean> {
  if (connectedUser) {
    try {
      await refreshTokenIfNeeded(); // At each page navigation, renew the token if needed. An idle user won't have its token renewed
    } catch (err) {
      console.log('Failed to refresh the token, logging out', err);
      document.cookie = 'userToken=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
      onLogout();
      return false;
    }
    return true;
  }
  clearPreloadData();
  const userToken = getCookie('userToken');
  if (!userToken) { // either not found or empty
    return false;
  }

  onReceivedToken(userToken);

  // got a token, try to get user name
  try {
    connectedUser = await client.whoAmI();
    await refreshTokenIfNeeded();
  } catch (err) {
    console.error('Failed to get WhoAmI or to refresh the token', err);
    document.cookie = 'userToken=;path=/;expires=Thu, 01 Jan 1970 00:00:00 UTC';
    tokenIssuanceDate = 0;
    return false;
  }
  preloadData();// Trigger the dataPreload early after login
  connectedUserCallback(connectedUser);
  return true;
}

/**
 * Gets the list of the available identity providers
 *
 * @param redirectUrl The URL to redirect to after a successful login
 * @returns The list of all identity providers
 */
export function getIdentityProviders (redirectUrl: string): Promise<IdentityProviderModel[]> {
  return client.getIdentityProviders(redirectUrl);
}

/**
 * Registers a single event handle to handle login/logout events
 *
 * @param callback The callback called on every login/logout
 */
export function registerLoginEvent (callback: ConnectedUserCallback): void {
  connectedUserCallback = callback;
  callback(connectedUser);
}

/**
 * Logs the user in
 *
 * @param login The login parameters
 * @throws if the login failed
 * @returns the URL where to redirect the user to. If it starts with /spotv2, this is to be considered a local URL and stay within the vue-router context
 */
export async function login (login: LoginModel): Promise<string> {
  // first, get user token
  const result = await client.login(login);
  broadcastChannel.postMessage({
    type: 'login'
  });

  const redirectUrl = result.redirectUrl;
  if (redirectUrl.startsWith('/spotv2')) {
    // We will be staying local, so we can start loading stuff
    onReceivedToken(result.token);
    // Get user name (and cohorts)
    connectedUser = await client.whoAmI();

    preloadData();// Trigger the dataPreload early after login
    connectedUserCallback(connectedUser);
  }

  return redirectUrl;
}

/**
 * When the token was received, save it into a cookie
 *
 * @param token The token that was received from the server
 */
function onReceivedToken (token: string) {
  // Inspect the content of the token to get the expiration date
  const jwtPayload = JSON.parse(atob(token.split('.')[1]));
  tokenIssuanceDate = jwtPayload.iat * 1000;
}

/**
 * Requests for a new token from the server
 */
async function refreshTokenIfNeeded () {
  if (+new Date() > tokenIssuanceDate + minAgeBeforeTokenRefresh) {
    const result = await client.renewToken();
    onReceivedToken(result.token);
  }
}

/**
 * Logs the current user out
 */
export async function logout (): Promise<void> {
  let redirectUrl: string | undefined;
  try {
    redirectUrl = (await client.logout()).redirectUrl;
  } catch (err) {
    console.error(err);
  }
  broadcastChannel.postMessage({
    type: 'login'
  });

  if (redirectUrl) {
    window.location.href = redirectUrl;
    return;
  }
  onLogout();
}

/**
 * Cleans up the data after a logout on the server side
 */
function onLogout () {
  connectedUser = null;
  clearPreloadData();
  connectedUserCallback(null);
}

/**
 * Returns the currently connected user
 *
 * @returns the currently connected user, or `null` if the user is not logged in
 */
export function getConnectedUser (): UserModel | null {
  return connectedUser;
}

/**
 * indicates if connected user has or not the requested permission
 *
 * @param permissionRequired the requested permission
 * @returns true if permission is granted
 */
export async function isPermissionGranted (permissionRequired: string): Promise<boolean> {
  if (connectedUser) {
    return connectedUser.permissions.includes(permissionRequired);
  }
  return false; // would never occur
}

/**
 * Preloads cached data in the browser memory (these are long requests that slows down the application)
 */
function preloadData () {
  dataPreload();
  visitsCachePreload();
  filtersPreload();
}

/**
 * Clears the data that were preloaded with preloadData
 */
function clearPreloadData () {
  dataPreloadUnload();
  visitsCachePreloadUnload();
  filterPreloadUnload();
}
