














































































































import { RectSize, SignatureImage } from '../../repository';
import Vue, { PropType } from 'vue';
import { LocaleMessage } from 'vue-i18n';
import { DocumentReportOptions, ModuleReportOptions, TrainingPathReportOptions } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'TrainingPathReportOptionsSelector',

  props: {
    documentOptions: { type: Object as PropType<DocumentReportOptions>, required: true },
    managerSignatureImage: { type: Object as PropType<SignatureImage | undefined>, default: undefined },
    trainingPathOptions: { type: Object as PropType<TrainingPathReportOptions>, required: true },
    moduleOptions: { type: Object as PropType<ModuleReportOptions>, required: true }
  },

  data: () => ({
    documentTitle: '',
    documentTitleError: false,
    displayExternalIds: true,
    learnerSignature: true,
    managerSignature: true,
    managerSignatureLabel: '',
    managerSignatureLabelError: false,
    managerSignatureFile: null as File | null,
    uploadError: '' as string | LocaleMessage,
    reader: new FileReader(),
    signatureAreaMaxSize: { width: 150, height: 60 } as RectSize, // must be greather than { 0, 0 }
    pathSelector: {} as TrainingPathReportOptions,
    moduleSelector: {} as ModuleReportOptions
  }),

  created () {
    this.initDocument();
    this.initPath();
    this.initModule();
    this.reader.onload = (e) => {
      // get real image size
      const bufferImage = new Image();
      bufferImage.src = e.target?.result as string ?? '';
      bufferImage.onload = () => {
        const signatureImage = {
          size: { width: bufferImage.width, height: bufferImage.height },
          url: this.reader.result as string
        };
        this.$emit('update', 'signatureImage', signatureImage);
      };
      bufferImage.onerror = () => {
        // something went wrong in loading the file
        this.removeSignature();
        this.uploadError = this.$t('fileUploadError');
      };
    };
    this.$emit('error', this.formError);
  },

  methods: {
    initDocument (): void {
      this.documentTitle = this.documentOptions.documentTitle;
      this.displayExternalIds = this.documentOptions.displayExternalIds;
      this.learnerSignature = this.documentOptions.learnerSignature;
      this.managerSignature = this.documentOptions.managerSignature;
      this.managerSignatureLabel = this.managerSignature ? this.documentOptions.managerSignatureLabel : '';
    },
    initPath (): void {
      this.pathSelector = { ...this.trainingPathOptions };
    },
    initModule (): void {
      this.moduleSelector = { ...this.moduleOptions };
    },
    updateDocument (): void {
      const newDocument = {
        documentTitle: this.documentTitle,
        displayExternalIds: this.displayExternalIds,
        learnerSignature: this.learnerSignature,
        managerSignature: this.managerSignature,
        managerSignatureLabel: this.managerSignatureLabel
      } as DocumentReportOptions;
      this.$emit('update', 'document', newDocument);
      this.$emit('focus');
    },
    upateManagerSignature (): void {
      if (!this.documentOptions.managerSignature) {
        this.managerSignatureFile = null;
        this.removeSignature();
      }
      this.updateManagerSignatureLabelError();
      this.updateDocument();
    },
    removeSignature (): void {
      this.$emit('update', 'signatureImage', undefined);
    },
    updatePath (): void {
      this.$emit('update', 'path', this.pathSelector);
      this.$emit('focus');
    },
    updateModule (): void {
      this.$emit('update', 'module', this.moduleSelector);
      this.$emit('focus');
    },
    updateMediatorSelector (): void {
      this.moduleSelector.mediators = this.moduleSelector.mediations ? this.moduleSelector.mediators : false;
      this.updateModule();
    },
    documentTitleValidator (): string | true {
      if (this.documentTitle === undefined) {
        return true;
      }
      if (this.documentTitle !== this.documentTitle.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.documentTitle.length === 0) { // title can't be empty
        return this.$t('emptyFieldError').toString();
      }
      return true;
    },
    managerSignatureLabelValidator (): string | true {
      if (!this.managerSignature) {
        return true;
      }
      if (this.managerSignatureLabel !== this.managerSignatureLabel.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.managerSignatureLabel.length === 0) { // label can't be empty
        return this.$t('emptyFieldError').toString();
      }
      return true;
    },
    updateManagerSignatureLabelError (): void {
      if (!this.managerSignature) {
        this.managerSignatureLabelError = false;
      } else {
        if (this.managerSignatureLabel !== this.managerSignatureLabel.trim() || this.managerSignatureLabel.length === 0) {
          this.managerSignatureLabelError = true;
          // ********************************************************************************
          // TODO : trouver comment déclencher la validation sur clic "signature responsable"
          // ********************************************************************************
        }
      }
    },
    upload (file: File): void {
      this.uploadError = '';
      this.removeSignature();
      if (!file) {
        return;
      }
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        this.uploadError = this.$t('badFileFormat');
        return;
      }
      this.reader.readAsDataURL(file);
    }
  },

  computed: {
    formError: function (): boolean { return this.documentTitleError || this.managerSignatureLabelError || this.uploadError !== ''; }
  },

  watch: {
    formError (): void { this.$emit('error', this.formError); },
    documentOptions: {
      immediate: true,
      deep: true,
      handler: function (): void {
        this.initDocument();
      }
    },
    managerSignatureImage: {
      immediate: true,
      handler: function (): void {
        if (this.managerSignatureImage === undefined) {
          this.managerSignatureFile = null;
        }
      }
    },
    trainingPathOptions: { immediate: true, handler: function (): void { this.initPath(); } },
    moduleOptions: { immediate: true, handler: function (): void { this.initModule(); } }
  }
});

