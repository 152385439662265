






















import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import Vue from 'vue';
import { IdAndNameModel, UserQualificationModel } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';
import { filterQualificationDashboard, getCartographySiteNames, getInitialQualificationLegend, getUserQualificationState, isPermissionGranted, QualificationDashboardGroup, QualificationDashboardSecondaryInfo, queryQualifications, queryUserQualifications, resolveUserNames } from '../repository';
import DisplayQualificationElement from '../components/qualifications/DisplayQualificationElement.vue';
import { sortArrayByNumber } from '../Tools';
import DisplayQualificationLegend from '../components/qualifications/DisplayQualificationLegend.vue';

export default Vue.extend({

  name: 'CartographyQualificationDashboard',

  components: {
    DisplayQualificationElement,
    DisplayQualificationLegend,
    SimplePageLayout
  },
  data: () => ({
    cartography: { id: '', name: '' } as IdAndNameModel,
    legendSelector: getInitialQualificationLegend(),
    loading: true,
    qualifications: [] as QualificationDashboardGroup[]
  }),

  async mounted () {
    if (!(await isPermissionGranted('perm_TableauBord'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
      return;
    }
    // get cartographyId
    const cartographyId = this.$route.params.cartographyId;
    if (cartographyId === '' || cartographyId === undefined || cartographyId === null) {
      this.$router.push('/NotFound');
      return;
    }
    try {
      this.cartography.id = cartographyId;
      // get cartography-site name
      const cartographySiteName = (await getCartographySiteNames([cartographyId]))[cartographyId];
      if (!cartographySiteName) {
        this.$router.push('/NotFound');
        return;
      }
      this.cartography.name = cartographySiteName;
      // get relevant qualifications
      let query = 'cartographies oneOf ' + cartographyId;
      const list = (await queryQualifications({ query: query })).results;
      if (list.length === 0) {
        this.loading = false;
        return;
      }
      query = 'qualification oneOf ' + list.map(q => q.id).join(',');
      const userQualifications = (await queryUserQualifications({ query: query })).results;
      const userQualificationsByQualification = new Map<string, UserQualificationModel[]>();
      const users = new Set<string>();
      for (const uq of userQualifications) {
        const qualificationResult = userQualificationsByQualification.get(uq.qualification);
        if (!qualificationResult) {
          userQualificationsByQualification.set(uq.qualification, [uq]);
        } else {
          qualificationResult.push(uq);
        }
        users.add(uq.user);
      }
      const userNameMapping = await resolveUserNames([...users]);
      for (const qualif of list) {
        // initialize qualification counters
        const currentQualification: QualificationDashboardGroup = {
          item: {
            id: qualif.id,
            name: qualif.name,
            total: 0,
            obtained: 0,
            qualificationState: 'neutral',
            link: { name: 'ViewQualification', params: { qualificationId: qualif.id } }
          },
          children: []
        };
        // get qualifiable users for this qualification
        const userList = userQualificationsByQualification.get(qualif.id) ?? [];
        currentQualification.item.total = userList.length;
        for (const user of userList) {
          // initialize user counters
          const currentUser: QualificationDashboardSecondaryInfo = {
            id: user.user,
            name: userNameMapping[user.user],
            obtainedlinkId: '', // not used here
            progressLinkId: '', // not used here
            userLinkId: user.user,
            ...getUserQualificationState(user)
          };
          if (currentUser.remainingDays >= 0) {
            currentQualification.item.obtained++;
          }
          currentQualification.children.push(currentUser);
        }
        // sort users
        sortArrayByNumber(currentQualification.children, c => c.remainingDays, 'desc');
        this.qualifications.push(currentQualification);
      }
      this.loading = false;
    } catch (err) {
      eventBus.$emit('error', err, null);
    }
  },

  computed: {
    filteredQualifications (): QualificationDashboardGroup[] {
      return filterQualificationDashboard(this.qualifications, this.legendSelector);
    }
  }
});

