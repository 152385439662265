































import { getQualificationLegendColor, QualificationDashboardSecondaryInfo } from '../../repository';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';

export default Vue.extend({

  name: 'DisplaySecondaryQualificationElement',

  props: {
    item: { type: Object as PropType<QualificationDashboardSecondaryInfo>, required: true }
  },

  data: () => ({
    showLinkSelection: false
  }),

  methods: {
    linkChoice (): void {
      if (this.item.obtainedlinkId === '' || this.item.progressLinkId === '') {
        return;
      }
      this.showLinkSelection = true;
    }
  },

  computed: {
    monoLink (): boolean {
      return (
        this.item.userLinkId !== '' ||
        (this.item.obtainedlinkId === '' && this.item.progressLinkId !== '') || (this.item.obtainedlinkId !== '' && this.item.progressLinkId === '')
      );
    },
    toMonoLink (): Location {
      // note : this is computed only if monolink is true
      // priority is done to user link
      if (this.item.userLinkId !== '') {
        return { name: 'UserQualificationDashboard', params: { userId: this.item.userLinkId } };
      }
      const trainingPathId = this.item.obtainedlinkId === '' ? this.item.progressLinkId : this.item.obtainedlinkId;
      return { name: 'ViewTrainingPath', params: { pathId: trainingPathId } };
    },
    legendColor (): string {
      return getQualificationLegendColor(this.item.qualificationState);
    },
    expirationDate (): string {
      if (this.item.expirationDate === '') {
        return ''; // no expiration date (permanent or never obtained)
      }
      // translate date with internationalization
      const formatedDate = LocalDate.parse(this.item.expirationDate).format(DateTimeFormatter.ofPattern(this.$t('datePattern').toString()));
      return this.$t('expirationDate', [formatedDate]).toString();
    },
    status (): string {
      if (this.item.remainingDays >= 10000) {
        return this.$t('permanent').toString();
      }
      if (this.item.remainingDays >= 0) {
        // add a day to remainingDays to display "1 day remaining" on last validity day
        return this.$t('stillRemaining', [this.item.remainingDays + 1]).toString();
      }
      if (this.item.remainingDays > -10000) {
        return this.$t('expiredSince', [Math.abs(this.item.remainingDays)]).toString();
      }
      return this.$t('neverValidated').toString();
    }
  }
});

