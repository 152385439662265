import { FieldType, FrontendFieldConfiguration } from '../queryEngine';
import i18n from '../i18n';

export default {
  code: {
    type: FieldType.string,
    displayName: i18n.t('reference.filter.content.code').toString(),
    sortable: true
  },
  label: {
    type: FieldType.string,
    displayName: i18n.t('reference.filter.content.label').toString(),
    sortable: true
  },
  description: {
    type: FieldType.string,
    displayName: i18n.t('reference.filter.content.description').toString()
  },
  disabled: {
    type: FieldType.boolean,
    displayName: i18n.t('reference.filter.status.disabled').toString(),
    sortable: true
  }
} as Record<string, FrontendFieldConfiguration>;
