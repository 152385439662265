import i18n from '../i18n';
import { FieldType } from '../queryEngine';
import { FrontendFieldConfiguration } from '../queryEngine/frontendFieldConfiguration';
import { autocompleteReference, autocompleteTrainingModule, autocompleteUser, resolveTrainingModuleNames, resolveUserNames } from '../repository';
import { AssignedTrainingModuleStatus, TrainingPathStatus, TrainingReferenceType } from '../../_GeneratedClients/SpotClient';

// Must be kept in sync with the backend
export default {
  title: {
    type: FieldType.string,
    displayName: i18n.t('path.filter.content.title').toString(),
    sortable: true
  },
  reference: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.content.reference').toString(),
    autocomplete: (search: string) => autocompleteReference(TrainingReferenceType.Path, search),
    resolveNames: (ids) => Promise.resolve(Object.fromEntries(ids.map(i => [i, i])))
  },
  learner: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.person.learner').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  status: {
    type: FieldType.enum,
    displayName: i18n.t('path.pathStatus.status').toString(),
    enumType: TrainingPathStatus,
    frontendEnumValues: Object.values(TrainingPathStatus).map(status => ({ id: status, name: i18n.t('path.pathStatus.' + status).toString() })),
    sortable: true
  },
  creator: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.person.creator').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  creation_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.created').toString(),
    sortable: true
  },
  start_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.pathStart').toString(),
    sortable: true
  },
  stop_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.pathStop').toString(),
    sortable: true
  },
  acceptance_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.pathAcceptance').toString(),
    sortable: true
  },
  validator: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.person.validator').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  validation_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.pathValidation').toString(),
    sortable: true
  },
  signature_date: {
    type: FieldType.date,
    displayName: i18n.t('path.filter.date.pathSignature').toString(),
    sortable: true
  },
  module: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.content.name').toString(),
    autocomplete: autocompleteTrainingModule,
    resolveNames: resolveTrainingModuleNames
  },
  referent: {
    type: FieldType.id,
    displayName: i18n.t('path.filter.person.referent').toString(),
    autocomplete: autocompleteUser,
    resolveNames: resolveUserNames
  },
  module_status: {
    type: FieldType.enum,
    displayName: i18n.t('path.moduleStatus.status').toString(),
    enumType: AssignedTrainingModuleStatus,
    frontendEnumValues: Object.values(AssignedTrainingModuleStatus).map(status => ({ id: status, name: i18n.t('path.moduleStatus.' + status).toString() }))
  },
  module_is_succeeded: {
    type: FieldType.boolean,
    displayName: i18n.t('path.filter.module.succeeded').toString()
  }
} as Record<string, FrontendFieldConfiguration>;
