





























import AddOrEditQualification from '../components/qualifications/AddOrEditQualification.vue';
import Vue from 'vue';
import { getEmptyQualification, getQualificationById, isPermissionGranted } from '../repository';
import { ApiException } from '../../_GeneratedClients/SpotClient';
import eventBus from '../eventBus';

export default Vue.extend({

  name: 'EditQualification',

  components: {
    AddOrEditQualification
  },

  data: () => ({
    loading: true,
    qualification: getEmptyQualification()
  }),

  async created () {
    if (!(await isPermissionGranted('perm_QualificationAdmin'))) {
      // user is not allowed to navigate there -> go to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
    // search for url parameter (qualification id)
    const qualificationId = this.$route.params.qualificationId ?? '??';
    try {
      this.qualification = await getQualificationById(qualificationId);
      this.loading = false;
    } catch (err) {
      if (err instanceof ApiException && err.status === 404) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      } else {
        eventBus.$emit('error', err, null);
      }
    }
  }
});

