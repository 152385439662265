








import Vue from 'vue';

export default Vue.extend({

  name: 'DisplayDiagram',

  props: {
    height: { type: Number, default: 50 }
  }
});

