import i18n from '../i18n';
import pdfMake from 'pdfmake/build/pdfmake';
import { TDocumentDefinitions, ContentTable, Content, TableCell } from 'pdfmake/interfaces';
import slugify from 'slugify';
import { downloadZip, InputWithoutMeta } from 'client-zip';
import { TranslateResult } from 'vue-i18n';
import { backendUrl } from '../Tools';
import { SignatureImage } from '../repository';

export interface PrintableInfos {
  title: string; period: string;
  learnerSignatureLabel: string;
  managerSignatureLabel: string;
  managerSignatureImage?: SignatureImage;
  learnerList: PrintableLearnerInfos[] }
export interface PrintableLearnerInfos { name: string; externalId: string; pathList: PrintableTrainingPathInfos[] }
export interface PrintableTrainingPathInfos {
  title: string;
  reference: string;
  period: string;
  duration?: string;
  status?: string;
  completion?: string;
  passThreshold?: string;
  grade?: string;
  managerComment?: string;
  learnerComment?: string;
  learnerSatisfaction?: string;
  moduleList: PrintableModuleInfos[];
}
export interface PrintableModuleInfos {
  name: string;
  reference: string;
  period: string;
  goals: string;
  moduleType?: string;
  duration?: string;
  status?: string;
  completion?: string;
  referent?: string;
  globalMediationDuration?: string;
  mediationsNumber?: string;
  mediators: string[];
  passThreshold?: string;
  grade?: string;
  referentComment?: string;
  learnerComment?: string;
  learnerSatisfaction?: string;
}

const blueColor = '#147FCC';
const signZoneBackground = '#FFFFFF';
const SignatureAreaWidth = 120;
const SignatureAreaHeight = 48;
const infoTable: ContentTable = { style: 'tableMargin', table: { body: [], widths: ['*', '*'] } };
const footerTable: ContentTable = { table: { body: [], widths: ['*', SignatureAreaWidth, 20, SignatureAreaWidth] }, layout: 'noBorders', margin: [30, 5, 30, 10] };

/**
 * internal function used to add a title line inside a ContentTable (pdf structure)
 *
 * @param label first part of title (default color)
 * @param title second part of title (highlighting color)
 * @param target the structural element in which the title line must be inserted
 * @param borders displays borders (default), or not
 */
function addTableTitle (label: string, title: string, target: ContentTable, borders = true): void {
  target.table.body.push([
    {
      colSpan: 2,
      text: [{ text: label, style: 'subHeader' }, { text: title, style: 'subHeaderBlue' }],
      border: borders ? [true, true, true, true] : [false, false, false, false]
    },
    {}
  ]);
}

/**
 * internal function used to insert a one-line element in a table cell
 *
 * @param label the label to print as the first part of the line
 * @param value the value to print as the second part of the line
 * @param target the structural element in which this line must be inserted
 * @param newLine if true, starts in a new line
 */
function addOneLineElement (label: TranslateResult, value: string | undefined, target: Content[], newLine = true): void {
  if (value === undefined) {
    return;
  }
  let text = newLine ? '\n' : '';
  text += label.length > 0 ? label.toString() + ' : ' : '';
  target.push({ text: text });
  target.push({ text: value, style: 'blue' });
}

/**
 * internal function used to insert a two-lines element in a table cell
 *
 * @param label the label to print on the first line
 * @param value the value to print on the second line
 * @param target the structural element in which these lines must be inserted
 * @param newLine if true, first element starts in a new line
 */
function addTwoLinesElement (label: TranslateResult, value: string | undefined, target: Content[], newLine = true): void {
  if (value === undefined) {
    return;
  }
  addOneLineElement(label, '', target, newLine);
  addOneLineElement('', value, target);
}
/**
 * internal function used to add a training path data line inside a ContentTable (pdf structure)
 *
 * @param data the data to display
 * @param target the structural element in which the data line must be inserted
 */
function addTrainingPathData (data: PrintableTrainingPathInfos, target: ContentTable): void {
  const leftText = [] as Content[];
  addOneLineElement(i18n.t('reports.trainingPath.reference'), data.reference, leftText, false);
  addOneLineElement(i18n.t('reports.trainingPath.achievementPeriod'), data.period, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.duration'), data.duration, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.status'), data.status, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.completion'), data.completion, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.passThreshold'), data.passThreshold, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.grade'), data.grade, leftText);

  const rightText = [{}] as Content[];
  addTwoLinesElement(i18n.t('reports.trainingPath.managerComment'), data.managerComment, rightText);
  addTwoLinesElement(i18n.t('reports.trainingPath.learnerComment'), data.learnerComment, rightText);
  addOneLineElement(i18n.t('reports.trainingPath.learnerSatisfaction'), data.learnerSatisfaction, rightText);

  target.table.body.push([{ text: leftText }, { text: rightText }]);
}

/**
 * internal function used to add module informations inside a ContentTable (pdf structure)
 *
 * @param data module data to print
 * @param target  the structural element in which the data line must be inserted
 */
function addModuleData (data: PrintableModuleInfos, target: ContentTable): void {
  // prepare left cell
  let leftText = [] as Content[];
  addOneLineElement(i18n.t('reports.trainingPath.reference'), data.reference, leftText, false);
  addOneLineElement(i18n.t('reports.trainingPath.achievementPeriod'), data.period, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.type'), data.moduleType, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.duration'), data.duration, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.status'), data.status, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.completion'), data.completion, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.passThreshold'), data.passThreshold, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.grade'), data.grade, leftText);
  addOneLineElement(i18n.t('reports.trainingPath.referent'), data.referent, leftText);
  // prepare right cell
  let rightText = [{}] as Content[];
  addTwoLinesElement(i18n.t('reports.trainingPath.goals'), data.goals, rightText, false);
  addTwoLinesElement(i18n.t('reports.trainingPath.referentComment'), data.referentComment, rightText);
  addTwoLinesElement(i18n.t('reports.trainingPath.learnerComment'), data.learnerComment, rightText);
  addOneLineElement(i18n.t('reports.trainingPath.learnerSatisfaction'), data.learnerSatisfaction, rightText);
  // insert row in table
  target.table.body.push([{ text: leftText }, { text: rightText }]);
  if (data.globalMediationDuration) {
    // prepare mediation row
    leftText = [];
    addOneLineElement(i18n.t('reports.trainingPath.mediationNumber'), data.mediationsNumber, leftText, false);
    if (data.globalMediationDuration === '0') { // there is nothing else to display
      rightText = [];
    } else {
      addOneLineElement(i18n.t('reports.trainingPath.mediationDuration'), data.globalMediationDuration, leftText);
      rightText = data.mediators.length > 0 ? [{ text: i18n.t('reports.trainingPath.mediators') + ' :' }] : [];
      for (const mediatorName of data.mediators) {
        addOneLineElement('', mediatorName, rightText);
      }
    }
    // insert row in table
    target.table.body.push([{ text: leftText }, { text: rightText }]);
  }
}

/**
 * internal function used to add training paths (of a learner) to pdf document
 *
 * @param pathList list of training paths to print
 * @param target  table (pdf structure) in which to add the rows
 */
function addTrainingPathsToDocument (pathList: PrintableTrainingPathInfos[], target: ContentTable): void {
  for (const path of pathList) {
    // training path part
    addTableTitle(i18n.t('reports.trainingPath.trainingPath') + ' ', path.title, target);
    addTrainingPathData(path, target);
    // module(s) part
    for (const module of path.moduleList) {
      addTableTitle('', '', target, false); // separator (nothing to print -> small row height )
      addTableTitle(i18n.t('reports.trainingPath.module') + ' ', module.name, target);
      addModuleData(module, target);
    }
    addTableTitle(' ', ' ', target, false); // separator (one space as element to print to have a full height row)
    addTableTitle(' ', ' ', target, false); // separator
  }
}

/**
 * generates a pdf file containing training path reports
 *
 * @param printableInfos structure containing learners, training paths and modules informations
 * @yields returns a pdf file for each learner cited in printableList
 */
export async function * generatePdfTrainingPathReport (printableInfos: PrintableInfos): AsyncGenerator<InputWithoutMeta> {
  for (const learner of printableInfos.learnerList) {
    // reset tables for this user
    infoTable.table.body = [];
    let learnerName = learner.name;
    learnerName += learner.externalId === '' ? '' : ' (' + learner.externalId + ')';
    // create document structure
    const document: TDocumentDefinitions = {
      // Images to use in pdf content
      images: {
        spotLogo: `${backendUrl}/organization/applogo`,
        customerLogo: `${backendUrl}/organization/logo`
      },
      pageSize: 'A4',
      pageOrientation: 'portrait',
      pageMargins: [30, 45, 30, printableInfos.learnerSignatureLabel === '' && printableInfos.managerSignatureLabel === '' ? 20 : SignatureAreaHeight * 2], // horizontal, vertical
      defaultStyle: { fontSize: 8 },
      header: (currentPage, pageCount) => {
        return {
          columns: [
            { width: 80, image: 'spotLogo', height: 30, alignment: 'left', margin: [30, 10, 0, 10] },
            {
              width: '*',
              text: `${i18n.t('reports.trainingPath.page')} ${currentPage} ${i18n.t('reports.trainingPath.on')} ${pageCount}`,
              style: 'boldBlue',
              alignment: 'center',
              margin: [0, 20, 0, 0]
            },
            { width: 80, image: 'customerLogo', height: 30, alignment: 'right', margin: [0, 10, 30, 10] }
          ]
        };
      },
      footer: () => footerTable,
      content: [
        { text: printableInfos.title, style: 'headerCenter' },
        { text: printableInfos.period, style: 'center' },
        { text: learnerName, style: 'subHeaderBlueCenter' },
        infoTable
      ],
      styles: {
        tableMargin: { margin: [0, 10, 0, 0] },

        headerCenter: { fontSize: 14, bold: true, alignment: 'center' },

        subHeader: { fontSize: 12, bold: true },
        subHeaderBlueCenter: { fontSize: 12, bold: true, color: blueColor, alignment: 'center' },
        subHeaderBlue: { fontSize: 12, bold: true, color: blueColor },

        bold: { bold: true },
        boldBlue: { bold: true, color: blueColor },
        center: { alignment: 'center' },
        blue: { color: blueColor }
      }
    };

    // computes footer
    if (printableInfos.learnerSignatureLabel === '' && printableInfos.managerSignatureLabel === '') {
      // nothing to set in footer -> set an empty table
      footerTable.table.body = [[{ text: '' }]];
    } else {
      const emptyZone = { text: ' ' } as TableCell;
      const signZone = { text: '\n\n\n\n', fillColor: signZoneBackground };
      const learnerLabel = { text: printableInfos.learnerSignatureLabel, style: 'center' };
      const managerLabel = { text: printableInfos.managerSignatureLabel, style: 'center' };
      let signZone1 = emptyZone;
      let signZone2 = emptyZone;
      let labelZone1 = emptyZone;
      let labelZone2 = emptyZone;
      if (printableInfos.managerSignatureLabel) {
        // manager signature zone must be printed
        signZone1 = printableInfos.learnerSignatureLabel ? signZone : emptyZone;
        labelZone1 = printableInfos.learnerSignatureLabel ? learnerLabel : emptyZone;
        if (printableInfos.managerSignatureImage === undefined) {
          // no image signature -> empty sign zone (white background for now)
          signZone2 = signZone;
        } else {
          // use image signature (adapt size and margins)
          let xMargin = 0;
          let yMargin = 0;
          const imageRatio = printableInfos.managerSignatureImage.size.width / printableInfos.managerSignatureImage.size.height;
          const displayRatio = SignatureAreaWidth / SignatureAreaHeight;
          let width = SignatureAreaWidth;
          let height = SignatureAreaHeight;
          if (imageRatio > displayRatio) {
            // full width, adapt height
            height = printableInfos.managerSignatureImage.size.height * SignatureAreaWidth / printableInfos.managerSignatureImage.size.width;
            yMargin = Math.floor((SignatureAreaHeight - height) / 2);
          } else {
            // full height, adapt width
            width = printableInfos.managerSignatureImage.size.width * SignatureAreaHeight / printableInfos.managerSignatureImage.size.height;
            xMargin = Math.floor((SignatureAreaWidth - width) / 2);
          }
          signZone2 = { image: printableInfos.managerSignatureImage.url, width: width, height: height, margin: [xMargin, yMargin] };
        }
        labelZone2 = managerLabel;
      } else {
        signZone2 = printableInfos.learnerSignatureLabel ? signZone : emptyZone;
        labelZone2 = printableInfos.learnerSignatureLabel ? learnerLabel : emptyZone;
      }
      footerTable.table.body = [
        [emptyZone, signZone1, emptyZone, signZone2],
        [emptyZone, labelZone1, emptyZone, labelZone2]
      ];
    }

    // add training paths
    addTrainingPathsToDocument(learner.pathList, infoTable);

    // create document
    const prefix = learner.externalId === '' ? '' : learner.externalId + '-';
    const fileName = slugify(prefix + learner.name) + '.pdf';
    const pdfDocGenerator = pdfMake.createPdf(document);
    const blob = await new Promise<Blob>((resolve) => { pdfDocGenerator.getBlob(resolve); });
    yield { name: fileName, lastModified: new Date(), input: blob };
  }
}

/**
 * used to create a zip file containing all the pdf files generated by the generatePdfTrainingPathReport function
 *
 * @param printableInfos structure containing learners, training paths and modules informations
 */
export async function filesToZipDownload (printableInfos: PrintableInfos): Promise<void> {
  const blobZip = await downloadZip(generatePdfTrainingPathReport(printableInfos)).blob();

  // make and click a temporary link to download the Blob
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blobZip);
  link.download = i18n.t('reports.trainingPath.report') + '.zip';
  link.click();
  link.remove();
}
