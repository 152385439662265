
















import { getFriendlyDate, sanitizeHTML } from '../../Tools';
import Vue, { PropType } from 'vue';
import { MessageModel, SystemMessageType } from '../../../_GeneratedClients/SpotClient';
import { resolveSubjectNames, UpdateInfo } from '../../repository';

export default Vue.extend({

  name: 'DisplayMessage',

  props: {
    message: { type: Object as PropType<MessageModel>, required: true },
    isNew: { type: Boolean, default: false },
    parentItemType: { type: String, required: true }
  },

  data: () => ({
    messageContent: ''
  }),

  async created () {
    let text = '';
    if (this.message.systemMessageMetadata) {
      let type = this.message.systemMessageMetadata.type.toString();
      type = type[0].toUpperCase() + type.substring(1); // uppercase on first char
      text = '' + this.$t(this.parentItemType + '.system' + type);
      switch (this.message.systemMessageMetadata.type) {
        case SystemMessageType.Criticity: // for subjects only
          text += '\n' + this.$t('subject.criteria') + ' : ';
          text += this.$t('subject.frequency') + ' -&gt; ' + this.$t('subject.frequencyLevel.' + this.message.systemMessageMetadata.arguments[0]) + ', ';
          text += this.$t('subject.gravity') + ' -&gt; ' + this.$t('subject.gravityLevel.' + this.message.systemMessageMetadata.arguments[1]) + ', ';
          break;
        case SystemMessageType.DuplicatedFrom: { // at this time, for subjects only
          const itemId = this.message.systemMessageMetadata.arguments[0];
          if (this.parentItemType === 'subject') {
            // gets subject name to know if subject is present in current list
            const subjectNames = await resolveSubjectNames([itemId]);
            if (subjectNames[itemId] === undefined) {
              text += ' :\n' + this.$t('unreachableOrDeletedElement');
            } else {
              text += ' <a href="' + this.$router.resolve({ name: 'Subject', params: { subjectId: itemId } }).href + '" target="_blank">' + this.$t('thisElement') + '</a>';
            }
          } // else ... other cases -> later
          break;
        }
        case SystemMessageType.StatusChanged: // for mediations only
          text += ' -&gt; ' + this.$t('mediation.steps.' + this.message.systemMessageMetadata.arguments[0]);
          break;
        case SystemMessageType.ResourceCreated:
        case SystemMessageType.ResourceUpdated:
        case SystemMessageType.ResourceDeleted:
          text += sanitizeHTML(this.message.systemMessageMetadata.arguments[1]);
          break;
        case SystemMessageType.Updated: {
          for (const precision of this.message.systemMessageMetadata.arguments) {
            try {
              const details: UpdateInfo = JSON.parse(precision);
              text += '\n' + this.$t(this.parentItemType + '.updatedElement.' + details.property, [details.oldValue, details.newValue]);
            } catch (err) {
              console.error('It seems that there is a problem with the JSON format in "' + precision + '"');
              // nothing else to do, the data in error is ignored
            }
          }
          break;
        }
      }
    } else {
      text = sanitizeHTML(this.message.content);
    }
    // TODO : add there links to attached elements -> "<icon> <type> : <name>\n"
    this.messageContent = text.replace(/\n/g, '<br />');
  },

  computed: {
    formatedDate (): string {
      return getFriendlyDate(this.message.date, 'short'); // fr : DD/MM/YYYY HH:mm
    }
  }

});

