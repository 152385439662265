

































import Vue, { PropType } from 'vue';
import { TrainingModuleModel } from '../../../_GeneratedClients/SpotClient';
import { getEmptyTrainingModule, getEnabledTrainingModules } from '../../repository';

export default Vue.extend({

  name: 'TrainingModulePicker',

  props: {
    /** Contains preselect for edit use, must contain an empty module object for add use */
    module: { type: Object as PropType<TrainingModuleModel>, required: true },
    /** true for view mode */
    readOnly: { type: Boolean, default: true },
    /** defines background field color */
    backgroundColor: { type: String, default: 'white' },
    /** message displayed on bottom of field. Empty string means "no error". Otherwise, field borders, field label and error message are displayed in red */
    errorMessage: { type: String, default: '' }
  },

  data: () => ({
    /** System (edit use) or user (add use) selection */
    selectedModule: undefined as TrainingModuleModel | undefined,
    /** list of modules availables for selection */
    enabledModules: [] as TrainingModuleModel[],
    /** the value entered by user (null if nothing yet) */
    search: null as string | null,
    /** empty module */
    emptyModule: getEmptyTrainingModule()
  }),

  async created () {
    this.enabledModules = await getEnabledTrainingModules();
    this.initPicker();
  },

  methods: {
    initPicker (): void {
      if (this.module.id === '') {
        this.selectedModule = undefined;
        this.search = null;
      } else {
        this.search = this.module.name;
        this.selectedModule = { ...this.module };
        this.onSelect();
      }
    },
    getFirstLine (text: string): string {
      const offset = text.indexOf('\n');
      return offset === -1 ? text : text.substring(0, offset) + ' ...';
    },
    onSelect () {
      if (this.selectedModule) {
        this.$emit('selected', this.selectedModule);
      } else {
        this.$emit('selected', this.emptyModule);
      }
    }
  },

  watch: {
    module: function () {
      // can overload created result
      this.initPicker();
    },
    search: function () {
      if (!this.search || this.search.trim().length === 0) {
        this.selectedModule = undefined;
        this.onSelect();
      }
    }
  }
});

