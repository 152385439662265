import { FieldType, FrontendFieldConfiguration } from '../queryEngine';
import i18n from '../i18n';
import { autocompleteCartographySite, autocompleteGroup, autocompleteTrainingModule, getCartographySiteNames, resolveGroupNames, resolveTrainingModuleNames } from '../repository';

export default {
  name: {
    type: FieldType.string,
    displayName: i18n.t('qualification.filter.content.name').toString(),
    sortable: true
  },
  cartographies: {
    type: FieldType.id,
    displayName: i18n.t('qualification.filter.content.cartographies').toString(),
    autocomplete: (search: string) => autocompleteCartographySite(search),
    resolveNames: getCartographySiteNames
  },
  qualifiableGroups: {
    type: FieldType.id,
    displayName: i18n.t('qualification.filter.content.groups').toString(),
    autocomplete: (search: string) => autocompleteGroup(search),
    resolveNames: resolveGroupNames
  },
  modules: {
    type: FieldType.id,
    displayName: i18n.t('qualification.filter.content.modules').toString(),
    autocomplete: (search: string) => autocompleteTrainingModule(search),
    resolveNames: resolveTrainingModuleNames
  }
} as Record<string, FrontendFieldConfiguration>;
