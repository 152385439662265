








































































































































import Vue from 'vue';
import { ContributionCounter, ContributionsQueryGranularity, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import { autocompleteGroup, autocompleteUser, exportExcelContributions, getAllUserIds, getUsersIdByGroups } from '../repository';
import MultiAutocomplete from '../components/common/MultiAutocomplete.vue';
import QueryLocationSelector from '../components/filters/QueryLocationSelector.vue';
import ChartContributions from '../components/dashboards/ChartContributions.vue';
import { LocaleMessage } from 'vue-i18n';
import { deduplicateArray } from '../Tools';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

export default Vue.extend({

  name: 'ContributionDashboard',

  components: {
    QueryLocationSelector,
    MultiAutocomplete,
    ChartContributions,
    SimplePageLayout
  },

  data: () => ({
    maxWeekDuration: 78, // one and a half year -> 78 weeks
    maxMonthDuration: 18, // one and a half year -> 18 months
    selectLocation: false,
    selectedItems: [] as IdAndNameModel[],
    selectContributors: false,
    selectedUsers: [] as IdAndNameModel[],
    selectedGroups: [] as IdAndNameModel[],
    usersError: false,
    groupsError: false,
    userIds: [] as string[],
    autocompleteUser: autocompleteUser,
    autocompleteGroup: autocompleteGroup,
    warningMessage: '' as string | LocaleMessage,
    preparingChart: false,
    preparingExport: false,
    contributions: [] as ContributionCounter[],
    duration: 0,
    durationError: '',
    selectedGranularity: {} as IdAndNameModel
  }),

  created () {
    // define default granularity and default duration
    this.selectedGranularity = this.granularityOptions[0]; // week
    this.duration = this.maxDuration;
  },

  methods: {
    update (element: string, newValue: IdAndNameModel[]): void {
      switch (element) {
        case 'location': this.selectedItems = [...newValue]; break;
        case 'userList': this.selectedUsers = [...newValue]; break;
        case 'groupList': this.selectedGroups = [...newValue]; break;
      }
    },
    targetValidator (): string | true {
      if (!this.selectContributors) {
        return true;
      }
      if (this.selectedUsers.length + this.selectedGroups.length === 0) {
        return this.$t('defineAtLeastOneContributorOrGroup').toString();
      }
      return true;
    },
    durationValidator (): void {
      this.durationError = '';
      if (this.duration < 1 || this.duration > this.maxDuration) {
        this.durationError = this.$t('valueMustBeBetween', [1, this.maxDuration]).toString();
      }
    },
    async askDrawing (): Promise<void> {
      if (this.globalError) {
        this.warningMessage = this.$t('atLeastOneErrorLeft');
        return;
      }
      this.userIds = deduplicateArray([
        ...(this.selectedGroups.length > 0 ? await getUsersIdByGroups(this.selectedGroups.map((g) => g.id)) : []),
        ...this.selectedUsers.map(u => u.id)
      ]);
      this.preparingChart = true;
    },
    async excelExport (): Promise<void> {
      if (this.globalError) {
        this.warningMessage = this.$t('atLeastOneErrorLeft');
        return;
      }
      this.preparingExport = true;
      this.userIds = deduplicateArray([
        ...(this.selectedGroups.length > 0 ? await getUsersIdByGroups(this.selectedGroups.map((g) => g.id)) : []),
        ...this.selectedUsers.map(u => u.id)
      ]);
      if (this.userIds.length === 0) {
        this.userIds = await getAllUserIds();
      }
      await exportExcelContributions(this.userIds, this.itemIds, this.granularity, this.duration, this.$t('excelTitle').toString());
      this.preparingExport = false;
    }
  },

  computed: {
    allLocationsClass (): string {
      return this.selectLocation ? '' : 'primary--text font-weight-bold';
    },
    allContributorsClass (): string {
      return this.selectContributors ? '' : 'primary--text font-weight-bold';
    },
    granularityOptions (): IdAndNameModel[] {
      // note : default is the first value
      return [
        { id: ContributionsQueryGranularity.Week, name: this.$t('granularity.' + ContributionsQueryGranularity.Week).toString() },
        { id: ContributionsQueryGranularity.Month, name: this.$t('granularity.' + ContributionsQueryGranularity.Month).toString() }
      ];
    },
    granularity (): ContributionsQueryGranularity {
      return (this.selectedGranularity.id ?? this.granularityOptions[0].id) as ContributionsQueryGranularity; // granularityOptions[0] is default
    },
    locationError (): string {
      return this.selectedItems.length === 0 && this.selectLocation ? this.$t('locationError').toString() : '';
    },
    itemIds (): string[] {
      return this.selectedItems.map(s => s.id);
    },
    globalError (): boolean {
      return (this.selectedItems.length === 0 && this.selectLocation) ||
        (this.selectedUsers.length === 0 && this.selectedGroups.length === 0 && this.selectContributors) ||
        this.durationError !== '';
    },
    maxDuration (): number {
      // max = one and a half years -> 78 weeks or 18 months
      switch (this.selectedGranularity.id) {
        case ContributionsQueryGranularity.Week: return this.maxWeekDuration;
        case ContributionsQueryGranularity.Month: return this.maxMonthDuration;
        default: return 0;
      }
    }
  },

  watch: {
    selectLocation: {
      immediate: true,
      handler: function (): void {
        if (!this.selectLocation) {
          this.update('location', []);
        }
      }
    },
    selectContributors: {
      immediate: true,
      handler: function (): void {
        if (!this.selectContributors) {
          this.update('userList', []);
          this.update('groupList', []);
        }
      }
    }
  }
});

