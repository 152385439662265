import { render, staticRenderFns } from "./DisplayPrimaryQualificationElement.vue?vue&type=template&id=6725c164&scoped=true&"
import script from "./DisplayPrimaryQualificationElement.vue?vue&type=script&lang=ts&"
export * from "./DisplayPrimaryQualificationElement.vue?vue&type=script&lang=ts&"
import style0 from "./DisplayPrimaryQualificationElement.vue?vue&type=style&index=0&id=6725c164&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6725c164",
  null
  
)

/* custom blocks */
import block0 from "./DisplayPrimaryQualificationElement.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCol,VRow,VSheet})
