





















































import DoublePageLayout from '../components/presentation/DoublePageLayout.vue';
import eventBus from '../eventBus';
import { getCartographyActivityPictos, getSpotItemById, Reloadable, SpotItem } from '../repository';
import Vue from 'vue';
import { ActivityModel, CartographyModel, ContextItemType, FunctionModel, IdAndNameModel } from '../../_GeneratedClients/SpotClient';
import DisplayContext from '../components/common/DisplayContext.vue';
import { backToList } from '../helpers/filteredListNavigation';
import DisplayActivityTable from '../components/function/DisplayActivityTable.vue';
import ResourceGallery from '../components/common/resources/ResourceGallery.vue';
import Discussion from '../components/common/Discussion.vue';

export interface ActivitySubjects { id: string; name: string; subjects: IdAndNameModel[] }
export interface ActivityGroup { pictogram: string; activity: ActivitySubjects; subActivities: ActivitySubjects[]; }

export default Vue.extend({

  name: 'ViewFunction',

  components: {
    Discussion,
    DisplayActivityTable,
    DisplayContext,
    DoublePageLayout,
    ResourceGallery
  },

  data: () => ({
    spotFunction: {} as SpotItem<FunctionModel>,
    activities: [] as ActivityGroup[],
    pictograms: {} as Record<string, string>,
    cartographyId: '',
    discussionId: '',
    loaded: false
  }),

  async created () {
    const functionId = this.$route.params.functionId;
    if (functionId === '' || functionId === undefined || functionId === null) {
      this.$router.push('/NotFound');
      return;
    }
    try {
      const spotItem = await getSpotItemById(functionId);
      if (spotItem === undefined || spotItem.item.type !== ContextItemType.Function) {
        this.$router.push('/NotFound');
        return;
      }
      this.spotFunction = spotItem as SpotItem<FunctionModel>;
      const cartoIds = this.spotFunction.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Cartography).map(c => c.id));
      this.cartographyId = cartoIds[0]; // cartoIds[0] must exist and represents parent cartography identifier
      const carto = await getSpotItemById(this.cartographyId);
      if (carto === undefined || carto.item.type !== ContextItemType.Cartography) {
        this.$router.push('/NotFound');
        return;
      }
      const allActivities = (carto.item as CartographyModel).activities;
      this.pictograms = await getCartographyActivityPictos(this.cartographyId);
      // Gets all subjects for this (sub)activity that are included in this function
      const getSubjects = (activity: ActivityModel) => activity.subjects
        .filter(subject => subject.contexts.flatMap(ctx => ctx.hierarchy).some(h => h.id === functionId))
        .map(subject => ({ id: subject.id, name: subject.name }));
      // For each activity, get all subjects directly mapped there and subactivities with their subjects
      this.activities = allActivities.map(activity => ({
        pictogram: this.pictograms[activity.id] ?? '',
        activity: { id: activity.id, name: activity.name, subjects: getSubjects(activity) },
        subActivities: activity.activities.map(a => ({ id: a.id, name: a.name, subjects: getSubjects(a) }))
      }));
      this.discussionId = this.spotFunction.item.discussionId;
      this.loaded = true;
    } catch (err) {
      eventBus.$emit('error', err, null);
    }
  },

  methods: {
    goBackToList () {
      this.$router.push(backToList('FilteredFunctions'));
    },
    refreshDiscussion () {
      (this.$refs.discussion as Reloadable).reload();
    }
  }
});

