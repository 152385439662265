









































































import eventBus from '../eventBus';
import { getAllPermissions, getGroupPermissionList, isPermissionGranted, resolvePermissionName, setGroupPermissions } from '../repository';
import Vue from 'vue';
import { GroupPermission } from '../../_GeneratedClients/SpotClient';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Permissions',

  components: {
    SimplePageLayout
  },

  data: () => ({
    groupList: [] as GroupPermission[],
    permissionList: [] as string[],
    loading: true,
    showEditForm: false,
    groupToEdit: { id: '', name: '', permissions: [] } as GroupPermission,
    newPermissions: {} as {[key: string]: boolean }
  }),

  async created () {
    if (!(await isPermissionGranted('perm_SetPermissions'))) {
      // user is not allowed to manage permissions -> redirect to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    } else {
      this.groupList = await getAllPermissions();
      this.permissionList = await getGroupPermissionList();
      this.loading = false;
    }
  },

  methods: {
    isSet (group: GroupPermission, permission: string): boolean {
      return group.permissions.includes(permission);
    },
    getPermissionLabel (permission: string): string {
      return resolvePermissionName(permission);
    },
    editGroup (group: GroupPermission): void {
      this.groupToEdit = group;
      for (const permission of this.permissionList) {
        this.newPermissions[permission] = group.permissions.includes(permission);
      }
      this.showEditForm = true;
    },
    async onSaveConfirmation (): Promise<void> {
      try {
        // prepare new data
        this.groupToEdit.permissions = Object.keys(this.newPermissions).filter((p) => this.newPermissions[p]);
        // save permissions on server
        await setGroupPermissions(this.groupToEdit.id, this.groupToEdit.permissions);
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
      this.showEditForm = false;
    }
  }
});

