



















































import { ActivityGroup, ActivitySubjects } from '../../views/ViewFunction.vue';
import Vue, { PropType } from 'vue';
import { Location } from 'vue-router';

export default Vue.extend({

  name: 'DisplayActivityTable',

  props: {
    activity: { type: Object as PropType<ActivityGroup>, required: true },
    functionId: { type: String, default: '' },
    cartographyId: { type: String, default: '' }
  },

  methods: {
    activityRoute (activity: ActivitySubjects): Location {
      if (this.functionId === '' || !activity) {
        return {};
      }
      const query = 'location oneOf ' + this.functionId + ' & location oneOf ' + activity.id;
      return { name: 'FilteredSubjects', params: { filter: query } };
    }
  },

  computed: {
    totalSubjects (): number {
      const subTotal = this.activity.subActivities.reduce((accumulator, currentValue) => accumulator + currentValue.subjects.length, 0);
      return subTotal + this.activity.activity.subjects.length;
    }
  }
});

