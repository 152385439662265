




















import Vue, { PropType } from 'vue';
import { UrlTrainingModuleItemModel } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'DisplayTrainingModuleUrlElement',

  props: {
    element: { type: Object as PropType<UrlTrainingModuleItemModel>, required: true }
  }
});

