



















import Vue, { PropType } from 'vue';
import panzoom, { PanZoom } from 'panzoom';
import { nextFrame } from '../../Tools';

export default Vue.extend({
  name: 'PanZoomZone',
  data: () => ({
    panZoomInstance: {} as PanZoom,
    minZoom: 0.1,
    maxZoom: 2,
    zoomSpeed: 0.5,
    fullscreen: false
  }),
  props: {
    contentSize: { type: Object as PropType<{ width: number, height: number}>, required: true },
    fit: { type: String as PropType<'fit' | 'width'>, default: 'width' }
  },
  async mounted () {
    const panZoomZone = this.$refs.panZoomZone as HTMLElement;
    this.panZoomInstance = panzoom(panZoomZone, {
      zoomSpeed: this.zoomSpeed,
      maxZoom: this.maxZoom,
      minZoom: this.minZoom,
      initialZoom: 0.3,
      zoomDoubleClickSpeed: 1 // Disable zoom by double click
    });
    await nextFrame();
    this.fitToView();
  },
  beforeDestroy () {
    if (this.fullscreen) {
      this.toggleFullscreen();
    }
  },
  methods: {
    async toggleFullscreen () : Promise<void> {
      this.fullscreen = !this.fullscreen;
      // Modifies the html { overflow-y: auto } declaration made in App.vue
      (document.firstElementChild as HTMLHtmlElement).style.overflowY = this.fullscreen ? 'hidden' : '';
      await nextFrame();
      this.fitToView();
    },
    fitToView () : void {
      let rectangle = {
        left: 0,
        top: 0,
        right: this.contentSize.width,
        bottom: this.contentSize.height
      };

      if (this.fit === 'width') {
        const containerRect = (this.$refs.panZoomZoneContainer as HTMLElement).getBoundingClientRect();
        rectangle = {
          left: 0,
          top: 0,
          right: this.contentSize.width,
          bottom: this.contentSize.width * containerRect.height / containerRect.width
        };
      }
      // TODO: the typing is invalid
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.panZoomInstance.showRectangle(rectangle as any);

      // Show rectangle doesn't refresh the view immediately (doesn't call "makeDirty")
      this.panZoomInstance.zoomTo(0, 0, 1);
    },
    zoomPlus (): void {
      this.panZoomInstance.smoothZoom(0, 0, 1 + this.zoomSpeed);
    },
    zoomMinus (): void {
      this.panZoomInstance.smoothZoom(0, 0, 1 - this.zoomSpeed);
    }
  },

  computed: {
    fullscreenIcon (): string {
      return this.fullscreen ? 'mdi-fullscreen-exit' : 'mdi-fullscreen';
    }
  }
});

