

















import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'QueryBooleanSelector',

  props: {
    value: { type: Array as PropType<IdAndNameModel[]>, default: function () { return [] as IdAndNameModel[]; } }
  },

  data: () => ({
    stateSelectionList: [] as IdAndNameModel[],
    selectedState: {} as IdAndNameModel
  }),

  created () {
    // state selector initialization
    this.stateSelectionList.push({ id: 'true', name: this.$t('yes').toString() } as IdAndNameModel);
    this.stateSelectionList.push({ id: 'false', name: this.$t('no').toString() } as IdAndNameModel);
    this.setValue();
  },

  methods: {
    setValue () {
      let definedState;
      if (this.value.length > 0) { // a value is already defined
        definedState = this.stateSelectionList.find((s) => s.id === this.value[0].id);
      }
      if (definedState) { // already defined value is valid
        this.selectedState = definedState;
      } else { // no defined (or no valid) value -> default value is first option
        this.selectedState = this.stateSelectionList[0];
        this.stateSelected();
      }
    },
    stateSelected () {
      this.$emit('state-selected', this.selectedState);
    }
  },

  watch: {
    value: function () {
      this.setValue();
    }
  }
});

