






































import eventBus from '../../eventBus';
import Vue, { PropType } from 'vue';
import { LmsTrainingModuleItemModel, LmsCourseModelLeafNode, LmsCourseModelNode, LmsCourseModelNodeType, ApiException } from '../../../_GeneratedClients/SpotClient';
import { getCourseById } from '../../repository';

export default Vue.extend({

  name: 'DisplayTrainingModuleLmsElement',
  props: {
    element: { type: Object as PropType<LmsTrainingModuleItemModel>, required: true }
  },

  data: () => ({
    itemList: [] as LmsCourseModelLeafNode[],
    root: undefined as LmsCourseModelNode | undefined,
    lmsElementNotFound: false
  }),

  async created () {
    await this.init();
  },

  methods: {
    async init (): Promise<void> {
      try {
        this.lmsElementNotFound = false;
        this.root = await getCourseById(this.element.lmsCourseId);
        // get sub-element list
        this.itemList = [];
        this.addLeafs(this.root);
      } catch (err) {
        if (err instanceof ApiException && err.status === 404) {
          // this section or element was not found
          this.lmsElementNotFound = true;
        } else {
          eventBus.$emit('error', err, null);
        }
        this.root = { type: LmsCourseModelNodeType.Group, id: '', name: '', children: [] };
      }
      this.root = await getCourseById(this.element.lmsCourseId);
      this.itemList = [];
      this.addLeafs(this.root);
    },
    addLeafs (root: LmsCourseModelNode) {
      if (root.type === LmsCourseModelNodeType.Group) {
        for (const node of root.children) {
          if (node.type === LmsCourseModelNodeType.Group) {
            this.addLeafs(node);
          } else {
            this.itemList.push(node);
          }
        }
      } else {
        this.itemList.push(root);
      }
    }
  },

  watch: {
    element: async function () {
      await this.init();
    }
  }
});

