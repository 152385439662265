





















































import Vue from 'vue';

export default Vue.extend({

  name: 'logUser',

  props: {
    title: { type: String, default: '???' },
    error: { type: String, default: '' },
    waitOnTry: { type: Boolean, default: false }
  },

  data: () => ({
    login: '',
    password: '',
    passwordVisible: false
  }),

  methods: {
    onConnectClicked () {
      // if user clicks on "erase field" button, component returns null as value (instead of '')
      if (this.login === null || this.login === undefined) {
        this.login = '';
      }
      if (this.password === null || this.password === undefined) {
        this.password = '';
      }
      this.$emit('connect', { username: this.login, password: this.password });
    }
  }
});

