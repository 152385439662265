
























































import Vue from 'vue';

export default Vue.extend({
  name: 'DoublePageLayout',
  props: {
    showSideContent: { type: Boolean, default: true }
  },
  data: () => ({
    screenHeight: window.innerHeight,
    sideContentWidth: 600,
    viewSideContent: false
  }),
  created () {
    this.viewSideContent = this.showSideContent && !this.verticalPresentation;
  },
  mounted () {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    toggleViewSideContent (): void {
      this.viewSideContent = !this.viewSideContent;
      this.$emit('toggleSideContent');
    },
    onResize (): void {
      this.screenHeight = window.innerHeight;
    }
  },
  computed: {
    hChevron (): string {
      if (this.viewSideContent) {
        return 'mdi-chevron-right';
      } else {
        return 'mdi-chevron-left';
      }
    },
    vChevron (): string {
      if (this.viewSideContent) {
        return 'mdi-chevron-down';
      } else {
        return 'mdi-chevron-up';
      }
    },
    verticalPresentation (): boolean {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
        case 'sm':
        case 'md': return true;
        case 'lg':
        case 'xl': return false;
        default: return false;
      }
    }
  }
});
