









import Vue, { PropType } from 'vue';

export default Vue.extend({

  name: 'DisplayCartographySiteName',

  props: {
    ids: { type: Array as PropType<string[]>, default: () => [] },
    resolver: { type: Function as PropType<(ids: string[]) => Promise<Record<string, string>>>, required: true }
  },

  data: () => ({
    names: {} as Record<string, string>
  }),

  async mounted () {
    if (this.ids.length > 0) {
      try {
        this.names = await this.resolver(this.ids);
      } catch {
        // nothing else to do
      }
    }
  }
});

