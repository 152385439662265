











import { getCartographyActivityPictos, getSpotItemById, getSubjectsFromFunction, SpotItem } from '../../repository';
import Vue, { PropType } from 'vue';
import { CartographyModel, ContextItemType, FunctionModel } from '../../../_GeneratedClients/SpotClient';

export interface Activity { name: string; pictogram: string; counter: number }

export default Vue.extend({

  name: 'DisplayFunctionCounters',

  props: {
    functionItem: { type: Object as PropType<SpotItem<FunctionModel>>, required: true }
  },

  data: () => ({
    activities: [] as Activity[]
  }),

  async created () {
    const actIndex = {} as Record<string, Activity>;
    const cartoIds = this.functionItem.contexts.flatMap((ctx) => ctx.hierarchy.filter((ctxnode) => ctxnode.type === ContextItemType.Cartography).map(c => c.id));
    const cId = cartoIds[0]; // cartoIds[0] must exist and represents parent cartography identifier
    // get pictograms for activities
    const pictos = await getCartographyActivityPictos(cId);
    // get activity names (from cartography data)
    const carto = await getSpotItemById(cId);
    for (const aId of Object.keys(pictos)) {
      let name = '';
      if (carto) {
        const found = (carto.item as CartographyModel).activities.find(a => a.id === aId);
        if (found) { name = found.name; }
      }
      actIndex[aId] = { name: name, pictogram: pictos[aId], counter: 0 };
    }
    // get subjects for this function
    const subjectList = await getSubjectsFromFunction(this.functionItem.item.id);
    for (const subject of subjectList) {
      // get activity from subject context -> activityNodes[0] must exist and represents searched activity
      // and count subjects by activities
      const activityNodes = subject.contexts.flatMap(ctx => ctx.hierarchy.filter(h => h.type === ContextItemType.Activity));
      actIndex[activityNodes[0].id].counter++;
    }
    this.activities = Object.values(actIndex);
  }
});

