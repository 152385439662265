














import Vue from 'vue';

export default Vue.extend({

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Forbidden'

});

