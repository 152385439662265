













































































































































import Vue, { PropType } from 'vue';
import { IdAndNameModel, MediationModel, MediationStatus } from '../../../_GeneratedClients/SpotClient';
import DatePicker from '../common/DatePicker.vue';
import TimePicker from '../common/TimePicker.vue';
import moment, { Moment } from 'moment';
import Autocomplete from '../common/Autocomplete.vue';
import MultiAutocomplete from '../common/MultiAutocomplete.vue';
import { autocompleteUser, autocompleteGroup, VForm, getEmptyMediation } from '../../repository';
import SimplePageLayout from '../presentation/SimplePageLayout.vue';

interface ReadOnlyFields {
  title: boolean,
  description: boolean,
  date: boolean,
  mediator: boolean,
  participants: boolean,
  groups: boolean,
  persons: boolean
}

export default Vue.extend({

  name: 'AddOrEditMediation',

  components: {
    Autocomplete,
    DatePicker,
    MultiAutocomplete,
    SimplePageLayout,
    TimePicker
  },

  props: {
    mediation: { type: Object as PropType<MediationModel>, required: true },
    connectedUser: { type: Object as PropType<IdAndNameModel>, required: true }
  },

  data: () => ({
    globalError: '',
    schedulingDateError: false,
    schedulingTimeError: false,
    mediatorError: false,
    participantsError: false,
    mediationToEdit: getEmptyMediation(),
    readOnly: { title: true, description: true, date: true, mediator: true, participants: true, groups: true, persons: true } as ReadOnlyFields,
    now: moment(),
    autocompleteUser: autocompleteUser,
    autocompleteGroup: autocompleteGroup
  }),

  mounted () {
    // Every minute, increase the minimum scheduling date
    setInterval(() => { this.now = moment(); }, 60000);
    this.initMediationToEdit();
  },

  methods: {
    initMediationToEdit () {
      this.mediationToEdit = { ...this.mediation };
      // set readonly values (default : all true)
      if ([this.mediation.mediator, this.mediation.creator].some((p) => p.id === this.connectedUser.id)) {
        // connected user is the creator or the mediator
        switch (this.mediation.status) {
          case MediationStatus.Created:
            // mediator and creator can edit title and description
            this.setReadOnly(['title', 'description'], false);
            if (this.mediation.creator.id === this.connectedUser.id) {
              // in this state, creator can also edit other fields
              this.setReadOnly(['date', 'mediator', 'participants', 'groups', 'persons'], false);
            }
            break;
          case MediationStatus.Started:
          case MediationStatus.Paused:
          case MediationStatus.Stopped:
            if (this.mediation.creator.id === this.connectedUser.id) {
              // creator can edit groups and persons lists
              this.setReadOnly(['groups', 'persons'], false);
            }
            if (this.mediation.mediator.id === this.connectedUser.id) {
              // mediator can edit title, description and participants list
              this.setReadOnly(['title', 'description', 'participants'], false);
            }
            break;
          case MediationStatus.Validated:
          case MediationStatus.Archived:
            if (this.mediation.creator.id === this.connectedUser.id) {
              // creator can edit groups and persons lists
              this.setReadOnly(['groups', 'persons'], false);
            }
            break;
        }
      }
      (this.$refs.globalForm as VForm).validate();
    },
    setReadOnly (fields: (keyof ReadOnlyFields)[], value: boolean) {
      for (const field of fields) {
        this.readOnly[field] = value;
      }
    },
    getBgColor (error: string, readonly = false): string {
      if (readonly === true) {
        return 'bgDisabled';
      }
      return error === '' ? 'bgAccent' : 'bgError';
    },
    onSaveClicked () {
      if (!(this.$refs.globalForm as VForm).validate() || this.schedulingDateError || this.schedulingTimeError || this.mediatorError || this.participantsError) {
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      this.$emit('save', this.mediationToEdit);
    },
    titleValidator (): string | true {
      if (this.mediationToEdit.title !== this.mediationToEdit.title.trim()) { // whitespace(s) detected at the beginning or end of text
        return this.$t('beginEndWhitespaceError').toString();
      }
      if (this.mediationToEdit.title.length === 0) {
        return this.$t('emptyFieldError').toString();
      }
      return true;
    },
    setSchedulingDate (newDate: Moment, newTime: Moment) {
      this.mediationToEdit.schedulingDate = newDate.clone().hour(newTime.hour()).minutes(newTime.minute());
    },
    participantsNotMediatorValidator (newParticipantsList: IdAndNameModel[]) {
      if (newParticipantsList.some((p) => p.id === this.mediationToEdit.mediator.id)) {
        return this.$t('mediatorCantBeParticipant').toString();
      }
      return true;
    }
  },

  computed: {
    mediator: {
      get: function () : IdAndNameModel | null {
        return this.mediationToEdit.mediator.id === '' ? null : this.mediationToEdit.mediator;
      },
      set: function (newMediator: IdAndNameModel | null) {
        this.mediationToEdit.mediator = newMediator ?? { id: '', name: '' };
      }
    },
    schedulingDate (): Moment {
      // if mediationToEdit.schedulingDate is undefined, the field is not displayed, so the non-null assertion is only needed for typescript purposes
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.mediationToEdit.schedulingDate!;
    },
    schedulingTime (): Moment {
      // if mediationToEdit.schedulingDate is undefined, the field is not displayed, so the non-null assertion is only needed for typescript purposes
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return this.mediationToEdit.schedulingDate!;
    },
    minSchedulingDate (): Moment | null {
      if (this.readOnly.date) {
        return null;
      }
      return this.now.clone().startOf('day');
    },
    minSchedulingTime (): Moment | null {
      if (!this.readOnly.date && this.mediationToEdit.schedulingDate && this.mediationToEdit.schedulingDate.isSame(this.now, 'day')) {
        return this.now.clone().add(1, 'minute');
      }
      return null;
    }
  }
});

