










































import Vue from 'vue';
import moment, { Moment } from 'moment';
import { getEmptyDiscussion, getDiscussion, postMessage, getAllVisits, setEntityLastVisit } from '../../repository';
import eventBus from '../../eventBus';
import DisplayMessage from './DisplayMessage.vue';

export default Vue.extend({
  name: 'Discussion',

  props: {
    discussionId: { type: String, required: true },
    parentItemId: { type: String, required: true },
    parentItemType: { type: String, required: true }
  },

  components: {
    DisplayMessage
  },

  data: () => ({
    loading: true,
    discussion: getEmptyDiscussion(),
    messageText: '',
    posting: false,
    previousVisit: undefined as Moment | undefined
  }),

  mounted () {
    if (this.discussionId !== '') {
      this.reload();
    }
  },

  updated () {
    // used to have scrollbar always at the bottom (to see last message)
    const scrollHeight = (this.$refs.messages as HTMLElement).scrollHeight;
    (this.$refs.messages as HTMLElement).scrollTo({ top: scrollHeight, left: 0, behavior: 'smooth' });
  },

  methods: {
    async reload () {
      try {
        this.loading = true;
        const visits = await getAllVisits();
        this.previousVisit = visits[this.parentItemId];
        setEntityLastVisit(this.parentItemId, moment());
        this.discussion = await getDiscussion(this.discussionId);
        this.loading = false;
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async onSendClicked () {
      if (this.messageText.trim().length > 0) {
        this.posting = true;
        this.messageText = this.messageText.trim();
        try {
          await postMessage(this.discussionId, { content: this.messageText }, this.parentItemId);
          await this.reload();
          this.messageText = '';
        } catch (err) {
          eventBus.$emit('error', err, null);
        } finally {
          this.posting = false;
        }
      }
    },
    isNew (eventDate: Moment): boolean {
      if (this.previousVisit === undefined) {
        return false;
      }
      return this.previousVisit.isBefore(eventDate);
    }
  },

  watch: {
    discussionId: async function () {
      if (this.discussionId !== '') {
        await this.reload();
      }
    }
  }
});

