import FunctionFieldsConfiguration from '../fieldsConfiguration/FunctionFieldsConfiguration';
import { doQuery, parseQuery } from '.';
import { FunctionModel, QueryRequest } from '../../_GeneratedClients/SpotClient';
import { getAllFunctions, SpotItem } from '../repository';
import { parseSorting } from './sorting';

/** The result object of a query */
// eslint-disable-next-line camelcase
export interface QueryResponse_FunctionModel {
  /** The query results in the page */
  results: SpotItem<FunctionModel>[];
  /** The total number of items (useful to get the number of pages) */
  totalItems: number;
}

/**
 * Performs a function query given the filters parameters.
 *
 * @param query The query that the functions need to match
 * @returns The filtered list of the functions
 */
// eslint-disable-next-line camelcase
export async function queryFunctions (query: QueryRequest): Promise<QueryResponse_FunctionModel> {
  // TODO: move this to the server-side
  const criterion = parseQuery(FunctionFieldsConfiguration, query.query);
  const sorting = parseSorting(FunctionFieldsConfiguration, query.sorting ?? '');
  const source = await getAllFunctions();
  const filteredItems = doQuery(FunctionFieldsConfiguration, criterion, sorting, source);
  let pagedFilteredItems = filteredItems;
  if (query.pageSize !== undefined) {
    const start = (query.page ?? 0) * query.pageSize;
    pagedFilteredItems = filteredItems.slice(start, start + query.pageSize);
  }
  return {
    results: pagedFilteredItems,
    totalItems: filteredItems.length
  };
}
