import CartographyFieldsConfiguration from '../fieldsConfiguration/CartographyFieldsConfiguration';
import { getAllCartographies, SpotItem } from '../repository';
import { CartographyModel, QueryRequest } from '../../_GeneratedClients/SpotClient';
import { doQuery } from './queryEngine';
import { parseQuery } from './querySerializer';
import { parseSorting } from './sorting';

/** The result object of a query */
// eslint-disable-next-line camelcase
export interface QueryResponse_CartographyModel {
  /** The query results in the page */
  results: SpotItem<CartographyModel>[];
  /** The total number of items (useful to get the number of pages) */
  totalItems: number;
}

/**
 * Performs a cartography query given the filters parameters.
 *
 * @param query The query that the cartographies need to match
 * @returns The filtered list of the cartographies
 */
// eslint-disable-next-line camelcase
export async function queryCartographies (query: QueryRequest): Promise<QueryResponse_CartographyModel> {
  // TODO: move this to the server-side
  const criterion = parseQuery(CartographyFieldsConfiguration, query.query);
  const sorting = parseSorting(CartographyFieldsConfiguration, query.sorting ?? '');
  const source = await getAllCartographies();

  const filteredItems = doQuery(CartographyFieldsConfiguration, criterion, sorting, source);
  let pagedFilteredItems = filteredItems;
  if (query.pageSize !== undefined) {
    const start = (query.page ?? 0) * query.pageSize;
    pagedFilteredItems = filteredItems.slice(start, start + query.pageSize);
  }
  return {
    results: pagedFilteredItems,
    totalItems: filteredItems.length
  };
}
