

































































import Vue from 'vue';
import { getEmptyTrainingReference, isPermissionGranted } from '../../repository';
import { TrainingReferenceType } from '../../../_GeneratedClients/SpotClient';
import AddOrEditTrainingReference from '../references/AddOrEditTrainingReference.vue';

export default Vue.extend({

  name: 'AddItemMenu',

  components: {
    AddOrEditTrainingReference
  },

  data: () => ({
    canAdminTrainingReferences: false,
    canManageQualifications: false,
    canManageTraining: false,
    displayReferenceEdition: false,
    newTrainingReference: getEmptyTrainingReference(),
    referenceType: undefined as TrainingReferenceType | undefined
  }),

  async created () {
    this.canManageTraining = await isPermissionGranted('perm_TrainingManagement');
    this.canAdminTrainingReferences = await isPermissionGranted('perm_TrainingReferenceAdmin');
    this.canManageQualifications = await isPermissionGranted('perm_QualificationAdmin');
  },

  methods: {
    createReference (referenceType: string): void {
      this.referenceType = referenceType as TrainingReferenceType;
      this.displayReferenceEdition = true;
    },
    closeEdit (): void {
      this.displayReferenceEdition = false;
      this.referenceType = undefined;
    }
  }
});

