
































































import Vue from 'vue';
import { IdAndNameModel, TrainingModuleType } from '../../_GeneratedClients/SpotClient';
import TrainingModuleFieldsConfiguration from '../fieldsConfiguration/TrainingModuleFieldsConfiguration';
import { getAllVisits, getConnectedUser, isPermissionGranted, queryTrainingModules, VisitsCache } from '../repository';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { firstLowerCase } from '../Tools';

export default Vue.extend({

  name: 'TrainingModules',

  components: {
    FilteredListPage,
    DisplayValue
  },

  data: () => ({
    me: { id: '', name: '' } as IdAndNameModel,
    visitsCache: {} as VisitsCache,
    fieldsConfiguration: TrainingModuleFieldsConfiguration,
    query: queryTrainingModules
  }),

  methods: {
    async onBeforeDataLoad () {
      const connectedUser = getConnectedUser();
      if (connectedUser) { // always true, but to avoid "possibly null" error
        this.me = { id: connectedUser.id, name: connectedUser.name };
      }
      if (!(await isPermissionGranted('perm_TrainingManagement'))) {
        // user is not allowed to navigate there -> go to forbidden page
        this.$router.replace({ name: 'Forbidden' });
      }
      this.visitsCache = await getAllVisits();
    },
    getFirstLine (text: string): string {
      const offset = text.indexOf('\n');
      return offset === -1 ? text : text.substring(0, offset) + ' ...';
    },
    getDuration (duration: number): string {
      // returns something like "x H y Mn", or just "x H" or "y Mn"
      if (duration === 0) {
        return this.$t('notDefined').toString();
      }
      const h = Math.floor(duration / 60);
      const m = duration % 60;
      let value = '';
      if (h > 0) {
        value = h + ' H';
      }
      if (m > 0) {
        value += value === '' ? m + ' Mn' : ' ' + m + ' Mn';
      }
      return value;
    },
    getPassThreshold (passThreshold: number): string {
      return passThreshold.toString() + ' / 10';
    },
    getModuleType (type: TrainingModuleType): string {
      return this.$t('moduleType.' + firstLowerCase(type)).toString();
    }
  }
});

