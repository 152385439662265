

























import Vue from 'vue';
import eventBus from '../../eventBus';
import { Location } from 'vue-router';
import { ApiException } from '../../../_GeneratedClients/SpotClient';

interface ProblemDetails { detail?: string; instance?: string; type?: string; title?: string; status?: number; }
interface ValidationProblemDetails extends ProblemDetails { status: 400; errors: Record<string, string>; }
interface ErrorDetail { label: string; labelColor: string; value: string; valueColor: string; }

export default Vue.extend({

  name: 'DisplayServerError',

  data: () => ({
    showDialogBox: false,
    backTo: null as Location | null,
    errorMessage: '',
    showDetails: false,
    errorDetails: [] as ErrorDetail[]
  }),

  created () {
    eventBus.$on('error', (error: Error, goBackAfterError: Location | null) => {
      this.errorDetails = [];
      if (error instanceof ApiException) {
        this.errorMessage = this.$t('serverError').toString();
        this.backTo = goBackAfterError;
        if (!error.response) {
          this.showDialogBox = false;
          this.goToAfterError();
        } else {
          try {
            const details: ProblemDetails | ValidationProblemDetails = JSON.parse(error.response);
            this.errorDetails.push({ label: 'Status', labelColor: 'secondary', value: error.status.toString(), valueColor: 'accent' });
            this.errorDetails.push({ label: 'Reason', labelColor: 'secondary', value: details.title ? details.title : 'unknown', valueColor: 'accent' });
            if (details.detail) {
              this.errorDetails.push({ label: 'message', labelColor: 'primary', value: details.detail, valueColor: 'warning' });
            }
            if ('errors' in details) {
              this.errorDetails.push({ label: 'Error(s)', labelColor: 'secondary', value: '', valueColor: 'warning' });
              for (const key in details.errors) {
                this.errorDetails.push({ label: key, labelColor: 'primary', value: details.errors[key], valueColor: 'warning' });
              }
            } else {
              this.errorDetails.push({ label: '', labelColor: 'warning', value: 'No more details', valueColor: 'warning' });
            }
          } catch (err) {
            this.errorDetails.push({ label: 'JSON', labelColor: 'error', value: (err as Error).message, valueColor: 'error' });
          }
          this.showDialogBox = true;
        }
      } else {
        this.errorMessage = this.$t('applicationError').toString();
        this.errorDetails.push({ label: 'name', labelColor: 'secondary', value: error.name, valueColor: 'accent' });
        this.errorDetails.push({ label: 'message', labelColor: 'primary', value: error.message, valueColor: 'warning' });
        this.showDialogBox = true;
      }
    });
  },

  methods: {
    goToAfterError () {
      this.showDialogBox = false;
      if (this.backTo) {
        this.$router.push(this.backTo);
      }
    }
  },

  computed: {
    detailsButtonText: function (): string {
      return this.showDetails === true ? this.$t('lessDetails').toString() : this.$t('moreDetails').toString();
    }
  }
});

