































































import Vue from 'vue';
import { IdAndNameModel, TrainingPathModel, TrainingPathStatus } from '../../_GeneratedClients/SpotClient';
import TrainingPathFieldsConfiguration from '../fieldsConfiguration/TrainingPathFieldsConfiguration';
import { getAllVisits, getConnectedUser, queryPaths, VisitsCache } from '../repository';
import FilteredListPage from '../components/filters/FilteredListPage.vue';
import RadioStep from '../components/common/RadioStep.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import { Moment } from 'moment';

export default Vue.extend({

  name: 'TrainingPaths',

  components: {
    FilteredListPage,
    RadioStep,
    DisplayValue
  },

  data: () => ({
    me: { id: '', name: '' } as IdAndNameModel,
    visitsCache: {} as VisitsCache,
    fieldsConfiguration: TrainingPathFieldsConfiguration,
    query: queryPaths
  }),

  methods: {
    async onBeforeDataLoad () {
      const connectedUser = getConnectedUser();
      if (connectedUser) { // always true, but to avoid "possibly null" error
        this.me = { id: connectedUser.id, name: connectedUser.name };
      }
      this.visitsCache = await getAllVisits();
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getCompletion (path: TrainingPathModel): number {
      if (path.totalSubModuleItems === 0) {
        return -1;
      }
      return Math.round(path.doneSubModuleItems / path.totalSubModuleItems * 100);
    },
    getDisplayableCompletion (path: TrainingPathModel): string {
      const completion = this.getCompletion(path);
      return completion === -1 ? this.$t('notQuantifiable').toString() : completion + ' %';
    },
    getBgColor (path: TrainingPathModel): string {
      const completion = this.getCompletion(path);
      if (path.status === TrainingPathStatus.Canceled || completion === -1) {
        return 'white';
      }
      return completion < 33 ? 'bgError' : completion > 66 ? 'bgSuccess' : 'bgAccent';
    },
    getCompletionColor (path: TrainingPathModel): string {
      const completion = this.getCompletion(path);
      if (path.status === TrainingPathStatus.Canceled || completion === -1) {
        return 'secondary--text';
      }
      return completion < 33 ? 'error--text' : completion > 66 ? 'success--text' : 'accent--text';
    },
    getStatusColor (status: TrainingPathStatus): string {
      switch (status) {
        case TrainingPathStatus.NotAccepted:
        case TrainingPathStatus.Accepted:
          return 'secondary--text';
        case TrainingPathStatus.Started:
        case TrainingPathStatus.Finished:
          return 'accent--text';
        case TrainingPathStatus.Validated:
        case TrainingPathStatus.Archived:
          return 'success--text';
        case TrainingPathStatus.Canceled:
          return 'error--text';
        default:
          return 'secondary--text';
      }
    }
  }
});

