





import Vue from 'vue';
// eslint-disable-next-line camelcase
import { QueryRequest, QueryResponse_TrainingReferenceModel_, TrainingReferenceType } from '../../_GeneratedClients/SpotClient';
import { isPermissionGranted, queryTrainingReferences } from '../repository';
import TrainingReferences from '../components/references/TrainingReferences.vue';

export default Vue.extend({
  components: {
    TrainingReferences
  },

  name: 'TrainingModuleReferences',

  async created () {
    if (!(await isPermissionGranted('perm_TrainingReferenceAdmin'))) {
      // user is not allowed to see this page -> redirect to forbidden page
      this.$router.replace({ name: 'Forbidden' });
    }
  },

  methods: {
    // eslint-disable-next-line camelcase
    query (query: QueryRequest): Promise<QueryResponse_TrainingReferenceModel_> {
      return queryTrainingReferences(TrainingReferenceType.Module, query);
    }
  }
});

