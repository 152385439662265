


















































import Vue from 'vue';
import { TrainingPathModel, UserModel } from '../../_GeneratedClients/SpotClient';
import {
  getConnectedUser,
  getInitialTrainingPathProgressLegend,
  getTrainingPathProgressState,
  getUsersGroups,
  queryPaths,
  TrainingPathCountingEntity,
  TrainingPathGroupEntity
} from '../repository';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';
import { sortArrayByNumber } from '../Tools';
import { Location } from 'vue-router';
import eventBus from '../eventBus';
import DisplayTrainingPathProgressCounter from '../components/dashboards/DisplayTrainingPathProgressCounter.vue';
import DisplayTrainingPathProgressLegend from '../components/dashboards/DisplayTrainingPathProgressLegend.vue';

export default Vue.extend({

  name: 'TrainingPathDashboard',

  components: {
    DisplayTrainingPathProgressCounter,
    DisplayTrainingPathProgressLegend,
    SimplePageLayout
  },

  data: () => ({
    computing: true,
    currentTrainingPathList: [] as TrainingPathModel[],
    groupList: [] as TrainingPathGroupEntity[],
    legendSelector: getInitialTrainingPathProgressLegend(),
    me: {} as UserModel
  }),

  async mounted () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
    }
    if (!this.me.permissions.includes('perm_TableauBord')) {
      // user is not allowed to see this page -> redirect to forbidden page
      this.$router.replace({ path: '/forbidden' });
    }
    // get current training path list
    try {
      const query = '(status noneOf canceled,archived)';
      const list = await queryPaths({ query: query });
      this.currentTrainingPathList = list.results;
      // get affected user list (learner of each training path)
      const userList = new Set(this.currentTrainingPathList.map((tp) => tp.learner.id));
      // get group(s) of each user
      const usersGroups = await getUsersGroups([...userList] as string[]);
      // computes counters
      for (const path of this.currentTrainingPathList) {
        // for each training path
        const pathProgress = path.totalSubModuleItems === 0 ? 0 : Math.round(path.doneSubModuleItems / path.totalSubModuleItems * 100);

        for (const group of usersGroups[path.learner.id]) {
          // for each group of the training path learner
          const affectedGroup = this.groupList.find((g) => g.group.id === group.id);
          if (affectedGroup === undefined) {
            // group don't yet exists in list -> create it and affect user
            this.groupList.push({
              group: { id: group.id, name: group.name, progress: [pathProgress], average: 0, state: 'low' },
              users: [{ id: path.learner.id, name: path.learner.name, progress: [pathProgress], average: 0, state: 'low' }]
            });
          } else {
            // group already exists -> add progress in groupList and add or complete user in userList
            affectedGroup.group.progress.push(pathProgress);
            const affectedUser = affectedGroup.users.find((u) => u.id === path.learner.id);
            if (affectedUser === undefined) {
              // user is not yet registred for this group -> create it
              affectedGroup.users.push({ id: path.learner.id, name: path.learner.name, progress: [pathProgress], average: 0, state: 'low' });
            } else {
              // user already registred for this group -> update progress
              affectedUser.progress.push(pathProgress);
            }
          }
        }
      }
      // computes averages
      for (const group of this.groupList) {
        // note : group.group.progress.length and user.progress.length can't be zero (no risk when dividing by these values)
        group.group.average = Math.round(group.group.progress.reduce((acc, curVal) => { return acc + curVal; }, 0) / group.group.progress.length);
        group.group.state = getTrainingPathProgressState(group.group.average);
        for (const user of group.users) {
          user.average = Math.round(user.progress.reduce((acc, curVal) => { return acc + curVal; }, 0) / user.progress.length);
          user.state = getTrainingPathProgressState(user.average);
        }
        // sort user list from smallest average to largest
        sortArrayByNumber(group.users as TrainingPathCountingEntity[], (u) => u.average);
      }
      // sort group list  from largest average to smallest
      sortArrayByNumber(this.groupList as TrainingPathGroupEntity[], (g) => g.group.average, 'desc');
      this.computing = false;
    } catch (err) {
      eventBus.$emit('error', err, null);
    }
  },

  methods: {
    getUserPathLink (userId: string): Location {
      const filter = '(status noneOf canceled,archived & learner oneOf ' + userId + ')';
      return { name: 'FilteredTrainingPaths', params: { filter: filter } };
    },
    filteredUsers (users: TrainingPathCountingEntity[]): TrainingPathCountingEntity[] {
      return users.filter(u => this.legendSelector[u.state] === true);
    }
  }
});

