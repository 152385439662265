
































































































































































import Vue from 'vue';
import {
  getEmptyMediation,
  getMediationById,
  getMyTracking,
  updateTrackingPeriods,
  saveMediationReport,
  setMediationStatus
} from '../repository';
import EditTrackingPeriod, { FinishedTrackingPeriodModel } from '../components/mediations/EditTrackingPeriod.vue';
import {
  TrackingPeriodModel,
  TrackingActionResult,
  MediationReport,
  MediationReportItem,
  ReportItemType,
  ReportItemRelatedEntity,
  RequestedMediationStatus
} from '../../_GeneratedClients/SpotClient';
import moment, { Moment } from 'moment';
import eventBus from '../eventBus';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

interface TrackingPeriodViewModel {
  trackingPeriod: FinishedTrackingPeriodModel;
  hasError: boolean;
}
interface TrackingActionViewModel { action: TrackingActionResult; checked: boolean }

export default Vue.extend({

  name: 'EditMediationReport',

  components: {
    EditTrackingPeriod,
    SimplePageLayout
  },

  data: () => ({
    parentMediation: getEmptyMediation(),
    comment: '',
    trackingPeriods: Array<TrackingPeriodViewModel>(),
    selectableActions: Array<TrackingActionViewModel>(),
    showReloadPeriodButton: false,
    hideUnselectedActions: false,
    showAddPeriodForm: false,
    newPeriod: {
      trackingPeriod: { start: moment().startOf('minute'), stop: moment().endOf('minute') } as FinishedTrackingPeriodModel,
      hasError: false
    } as TrackingPeriodViewModel,
    periodsError: '',
    globalError: '',
    showValidationConfirmationForm: false
  }),

  async created () {
    const mediationId = this.$route.params.mediationId;
    try {
      this.parentMediation = { ...await getMediationById(mediationId) };
      await this.initParameters();
    } catch (err) {
      console.error(err);
      this.$router.push({ name: 'NotFound' });
    }
  },

  methods: {
    async initParameters () {
      let oldSelectedActionIds = [] as string[];
      if (this.parentMediation.reporting) {
        // get comment
        this.comment = this.parentMediation.reporting.comment;
        // get selected tracking actions
        oldSelectedActionIds = this.parentMediation.reporting.items.map(i => i.trackingId).filter(t => t) as string[];
      }
      this.trackingPeriods = this.parentMediation.trackingPeriods.map(period => ({
        trackingPeriod: {
          start: period.start,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          stop: period.stop!
        },
        hasError: false
      }));
      await this.refreshSelectableActions(oldSelectedActionIds);
    },
    addPeriod () {
      this.newPeriod = {
        trackingPeriod: { start: moment().startOf('minute'), stop: moment().endOf('minute') },
        hasError: false
      } as TrackingPeriodViewModel;
      this.showAddPeriodForm = true;
    },
    onInsertPeriodClicked () {
      // check if there is no error
      if (this.newPeriod.hasError || this.newPeriodError) {
        return;
      }
      this.showAddPeriodForm = false;
      let insertPoint = 0;
      for (const period of this.trackingPeriods) {
        if (this.newPeriod.trackingPeriod.start.isBefore(period.trackingPeriod.start)) {
          break;
        }
        insertPoint++;
      }
      this.trackingPeriods.splice(insertPoint, 0, this.newPeriod); // Insert the new period at the correct place
    },
    deletePeriod (idx: number) {
      this.trackingPeriods.splice(idx, 1);
    },
    async refreshSelectableActions (oldSelectedActionIds: string[]) {
      const oldSelectedActions = new Set(oldSelectedActionIds);
      const newSelectableActions = [];
      for (const period of this.trackingPeriods) {
        const actions = await getMyTracking(period.trackingPeriod.start, period.trackingPeriod.stop);
        newSelectableActions.push(...actions.map(action => ({
          action: action,
          checked: oldSelectedActions.has(action.id)
        })));
      }
      this.selectableActions = newSelectableActions;
      this.showReloadPeriodButton = false;
    },
    async getTrackedActions () {
      if (this.trackingPeriods.some((t) => t.hasError)) {
        this.periodsError = this.$t('atLeastOneErrorLeft').toString();
        return;
      }
      await this.refreshSelectableActions(this.selectableActions.filter((a) => a.checked).map((a) => a.action.id));
    },
    formattedDate (date: Moment): string {
      return date.format(this.$t('dateFormat.dateTimePattern').toString());
    },
    formattedMode (mode: 'R' | 'W'): string {
      return mode === 'R' ? this.$t('read').toString() : this.$t('write').toString();
    },
    toggleActionSelection (action: TrackingActionViewModel) {
      action.checked = !action.checked;
    },
    toggleAllActionSelections (selectAll: boolean) {
      for (const action of this.selectableActions) {
        action.checked = selectAll;
      }
    },
    getPeriodMinDate (index: number) {
      if (index === 0) {
        return null;
      } else {
        return this.trackingPeriods[index - 1].trackingPeriod.stop;
      }
    },
    getPeriodMaxDate (index: number) {
      if (index === this.trackingPeriods.length - 1) {
        return null;
      } else {
        return this.trackingPeriods[index + 1].trackingPeriod.start;
      }
    },
    async saveReport (): Promise<boolean> {
      // check if there is no date error
      if (this.trackingPeriods.some((p) => p.hasError)) {
        this.periodsError = '';
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        return false;
      }
      // check if at least one action is selected or if there is a comment
      if ((this.showReloadPeriodButton || !this.selectableActions.some((a) => a.checked)) && this.comment.trim() === '') {
        this.globalError = this.$t('commentOrActionSelectionNeeded').toString();
        return false;
      }
      try {
        // save tracking periods
        if (this.parentMediation.trackingPeriods.length !== this.trackingPeriods.length ||
          !this.parentMediation.trackingPeriods.every((p:TrackingPeriodModel, i) =>
            p.start.isSame(this.trackingPeriods[i].trackingPeriod.start) &&
            p.stop && p.stop.isSame(this.trackingPeriods[i].trackingPeriod.stop))) {
          // Tracking period has changed
          const newTrackingPeriods = this.trackingPeriods.map((p) => p.trackingPeriod);
          await updateTrackingPeriods(this.parentMediation.id, newTrackingPeriods);
          this.parentMediation.trackingPeriods = this.trackingPeriods.map((p) => p.trackingPeriod);
        }

        // create mediation report
        const mediationReport: MediationReport = {
          comment: this.comment,
          items: Array<MediationReportItem>()
        };
        for (const track of this.selectableActions.filter((a) => a.checked)) {
          const entityType = this.$t('entityType.' + track.action.entityType.toString());
          const content = `${this.dateToText(track.action.date)} : ${this.formattedMode(track.action.mode)} ${entityType} "${track.action.entityName}"`;
          mediationReport.items.push({
            itemType: ReportItemType.Tracking,
            trackingId: track.action.id,
            relatedEntity: { entityType: track.action.entityType, id: track.action.entityKey } as ReportItemRelatedEntity,
            date: track.action.date,
            content: content
          } as MediationReportItem);
        }
        // save mediation report
        await saveMediationReport(this.parentMediation.id, mediationReport);
        return true;
      } catch (err) {
        eventBus.$emit('error', err, null);
        return false;
      }
    },
    async onSaveClicked () {
      if (await this.saveReport()) {
        // go back to view mediation
        this.$router.push({ name: 'ViewMediation', params: { mediationId: this.parentMediation.id } });
      }
    },
    async onSaveAndValidateClicked () {
      if (await this.saveReport()) {
        this.showValidationConfirmationForm = true;
      }
    },
    async onConfirmValidationClicked () {
      this.showValidationConfirmationForm = false;
      try {
        // change mediation status -> validated
        await setMediationStatus(this.parentMediation.id, { status: RequestedMediationStatus.Validated });
        this.$router.push({ name: 'ViewMediation', params: { mediationId: this.parentMediation.id } });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    dateToText (date: Moment): string {
      return date.format(this.$t('dateFormat.dateTimePattern').toString());
    }
  },

  watch: {
    comment (): void {
      this.periodsError = '';
      this.globalError = '';
    },
    trackingPeriods: {
      deep: true,
      handler: function () {
        this.periodsError = '';
        this.globalError = '';
        this.showReloadPeriodButton = true;
      }
    },
    selectableActions: {
      deep: true,
      handler: function () {
        this.periodsError = '';
        this.globalError = '';
      }
    }
  },

  computed: {
    trackedActions (): TrackingActionViewModel[] {
      return this.hideUnselectedActions ? this.selectableActions.filter((a) => a.checked === true) : this.selectableActions;
    },
    allActionsSelected (): boolean {
      return this.selectableActions.every(a => a.checked === true);
    },
    // eslint-disable-next-line jsdoc/require-returns
    /**
     * The error that is shown as an error in the newPeriod form, in addition to start/stop ordering rules (which are displayed inside the start/stop pickers directly)
     *
     * @returns the error message, or null if everything is ok
     */
    newPeriodError (): string | null {
      if (this.showAddPeriodForm) {
        for (const period of this.trackingPeriods) {
          if (period.trackingPeriod.start.isBefore(this.newPeriod.trackingPeriod.stop) && period.trackingPeriod.stop.isAfter(this.newPeriod.trackingPeriod.start)) {
            return this.$t('trackingPeriodDateConflict').toString();
          }
        }
      }
      return null;
    }
  }
});

