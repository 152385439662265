













import Vue, { PropType } from 'vue';
import DisplayValue from '../common/DisplayValue.vue';
import { Location } from 'vue-router';
import { getTrainingPathProgressLegendColor, TrainingPathCountingEntity } from '../../repository';

export default Vue.extend({

  name: 'DisplayTrainingPathProgressCounter',

  components: {
    DisplayValue
  },

  props: {
    counter: { type: Object as PropType<TrainingPathCountingEntity>, required: true },
    border: { type: Boolean, default: false },
    link: { type: Object as PropType<Location | undefined>, default: undefined }
  },

  computed: {
    bgColor (): string {
      return getTrainingPathProgressLegendColor(this.counter.state);
    },
    progressValue (): string {
      return this.counter.progress.length === 0 ? '0 %' : Math.round(this.counter.progress.reduce((acc, curVal) => { return acc + curVal; }, 0) / this.counter.progress.length) + ' %';
    }
  }
});

