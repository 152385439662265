




























































































































































































































import Vue from 'vue';
import { AssignedTrainingModuleStatus, TrainingPathModel, TrainingPathStatus, UserModel } from '../../_GeneratedClients/SpotClient';
import { getConnectedUser, queryAssignedTrainingModules, queryMediations, queryPaths } from '../repository';
import ChartMyContributions from '../components/dashboards/ChartMyContributions.vue';
import moment, { Moment } from 'moment';
import { querySubjects } from '../queryEngine/querySubjects';
import eventBus from '../eventBus';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

interface Todo { id: string; name: string; type: string; waitFor: string; role: string }

export default Vue.extend({

  name: 'MyDashboard',

  components: {
    ChartMyContributions,
    SimplePageLayout
  },

  data: () => ({
    loading: true,
    me: {} as UserModel,
    myCurrentTrainingPathList: [] as TrainingPathModel[],
    subjectTodoList: [] as Todo[],
    mediationTodoList: [] as Todo[],
    trainingPathModuleTodoList: [] as Todo[],
    trainingPathTodoList: [] as Todo[],
    periodicModuleTodoList: [] as Todo[]
  }),

  async mounted () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
    }
    try {
      const today = moment().format('YYYY-MM-DD');
      let myCurrentTrainingPathsFilter = '(learner oneOf ' + this.me.id; // connected user identifier
      // myCurrentTrainingPathsFilter += ' & start_date beforeOrSameDay ' + today; // training path start date must be before or same day than today
      myCurrentTrainingPathsFilter += ' & stop_date afterOrSameDay ' + today; // traning path stop date must be after or same day than today
      myCurrentTrainingPathsFilter += ' & status noneOf canceled)'; // traning path must not have been canceled
      const list = await queryPaths({ query: myCurrentTrainingPathsFilter });
      this.myCurrentTrainingPathList = list.results;
    } catch (err) {
      eventBus.$emit('error', err, null);
    }

    // see if there is something to do
    // *** Subjects (followed by me and not yet resolved)
    try {
      const filter = `followed_by oneOf ${this.me.id} & resolved equals false`;
      const subjectList = await querySubjects({ query: filter });
      for (const subject of subjectList.results) {
        this.addTodo(this.subjectTodoList, subject.id, subject.name, 'subject', 'awaitingResolution', '');
      }
    } catch (err) {
      eventBus.$emit('error', err, null);
    }

    // *** mediations
    try {
      // 1 - i am participant and i have to sign
      let query = '(status oneOf validated & participants oneOf ' + this.me.id + ')';
      let mediationList = (await queryMediations({ query: query })).results;
      for (const mediation of mediationList) {
        if (mediation.signatures.every((s) => s.userId !== this.me.id)) {
          // not yet signed by me
          this.addTodo(this.mediationTodoList, mediation.id, mediation.title, 'mediation', 'awaitingSignature', 'participant');
        }
      }
      // 2 - i am mediator and i have to validate
      query = '(status oneOf stopped & mediator oneOf ' + this.me.id + ')';
      mediationList = (await queryMediations({ query: query })).results;
      for (const mediation of mediationList) {
        this.addTodo(this.mediationTodoList, mediation.id, mediation.title, 'mediation', 'awaitingValidation', 'mediator');
      }
    } catch (err) {
      eventBus.$emit('error', err, null);
    }

    // *** training path modules
    try {
      // 1 - i am referent and i have to validate a module
      let query = '(status noneOf canceled & module_status oneOf finished & module_status noneOf disabled)';
      let pathList = (await queryPaths({ query: query })).results;
      for (const path of pathList) {
        if (path.assignedModules.some((am) => am.referents.some((r) => r.id === this.me.id))) {
          // i am referent on at least one module for this training path
          this.addTodo(this.trainingPathModuleTodoList, path.id, path.title, 'trainingModule', 'awaitingClosure', 'referent');
        }
      }
      // 2 - i am the learner and i have to sign a module
      query = '(status noneOf canceled & module_status oneOf validated & module_status noneOf disabled & learner oneOf ' + this.me.id + ')';
      pathList = (await queryPaths({ query: query })).results;
      for (const path of pathList) {
        this.addTodo(this.trainingPathModuleTodoList, path.id, path.title, 'trainingModule', 'awaitingSignature', 'learner');
      }
    } catch (err) {
      eventBus.$emit('error', err, null);
    }

    // *** training paths
    try {
      // 1 - i am the learner and i have to accept training path (if stop date isn't in past)
      const toDay = moment().startOf('day');
      let query = '(status oneOf notAccepted & learner oneOf ' + this.me.id + ')';
      let pathList = (await queryPaths({ query: query })).results;
      for (const path of pathList) {
        if (path.stopDate.isSameOrAfter(toDay)) {
          this.addTodo(this.trainingPathTodoList, path.id, path.title, 'trainingPath', 'awaitingAcceptance', 'learner');
        }
      }
      // 2 - i am the creator and i have to validate training path
      query = '(status oneOf finished & creator oneOf ' + this.me.id + ')';
      pathList = (await queryPaths({ query: query })).results;
      for (const path of pathList) {
        this.addTodo(this.trainingPathTodoList, path.id, path.title, 'trainingPath', 'awaitingClosure', 'creator');
      }
      // 3 - i am the learner and i have to sign training path
      query = '(status oneOf validated & learner oneOf ' + this.me.id + ')';
      pathList = (await queryPaths({ query: query })).results;
      for (const path of pathList) {
        this.addTodo(this.trainingPathTodoList, path.id, path.title, 'trainingPath', 'awaitingSignature', 'learner');
      }
    } catch (err) {
      eventBus.$emit('error', err, null);
    }

    // *** search for periodic modules in all my training paths with closing date later than today minus 2 months
    try {
      const now = moment().startOf('day');
      const displayLimit = moment().startOf('day').add(-2, 'months');
      const warningLimit = moment().startOf('day').add(1, 'months');
      let query = '(learner oneOf ' + this.me.id + ' & status noneOf disabled & path_is_canceled equals false & ';
      query += 'grading_valid_until afterOrSameDay ' + displayLimit.format('YYYY-MM-DD') + ')';
      const moduleList = await queryAssignedTrainingModules({ query: query });
      // Sort modules by expiration date. validUntil is an ISO date string, so we can compare dates as strings
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      moduleList.sort((a, b) => a.assignedModule.grading!.validUntil!.localeCompare(b.assignedModule.grading!.validUntil!, 'en'));
      for (const module of moduleList.map((am) => am.assignedModule)) {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const validUntil = moment(module.grading!.validUntil!, 'YYYY-MM-DD');
        const validity = this.$t('expirationDate') + ' : ' + validUntil.format(this.$t('dateFormat.pattern').toString());
        let diffDays = validUntil.diff(now, 'days', false);
        let remaining;
        let status;
        if (diffDays < 0) {
          // outdated
          remaining = this.$t('outdatedFor') + ' ' + (-diffDays) + ' ' + this.$t('days');
          status = 'secondary';
        } else {
          diffDays++; // to adjust interval
          remaining = this.$t('stillValidFor') + ' ' + diffDays + ' ' + this.$t('days');
          status = validUntil.isSameOrAfter(warningLimit) ? 'bgSuccess' : 'bgError';
        }
        this.periodicModuleTodoList.push({ id: '', name: module.module.name, type: validity, waitFor: remaining, role: status } as Todo);
      }
    } catch (err) {
      eventBus.$emit('error', err, null);
    }
    this.loading = false;
  },

  methods: {
    addTodo (list: Todo[], id: string, name: string, type: string, messageType: string, role: string): void {
      list.push({
        id: id,
        name: name,
        type: type,
        waitFor: this.$t(messageType).toString(),
        role: role
      } as Todo);
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDisplayableCompletion (path: TrainingPathModel): string {
      if (path.totalSubModuleItems === 0) { // would never occur, but to avoid "divide by 0"
        return this.$t('notQuantifiable').toString();
      }
      return Math.round(path.doneSubModuleItems / path.totalSubModuleItems * 100) + ' %';
    },
    getStatusColor (status: TrainingPathStatus): string {
      switch (status) {
        case TrainingPathStatus.NotAccepted:
        case TrainingPathStatus.Accepted:
          return 'secondary--text';
        case TrainingPathStatus.Started:
        case TrainingPathStatus.Finished:
          return 'accent--text';
        case TrainingPathStatus.Validated:
        case TrainingPathStatus.Archived:
          return 'success--text';
        default:
          return 'secondary--text';
      }
    },
    getBgStatusColor (status: TrainingPathStatus): string {
      switch (status) {
        case TrainingPathStatus.NotAccepted:
        case TrainingPathStatus.Accepted:
          return 'bgDisabled';
        case TrainingPathStatus.Started:
        case TrainingPathStatus.Finished:
          return 'bgAccent';
        case TrainingPathStatus.Validated:
        case TrainingPathStatus.Archived:
          return 'bgSuccess';
        default:
          return 'bgDisabled';
      }
    },
    getAverageGrade (path: TrainingPathModel): number {
      const availableModules = path.assignedModules.filter(am => am.status !== AssignedTrainingModuleStatus.Disabled);
      if (availableModules.length === 0) {
        return 0;
      }
      let sum = 0;
      for (const module of availableModules) {
        if (module.grading !== undefined) {
          sum += module.grading.grade;
        }
      }
      const average = sum / availableModules.length;
      return Math.round(average * 10) / 10;
    },
    getSuccessColor (path: TrainingPathModel): string {
      return this.getAverageGrade(path) >= path.passThreshold ? 'success--text' : 'error--text';
    },
    getLink (todo: Todo) {
      switch (todo.type) {
        case 'trainingPath':
        case 'trainingModule':
          return { name: 'ViewTrainingPath', params: { pathId: todo.id } };
        case 'mediation':
          return { name: 'ViewMediation', params: { mediationId: todo.id } };
        case 'subject':
          return { name: 'Subject', params: { subjectId: todo.id } };
        default:
          return { name: 'notFound' };
      }
    }
  }
});

