







































import Vue from 'vue';
import { TrainingPathModel, UserModel } from '../../_GeneratedClients/SpotClient';
import {
  getConnectedUser,
  getInitialTrainingPathProgressLegend,
  getTrainingPathProgressState,
  getUsersGroups,
  getUsersIdByGroups,
  isPermissionGranted,
  queryPaths,
  TrainingPathCountingEntity,
  TrainingPathGroupEntity
} from '../repository';
import { sortArrayByNumber } from '../Tools';
import { Location } from 'vue-router';
import DisplayTrainingPathProgressCounter from '../components/dashboards/DisplayTrainingPathProgressCounter.vue';
import DisplayTrainingPathProgressLegend from '../components/dashboards/DisplayTrainingPathProgressLegend.vue';
import SimplePageLayout from '../components/presentation/SimplePageLayout.vue';

export default Vue.extend({

  name: 'GroupTrainingPathDashboard',

  components: {
    DisplayTrainingPathProgressCounter,
    DisplayTrainingPathProgressLegend,
    SimplePageLayout
  },

  data: () => ({
    computing: true,
    group: { group: { id: '', name: '', progress: [], average: 0, state: 'low' }, users: [] } as TrainingPathGroupEntity,
    legendSelector: getInitialTrainingPathProgressLegend(),
    me: {} as UserModel,
    trainingPathList: [] as TrainingPathModel[]
  }),

  async mounted () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
    }
    if (!(await isPermissionGranted('perm_TableauBord'))) {
      // user is not allowed to see this page -> redirect to forbidden page
      this.$router.replace({ path: '/forbidden' });
    }
    const groupId = this.$route.params.groupId ?? '';
    // get the list of users for this group
    const userIdList = await getUsersIdByGroups([groupId]);
    if (userIdList.length === 0) {
      console.error('group id ' + groupId + ' : no users found for this group');
      this.$router.replace('/NotFound');
    }
    // get group name (now, we have just group identifier)
    const idRef = userIdList[0]; // arbitrary, first user of this group
    const groupInfo = await getUsersGroups([idRef]); // list of groups for this user
    const groupFound = groupInfo[idRef].find((g) => g.id === groupId);
    if (groupFound === undefined) {
      console.error('unable to find group information from user id : ' + idRef);
      this.$router.replace('/NotFound');
    } else {
      this.group.group.id = groupFound.id;
      this.group.group.name = groupFound.name;
    }
    // get training path list
    const query = '(status noneOf canceled,archived & learner oneOf ' + userIdList.join(',') + ')';
    this.trainingPathList = (await queryPaths({ query: query })).results;
    // computes counters
    for (const path of this.trainingPathList) {
      // for each training path
      const pathProgress = path.totalSubModuleItems === 0 ? 0 : Math.round(path.doneSubModuleItems / path.totalSubModuleItems * 100);
      this.group.group.progress.push(pathProgress);
      const affectedUser = this.group.users.find((u) => u.id === path.learner.id);
      if (affectedUser === undefined) {
        // user is not yet registred -> create it
        this.group.users.push({ id: path.learner.id, name: path.learner.name, progress: [pathProgress], average: 0, state: 'low' });
      } else {
        // user already registred -> update progress
        affectedUser.progress.push(pathProgress);
      }
    }
    // computes averages
    // note : group.group.progress.length and user.progress.length can't be zero (no risk when dividing by these values)
    this.group.group.average = Math.round(this.group.group.progress.reduce((acc, curVal) => { return acc + curVal; }, 0) / this.group.group.progress.length);
    for (const user of this.group.users) {
      user.average = Math.round(user.progress.reduce((acc, curVal) => { return acc + curVal; }, 0) / user.progress.length);
      user.state = getTrainingPathProgressState(user.average);
    }
    // sort user list from smallest average to largest
    sortArrayByNumber(this.group.users as TrainingPathCountingEntity[], (u) => u.average);
    this.computing = false;
  },

  methods: {
    getUserPathLink (userId: string): Location {
      const filter = '(status noneOf canceled,archived & learner oneOf ' + userId + ')';
      return { name: 'FilteredTrainingPaths', params: { filter: filter } };
    },
    filteredUsers (users: TrainingPathCountingEntity[]): TrainingPathCountingEntity[] {
      return users.filter(u => this.legendSelector[u.state] === true);
    }
  }
});

