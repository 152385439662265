






































import { getEmptyTrainingReference } from '../../repository';
import Vue, { PropType } from 'vue';
// eslint-disable-next-line camelcase
import { QueryRequest, QueryResponse_TrainingReferenceModel_, TrainingReferenceModel } from '../../../_GeneratedClients/SpotClient';
import TrainingReferenceFieldsConfiguration from '../../fieldsConfiguration/TrainingReferenceFieldsConfiguration';
import FilteredListPage from '../filters/FilteredListPage.vue';
import AddOrEditTrainingReference from '../references/AddOrEditTrainingReference.vue';

export default Vue.extend({

  name: 'TrainingReferences',

  components: {
    FilteredListPage,
    AddOrEditTrainingReference
  },

  props: {
    referenceType: { type: String, default: undefined, required: true },
    // eslint-disable-next-line camelcase
    query: { type: Function as PropType<(query: QueryRequest) => Promise<QueryResponse_TrainingReferenceModel_>>, required: true }
  },

  data: () => ({
    fieldsConfiguration: TrainingReferenceFieldsConfiguration,
    routeName: '',
    title: '',
    displayReferenceEdition: false,
    referenceToEdit: getEmptyTrainingReference()
  }),

  created () {
    switch (this.referenceType) {
      case 'module':
        this.routeName = 'FilteredTrainingModuleReferences';
        this.title = this.$t('trainingModuleReferences').toString();
        break;
      case 'path':
        this.routeName = 'FilteredTrainingPathReferences';
        this.title = this.$t('trainingPathReferences').toString();
        break;
    }
  },

  methods: {
    editReference (trainingReference: TrainingReferenceModel): void {
      this.referenceToEdit = { ...trainingReference };
      this.displayReferenceEdition = true;
    },
    closeEdit (): void {
      this.displayReferenceEdition = false;
      this.referenceToEdit = getEmptyTrainingReference();
    }
  }
});

