





















































































import eventBus from '../../../eventBus';
import { createResource, updateResource } from '../../../repository';
import Vue, { PropType } from 'vue';
import { IdAndNameModel, ResourceModel, ResourceParentType, ResourceType } from '../../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'AddOrEditResource',

  props: {
    showAddOrEditResourceForm: { type: Boolean, default: false },
    width: { type: Number, default: 600 },
    resource: { type: Object as PropType<ResourceModel>, default: () => ({ id: '', name: '', resourceType: ResourceType.Image, link: '', comment: '' }) },
    parentType: { type: String, required: true },
    parentId: { type: String, required: true }
  },

  data: () => ({
    resourceTypes: [] as IdAndNameModel[],
    selectedType: {} as IdAndNameModel,
    resourceToEdit: undefined as ResourceModel | undefined,
    resourceFile: null as File | null,
    globalError: '',
    nameError: '',
    uploadError: '',
    linkError: '',
    youtubeId: '',
    saveButtonDisabled: false
  }),

  created () {
    // initialize resource types selector
    for (let type in ResourceType) {
      type = type.toLowerCase();
      this.resourceTypes.push({ id: type, name: this.$t('resourceTypes.' + type).toString() });
    }
    this.initForm();
  },

  methods: {
    initForm (): void {
      this.globalError = '';
      this.saveButtonDisabled = false;
      this.resourceFile = null;
      this.resourceToEdit = { ...this.resource };
      const foundType = this.resourceTypes.find(t => t.id === this.resourceToEdit?.resourceType);
      this.selectedType = foundType === undefined ? this.resourceTypes[0] : foundType;
      if (this.selectedType.id === ResourceType.Youtube) {
        // only Youtube video id is registered, but user must enter an entire and valid Youtube video URL. Then, recreate URL from id
        this.resourceToEdit.link = 'https://www.youtube.com/watch?v=' + this.resource.link;
      }
      this.validate();
    },
    async saveResource (): Promise<void> {
      this.saveButtonDisabled = true;
      if (this.nameError !== '' || // name is required for all resource types
        (this.resourceIsFile && this.uploadError !== '') || // upload must be ok for a file type (image, pdf and file), but just for creation
        (this.resourceIsLink && this.linkError !== '')) { // link is required for a non file type
        this.globalError = this.$t('atLeastOneErrorLeft').toString();
        this.saveButtonDisabled = false;
        return;
      }
      // assume that parentType, parentId and resourceToEdit are set
      try {
        if (!this.resourceToEdit) {
          return;
        }
        const link = this.selectedType.id === ResourceType.Youtube ? this.youtubeId : this.resourceToEdit.link;
        if (this.edit) {
          await updateResource(
            this.parentType as ResourceParentType,
            this.parentId,
            this.resourceToEdit.id,
            { name: this.resourceToEdit.name, link: link, comment: this.resourceToEdit.comment }
          );
        } else {
          await createResource(
            this.parentType as ResourceParentType,
            this.parentId,
            this.selectedType.id as ResourceType,
            encodeURIComponent(this.resourceToEdit.name),
            encodeURIComponent(link),
            this.resourceToEdit.comment === undefined ? undefined : encodeURIComponent(this.resourceToEdit.comment),
            this.resourceFile ?? undefined
          );
        }
        this.$emit('updated');
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        this.saveButtonDisabled = false;
      }
    },
    validate (): void {
      this.nameValidator();
      if (this.resourceIsFile) {
        this.fileValidator();
      } else if (this.resourceIsLink) {
        this.linkValidator();
      }
    },
    nameValidator (): void {
      this.nameError = '';
      if (this.resourceToEdit !== undefined) {
        if (this.resourceToEdit.name !== this.resourceToEdit.name.trim()) { // whitespace(s) detected at the beginning or end of text
          this.nameError = this.$t('beginEndWhitespaceError').toString();
        } else if (this.resourceToEdit.name.length === 0) { // name can't be empty
          this.nameError = this.$t('emptyFieldError').toString();
        }
      }
    },
    fileValidator (): void {
      this.uploadError = '';
      if (this.edit) {
        return;
      }
      if (this.resourceToEdit !== undefined) {
        if (this.resourceFile === null) {
          this.uploadError = this.$t('emptyFieldError').toString();
        } else if ((this.selectedType.id === ResourceType.Image && this.resourceFile.type !== 'image/jpeg' && this.resourceFile.type !== 'image/png') ||
          (this.selectedType.id === ResourceType.Pdf && this.resourceFile.type !== 'application/pdf')) {
          this.uploadError = this.$t('badFileFormat').toString();
        } else if (this.resourceFile.size > 30 * 1000 * 1000) { // 30MB, don't forget to change the translation below if you change this
          this.uploadError = this.$t('fileTooBig').toString();
        } else {
          // original file name is registered in link field
          this.resourceToEdit.link = this.resourceFile.name;
        }
      }
    },
    linkValidator (): void {
      this.linkError = '';
      if (this.resourceToEdit !== undefined) {
        if (this.resourceToEdit.link !== this.resourceToEdit.link.trim()) { // whitespace(s) detected at the beginning or end of text
          this.linkError = this.$t('beginEndWhitespaceError').toString();
        } else if (this.resourceToEdit.link.length === 0) { // can't be empty
          this.linkError = this.$t('emptyFieldError').toString();
        } else if (this.selectedType.id === ResourceType.Link && !this.resourceToEdit.link.match(/^https?:\/\//)) {
          this.linkError = this.$t('urlMustBeginBy').toString();
        } else if (this.selectedType.id === ResourceType.Youtube) {
          // control youtube link validity, inspired by https://gist.github.com/deunlee/0b45cfacb7e8f788e5bbfa2911f54d3e
          const reg = /^(?:https:\/\/)?(?:(?:www\.|m\.)?youtube\.com\/(?:(?:watch)?\?(?:feature=\w*&)?vi?=|embed\/|vi?\/|e\/)|youtu.be\/)([\w-]{10,20})/i;
          const match = this.resourceToEdit.link.match(reg);
          if (!match) {
            this.linkError = this.$t('notValidYoutubeUrl').toString();
          } else {
            // extract video id from youtube URL (must not be empty)
            this.youtubeId = match[1];
            if (this.youtubeId.length === 0) {
              this.linkError = this.$t('notValidYoutubeUrl').toString();
            }
          }
        }
      }
    }
  },

  computed: {
    edit (): boolean {
      return this.resource.id !== '';
    },
    resourceIsFile (): boolean {
      return ['image', 'pdf', 'file'].includes(this.selectedType.id);
    },
    resourceIsLink (): boolean {
      return ['link', 'youtube'].includes(this.selectedType.id);
    },
    acceptFileType (): string {
      switch (this.selectedType.id) {
        case 'image': return 'image/jpeg, image/png';
        case 'pdf': return 'application/pdf';
        case 'file': return '*';
        default: return '';
      }
    },
    linkFieldName (): string {
      switch (this.selectedType.id) {
        case 'image':
        case 'pdf':
        case 'file':
          return this.$t('fileName').toString();
        case 'link':
          return this.$t('url').toString();
        case 'youtube':
          return this.$t('youtubeUrl').toString();
        default:
          return '???';
      }
    }
  },

  watch: {
    selectedType (): void {
      if (this.resourceIsFile) {
        this.linkError = '';
      }
      this.validate();
    },
    showAddOrEditResourceForm (): void {
      if (this.showAddOrEditResourceForm) {
        this.initForm();
      }
    }
  }

});

