






























import Vue, { PropType } from 'vue';
import moment, { Moment } from 'moment';

/**
 * The type of error that the date picker emits
 */
export enum DatePickerErrorType {
  /**
   * The input value failed to parse
   */
  parse = 'parse',

  /**
   * The selected value is less than the minimum date
   */
  lessThanMin = 'lessThanMin',

  /**
   * The selected value is greater than the maximum value
   */
  greaterThanMax = 'greaterThanMax'
}

export default Vue.extend({
  name: 'DatePicker',

  props: {
    label: { type: String, default: function () { return this.$t('defaultLabel'); } },
    value: { type: moment, required: true },
    min: { type: Object as PropType<Moment | null>, default: null },
    max: { type: Object as PropType<Moment | null>, default: null },
    readOnly: { type: Boolean, default: false },
    compact: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false }
  },

  data: () => ({
    inputDate: '',
    currentError: null as DatePickerErrorType | null,
    showPicker: false
  }),

  methods: {
    getBgColor (): string {
      if (this.readOnly === true) {
        return 'bgDisabled';
      }
      return this.displayedError === '' ? 'bgAccent' : 'bgError';
    },
    onDateChanged (): void {
      this.checkDate(this.parseUserDate(this.inputDate), true);
    },
    checkDate (date: Moment, emitInputEvent: boolean): void {
      if (date.isValid()) {
        if (emitInputEvent) {
          this.$emit('input', date);
        }
        if (this.min && date.diff(this.min) < 0) {
          this.currentError = DatePickerErrorType.lessThanMin;
        } else if (this.max && this.max.diff(date) < 0) {
          this.currentError = DatePickerErrorType.greaterThanMax;
        } else {
          this.currentError = null;
        }
      } else {
        this.currentError = DatePickerErrorType.parse;
      }
    },
    displayPicker (): void {
      this.$emit('focus'); // clicked on append-icon
      this.showPicker = true;
    },
    pickerDateChanged (newPickerDate: string): void {
      const parsedDate = moment(newPickerDate, 'YYYY-MM-DD');
      this.checkDate(parsedDate, true);
    },
    formatUserDate (date: Moment): string {
      return date.format(this.$t('datepattern').toString());
    },
    parseUserDate (valueToParse: string): Moment {
      return moment(valueToParse, this.$t('datepattern').toString());
    }
  },

  watch: {
    value: {
      immediate: true,
      handler: function (newValue: Moment): void {
        this.inputDate = this.formatUserDate(newValue);
        this.checkDate(this.value, false);
      }
    },
    min (): void {
      this.checkDate(this.value, false);
    },
    max (): void {
      this.checkDate(this.value, false);
    },
    currentError: {
      immediate: true,
      handler: function (newError: DatePickerErrorType | null): void {
        this.$emit('error-changed', newError);
      }
    }
  },

  computed: {
    pickerDate () : string {
      return this.value.format('YYYY-MM-DD');
    },
    displayedError () : string {
      switch (this.currentError) {
        case DatePickerErrorType.parse: return this.$t('errors.parse').toString();

        // The two errors below can only be triggered if min or max are not null.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        case DatePickerErrorType.lessThanMin: return this.$t('errors.lessThanMin', { min: this.formatUserDate(this.min!) }).toString();
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        case DatePickerErrorType.greaterThanMax: return this.$t('errors.greaterThanMax', { max: this.formatUserDate(this.max!) }).toString();
        default: return '';
      }
    }
  }
});

