



















import Vue from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';
import { FieldType, BooleanComparisonOperator, DateComparisonOperator, StringComparisonOperator, EnumComparisonOperator } from '../../queryEngine';

export default Vue.extend({

  name: 'QueryOperatorSelector',

  props: {
    type: { type: String, required: true },
    operator: { type: String, default: undefined }
  },

  data: () => ({
    operatorList: [] as IdAndNameModel[],
    selectedOperator: undefined as IdAndNameModel | undefined
  }),

  created () {
    this.initOperatorList();
  },

  methods: {
    initOperatorList () {
      let options: string[];
      switch (this.type as FieldType) {
        case FieldType.string: options = Object.values(StringComparisonOperator); break;
        case FieldType.boolean: options = Object.values(BooleanComparisonOperator); break;
        case FieldType.date: options = Object.values(DateComparisonOperator); break;
        case FieldType.enum:
        case FieldType.id: options = Object.values(EnumComparisonOperator); break;
      }
      this.operatorList = options.map((opt) => {
        return { id: opt, name: this.$t('operators.' + opt).toString() };
      });
      this.selectOperator();
    },
    selectOperator () {
      const definedOperator = this.operatorList.find((o) => o.id === this.operator);
      if (definedOperator) { // a value is already defined
        this.selectedOperator = definedOperator;
      } else { // no defined value -> default value is first option
        this.selectedOperator = this.operatorList[0];
        this.operatorSelected(); // set this new value
      }
    },
    operatorSelected () {
      if (this.selectedOperator) {
        this.$emit('operator-selected', this.selectedOperator.id);
      }
    }
  },

  watch: {
    type: function () {
      this.initOperatorList();
    },
    operator: function () {
      this.selectOperator();
    }
  }
});

