import moment from 'moment';
import { DiscussionModel, PostMessageRequest } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';
import { reloadSubject } from './Search';
import { setEntityLastVisit } from './Visits';

/**
 * Creates an empty DiscussionModel
 *
 * @returns The created model
 */
export function getEmptyDiscussion (): DiscussionModel {
  return {
    id: '',
    contexts: [],
    messages: []
  };
}

/**
 * Gets a discussion given its identifier
 *
 * @param discussionId The discussion identifier to retrieve
 * @returns The discussion model
 */
export async function getDiscussion (discussionId: string): Promise<DiscussionModel> {
  const discussion = await client.getDiscussion(discussionId);
  for (const message of discussion.messages) {
    message.date = moment(message.date);
  }
  return discussion;
}

/**
 * Posts a message
 *
 * @param discussionId The discussion in which to post the message
 * @param body The message body
 * @param subjectId The subject identifier
 * @returns the promise
 */
export async function postMessage (discussionId: string, body: PostMessageRequest, subjectId: string): Promise<void> {
  await client.postMessage(discussionId, body);
  await reloadSubject(subjectId);
  setEntityLastVisit(subjectId, moment());
}
