import Vue from 'vue';
import Vuetify from 'vuetify';
import fr from 'vuetify/src/locale/fr';
import en from 'vuetify/src/locale/en';
import i18n from '../i18n';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr, en },
    current: i18n.locale
  },
  theme: {
    dark: false,
    options: { customProperties: true },
    themes: {
      light: {
        primary: '#0074C8',
        secondary: '#666666',
        accent: '#007193',
        error: '#FC724C',
        info: '#00ACDF',
        success: '#009933',
        warning: '#FFC107',
        bgAccent: '#E1EFF9',
        bgDisabled: '#F0F0F0',
        bgSuccess: '#B1FAB7',
        bgError: '#FFE8E8',
        bgUnread: '#FFF6C0',
        separator: '#DDDDDD'
      } // Later, we could add a dark key here
    }
  }
});
