





































import Vue from 'vue';
import { CartographyStatsModel } from '../../../_GeneratedClients/SpotClient';
import { getCartographyActivityPictos, getCartographyStats, getCartographyActivitiesCounters, CartographyActivityCounter } from '../../repository';

/**
 * Displays the counters for a cartography in the cartography list
 */
export default Vue.extend({

  name: 'CartographyCounters',

  props: {
    // The cartography identifier
    cartographyId: { type: String, required: true }
  },

  async created () {
    this.loadPictograms(); // Don't await this so it finishes in the background
    this.stats = await getCartographyStats(this.cartographyId);
    this.activities = await getCartographyActivitiesCounters(this.cartographyId);
    this.totalSubjects = this.activities.reduce((accumulator, currentValue) => accumulator + currentValue.subjectsCount, 0);
    this.dataLoaded = true;
  },

  data: () => ({
    dataLoaded: false,
    stats: {} as CartographyStatsModel,
    activities: [] as CartographyActivityCounter[],
    totalSubjects: 0,
    pictograms: {} as Record<string, string>
  }),

  methods: {
    async loadPictograms (): Promise<void> {
      this.pictograms = await getCartographyActivityPictos(this.cartographyId);
    }
  }
});

