























import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'QueryEnumSelector',

  props: {
    values: { type: Array as PropType<IdAndNameModel[]>, default: function () { return [] as IdAndNameModel[]; } },
    options: { type: Array as PropType<IdAndNameModel[]>, required: true }
  },

  data: () => ({
    selectedItems: [] as IdAndNameModel[]
  }),

  created () {
    this.setValues();
  },

  methods: {
    getBgColor (): string {
      return this.selectedItems.length === 0 ? 'bgError' : 'bgAccent';
    },
    setValues () {
      this.selectedItems = [] as IdAndNameModel[];
      let changed = false;
      // if value(s) is (are) already selected -> set selectedItems
      for (const value of this.values) {
        if (this.options.some((o) => o.id === value.id)) {
          this.selectedItems.push(value);
        } else {
          console.error(`Option not found : ${value.id}. Removing it from the selected items list.`);
          changed = true;
        }
      }
      if (changed) {
        this.listChanged();
      }
    },
    listChanged () {
      this.$emit('list-selected', this.selectedItems);
    },
    closeSelector () {
      (this.$refs.enumSelector as HTMLElement).blur();
      this.listChanged();
    }
  },

  watch: {
    values: function () {
      this.setValues();
    }
  }
});

