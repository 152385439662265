






















import Vue, { PropType } from 'vue';
import { ActivityModel, CartographyModel, ContextItemType, IdAndNameModel, SpotTrainingModuleItemModel } from '../../../_GeneratedClients/SpotClient';
import { ContextNodeViewModel, ContextViewModel, getContextViewModel, getSpotItemById, getSubjectsFromFunction, ISpotItem } from '../../repository';
import DisplayModuleSpotSource from '../common/DisplayModuleSpotSource.vue';

export default Vue.extend({

  name: 'DisplayTrainingModuleSpotElement',

  components: {
    DisplayModuleSpotSource
  },

  props: {
    element: { type: Object as PropType<SpotTrainingModuleItemModel>, required: true }
  },

  data: () => ({
    /** the parent spot item (can be site, cartography, function, activity or subject) */
    parent: undefined as ISpotItem | undefined,
    /** the subject(s) attached or under the given element */
    subjectList: [] as IdAndNameModel[],
    /** context of the given element */
    elementContexts: [] as ContextViewModel[]
  }),

  async created () {
    await this.init();
  },

  methods: {
    getSubjectsFromActivity (activity: ActivityModel): void {
      for (const subject of activity.subjects) {
        this.subjectList.push({ id: subject.id, name: subject.name });
      }
      for (const act of activity.activities) {
        this.getSubjectsFromActivity(act);
      }
    },
    async init (): Promise<void> {
      this.subjectList = [];
      this.parent = await getSpotItemById(this.element.spotItemId);
      if (this.parent) {
        switch (this.parent.item.type) {
          case ContextItemType.Cartography:
            for (const activity of (this.parent.item as CartographyModel).activities) {
              this.getSubjectsFromActivity(activity);
            }
            this.elementContexts = [{ nodes: [{ name: this.parent.contexts[0].hierarchy[0].name, link: undefined }] }];
            break;
          case ContextItemType.Activity: {
            this.getSubjectsFromActivity(this.parent.item as ActivityModel);
            const nodes = [] as ContextNodeViewModel[];
            for (const node of this.parent.contexts[0].hierarchy) {
              nodes.push({ name: node.name, link: undefined });
            }
            nodes.push({ name: this.parent.item.name, link: undefined });
            this.elementContexts = [{ nodes: nodes }];
            break;
          }
          case ContextItemType.Function:
            for (const subject of await getSubjectsFromFunction(this.element.spotItemId)) {
              this.subjectList.push({ id: subject.id, name: subject.name });
            }
            this.elementContexts = [
              {
                nodes: [
                  { name: this.parent.contexts[0].hierarchy[0].name, link: undefined },
                  { name: this.parent.contexts[0].hierarchy[1].name, link: undefined },
                  { name: this.parent.item.name, link: undefined }
                ]
              }
            ];
            break;
          case ContextItemType.Subject:
            this.subjectList.push({ id: this.element.spotItemId, name: this.parent.item.name });
            this.elementContexts = getContextViewModel(this.parent.contexts);
            break;
        }
      }
    }
  },

  watch: {
    element: async function () {
      await this.init();
    }
  }

});

