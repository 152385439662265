

















import { getContributions } from '../../repository';
import Vue, { PropType } from 'vue';
import { ContributionsQuery, ContributionsQueryGranularity } from '../../../_GeneratedClients/SpotClient';
import BarChart from './BarChart.vue';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';

interface Dataset { label: string; backgroundColor: string; borderColor: string; borderWidth: number; data: number[]; }
interface ChartData { labels: string[]; datasets: Dataset[]; }

export default Vue.extend({

  name: 'ChartContributions',

  components: {
    BarChart
  },

  props: {
    height: { type: Number, default: 300 },
    itemIds: { type: Array as PropType<string[]>, default: () => [] },
    userIds: { type: Array as PropType<string[]>, default: () => [] },
    granularity: { type: String as PropType<ContributionsQueryGranularity>, default: ContributionsQueryGranularity.Week },
    duration: { type: Number, default: 78 },
    startComputing: { type: Boolean, default: false }
  },

  data: () => ({
    chartData: {} as ChartData,
    chartOptions: {},
    chartColors: {
      pink: { backgroundColor: 'rgba(255, 224, 230, 0.5)', borderColor: 'rgba(255, 122, 150, 1)', borderWidth: 1 },
      blue: { backgroundColor: 'rgba(219, 242, 242, 0.5)', borderColor: 'rgba(110, 188, 240, 1)', borderWidth: 1 },
      purple: { backgroundColor: 'rgba(245, 154, 242, 0.5)', borderColor: 'rgba(181, 11, 175, 1)', borderWidth: 1 },
      green: { backgroundColor: 'rgba(169, 252, 124, 0.5)', borderColor: 'rgba(64, 174, 4, 1)', borderWidth: 1 },
      yellow: { backgroundColor: 'rgba(255, 255, 117, 0.5)', borderColor: 'rgba(255, 219, 15, 1)', borderWidth: 1 },
      orange: { backgroundColor: 'rgba(252, 198, 126, 0.5)', borderColor: 'rgba(214, 121, 0, 1)', borderWidth: 1 }
    },
    ready: false,
    loadingData: false
  }),

  created () {
    this.chartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          min: 0,
          suggestedMin: 0
        }
      }
    };
  },

  methods: {
    async prepareChart (): Promise<void> {
      this.ready = false;
      let startDate = LocalDate.now();
      let start = 0;
      let fromYear;
      // first, computes period parameters
      switch (this.granularity) {
        case ContributionsQueryGranularity.Month: {
          startDate = startDate.minusMonths(this.duration - 1);
          start = startDate.monthValue();
          fromYear = startDate.year();
          break;
        }
        case ContributionsQueryGranularity.Week: {
          startDate = startDate.minusWeeks(this.duration - 1);
          start = startDate.isoWeekOfWeekyear();
          fromYear = startDate.isoWeekyear();
          break;
        }
        default:
          throw new Error('Unknown granularity : ' + this.granularity);
      }

      // second, computes labels
      const labels: string[] = [];
      switch (this.granularity) {
        case ContributionsQueryGranularity.Month: {
          // label type : "<month name> <year>"
          let refDate = startDate;
          for (let i = 0; i < this.duration; i++) {
            labels.push(this.$t('month.' + refDate.monthValue()) + ' ' + refDate.year());
            refDate = refDate.plusMonths(1); // next month
          }
          break;
        }
        case ContributionsQueryGranularity.Week: {
          // we have startDate, then find the first day of the week containing startDate
          const dayNum = startDate.dayOfWeek().value(); // monday=1, ... , sunday=7
          let firstDayOfWeek = startDate.minusDays(dayNum - 1);
          // now set labels for each week as "<date of first day of week>" (en : YYYY-MM-DD, fr: DD/MM/YYYY)
          for (let i = 0; i < this.duration; i++) {
            labels.push(firstDayOfWeek.format(DateTimeFormatter.ofPattern(this.$t('jodaPattern').toString())));
            firstDayOfWeek = firstDayOfWeek.plusDays(7); // first day of next week
          }
          break;
        }
      }

      // third, display empty chart
      this.chartData = {
        labels: labels,
        datasets: [
          {
            ...this.chartColors.yellow,
            label: this.$t('views').toString(),
            data: []
          },
          {
            ...this.chartColors.blue,
            label: this.$t('writes').toString(),
            data: []
          }
        ]
      };
      this.loadingData = true;
      this.ready = true;

      // fourth, get contributions data
      const query: ContributionsQuery = {
        userIds: this.userIds,
        itemIds: this.itemIds,
        fromYear: fromYear,
        granularity: this.granularity,
        start: start,
        count: this.duration
      };
      const contributions = await getContributions(query);
      this.loadingData = false;

      // fifth, display real chart data
      this.chartData = {
        labels: labels,
        datasets: [
          {
            ...this.chartColors.yellow,
            label: this.$t('views').toString(),
            data: contributions.map(c => c.views)
          },
          {
            ...this.chartColors.blue,
            label: this.$t('writes').toString(),
            data: contributions.map(c => c.contributions)
          }
        ]
      };
      this.$emit('done');
    }
  },

  watch: {
    startComputing: {
      immediate: true,
      handler: async function (): Promise<void> {
        if (this.startComputing) {
          // startComputing is comming up -> can compute parameters
          await this.prepareChart();
        }
      }
    }
  }
});

