




























import { queryTrainingReferences } from '../../repository';
import Vue, { PropType } from 'vue';
import { IdAndNameModel, TrainingReferenceModel, TrainingReferenceType } from '../../../_GeneratedClients/SpotClient';
import { BooleanComparisonOperator, FieldType, MultipleQueryCriteriaType, QueryCriterion, serializeQuery, StringComparisonOperator } from '../../queryEngine';
import Autocomplete from './Autocomplete.vue';

/**
 * An item in the autocomplete picker
 */
export interface ReferencePickerData extends IdAndNameModel{
  reference: TrainingReferenceModel;
}

export default Vue.extend({

  name: 'ReferencePicker',

  components: { Autocomplete },

  props: {
    trainingReferenceType: { type: String as PropType<TrainingReferenceType>, required: true },
    label: { type: String, default: '???' },
    value: { type: Object as PropType<TrainingReferenceModel | undefined>, default: undefined }
  },

  methods: {
    async autocomplete (search:string): Promise<ReferencePickerData[]> {
      const query: QueryCriterion = {
        type: MultipleQueryCriteriaType.and,
        criteria: [
          { type: FieldType.boolean, property: 'disabled', operator: BooleanComparisonOperator.equals, value: false },
          {
            type: MultipleQueryCriteriaType.or,
            criteria: [
              { type: FieldType.string, property: 'code', operator: StringComparisonOperator.contains, value: search },
              { type: FieldType.string, property: 'label', operator: StringComparisonOperator.contains, value: search },
              { type: FieldType.string, property: 'description', operator: StringComparisonOperator.contains, value: search }
            ]
          }
        ]
      };
      const { results } = await queryTrainingReferences(this.trainingReferenceType, {
        query: serializeQuery(query),
        pageSize: 30
      });

      return results.map(this.makeReferencePickerData);
    },
    makeReferencePickerData (item: TrainingReferenceModel): ReferencePickerData {
      return {
        id: item.code,
        name: item.code + ' - ' + item.label,
        reference: item
      };
    }
  },

  computed: {
    selectedReference: {
      get: function () : ReferencePickerData | null {
        return this.value === undefined ? null : this.makeReferencePickerData(this.value);
      },
      set: function (newValue: ReferencePickerData | null) {
        this.$emit('input', newValue === null ? undefined : newValue.reference);
      }
    }
  }
});

