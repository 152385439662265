/**
 * A field declaration
 */
export type FieldConfiguration = FieldFilteringConfiguration & (SimpleFieldConfiguration | EnumFieldConfiguration);

export interface SimpleFieldConfiguration {
  /**
   * The type of field
   */
  type: Exclude<FieldType, FieldType.enum>;
}

export interface EnumFieldConfiguration {
  /**
   * The type of field
   */
  type: FieldType.enum;

  /**
   * The enum values declaration
   */
  enumType: Record<string, string>;
}

export enum FieldType {
  /**
   * The field is a string
   */
  string = 'string',

  /**
   * The field is a date
   */
  date = 'date',

  /**
   * The field is a boolean
   */
  boolean = 'boolean',

  /**
   * The field is an enumeration
   */
  enum = 'enum',

  /**
   * The field is an id (aka infinite enumeration with autocomplete on the UI)
   */
  id = 'id',
  // numbers ...
}

export interface FieldFilteringConfiguration {
  /**
   * A boolean indicating whether this field can be used for filtering (default : true)
   */
  filterable?: boolean

  /**
   * A boolean indicating whether this field can be used for ordering (default : false)
   */
  sortable?: boolean
}
