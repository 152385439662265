








































import SimplePageLayout from '../../presentation/SimplePageLayout.vue';
import Vue, { PropType } from 'vue';
import { ResourceModel, ResourceType } from '../../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'ReorderResource',

  components: {
    SimplePageLayout
  },

  props: {
    showForm: { type: Boolean, default: false },
    width: { type: Number, default: 300 },
    resources: { type: Array as PropType<ResourceModel[]>, default: () => [] }
  },

  data: () => ({
    resourceList: [] as ResourceModel[],
    height: 550,
    saveButtonDisabled: false
  }),

  methods: {
    getIcon (type: ResourceType): string {
      switch (type) {
        case ResourceType.Image: return 'mdi-image-outline';
        case ResourceType.Pdf: return 'mdi-file-pdf-box';
        case ResourceType.File: return 'mdi-file-document-outline';
        case ResourceType.Link: return 'mdi-link-variant';
        case ResourceType.Youtube: return 'mdi-youtube';
        default: return '';
      }
    },
    move (direction: string, idx: number): void {
      const buffer = this.resourceList[idx];
      switch (direction) {
        case 'up': {
          if (idx > 0) {
            this.resourceList.splice(idx, 1);
            this.resourceList.splice(idx - 1, 0, buffer);
          }
          break;
        }
        case 'dn': {
          if (idx < this.resourceList.length - 1) {
            this.resourceList.splice(idx, 1);
            this.resourceList.splice(idx + 1, 0, buffer);
          }
          break;
        }
      }
    },
    saveOrder (): void {
      this.saveButtonDisabled = true;
      this.$emit('save', this.resourceList.map(r => r.id));
    }
  },

  watch: {
    resources: {
      immediate: true,
      handler (): void {
        this.resourceList = [...this.resources];
      }
    },
    showForm (): void {
      if (!this.showForm) {
        this.saveButtonDisabled = false;
      }
    }
  }
});

