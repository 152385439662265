







































































































































































































































































































































































































































































































































































import Vue from 'vue';
import {
  attachMediationToTrainingPathResult,
  cancelTrainingPath,
  deleteTrainingPath,
  detachMediationFromTrainingPathResult,
  disableTrainingPathModule,
  getConnectedUser,
  getEmptyTrainingPath,
  getTrainingPathById,
  getTrainingPathStatusById,
  setAssignedModuleGrade,
  setTrainingPathUrlResult,
  signAssignedModule,
  signTrainingPath,
  startTrainingPath,
  startTrainingPathModuleNow,
  updateTrainingPathResults,
  validateTrainingPath
} from '../repository';
import moment, { Moment } from 'moment';
import {
  AssignedTrainingModuleModel,
  AssignedTrainingModuleStatus,
  TrainingModuleItemResult,
  TrainingPathStatus,
  UserModel
} from '../../_GeneratedClients/SpotClient';
import DisplayAssignedModuleUrlElement from '../components/trainingPaths/DisplayAssignedModuleUrlElement.vue';
import DisplayAssignedModuleSpotElement from '../components/trainingPaths/DisplayAssignedModuleSpotElement.vue';
import DisplayAssignedModuleMediationElement from '../components/trainingPaths/DisplayAssignedModuleMediationElement.vue';
import DisplayAssignedModuleLmsElement from '../components/trainingPaths/DisplayAssignedModuleLmsElement.vue';
import DisplayValue from '../components/common/DisplayValue.vue';
import DisplayLifetimeEvent from '../components/common/DisplayLifetimeEvent.vue';
import DisplayConfirmationDialogBox from '../components/common/DisplayConfirmationDialogBox.vue';
import { sanitizeHTML } from '../Tools';
import eventBus from '../eventBus';
import { backToList } from '../helpers/filteredListNavigation';

export default Vue.extend({

  name: 'ViewTrainingPath',

  components: {
    DisplayAssignedModuleUrlElement,
    DisplayAssignedModuleSpotElement,
    DisplayAssignedModuleMediationElement,
    DisplayAssignedModuleLmsElement,
    DisplayValue,
    DisplayLifetimeEvent,
    DisplayConfirmationDialogBox
  },

  data: () => ({
    me: {} as UserModel,
    path: getEmptyTrainingPath(),
    pathId: '',
    pathCompletion: -1,
    panel: [],
    canEditPath: false, // the creator can edit the training path
    canCreatePathFrom: false, // a manager can create new paths from displayed path
    canDeletePath: false, // the creator can delete training path
    canCancelPath: false, // the creator can cancel the training path
    canAcceptPath: false, // the learner can accept the training path
    canValidatePath: false, // the creator can validate the training path
    canSignPath: false, // the learner can sign the training path
    canSeeGrading: false, // users who can see all modules, can see gradings
    showConfirmDeletion: false,
    showConfirmCancellation: false,
    showConfirmAcceptance: false,
    overallState: 0,
    showDisableModuleForm: false,
    showModuleGradingForm: false,
    showModuleSignForm: false,
    processedModule: undefined as AssignedTrainingModuleModel | undefined,
    processedModuleGrade: 0,
    processedModuleGradeIsSet: false,
    processedModuleComment: '',
    processedModuleCommentError: true,
    moduleValidationError: '',
    processedModuleSatisfaction: 1,
    showConfirmValidation: false,
    pathValidationComment: '',
    showConfirmSignature: false,
    pathSignatureComment: '',
    pathSatisfaction: 1,
    refreshing: false,
    pathIsCanceled: false,
    pathIsArchived: false,
    iAmPathAdmin: false
  }),

  async created () {
    const connectedUser = getConnectedUser();
    if (connectedUser) { // would be always true, but to avoid "possibly undefined" errors
      this.me = connectedUser;
      this.iAmPathAdmin = this.me.permissions.includes('perm_TrainingPathAdmin');
    }
    this.pathId = this.$route.params.pathId;
    await this.initPath();
  },

  methods: {
    async initPath (): Promise<void> {
      try {
        this.refreshing = true;
        await updateTrainingPathResults(this.pathId);
        this.path = await getTrainingPathById(this.pathId);
        this.pathIsCanceled = this.path.status === TrainingPathStatus.Canceled;
        this.pathIsArchived = this.path.status === TrainingPathStatus.Archived;
        // note : this.path.totalSubModuleItems === 0 would never occur, but to avoid "divide by 0"
        this.pathCompletion = this.path.totalSubModuleItems === 0 ? -1 : Math.round(this.path.doneSubModuleItems / this.path.totalSubModuleItems * 100);
        if (this.me.id === this.path.learner.id) {
          // i am the learner
          this.canAcceptPath = this.path.status === TrainingPathStatus.NotAccepted && this.path.stopDate.isSameOrAfter(moment().startOf('day'));
          this.canSignPath = !this.pathIsCanceled && this.path.status === TrainingPathStatus.Validated && this.path.signature === undefined;
        } else if (this.me.id === this.path.creation.userId || this.iAmPathAdmin) {
          // i am the creator or i am a training path administartor
          this.canEditPath = true;
          this.canDeletePath = !this.path.acceptanceDate;
          this.canCancelPath = !this.pathIsCanceled && !this.canDeletePath;
          this.canValidatePath = !this.pathIsCanceled && this.path.status === TrainingPathStatus.Finished && this.path.validation === undefined;
        }
        this.canCreatePathFrom = this.me.permissions.includes('perm_TrainingManagement');
        // note : The following rule is approximate, but may be sufficient as it stands
        this.canSeeGrading = this.path.totalAssignedModules <= this.path.assignedModules.length;
        this.processedModule = undefined;
        this.refreshing = false;
      } catch (err) {
        console.error(err);
        this.$router.push({ name: 'NotFound' });
      }
    },
    async updatePathStatus () {
      this.path.status = await getTrainingPathStatusById(this.pathId);
    },
    async reload (): Promise<void> {
      if (this.path.id === '' || this.refreshing) {
        return;
      }
      this.path = getEmptyTrainingPath();
      await this.initPath();
    },
    getDisplayableDate (date: Moment): string {
      return date.format(this.$t('dateFormat.pattern').toString());
    },
    getDuration (minutes: number): string {
      // returns something like "x H y Mn", or just "x H" or "y Mn"
      if (minutes === 0) {
        return this.$t('notDefined').toString();
      }
      const h = Math.floor(minutes / 60);
      const m = minutes % 60;
      let value = '';
      if (h > 0) {
        value = h + ' H';
      }
      if (m > 0) {
        value += value === '' ? m + ' Mn' : ' ' + m + ' Mn';
      }
      return value;
    },
    getModuleCompletion (elements: TrainingModuleItemResult[]): number {
      let totalItems = 0;
      let completedItems = 0;
      for (const item of elements) {
        totalItems += item.totalSubModuleItems;
        completedItems += item.doneSubModuleItems;
      }
      if (totalItems === 0) { // would never occur, but to avoid "divide by 0"
        return -1;
      }
      return Math.round(completedItems / totalItems * 100);
    },
    getModuleDisplayableCompletion (elements: TrainingModuleItemResult[]): string {
      const completion = this.getModuleCompletion(elements);
      if (completion === -1) {
        return this.$t('notQuantifiable').toString();
      }
      return completion + ' %';
    },
    getSuccessedModules (): number {
      let num = 0;
      for (const module of this.path.assignedModules) {
        if (module.status !== AssignedTrainingModuleStatus.Disabled && module.grading !== undefined && module.grading.isSuccess) {
          num++;
        }
      }
      return num;
    },
    getAverageGrade (): number {
      if (this.path.totalAssignedModules === 0) { // should never happen
        return 0;
      }
      let sum = 0;
      for (const module of this.path.assignedModules) {
        if (module.status !== AssignedTrainingModuleStatus.Disabled && module.grading !== undefined) {
          sum += module.grading.grade;
        }
      }
      const average = sum / this.path.totalAssignedModules;
      return Math.round(average * 10) / 10; // to have just one decimal
    },
    getSuccessColor (): string {
      return this.getAverageGrade() >= this.path.passThreshold ? 'success--text' : 'error--text';
    },
    getStatusColor (status: string): string {
      // note : some status are for modules, others for training paths
      switch (status) {
        case 'notAccepted':
        case 'Accepted':
        case 'notStarted':
        case 'disabled':
          return 'secondary--text';
        case 'started':
        case 'finished':
          return 'accent--text';
        case 'validated':
        case 'archived':
          return 'success--text';
        case 'canceled':
          return 'error--text';
        default:
          return 'secondary--text';
      }
    },
    getContent (content: string): string {
      return sanitizeHTML(content).replace(/\n/g, '<br />');
    },
    getModuleBgColor (module: AssignedTrainingModuleModel): string {
      const completion = this.getModuleCompletion(module.results);
      if (module.status === AssignedTrainingModuleStatus.Disabled || completion === -1) {
        return 'bgDisabled';
      }
      return completion < 33 ? 'bgError' : completion < 66 ? 'bgAccent' : 'bgSuccess';
    },
    validateModuleReferentComment (): string | true {
      if (this.processedModuleComment.length > 0) {
        return true;
      }
      return this.$t('emptyFieldError').toString();
    },
    canStartModuleNow (module: AssignedTrainingModuleModel): boolean {
      // module can be started now if module is in "notStarted" status and path is in "started" status
      // and connected user is one of the referents for this module or a training path administrator
      return module.status === AssignedTrainingModuleStatus.NotStarted &&
        this.path.status === TrainingPathStatus.Started &&
        (this.iAmReferent(module) || this.iAmPathAdmin);
    },
    canDisableModule (module: AssignedTrainingModuleModel): boolean {
      // module can't be disabled if module is already disabled or if path is canceled, not accepted, validated or archived
      if (module.status === AssignedTrainingModuleStatus.Disabled || ![TrainingPathStatus.Accepted, TrainingPathStatus.Started, TrainingPathStatus.Finished].includes(this.path.status)) {
        return false;
      }
      // traning path administrator can disable module in all existing cases
      if (this.iAmPathAdmin) {
        return true;
      }
      // referents for this module can disable the module if path is started and module is not archived or path is accepted and module is not started
      return this.iAmReferent(module) && (
        (this.path.status === TrainingPathStatus.Started && module.status !== AssignedTrainingModuleStatus.Archived) ||
        (this.path.status === TrainingPathStatus.Accepted && module.status !== AssignedTrainingModuleStatus.Started)
      );
    },
    canGradeAndCloseModule (module: AssignedTrainingModuleModel): boolean {
      // module can be graded and closed if path is in "Started" status and module is in "Started" or "Finished" status
      // and connected user is one of the referents for this module or a training path administrator
      return this.path.status === TrainingPathStatus.Started &&
        (module.status === AssignedTrainingModuleStatus.Started || module.status === AssignedTrainingModuleStatus.Finished) &&
        (this.iAmReferent(module) || this.iAmPathAdmin);
    },
    canChangeModuleGrading (module: AssignedTrainingModuleModel): boolean {
      // Grading of module can be changed if
      // - path is in "Started" status and module is in "Validated" status and connected user is one of the referents for this module or a training path administrator
      // - or path is in "Started" or "Finished" status and module status is "archived" and connected user is a training path administrator
      return (this.path.status === TrainingPathStatus.Started && module.status === AssignedTrainingModuleStatus.Validated && (this.iAmReferent(module) || this.iAmPathAdmin)) ||
        ((this.path.status === TrainingPathStatus.Started || this.path.status === TrainingPathStatus.Finished) && module.status === AssignedTrainingModuleStatus.Archived && this.iAmPathAdmin);
    },
    canManageMediation (module: AssignedTrainingModuleModel): boolean {
      // connected user can manage mediation (attach/detach mediation to/from module) if  path status is "Started" and module status is "Started" or "Finished" and
      // connected user id one of the referents for this module or a training path administrator
      return this.path.status === TrainingPathStatus.Started &&
        (module.status === AssignedTrainingModuleStatus.Started || module.status === AssignedTrainingModuleStatus.Finished) &&
        (this.iAmReferent(module) || this.iAmPathAdmin);
    },
    atLeastOneModuleNotYetSigned (modules: AssignedTrainingModuleModel[]): boolean {
      return modules.some(m => !m.disabled && m.signature === undefined);
    },
    allModulesPassed (modules: AssignedTrainingModuleModel[]): boolean {
      return !modules.some(m => !m.disabled && m.grading !== undefined && !m.grading.isSuccess);
    },
    async startModule (moduleIndex: number): Promise<void> {
      try {
        await startTrainingPathModuleNow(this.path.id, moduleIndex);
        await this.initPath();
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    goBackToList () {
      this.$router.push(backToList('FilteredTrainingPaths'));
    },
    onDeletionConfirmation (): void {
      this.showConfirmDeletion = false;
      try {
        deleteTrainingPath(this.path.id);
        this.$router.push({ path: '/training/paths/' });
      } catch (err) {
        eventBus.$emit('error', err, null);
      }
    },
    async onCancellationConfirmation (): Promise<void> {
      this.showConfirmCancellation = false;
      try {
        await cancelTrainingPath(this.path.id);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async onAcceptanceConfirmation (): Promise<void> {
      this.showConfirmAcceptance = false;
      try {
        await startTrainingPath(this.path.id);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async urlClicked (moduleIndex: number, elementIdx: number): Promise<void> {
      if (this.me.id === this.path.learner.id) {
        // user clicked on the link
        try {
          await setTrainingPathUrlResult(this.path.id, moduleIndex, elementIdx);
        } catch (err) {
          console.error(err);
        } finally {
          await this.initPath();
        }
      }
    },
    disableModule (module: AssignedTrainingModuleModel): void {
      this.processedModule = { ...module };
      this.showDisableModuleForm = true;
    },
    async saveDisableModule (): Promise<void> {
      this.showDisableModuleForm = false;
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await disableTrainingPathModule(this.path.id, this.processedModule!.index);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    gradeModule (module: AssignedTrainingModuleModel): void {
      this.moduleValidationError = '';
      this.processedModule = { ...module };
      this.processedModuleGrade = module.grading?.grade ?? 0;
      this.processedModuleGradeIsSet = module.grading !== undefined;
      this.processedModuleComment = module.grading?.comment ?? '';
      this.processedModuleCommentError = this.processedModuleComment.length === 0;
      this.showModuleGradingForm = true;
    },
    cancelGradeModule (): void {
      this.showModuleGradingForm = false;
      this.processedModule = undefined;
    },
    async saveModuleGrading (): Promise<void> {
      if (!this.processedModuleGradeIsSet || this.processedModuleCommentError) {
        this.moduleValidationError = this.$t('atLeastOneErrorLeft').toString();
        return; // module must be graded and comment must not be empty
      }
      this.showModuleGradingForm = false;
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await setAssignedModuleGrade(this.path.id, this.processedModule!.index, { grade: this.processedModuleGrade, comment: this.processedModuleComment });
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    canBeSigned (module: AssignedTrainingModuleModel): boolean {
      if (this.pathIsCanceled || module.status === AssignedTrainingModuleStatus.Disabled) {
        return false;
      }
      if (this.path.learner.id !== this.me.id) {
        // i am not the learner for this training path
        return false;
      }
      return module.status === AssignedTrainingModuleStatus.Validated;
    },
    signModule (module: AssignedTrainingModuleModel): void {
      this.processedModule = { ...module };
      this.processedModuleComment = '';
      this.processedModuleSatisfaction = 1;
      this.showModuleSignForm = true;
    },
    cancelSignModule (): void {
      this.showModuleSignForm = false;
      this.processedModule = undefined;
    },
    async addAttachedMediation (moduleIndex: number, elementIdx: number, mediationId: string) {
      try {
        await attachMediationToTrainingPathResult(this.path.id, moduleIndex, elementIdx, mediationId);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async removeAttachedMediation (moduleIndex: number, elementIdx: number, mediationId: string) {
      try {
        await detachMediationFromTrainingPathResult(this.path.id, moduleIndex, elementIdx, mediationId);
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async saveModuleSignature (): Promise<void> {
      this.showModuleSignForm = false;
      try {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        await signAssignedModule(this.path.id, this.processedModule!.index, { comment: this.processedModuleComment, satisfaction: this.processedModuleSatisfaction });
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async savePathValidation (): Promise<void> {
      this.showConfirmValidation = false;
      try {
        await validateTrainingPath(this.path.id, { comment: this.pathValidationComment });
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    async savePathSignature (): Promise<void> {
      this.showConfirmSignature = false;
      try {
        await signTrainingPath(this.path.id, { comment: this.pathSignatureComment, satisfaction: this.pathSatisfaction });
      } catch (err) {
        eventBus.$emit('error', err, null);
      } finally {
        await this.initPath();
      }
    },
    iAmReferent (module: AssignedTrainingModuleModel): boolean {
      return module.referents.some((r) => r.id === this.me.id);
    }
  },

  computed: {
    totalPathAssignedModules: function (): string {
      return this.path.totalAssignedModules === undefined ? '0' : this.path.totalAssignedModules.toString();
    },
    totalPathSubModuleItems: function (): string {
      return this.path.totalSubModuleItems === undefined ? '0' : this.path.totalSubModuleItems.toString();
    },
    pathDisplayableCompletion: function (): string {
      return this.pathCompletion === -1 ? this.$t('notQuantifiable').toString() : this.pathCompletion + ' %';
    },
    pathCompletionColor: function (): string {
      if (this.pathIsCanceled || this.pathCompletion === -1) {
        return 'secondary--text';
      }
      return this.pathCompletion < 33 ? 'error--text' : this.pathCompletion < 66 ? 'primary--text' : 'success--text';
    }
  }
});

