import { ContributionCounter, ContributionsQuery, ContributionsQueryGranularity } from '../../_GeneratedClients/SpotClient';
import { client } from './Client';
import i18n from '../i18n';
import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import ExcelJS from 'exceljs';
import slugify from 'slugify';
import { getUsersByIds } from '.';

/**
 * gets from API contributions for user(s), SPOT items, and period
 *
 * @param query the query parameters
 * @returns contributions counters values for specified elements
 */
export async function getContributions (query: ContributionsQuery): Promise<ContributionCounter[]> {
  return client.getContributions(query);
}

/**
 * Generates and export contributions as excel file
 *
 * @param userIds a list of user identifiers
 * @param itemIds a list of target SPOT objects (if empty, use all SPOT objects)
 * @param granularity defines the granularity of measurements : weekly or monthly
 * @param duration number of weeks or months for measurement
 * @param fileName excel file name
 */
export async function exportExcelContributions (userIds: string[], itemIds: string[], granularity: ContributionsQueryGranularity, duration: number, fileName: string): Promise<void> {
  // duration must be an integer > 0
  duration = Math.floor(duration);
  if (duration < 1) {
    throw new Error('duration must be an integer > 0 (given ' + duration + ')');
  }
  let startDate = LocalDate.now();
  let start = 0;
  let fromYear;

  // computes period parameters
  switch (granularity) {
    case ContributionsQueryGranularity.Month: {
      startDate = startDate.minusMonths(duration - 1);
      start = startDate.monthValue();
      fromYear = startDate.year();
      break;
    }
    case ContributionsQueryGranularity.Week: {
      startDate = startDate.minusWeeks(duration - 1);
      start = startDate.isoWeekOfWeekyear();
      fromYear = startDate.isoWeekyear();
      break;
    }
    default:
      throw new Error('Unknown granularity : ' + granularity);
  }

  // prepare excel workbook
  const workbook = new ExcelJS.Workbook();
  const writesWorksheet = workbook.addWorksheet(i18n.t('dashboard.writes').toString());
  const viewsWorksheet = workbook.addWorksheet(i18n.t('dashboard.views').toString());
  const writeTotalColumn = [] as number[];
  const readTotalColumn = [] as number[];

  // create column names (column names are dates : first day of period)
  const columns: { header: string, key: string }[] = [{ header: i18n.t('dashboard.user').toString(), key: 'User' }];
  switch (granularity) {
    case ContributionsQueryGranularity.Month: {
      let firstDayOfMonth = startDate.withDayOfMonth(1); // first day of month containing startDate
      for (let i = 0; i < duration; i++) {
        columns.push({ header: firstDayOfMonth.format(DateTimeFormatter.ofPattern(i18n.t('dateFormat.jodaPattern').toString())), key: 'Value' + i });
        firstDayOfMonth = firstDayOfMonth.plusMonths(1); // first day of next month
        writeTotalColumn.push(0);
        readTotalColumn.push(0);
      }
      break;
    }
    case ContributionsQueryGranularity.Week: {
      const dayNum = startDate.dayOfWeek().value(); // monday=1, ... , sunday=7
      let firstDayOfWeek = startDate.minusDays(dayNum - 1); // first day of week containing startDate
      for (let i = 0; i < duration; i++) {
        columns.push({ header: firstDayOfWeek.format(DateTimeFormatter.ofPattern(i18n.t('dateFormat.jodaPattern').toString())), key: 'Value' + i });
        firstDayOfWeek = firstDayOfWeek.plusDays(7); // first day of next week
        writeTotalColumn.push(0);
        readTotalColumn.push(0);
      }
      break;
    }
  }
  columns.push({ header: i18n.t('dashboard.total').toString(), key: 'totalRow' });
  writesWorksheet.columns = columns; // same columns for the two worksheets
  viewsWorksheet.columns = columns;

  // get user complementary informations
  const userInfos = await getUsersByIds(userIds);
  const query: ContributionsQuery = {
    userIds: [],
    itemIds: itemIds,
    fromYear: fromYear,
    granularity: granularity,
    start: start,
    count: duration
  };

  let totalRow = 0;
  let totalReads = 0;
  let totalWrites = 0;
  let excelRow = {} as Record<string, string | number>;
  // now, for each user, get contribution counters and set corresponding Excel rows
  for (const userId of userIds) {
    const contributions = await getContributions({ ...query, userIds: [userId] });
    excelRow = { User: userInfos[userId].lastName + ' ' + userInfos[userId].firstName };
    // contributions worksheet
    totalRow = 0;
    for (let i = 0; i < contributions.length; i++) {
      const contribution = contributions[i].contributions;
      excelRow['Value' + i] = contribution;
      writeTotalColumn[i] += contribution;
      totalRow += contribution;
    }
    excelRow.totalRow = totalRow;
    totalWrites += totalRow;
    writesWorksheet.addRow(excelRow);
    // consultations worksheets
    totalRow = 0;
    for (let i = 0; i < contributions.length; i++) {
      const views = contributions[i].views;
      excelRow['Value' + i] = views;
      readTotalColumn[i] += views;
      totalRow += views;
    }
    excelRow.totalRow = totalRow;
    totalReads += totalRow;
    viewsWorksheet.addRow(excelRow);
  }
  // add last row  = average values of columns
  excelRow = { User: i18n.t('dashboard.average').toString() };
  // for contributions
  totalRow = 0;
  for (let i = 0; i < duration; i++) {
    excelRow['Value' + i] = writeTotalColumn[i] / userIds.length;
  }
  excelRow.totalRow = totalWrites / userIds.length;
  writesWorksheet.addRow(excelRow);
  // for consultations
  totalRow = 0;
  for (let i = 0; i < duration; i++) {
    excelRow['Value' + i] = readTotalColumn[i] / userIds.length;
  }
  excelRow.totalRow = totalReads / userIds.length;
  viewsWorksheet.addRow(excelRow);

  // generate file
  const data = await workbook.xlsx.writeBuffer();
  const excelBlob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  // make and click a temporary link to download the Blob
  const link = document.createElement('a');
  link.href = URL.createObjectURL(excelBlob);
  link.download = slugify(fileName) + '.xlsx';
  link.click();
  link.remove();
}
