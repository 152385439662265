import { FieldType, serializeQuery, StringComparisonOperator } from '../queryEngine';
import {
  IdAndNameModel,
  QueryRequest,
  // eslint-disable-next-line camelcase
  QueryResponse_TrainingReferenceModel_,
  TrainingReferenceModel,
  TrainingReferenceType,
  TrainingReferenceUsageStats
} from '../../_GeneratedClients/SpotClient';
import { client } from './Client';

/**
 * gets reference list from api, with query filter(s)
 *
 * @param referenceType Type of reference list ('module' or 'path')
 * @param query query parameters (filters)
 * @returns a promise, the list of references according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryTrainingReferences (referenceType: TrainingReferenceType, query: QueryRequest): Promise<QueryResponse_TrainingReferenceModel_> {
  return await client.queryTrainingReferences(referenceType, query);
}

/**
 * An autocomplete function that searches for references code
 *
 * @param referenceType Type of reference list ('module' or 'path')
 * @param search The searched text
 * @returns The search results
 */
export async function autocompleteReference (referenceType: TrainingReferenceType, search: string): Promise<IdAndNameModel[]> {
  const query: QueryRequest = {
    query: serializeQuery({ type: FieldType.string, property: 'code', operator: StringComparisonOperator.contains, value: search }),
    pageSize: 30
  };
  const results = await queryTrainingReferences(referenceType, query);
  return results.results.map(tr => ({ id: tr.code, name: tr.code }));
}

/**
 *Asks API to save a new or edited training reference into database
 *
 * @param referenceType Type of reference list ('module' or 'path')
 * @param referenceItem The reference to create or update
 */
export async function createOrUpdateTrainingReference (referenceType: TrainingReferenceType, referenceItem: TrainingReferenceModel): Promise<void> {
  await client.createOrUpdateTrainingReference(referenceType, referenceItem);
}

/**
 *Asks API to delete a training reference from database
 *
 * @param referenceType Type of reference list ('module' or 'path')
 * @param code The code (identifier) of the reference to delete
 */
export async function deleteTrainingReference (referenceType: TrainingReferenceType, code: string): Promise<void> {
  await client.deleteTrainingReference(referenceType, code);
}

/**
 *
 * @param referenceType Type of reference list ('module' or 'path')
 * @param code The code (identifier) of the reference to analyse
 * @returns a result containing the number of modules/paths using this code.
 */
export async function getTrainingReferenceUsageStats (referenceType: TrainingReferenceType, code: string): Promise<TrainingReferenceUsageStats> {
  return await client.getTrainingReferenceUsageStats(referenceType, code);
}

/**
 * gets an empty training reference
 *
 * @returns an empty training reference
 */
export function getEmptyTrainingReference (): TrainingReferenceModel {
  return { code: '', label: '', description: '', disabled: false };
}
