













import Vue, { PropType } from 'vue';
import { getFriendlyDate } from '../../Tools';
import { Moment } from 'moment';
import { LifetimeEvent } from '../../../_GeneratedClients/SpotClient';

export default Vue.extend({

  name: 'DisplayLifetimeEvent',

  props: {
    startMessage: { type: String, default: '' },
    midMessage: { type: String, default: '' },
    altMessage: { type: String, default: '???' },
    eventName: { type: Object as PropType<LifetimeEvent | undefined> }
  },

  methods: {
    getDate (date: Moment): string {
      return getFriendlyDate(date, 'short');
    }
  }
});

