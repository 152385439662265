import { client } from './Client';
import { SavedFilter } from '../../_GeneratedClients/SpotClient';

let filterList: { [key: string]: SavedFilter[] } = {};
let preloadListPromise: Promise<void> | null = null;

/**
 * Preloads the registered filters for the connected user.
 *
 * @returns The promise that resolves the registered filter list.
 */
async function preloadFiltersFromApi (): Promise<void> {
  filterList = await client.getUsersFilters();
}

/**
 * Preloads the data for the search bar and subjects hierarchy. Ugly, but the DB doesn't give us the choice.
 *
 * @returns The promise that resolves when it has been saved.
 */
export async function filtersPreload (): Promise<void> {
  if (preloadListPromise === null) {
    preloadListPromise = preloadFiltersFromApi();
  }
  await preloadListPromise;
}

/**
 * gets the list of registered filters for a given list
 *
 * @param entityType indicates the list on which the filters to load apply
 * @returns a promise, the requested filter list
 */
export async function getFilterList (entityType: string): Promise<SavedFilter[]> {
  await filtersPreload();
  if (entityType === '' || !filterList[entityType]) {
    return [];
  }
  const list = filterList[entityType];
  for (const filter of list) {
    if (filter.isDefault === undefined) {
      filter.isDefault = false;
    }
  }
  return list;
}

/**
 * Update and save a registered filter list
 *
 * @param entityType indicates the list in which the filters must be saved
 * @param newFilterList the filter list to save
 */
export async function updateFilterList (entityType: string, newFilterList: SavedFilter[]): Promise<void> {
  if (entityType === '') {
    return;
  }
  filterList[entityType] = newFilterList;
  await client.saveUserFilter({ entityType: entityType, filters: newFilterList });
}

/**
 * unload the preloaded registered filter list
 */
export function filterPreloadUnload (): void {
  preloadListPromise = null;
  filterList = {};
}
