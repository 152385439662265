












































import Vue, { PropType } from 'vue';
import DatePicker from './DatePicker.vue';
import TimePicker from './TimePicker.vue';
import moment, { Moment } from 'moment';

export default Vue.extend({

  name: 'DateTimePicker',

  components: {
    DatePicker,
    TimePicker
  },

  props: {
    value: { type: moment, required: true },
    min: { type: Object as PropType<Moment | null>, default: null },
    max: { type: Object as PropType<Moment | null>, default: null },
    readOnly: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    label: { type: String, default: '' }
  },

  data: () => ({
    hasDateError: false,
    hasTimeError: false
  }),

  methods: {
    emitInput (newDate: Moment, newTime: Moment) {
      this.$emit('input', newDate.clone().hour(newTime.hour()).minutes(newTime.minute()));
    }
  },

  watch: {
    hasDateError: function (): void {
      this.$emit('error-changed', this.hasDateError || this.hasTimeError);
    },
    hasTimeError: function (): void {
      this.$emit('error-changed', this.hasDateError || this.hasTimeError);
    }
  },
  computed: {
    date (): Moment {
      return this.value.clone().startOf('day');
    },
    time (): Moment {
      return this.value;
    },
    minDate (): Moment | null {
      if (this.min !== null) {
        return this.min.clone().startOf('day');
      }
      return null;
    },
    maxDate (): Moment | null {
      if (this.max !== null) {
        return this.max.clone().startOf('day');
      }
      return null;
    },
    minTime (): Moment | null {
      if (this.min !== null && this.value.isSame(this.min, 'day')) {
        return this.min;
      }
      return null;
    },
    maxTime (): Moment | null {
      if (this.max !== null && this.value.isSame(this.max, 'day')) {
        return this.max;
      }
      return null;
    }
  }
});

