




























































































import Vue, { PropType } from 'vue';
import { IdAndNameModel } from '../../../_GeneratedClients/SpotClient';
import { FieldType, FrontendFieldConfiguration, FrontendCriterion } from '../../queryEngine';
import QueryOperatorSelector from './QueryOperatorSelector.vue';
import QueryBooleanSelector from './QueryBooleanSelector.vue';
import QueryDateSelector from './QueryDateSelector.vue';
import QueryEnumSelector from './QueryEnumSelector.vue';
import QueryIdSelector from './QueryIdSelector.vue';
import QueryStringSelector from './QueryStringSelector.vue';
import QueryLocationSelector from './QueryLocationSelector.vue';
import moment, { Moment } from 'moment';

export default Vue.extend({

  name: 'QueryCriterionSelector',

  components: {
    QueryOperatorSelector,
    QueryBooleanSelector,
    QueryDateSelector,
    QueryEnumSelector,
    QueryIdSelector,
    QueryStringSelector,
    QueryLocationSelector
  },

  props: {
    fieldsConfiguration: { type: Object as PropType<Record<string, FrontendFieldConfiguration>>, required: true },
    criterion: { type: Object as PropType<FrontendCriterion>, required: true }
  },

  data: () => ({
    selectedField: undefined as IdAndNameModel | undefined,
    selectedNameOrState: {} as IdAndNameModel,
    selectedDate: moment()
  }),

  created () {
    if (this.criterion.field) {
      // selection has previously made. Set component with given values
      this.selectedField = this.availableFields.find((c) => c.id === this.criterion.field);
    }
  },

  methods: {
    getBgColor (): string {
      return this.selectedField ? 'bgAccent' : 'bgError';
    },
    getBooleanValue () : IdAndNameModel[] {
      return [{ id: this.criterion.value.toString(), name: this.$t(this.criterion.value.toString()).toString() }];
    },
    onCriterionSelected () {
      if (this.selectedField === undefined) {
        return;
      }
      this.$emit('field-selected', this.selectedField.id);
    },
    operatorSelected (operator: string) {
      this.$emit('operator-selected', operator);
    },
    stringSelected (search: string) {
      this.$emit('value-selected', search);
      this.errorChanged(false); // can't have error on seach criterion
    },
    itemSelected (items: IdAndNameModel[]) {
      this.$emit('value-selected', items);
      this.errorChanged(items.length === 0); // The field cannot be empty
    },
    booleanSelected (state: IdAndNameModel) {
      this.$emit('value-selected', state.id === 'true');
      this.errorChanged(false); // can't have error on seach criterion
    },
    dateSelected (date: Moment) {
      this.$emit('value-selected', date);
    },
    listSelected (list: IdAndNameModel[]) {
      this.$emit('value-selected', list);
      this.errorChanged(list.length === 0); // error is true if list is empty
    },
    locationSelected (list: IdAndNameModel[]) {
      this.$emit('value-selected', list);
      this.errorChanged(list.length === 0); // error is true if list is empty
    },
    errorChanged (error: boolean) {
      this.$emit('error-changed', error);
    }
  },

  computed: {
    selectedType (): FieldType | '' {
      return this.selectedFieldConfiguration?.type ?? '';
    },
    selectedFieldConfiguration (): FrontendFieldConfiguration | undefined {
      return this.selectedField ? this.fieldsConfiguration[this.selectedField.id] : undefined;
    },
    availableFields (): IdAndNameModel[] {
      const result: IdAndNameModel[] = [];
      for (const field in this.fieldsConfiguration) {
        if (this.fieldsConfiguration[field].filterable !== false) {
          result.push({
            id: field,
            name: this.fieldsConfiguration[field].displayName
          });
        }
      }
      return result;
    }
  },

  watch: {
    criterion: function () {
      // selection has previously made. Set component with given values
      this.selectedField = this.availableFields.find((c) => c.id === this.criterion.field);
    }
  }
});

