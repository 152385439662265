import { DateTimeFormatter, LocalDate } from '@js-joda/core';
import moment, { Moment } from 'moment';
import i18n from './i18n';

export const baseUrl = window.location.protocol + '//' + window.location.host + '/';
export const spotV1Url = baseUrl + 'spot/';
export const spotV2Url = baseUrl + 'spotv2/';
export const backendUrl = baseUrl + 'backend/api/v2';

/**
 * Sorts array(source) by string property(propertySelector)
 *
 * @param source The array to be sorted
 * @param propertySelector The string property by which we sort
 * @param direction indicates ascending sort (asc, smallest in first) or descending (desc, largest in first)
 * @returns The source array (sorted in place)
 */
export function sortArrayByString<TItem> (source: TItem[], propertySelector: (item: TItem) => string, direction: 'asc' | 'desc' = 'asc') : TItem[] {
  if (direction === 'desc') {
    return source.sort((a, b) => propertySelector(b).localeCompare(propertySelector(a), undefined, { sensitivity: 'base' }));
  } else {
    return source.sort((a, b) => propertySelector(a).localeCompare(propertySelector(b), undefined, { sensitivity: 'base' }));
  }
}

/**
 * Sorts array(source) by numeric property(propertySelector)
 *
 * @param source The array to be sorted
 * @param propertySelector The numeric property by which we sort
 * @param direction indicates ascending sort (asc, smallest in first) or descending (desc, largest in first)
 * @returns The source array (sorted in place)
 */
export function sortArrayByNumber<TItem> (source: TItem[], propertySelector: (item: TItem) => number, direction: 'asc' | 'desc' = 'asc') : TItem[] {
  return source.sort((a, b) => {
    if (direction === 'desc') {
      return propertySelector(a) < propertySelector(b) ? 1 : propertySelector(a) > propertySelector(b) ? -1 : 0;
    } else {
      return propertySelector(a) < propertySelector(b) ? -1 : propertySelector(a) > propertySelector(b) ? 1 : 0;
    }
  });
}

/**
 * Sorts array(source) by property(propertySelector)
 *
 * @param source The array to be sorted
 * @param propertySelector The date (moment) property by which we sort (can be undefined)
 * @param direction indicates ascending sort (asc, oldest in first) or descending (desc, newest in first)
 * @returns The source array (sorted in place)
 */
export function sortArrayByMoment<TItem> (source: TItem[], propertySelector: (item: TItem) => Moment | undefined, direction: 'asc' | 'desc' = 'asc'): TItem[] {
  if (direction !== 'asc' && direction !== 'desc') {
    direction = 'asc';
  }
  return source.sort((a, b) => {
    const vA = propertySelector(a);
    const vB = propertySelector(b);
    if (vA === vB) {
      return 0;
    }
    if (vA === undefined) {
      return direction === 'asc' ? -1 : 1;
    }
    if (vB === undefined) {
      return direction === 'asc' ? 1 : -1;
    }
    if (vA.isBefore(vB)) {
      return direction === 'asc' ? -1 : 1;
    }
    if (vA.isAfter(vB)) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  });
}

/**
 * Formats the date in a human-friendly format
 *
 * @param date The date to format
 * @param type The type of formatting to apply
 * @returns The formatted date
 */
export function getFriendlyDate (date: Moment, type: 'shortTime' | 'shortDate' | 'short' | 'long'): string {
  switch (type) {
    case 'shortTime': return moment(date).format('LT');
    case 'shortDate': return moment(date).format('L');
    case 'short': return moment(date).format('L LT');
    default: return moment(date).format('LLLL');
  }
}

/**
 * Lets the browser do its things for this amount of time
 *
 * @param ms The number of milliseconds to wait
 * @returns The promise that resolves when this number of milliseconds have elapsed (approximatively)
 */
export async function sleep (ms: number) : Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * Awaits for the next animation frame (forces browser to do its rendering)
 *
 * @returns The promise that resolves on the next animation frame
 */
export function nextFrame () : Promise<void> {
  return new Promise((resolve) => {
    window.requestAnimationFrame(() => resolve());
  });
}

/**
 * The reused instance that will allow to sanitize HTML
 */
const sanitizeHTMLContainer = document.createElement('p');

/**
 * Transforms the given content so that no HTML tag will be interpreted when it will be passed to `innerHTML`
 *
 * @param content The content to transforme
 * @returns The sanitized string
 */
export function sanitizeHTML (content: string): string {
  // Inspired from https://stackoverflow.com/a/29482788/2663813
  sanitizeHTMLContainer.textContent = content;
  const result = sanitizeHTMLContainer.innerHTML;
  sanitizeHTMLContainer.textContent = '';
  return result;
}

/**
 * The class that calls the specified function at most once in the specified amount of time.
 * Only the last event is kept. Every call resets the timer
 */
export class Debouncer<T> {
  private _currentTimer: number | null = null;

  private _time: number;
  private _executor: (arg: T) => void;

  /**
   * The constructor
   *
   * @param time The number of milliseconds to wait before triggering the executor function
   * @param executor The action to be executed
   */
  constructor (time: number, executor: (arg: T) => void) {
    this._time = time;
    this._executor = executor;
  }

  /**
   * Queues an execution with the specified argument.
   * If no other call are made within `_time` milliseconds,
   * the executor will be run with the specified argument
   *
   * @param arg The argument to pass to the executor function
   */
  public run (arg: T): void {
    if (this._currentTimer !== null) {
      window.clearTimeout(this._currentTimer);
    }
    this._currentTimer = window.setTimeout(() => {
      this._currentTimer = null;
      this._executor(arg);
    }, this._time);
  }
}

/**
 * loads an image asynchronously
 *
 * @param src source url of image to load
 * @returns the image loaded (as an HTML image element)
 */
export async function loadImage (src: string): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    image.onload = () => resolve(image);
    image.onerror = () => reject(new Error('could not load image'));
  });
}

/**
 * returns a text indicating a duration in hours and minutes
 *
 * @param duration la durée en minutes
 * @returns la durée en texte "H h MM mn"
 */
export function durationToText (duration: number): string {
  if (duration === 0) {
    return '0';
  }
  let text = '';
  const hours = Math.floor(duration / 60);
  const minutes = Math.round(duration % 60);
  if (hours > 0) {
    text += hours + ' h';
  }
  if (minutes > 0) {
    text += text.length > 0 ? ' ' : '';
    text += minutes + ' mn';
  }
  return text;
}

/**
 * returns a text indicating a period from date to date
 *
 * @param start start period date
 * @param stop stop period date
 * @returns a formatted text like "from <deb> to <end>"
 */
export function periodToText (start: LocalDate, stop: LocalDate): string {
  // warning : start and stop are JS-JODA dates
  // i18n translations are in global files locales/<localeString>.json
  return [
    i18n.t('dateFormat.from'),
    start.format(DateTimeFormatter.ofPattern(i18n.t('dateFormat.jodaPattern').toString())),
    i18n.t('dateFormat.to'),
    stop.format(DateTimeFormatter.ofPattern(i18n.t('dateFormat.jodaPattern').toString()))
  ].join(' ');
}

/**
 * Deduplicate array values
 *
 * @param input list with potentially duplicate data
 * @returns deduplicated values
 */
export function deduplicateArray<T> (input: T[]): T[] {
  return [...new Set<T>(input)];
}

/**
 * returns input text with firs letter in lower case
 *
 * @param text the text to transform
 * @returns text with firs letter in lower case
 */
export function firstLowerCase (text: string): string {
  if (text.length === 0) {
    return '';
  }
  return text[0].toLowerCase() + text.substring(1);
}

/**
 * returns input text with firs letter in upper case
 *
 * @param text the text to transform
 * @returns text with firs letter in upper case
 */
export function firstUpperCase (text: string): string {
  if (text.length === 0) {
    return '';
  }
  return text[0].toUpperCase() + text.substring(1);
}

/**
 * truncates a too long text (like an elipsis function)
 *
 * @param text the text to truncate (if more long than "max")
 * @param max max length to display (truncate if text is too long)
 * @returns truncated text
 */
export function truncate (text: string, max: number): string {
  if (text.length <= max) {
    return text;
  }
  return text.substring(0, max - 3) + '…';
}
