import {
  CreateOrUpdateTrainingModuleRequest,
  EntityCreatedModel,
  LifetimeEvent,
  QueryRequest,
  // eslint-disable-next-line camelcase
  QueryResponse_TrainingModuleModel_,
  TrainingModuleItemModel,
  TrainingModuleModel,
  TrainingModuleType,
  TrainingModuleUsageStats
} from '../../_GeneratedClients/SpotClient';
import moment from 'moment';
import { client } from './Client';

/**
 * creates an empty training module
 *
 * @returns an empty training module object
 */
export function getEmptyTrainingModule (): TrainingModuleModel {
  return {
    id: '',
    name: '',
    moduleType: TrainingModuleType.Other,
    reference: undefined,
    goals: '',
    enabled: true,
    estimatedDuration: 0,
    creation: { userId: '', userName: '', date: moment() } as LifetimeEvent,
    periodicity: 0,
    passThreshold: 10,
    moduleItems: [] as TrainingModuleItemModel[]
  };
}

/**
 * Transforms module datas from API format to front format
 * - transforms dates from iso string to moment
 * - add reference as undefined if necessary
 *
 * @param module the object where to transform dates
 * @returns mediation object with dates as moment instead of iso strings
 */
function transformApiModule (module: TrainingModuleModel): TrainingModuleModel {
  if (!('reference' in module)) {
    module.reference = undefined; // Avoids reactivity issues. the property exists but is not defined
  }
  module.creation.date = moment(module.creation.date);
  return module;
}

/**
 * gets training module list from api
 *
 * @param query query parameters
 * @returns a promise, the list of training modules according to query parameters
 */
// eslint-disable-next-line camelcase
export async function queryTrainingModules (query: QueryRequest): Promise<QueryResponse_TrainingModuleModel_> {
  const modules = await client.queryTrainingModules(query);
  return {
    results: modules.results.map((m) => transformApiModule(m)),
    totalItems: modules.totalItems
  };
}

/**
 * gets a list of enabled training modules from API
 *
 * @returns a promise, the list of the enabled training modules
 */
export async function getEnabledTrainingModules (): Promise<TrainingModuleModel[]> {
  const modules = (await queryTrainingModules({ query: 'enabled equals true' })).results;
  return modules.map((m) => transformApiModule(m));
}

/**
 * Gets a training module object from API
 *
 * @param moduleId the identifier of the training module to obtain
 * @returns a promise, the training module object asked
 */
export async function getTrainingModuleById (moduleId: string): Promise<TrainingModuleModel> {
  const module = await client.getTrainingModuleById(moduleId);
  return transformApiModule(module);
}

/**
 *Asks API to save a new trainingModule into database
 *
 * @param toSave elements to save
 * @returns a promise, a result containing the identifier of the created object
 */
export async function createTrainingModule (toSave: CreateOrUpdateTrainingModuleRequest): Promise<EntityCreatedModel> {
  return await client.createTrainingModule(toSave);
}

/**
 * Asks API to update a training module
 *
 * @param trainingModuleId the identifier of the training module to update
 * @param toSave elements to save/update
 */
export async function updateTrainingModule (trainingModuleId: string, toSave: CreateOrUpdateTrainingModuleRequest): Promise<void> {
  await client.updateTrainingModule(trainingModuleId, toSave);
}

/**
 * Asks API to enable or disable a training module
 *
 * @param trainingModuleId the identifier of the training module to update
 * @param enabled value to set (true/false)
 * @returns a promise, returns nothing
 */
export async function setTrainingModuleEnabled (trainingModuleId: string, enabled: boolean): Promise<void> {
  return await client.setTrainingModuleEnabled(trainingModuleId, { enabled: enabled });
}

/**
 * Asks API to return module usage (in training paths)
 *
 * @param moduleId the identifier of the module to scan
 * @returns a promise, the module usage statistics
 */
export async function getTrainingModuleUsageStats (moduleId: string): Promise<TrainingModuleUsageStats> {
  return await client.getTrainingModuleUsageStats(moduleId);
}

/**
 * Asks API to delete a training module
 *
 * @param trainingModuleId the identifier of the training module to delete
 */
export async function deleteTrainingModule (trainingModuleId: string): Promise<void> {
  await client.deleteTrainingModule(trainingModuleId);
}
