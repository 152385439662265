
















import Vue, { PropType } from 'vue';
import { QualificationDashboardGroup } from '../../repository';
import DisplayPrimaryQualificationElement from './DisplayPrimaryQualificationElement.vue';
import DisplaySecondaryQualificationElement from './DisplaySecondaryQualificationElement.vue';

export default Vue.extend({

  name: 'DisplayQualificationElement',

  components: {
    DisplayPrimaryQualificationElement,
    DisplaySecondaryQualificationElement
  },

  props: {
    loading: { type: Boolean, default: false },
    elements: { type: Array as PropType<QualificationDashboardGroup[]>, required: true }
  }
});

